import { Suspense } from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import { theme } from 'theme/theme';

const ICON_SIZE = 24;

const PreLoader = (): JSX.Element => {
  // Preload icons
  return (
    <Suspense fallback={null}>
      <div hidden>
        <SvgIcon
          name="check_circle_fill"
          size={ICON_SIZE}
          color={theme.colors.green_700}
        />
        <SvgIcon
          name="info_fill"
          size={ICON_SIZE}
          color={theme.colors.blue_300}
        />
        <SvgIcon
          name="warning_fill"
          size={ICON_SIZE}
          color={theme.colors.orange_550}
        />
        <SvgIcon
          name="error_fill"
          size={ICON_SIZE}
          color={theme.colors.red_500}
        />
        <SvgIcon
          name="error_fill"
          size={ICON_SIZE}
          color={theme.colors.red_500}
        />
        <SvgIcon
          name="close"
          size={ICON_SIZE}
          color={theme.colors.grayscale_600}
        />
        <SvgIcon
          name="wifi"
          size={ICON_SIZE}
          color={theme.colors.grayscale_600}
        />
        <SvgIcon
          name="wifi_off"
          size={ICON_SIZE}
          color={theme.colors.grayscale_600}
        />
        <SvgIcon
          name="incoming_color"
          size={30}
          color={theme.colors.green_700}
        />
        <SvgIcon name="phone" size={30} color={theme.colors.green_700} />
        <SvgIcon
          name="off_hook_color"
          size={30}
          color={theme.colors.green_700}
        />
        <SvgIcon
          name="calling_color"
          size={30}
          color={theme.colors.green_700}
        />
        <SvgIcon name="search" size={30} color={theme.colors.green_700} />
        <SvgIcon
          name="DEFAULT_LOADING"
          size={30}
          color={theme.colors.green_700}
        />
        <SvgIcon name="close" size={30} color={theme.colors.green_700} />
        <img
          alt="server maintaining"
          src="https://cdn.proxy.simplifi.io/public/server_maintain.gif"
        />
      </div>
    </Suspense>
  );
};
export default PreLoader;
