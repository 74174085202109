import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiPost } from 'apis/baseAPI';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import { parseError } from 'utils/strings';
import { isLoggedIn } from 'utils/token';
import { ApiError } from 'models/Errors';
import { OrgRolePair } from 'modules/UserInvite/components/OrgRoleSelect';
import { _translate } from 'translate/TranslateProvider';
import notification from 'modules/notification';

const name = 'inviteUser';

type State = StateType<unknown>;

export type UserInviteRequest = {
  email: string;
  org_role_list: OrgRolePair[];
};

const initialState: State = {
  data: null,
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const inviteUser = createAsyncThunk(
  `${name}/inviteUser`,
  async (data: UserInviteRequest, { rejectWithValue }) => {
    const validData = (data: OrgRolePair) => data.org_id && data.role_name;

    try {
      const validOrgRole = data.org_role_list.filter(validData);
      await apiPost({
        url: 'portal/user/invite',
        apiVersion: 'v2',
        data: { ...data, org_role_list: validOrgRole },
        reduxActionName: `${name}/inviteUser`,
      });
      notification.success(
        _translate('USER_INVITE.SUCCESS.MESSAGE', { email: data.email }),
      );

      return;
    } catch (e) {
      const error = parseError(e as ApiError);
      return rejectWithValue(error);
    }
  },
);

const resetState = (state: State) => {
  state.error = '';
  state.errorId = '';
  state.errorCode = '';
  state.status = THUNK_STATUS.IDLE;
  state.data = initialState.data;
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state: State) => resetState(state),
  },
  extraReducers: (builder) => {
    builder
      .addCase(inviteUser.pending, (state) => {
        state.status = THUNK_STATUS.LOADING;
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
      })
      .addCase(inviteUser.fulfilled, (state) => {
        state.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(inviteUser.rejected, (state, action) => {
        if (!isLoggedIn()) {
          return resetState(state);
        }
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `invite_user.${code}`;
        }
      });
  },
});

const selector = (state: AppState) => state[name];

export const { reset } = slice.actions;

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export default slice.reducer;
