import { useEffect } from 'react';
import styled from 'styled-components';
import { Layout, LayoutProps } from 'antd';
import { useHistory } from 'react-router-dom';

import { state as internetConnectionState } from 'data/internet/status';

const StyledMainContent = styled(Layout)`
  overflow: hidden;
  background: ${(p) => p.theme.colors.grayscale_50};
`;

const MainContent = (props: LayoutProps): JSX.Element => {
  const history = useHistory();
  useEffect(() => {
    const isOnline = internetConnectionState.getState().isOnline;
    if (!isOnline) {
      const chunkLoadError = Error('ChunkLoadError');
      chunkLoadError.name = 'ChunkLoadError';
      throw chunkLoadError;
    }
  }, [history.location.pathname]);
  return <StyledMainContent {...props} />;
};

export default MainContent;
