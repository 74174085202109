import { createSelector, createSlice } from '@reduxjs/toolkit';

import { PHONE_STATE } from 'constants/POT';
import { AppState } from 'store/rootReducer';

const name = 'callQueue';

export type CallState = 'alerting' | 'talking' | 'outgoing' | 'end';

export type CallQueue = {
  id: string;
  state: CallState;
  number: string;
  outgoing: boolean;
  since: number;
  ts: number;
};

type State = {
  phoneState: {
    since: number;
    ts: number;
    state: number;
    call_duration: number;
  };
  callQueue: CallQueue;
};

const initialState: State = {
  callQueue: {
    id: '',
    number: '',
    outgoing: false,
    state: 'end',
    since: 0,
    ts: 0,
  },
  phoneState: {
    since: 0,
    ts: 0,
    state: 0,
    call_duration: 0,
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state) {
      state.phoneState = initialState.phoneState;
      state.callQueue = initialState.callQueue;
    },
    setPhoneState(state, action) {
      state.phoneState = action.payload;

      // reset call queue
      if (
        [
          PHONE_STATE.LABEL_KEY.UNINITIALIZED,
          PHONE_STATE.LABEL_KEY.STANDBY,
        ].includes(action.payload.state)
      ) {
        state.callQueue = initialState.callQueue;
      }
    },
    setCallQueue(state, action) {
      // do not update call queue if current data is: Ringing, Calling, InCall and receive new queue id
      const { id } = action.payload;
      if (
        [
          PHONE_STATE.LABEL_KEY.CALLING,
          PHONE_STATE.LABEL_KEY.INCALL,
          PHONE_STATE.LABEL_KEY.RINGING,
        ].includes(state.phoneState.state) &&
        state.callQueue.id !== id &&
        state.callQueue.id
      ) {
        return;
      }
      state.callQueue = action.payload;
    },
    setQueue(state, action) {
      const { call_queue, phone_state, ts, current_time } = action.payload;
      state.callQueue = call_queue;
      state.phoneState = {
        state: phone_state,
        ts,
        since: 0,
        call_duration: current_time - ts,
      };
    },
  },
});

export const { reset, setPhoneState, setCallQueue, setQueue } = slice.actions;

const selector = (state: AppState) => state[name];

export const selectPhoneQueue = createSelector(
  selector,
  (state: State) => state.callQueue,
);
export const selectPhoneState = createSelector(
  selector,
  (state: State) => state.phoneState,
);

export default slice.reducer;
