import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { StateType } from 'models/Store';
import { Device } from 'models/Device';
import { apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import notification from 'modules/notification';
import { ApiError } from 'models/Errors';
import { _translate } from 'translate/TranslateProvider';

const name = 'unblockDevice';

type RequestData = {
  routerId: string;
  devices: Device[];
};

type State = StateType<{
  blockedDevices: Device[];
}>;

const initialState: State = {
  data: {
    blockedDevices: [],
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const unblockClientDevice = createAsyncThunk(
  name,
  async (_data: RequestData, { rejectWithValue }) => {
    try {
      const { routerId, devices } = _data;
      const data = await apiPost<{ data: { blocked_devices: Device[] } }>({
        diffDomain: `${PROXY_DOMAIN}/api`,
        url: `router/rpc/unblock_client_device/${routerId}`,
        apiVersion: 'v2',
        data: {
          devices,
        },
        reduxActionName: name,
      });
      notification.success(
        _translate(
          'ROUTER_DETAIL.ADVANCED_INFO.CONNECTED_DEVICES.TOAST.UNBLOCKSUCCESSMESSAGE',
        ),
      );
      return data;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [unblockClientDevice.pending as never]: (state: State) => {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [unblockClientDevice.fulfilled as never]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data.blockedDevices = action.payload.data.blocked_devices;
    },
    [unblockClientDevice.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      if (!action.payload) {
        state.error = 'MESSAGE.UNKNOWN_ERROR';
      } else {
        const {
          code,
          id,
          message,
        } = action.payload as ApiError['response']['data']['error'];
        state.error = message.split(':')[0];
        state.errorId = id;
        state.errorCode = code;
      }
    },
  },
});

const selector = (state: AppState) => state.router[name];

export const unblockClientDeviceLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const unblockClientDeviceSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const unblockClientDeviceFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const unblockClientDeviceError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const { reset } = slice.actions;

export default slice.reducer;
