import {
  createAsyncThunk,
  createSelector,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit';

import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { apiGet } from 'apis/baseAPI';
import {
  Router,
  RouterLocation,
  RouterAlarm,
  RouterDetail,
  RouterHeaderInfo,
  RouterInfo,
  ExtraInfos,
  Attribute,
  PhoneInfo,
  ServicePermission,
} from 'models/Router';
import { PROXY_DOMAIN } from 'constants/configs';
import { Organization } from 'models/Organization';
import { ApiError } from 'models/Errors';
import { setValue as setLastCommunication } from 'page/private/RouterDetail/components/BasicInformation/data/lastCommunicate';
import { getSafe } from 'utils/getSafe';
import { basicInformation } from 'page/private/RouterDetail/components/BasicInformation/data/basicInformation';
import { useIsAllowRouterServiceSubscription } from 'hooks/useCheckSubscriptionService';

type State = StateType<{
  router: RouterDetail & { id: string; vid: string };
}>;

const name = 'routerDetail';
const initialState: State = {
  data: {
    router: {
      id: '',
      vid: '',
      routerInfo: {} as RouterInfo,
      routerHeaderInfo: {} as RouterHeaderInfo,
      routerAlarm: {} as RouterAlarm,
      routerLocation: {} as RouterLocation,
      organizations: {} as Organization,
      phoneInfo: {} as PhoneInfo,
      managers: [],
      extraInfos: {} as ExtraInfos,
      readonly: true,
      attribute: {} as Attribute,
      emailAlertEnabled: false,
      emailAlertRouterStatusChangeEnabled: false,
      address: '',
      cellBand: '',
      servicePermissions: [] as ServicePermission[],
      subscriptionAgreement: [],
    },
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

type RouterResponse = {
  data: Router;
  meta: { current_time: number };
};

const DEFAULT_ALARM = {
  since: 0,
  ts: 0,
  state: undefined,
};

export const getRouter = createAsyncThunk(
  `${name}/getRouter`,
  async (_routerId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data, meta } = await apiGet<RouterResponse>({
        url: `router/detail/${_routerId}`,
        apiVersion: 'v2',
        diffDomain: `${PROXY_DOMAIN}/api`,
        reduxActionName: `${name}/getRouter`,
      });

      const { active_call } = data;
      dispatch({
        type: 'callQueue/setQueue',
        payload: { ...active_call, current_time: meta.current_time },
      });

      // set last communication
      setLastCommunication(data.last_communication);

      basicInformation.setState({
        gid: data.gid,
        readonly: data.readonly,
        firmware_version_number: data.firmware_version_number,
        firmware_version: getSafe(
          () =>
            data.attrs.firmware_version ||
            ({
              since: 0,
              ts: 0,
              value: undefined,
            } as Router['attrs']['firmware_version']),
        ),
        min_disconnect_timer: data.min_disconnect_timer,
        status: {
          value: data.status,
          ts: data.status_ts,
        },
        id: data.id,
        active_intf: getSafe(
          () => data.alarms.active_intf || DEFAULT_ALARM,
          DEFAULT_ALARM,
        ),
        failover: getSafe(
          () => data.alarms.failover || DEFAULT_ALARM,
          DEFAULT_ALARM,
        ),
        wwan_status: getSafe(
          () => data.alarms.wwan_status || DEFAULT_ALARM,
          DEFAULT_ALARM,
        ),
        serial_number: data.serial_number,
      });

      return data;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
    setLineStatus(state, action) {
      state.data.router.phoneInfo.line_status = action.payload;
    },
    setCallStatus(state, action) {
      state.data.router.phoneInfo.call_status = action.payload;
    },
    setPhoneName(state, action) {
      state.data.router.phoneInfo.phone_name = action.payload;
    },
    setPhoneNumber(state, action) {
      state.data.router.phoneInfo.phone_number = action.payload;
    },
    setPhoneModel(state, action) {
      state.data.router.phoneInfo.phone_model = action.payload;
    },
    setPhoneFirmware(state, action) {
      state.data.router.phoneInfo.phone_firmware_version = action.payload;
    },
    setFailOver(s, action: PayloadAction<RouterAlarm['failover']>) {
      const { state } = action.payload;
      if (s.data.router.routerAlarm.failover.state === state) {
        return;
      }
      s.data.router.routerAlarm.failover = action.payload;
    },
    setActiveIntf(s, action: PayloadAction<RouterAlarm['active_intf']>) {
      const { state } = action.payload;
      if (s.data.router.routerAlarm.active_intf.state === state) {
        return;
      }
      s.data.router.routerAlarm.active_intf = action.payload;
    },
    setWwanStatus(s, action: PayloadAction<RouterAlarm['wwan_status']>) {
      const { state } = action.payload;
      if (s.data.router.routerAlarm.wwan_status.state === state) {
        return;
      }
      s.data.router.routerAlarm.wwan_status = action.payload;
    },
    setRouterStatus(state, action) {
      state.data.router.routerInfo.status = action.payload;
    },
    setGID(state, action) {
      state.data.router.routerInfo.gid = action.payload;
    },
    setKAT(state, action) {
      state.data.router.routerInfo.keep_alive_time = action.payload;
    },
    setMDT(state, action) {
      state.data.router.routerInfo.min_disconnect_timer = action.payload;
    },
    setAlarm(state, action) {
      state.data.router.routerAlarm = action.payload;
    },
    setPhoneInfo(state, action) {
      state.data.router.phoneInfo = action.payload;
    },
    setManagers(state, action) {
      state.data.router.managers = action.payload;
    },
    setRouterLocation(state, action) {
      state.data.router.routerLocation = action.payload;
    },
    setLastCommunication(state, action) {
      state.data.router.routerInfo.last_communication = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRouter.pending, (state: State) => {
        state.error = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(getRouter.fulfilled, (state: State, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        const data = action.payload;

        const prevRegtoken = current(state).data.router.routerInfo?.regtoken;
        const routerHeaderInfo: RouterHeaderInfo = {
          imei: data.imei,
          created_date: data.created_date,
          created_by: data.created_by,
          name: data.name,
        };
        const routerInfo: RouterInfo = {
          routerId: data.id,
          def_name: data.def_name,
          ssid_name: data.attrs.ssid_name?.value || '',
          status: data.status,
          firmware_version: data.attrs.firmware_version?.value || '',
          regtoken: data.attrs.regtoken?.value || '',
          gid: data.attrs.gid.value || '',
          hostName: data.host_name,
          readonly: data.readonly,
          firmware_version_number: data.firmware_version_number,
          keep_alive_time: data.kat,
          min_disconnect_timer: data.min_disconnect_timer,
          has_org_admin_role: data.has_org_admin_role,
          last_communication: data.last_communication,
          serial_number: data.serial_number,
        };

        const { active_call } = data;

        const phoneInfo: PhoneInfo = {
          phone_name: data.phone_name,
          phone_number: data.phone_number,
          phone_firmware_version: data.phone_firmware_version,
          phone_model: data.phone_model,
          call_status: active_call?.phone_state,
          line_status: data.line_status,
          phone_program_dial: {
            number: data.phone_program_dial?.Number ?? '',
            status: data.phone_program_dial?.Status ?? '',
          },
        };
        if (prevRegtoken) {
          routerInfo.prevRegtoken = prevRegtoken;
        }
        const routerAlarm: RouterAlarm = data.alarms;

        state.data.router = {
          id: data.id,
          vid: data.vid || '',
          routerInfo,
          routerHeaderInfo,
          routerAlarm,
          managers: data.managers,
          routerLocation: data.loc || {},
          organizations: data.organizations,
          phoneInfo,
          extraInfos: data.extra_infos,
          readonly: data.readonly,
          attribute: data.attrs,
          emailAlertEnabled: data.email_alert_enabled,
          emailAlertRouterStatusChangeEnabled:
            data.email_alert_router_status_change_enabled,
          address: data.address,
          cellBand: data.cell_band,
          servicePermissions: data.service_permissions,
          subscriptionAgreement: data.subscription_agreements,
        };
      })
      .addCase(getRouter.rejected, (state: State, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `router_detail.${code}`;
        }
      });
    // .addCase(routerUpdateOrg.fulfilled, (data: State, action) => {
    //   data.data.router.organizations = action.payload.data.organizations;
    // });
  },
});

export const {
  reset,
  setLineStatus,
  setCallStatus,
  setPhoneFirmware,
  setPhoneModel,
  setPhoneName,
  setPhoneNumber,
  setGID,
  setKAT,
  setMDT,
  setAlarm,
  setPhoneInfo,
  setManagers,
  setRouterLocation,
} = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectRouterSubscriptions = createSelector(
  selector,
  (s: State) => s.data.router.subscriptionAgreement || [],
);

export const selectHasSubscriptionAgreement = createSelector(
  selector,
  (s: State) => (s.data.router.subscriptionAgreement || []).length > 0,
);

export const selectRouterDetail = createSelector(
  selector,
  (state: State) => state.data.router,
);
export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);
export const selectFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);
export const selectLoaded = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectRouterImei = createSelector(
  selector,
  (state: State) => state.data.router.routerHeaderInfo.imei,
);

export const selectRouterFirmware = createSelector(
  selector,
  (s: State) => s.data.router.routerInfo.firmware_version,
);

export const selectRouterFirmwareVersionNumber = createSelector(
  selector,
  (s: State) => s.data.router.routerInfo.firmware_version_number,
);

export const selectPhoneCallStatus = createSelector(
  selector,
  (state: State) => state.data.router.phoneInfo.call_status,
);

export const selectPhoneNumber = createSelector(
  selector,
  (state: State) => state.data.router.phoneInfo.phone_number,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const selectAddress = createSelector(selectRouterDetail, (router) => {
  return router.routerLocation.address_formatter;
});

export const selectLocation = createSelector(
  selectRouterDetail,
  (r) => r.routerLocation,
);

export const selectIsReadOnly = createSelector(
  selectRouterDetail,
  (s) => s.readonly,
);

export const selectManagers = createSelector(
  selectRouterDetail,
  (s) => s.managers,
);

export const selectOrganizationInfo = createSelector(
  selectRouterDetail,
  (s) => ({
    org: s.organizations,
    readOnly: !s.routerInfo.has_org_admin_role,
  }),
);

export const selectRouterService = createSelector(selectRouterDetail, (s) => ({
  isAllowRouterService: useIsAllowRouterServiceSubscription(
    s.servicePermissions,
  ),
}));

export default slice.reducer;
