import { QueryFunction, useQuery } from 'react-query';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { API_QUERY_KEY } from 'apis/ApiQueryKey';
import { PERSIST_KEY } from 'data/keys';
import { getAppConfig } from 'apis/appConfig/getAppConfig';
import { registerAutoResetPersist } from 'data/reseter';
import { NotificationBannerConfig } from '../index';

type Req = {
  type: string;
  name: string;
};

export type AppConfigs = {
  id: string;
  type: string;
  name: string;
  value: string;
  desc: string;
  created_date: number;
  modified_date: number;
};

const useGetAppNotificationBanner = (
  req: Req,
  onSuccess?: (_d: AppConfigs) => void,
) => {
  return useQuery({
    queryKey: [API_QUERY_KEY.APPCONFIG.GET_CONFIG_ADMIN, req],
    queryFn: getConfig,
    refetchOnMount: true,
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export { useGetAppNotificationBanner };

const getConfig: QueryFunction<AppConfigs> = async ({
  queryKey: [_, config],
}) => {
  const { type, name } = config as Req;
  const rs = await getAppConfig({
    type: type,
    name: name,
  });
  return rs.data.configs[0];
};

type State = {
  config: NotificationBannerConfig;
};

const initialState: State = {
  config: {
    date: ['', ''],
    content: '',
    isShow: false,
  },
};

export const useAppConfigBanner = create<State>()(
  persist(() => initialState, {
    name: PERSIST_KEY.APP_CONFIG.NOTIFICATION_BANNER,
  }),
);
registerAutoResetPersist(useAppConfigBanner, initialState);

export const setConfigNotificationBanner = (c: NotificationBannerConfig) =>
  useAppConfigBanner.setState(() => ({
    config: c,
  }));

export const closeBanner = () =>
  useAppConfigBanner.setState(() => ({
    config: { ...initialState.config, isShow: false },
  }));
