import { css } from 'styled-components';
import { theme } from './theme';

export const switchStyle = css`
  .ant-switch {
    min-width: 37px;
    background-color: rgba(120, 120, 128, 0.16);
  }

  .ant-switch-checked {
    background-color: ${theme.colors.blue_500};
  }

  .ant-switch-handle {
    top: 1px;
    left: 1px;
    width: 19.5px;
    height: 19.5px;
  }
`;
