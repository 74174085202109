export interface BaseError {
  code: string;
  id: string;
  message: string;
}

export const ErrorEmpty: BaseError = {
  code: '',
  id: '',
  message: '',
};

interface ApiError {
  response: {
    data: {
      error: BaseError;
    };
  };
  message: string;
}

export type { ApiError };
