import { AppState } from 'store/rootReducer';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { StateType } from 'models/Store';
import { apiGet } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { LanConfig, LanWanStatus } from 'models/LanWanStatus';
import { ApiError } from 'models/Errors';
import { setValue } from 'page/private/RouterDetail/components/BasicInformation/data/lastCommunicate';
import { getSafe } from 'utils/getSafe';

const name = 'getLanWan';

type RequestData = {
  routerId: string;
};

type State = StateType<{
  lanStatus: LanWanStatus;
  wanStatus: LanWanStatus;
  guestStatus: LanWanStatus;
  cellularStatus: LanWanStatus;
  lanConfig: LanConfig;
}>;

const initStatus = {
  protocol: '',
  ip_address: '',
  mac_address: '',
  netmask: '',
  gateway: '',
  dns_list: [],
  connected_time: 0,
};

const initialState: State = {
  data: {
    lanStatus: initStatus,
    wanStatus: initStatus,
    guestStatus: initStatus,
    cellularStatus: initStatus,
    lanConfig: { ports: [''] },
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const getLanWan = createAsyncThunk(
  name,
  async (data: RequestData, { rejectWithValue }) => {
    try {
      const { routerId } = data;
      const rs = await apiGet<ServerResponse<State['data']>>({
        diffDomain: `${PROXY_DOMAIN}/api`,
        url: `router/rpc/lan_wan_status/${routerId}`,
        apiVersion: 'v2',
        reduxActionName: name,
      });

      setValue(rs.meta.last_communication);
      return rs;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorCode = '';
      state.errorId = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
    resetStatus(state: State) {
      state.error = '';
      state.errorCode = '';
      state.errorId = '';
      state.status = THUNK_STATUS.IDLE;
    },
  },
  extraReducers: {
    [getLanWan.pending as never]: (state) => {
      state.error = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [getLanWan.fulfilled as never]: (state, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data.wanStatus = action.payload.data.wan_status;
      state.data.lanStatus = action.payload.data.lan_status;
      state.data.guestStatus = action.payload.data.guest_status;
      state.data.cellularStatus = action.payload.data.cell_status;
      state.data.lanConfig = action.payload.data.lan_configuration;
    },
    [getLanWan.rejected as never]: (state, action) => {
      state.status = THUNK_STATUS.FAILED;
      if (!action.payload) {
        state.error = 'MESSAGE.UNKNOWN_ERROR';
      } else {
        const {
          code,
          id,
          message,
        } = action.payload as ApiError['response']['data']['error'];
        state.error = message;
        state.errorId = id;
        state.errorCode = `router_detail.${code}`;
      }
    },
  },
});

const selector = (state: AppState) => state.router[name];

export const selectLanStatus = createSelector(
  selector,
  (state: State) => state.data.lanStatus,
);

export const selectWanStatus = createSelector(
  selector,
  (state: State) => state.data.wanStatus,
);

export const selectGuestStatus = createSelector(
  selector,
  (state: State) => state.data.guestStatus,
);

export const selectCellularStatus = createSelector(
  selector,
  (state: State) => state.data.cellularStatus,
);

export const selectIsWanPort = createSelector(
  selector,
  (state: State) =>
    getSafe(() => state.data.lanConfig.ports[0], null) === 'eth0',
);

export const selectLanWanLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectLanWanSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectLanWanFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorCode, errorId }) => ({ error, errorCode, errorId }),
);

export const selectLanWanReady = createSelector(
  selector,
  (state: State) =>
    state.status === THUNK_STATUS.SUCCEEDED ||
    state.status === THUNK_STATUS.FAILED,
);

export const { reset, resetStatus } = slice.actions;

export default slice.reducer;
