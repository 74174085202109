import React, { FC } from 'react';
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import styled from 'styled-components';

import Block from 'components/atoms/Block';
import AppHeader from 'components/organism/AppHeader';
import { tokenStore } from 'data/auth/token';
import { theme } from 'theme/theme';

import InternetErrorBoundary from '../components/organism/InternetErrorBoundary';

type Props = {
  renderComponent: React.FC<RouteComponentProps>;
  restricted?: boolean;
} & RouteProps;

const StyledBlock = styled(Block)`
  background-color: ${theme.colors.grayscale_50};
  height: 100%;
`;

const PublicRoute: FC<Props> = ({
  renderComponent: Component,
  restricted = false,
  ...rest
}: Props) => {
  const hasToken = tokenStore((s) => Boolean(s.accessToken));

  if (hasToken && restricted) {
    return <Route render={() => <Redirect to="/dashboard" />} />;
  }

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <StyledBlock flex column>
      <AppHeader />
      <InternetErrorBoundary>
        <Route {...rest} component={Component} />
      </InternetErrorBoundary>
    </StyledBlock>
  );
};

export default PublicRoute;
