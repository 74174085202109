import { combineReducers } from '@reduxjs/toolkit';
import updateManagers from './updateManagers';
import listConnectedDevices from './listConnectedDevices';
import listBlockedDevices from './listBlockedDevices';
import blockDevice from './blockDevice';
import unblockDevice from './unblockDevice';
import wifiInfo from './wiFiInformation';
import wiFiChanel from './wiFiChanel';
import updateWiFiInformation from './updateWiFiInformation';
import routerDetail from './detail';
import getLanWan from './lanWan';
import getCellularStatus from './getCellularStatus';
import getCellularConfig from './getCellularConfig';
import updateCellular from './updateCellular';
import updateGuest from './updateGuest';
import updateLan from './updateLan';
import getLanConfig from './getLan';
import getWanConfig from './getWan';
import getGuestConfig from './getGuest';
import locationHistory from './locationHistory';
import updateOrganization from './updateOrganization';
import unbindRouter from './unbindRouter';
import callHistory from './callHistory';
import notificationEmail from './notificationEmail';
import updateLocation from './updateLocation';

export default combineReducers({
  routerDetail,
  updateManagers,
  listConnectedDevices,
  listBlockedDevices,
  blockDevice,
  unblockDevice,
  wifiInfo,
  wiFiChanel,
  updateWiFiInformation,
  getLanWan,
  getCellularStatus,
  getCellularConfig,
  updateCellular,
  updateLan,
  updateGuest,
  getLanConfig,
  getWanConfig,
  getGuestConfig,
  locationHistory,
  updateOrganization,
  notificationEmail,
  unbindRouter,
  callHistory,
  updateLocation,
});
