import { login } from 'apis/auth/login';
import { useMutation } from 'react-query';

import { setTokenStore } from 'data/auth/token';

type Req = Parameters<typeof login>[0];
type Response = UnwrapPromise<ReturnType<typeof login>>;

const useLogin = (cb?: Callback<Response>) => {
  const _action = useMutation((req: Req) => login(req), {
    onSuccess: (d) => {
      setTokenStore(transformToken(d));
      if (cb) {
        cb(d);
      }
      LoginManager.instance.setJustLoggedIn(true);
    },
  });
  return {
    login: _action.mutate,
    isError: _action.isError,
    isLoading: _action.isLoading,
  };
};

export { useLogin };

// util
const transformToken = ({
  data,
}: Response): Parameters<typeof setTokenStore>[0] => {
  return {
    accessToken: data.access_token || '',
    expiresIn: data.expires_in || 0,
    idToken: data.id_token || '',
    refreshToken: data.refresh_token || '',
    scope: data.scope || '',
    tokenType: data.token_type || '',
  };
};

export default class LoginManager {
  static instance: LoginManager;

  justLoggedIn = false;

  /**
   * @returns {LoginManager}
   */
  static getInstance() {
    if (!LoginManager.instance) {
      LoginManager.instance = new LoginManager();
    }

    return this.instance;
  }

  isJustLoggedIn() {
    return this.justLoggedIn;
  }

  setJustLoggedIn(_justLoggedIn: boolean) {
    this.justLoggedIn = _justLoggedIn;
  }
}
