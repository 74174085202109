import { createSelector, createSlice } from '@reduxjs/toolkit';
import { apiPost } from 'apis/baseAPI';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import { parseError } from 'utils/strings';
import { thunkWrapper } from 'utils/thunkWrapper';
import notification from 'modules/notification';
import { ApiError } from '../../../models/Errors';
import { _translate } from 'translate/TranslateProvider';

const name = 'resendInvitation';

type State = StateType<unknown>;

export type UserResendRequest = {
  request_id: string;
  email: string;
};

const initialState: State = {
  data: null,
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export const resendInvitationUser = thunkWrapper<UserResendRequest>({
  name: `${name}/resend`,
  withToken: true,
  action: async ({ headers, thunkApi, requestData }) => {
    {
      try {
        const { request_id, email } = requestData;
        await apiPost({
          url: 'portal/user/invitation/resend',
          apiVersion: 'v2',
          token: headers.token,
          data: {
            request_id,
          },
          reduxActionName: `${name}/resend`,
        });
        notification.success(
          _translate('PROFILE.TABLE.ROUTERS.ACTION.RESEND.TOAST.SUCCESS', {
            email,
          }),
        );
        return;
      } catch (e) {
        return thunkApi.rejectWithValue(parseError(e as ApiError));
      }
    }
  },
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [resendInvitationUser.pending as never]: (state: State) => {
      state.status = THUNK_STATUS.LOADING;
    },
    [resendInvitationUser.fulfilled as never]: (state: State) => {
      state.status = THUNK_STATUS.SUCCEEDED;
    },
    [resendInvitationUser.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      if (!action.payload) {
        state.error = 'MESSAGE.UNKNOWN_ERROR';
      } else {
        const {
          code,
          id,
          message,
        } = action.payload as ApiError['response']['data']['error'];
        state.error = message;
        state.errorId = id;
        state.errorCode = `resend_invitation.${code}`;
      }
    },
  },
});

const selector = (state: AppState) => state[name];

export const { reset } = slice.actions;

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({
    error,
    errorId,
    errorCode,
  }),
);

export default slice.reducer;
