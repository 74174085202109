import axios from 'axios';
import { defaultHeaders, env } from 'constants/configs';
import { TokenResponse } from './model';

const BASE_URL = `${env.REACT_APP_API_URL}/api`;

type Req = { refreshTokenKey: string; userId: string };

const refreshToken = ({
  refreshTokenKey,
  userId,
}: Req): Promise<ServerResponse<{ data: TokenResponse }>> => {
  const params = new URLSearchParams();
  params.append('refresh_token', refreshTokenKey);
  params.append('user_id', userId);
  params.append('client_id', env.REACT_APP_CLIENT_ID);
  params.append('grant_type', 'refresh_token');
  const config = {
    headers: {
      ...defaultHeaders,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return axios.post(`${BASE_URL}/v2/oauth/token`, params, config);
};

export { refreshToken };
