import { actions } from 'page/private/RouterDetail/components/PotSection/data/phoneLineHealthStatus';
import { getSafe } from 'utils/getSafe';
import { kase } from 'utils/kase';

import { MESSAGE_KEY } from '../config';
import { ServerTopic } from './types';
import { lastCommunicationHandler } from './lastCommunication';

const LC_EFFECT_TOPICS = {
  slic_batt_volt: true,
  slic_longi_current: true,
  slic_loop_resistance: true,
  slic_metallic_current: true,
  slic_tip_ring_volt: true,
  datausage: true,
  cell_rx_usage: true,
  rssi: true,
  cell_tx_usage: true,
  connected_devices: true,
  wan_datausage: true,
  wan_rx_usage: true,
  wan_tx_usage: true,
};

const propertyHandler = (t: string, m: Buffer) => {
  const serverPayload = JSON.parse(m.toString()) as ServerTopic;
  const payload = getSafe<string, string>(
    () => String(serverPayload.value),
    '',
  );
  const key = t.split('/').slice(-1)[0];

  // check to update last communication
  if (key in LC_EFFECT_TOPICS) {
    lastCommunicationHandler(t, m);
  }

  kase(key)
    .when(MESSAGE_KEY.SLIC_TIP_RING_VOLT, () => {
      actions.addTipRingVoltData(payload);
    })
    .when(MESSAGE_KEY.SLIC_LOOP_RESISTANCE, () => {
      actions.addResistanceData(payload);
    });
};

export { propertyHandler };
