import { CSSProperties } from 'styled-components';
import { theme } from 'theme/theme';

type Props = {
  width: string | number;
  height: string | number;
  css?: CSSProperties;
  borderRadius?: number;
};

const LineSkeleton = ({ height, width, css, borderRadius = 2 }: Props) => {
  return (
    <svg
      style={css}
      width={width}
      height={height}
      fill={theme.colors.grayscale_200}
      className="skeleton-animation"
    >
      <rect
        x="0"
        y="0"
        rx={borderRadius}
        ry={borderRadius}
        width={width}
        height={height}
      />
    </svg>
  );
};

export default LineSkeleton;
