import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';
import { validate } from 'validators';

import Block from 'components/atoms/Block';
import Text from 'components/atoms/Text';
import FormContainer from 'components/atoms/PublicPageFormContainer';
import Input from 'components/molecules/Input';
import FormItem from 'components/molecules/FormItem';
import Button from 'components/molecules/Button';
import AppIcon from 'components/atoms/SvgIcon';
import { useTranslate } from 'translate/useTranslate';
import Link from 'components/molecules/Link';
import { useMutation } from 'react-query';
import { forgotPassword } from 'apis/auth/forgotPassword';

const StyledTitle = styled(Text)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const StyledSubtitle = styled(Text)`
  text-align: center;
  width: 100%;
  overflow-wrap: break-word;
`;

const BackToLoginItem = styled(Block)`
  padding-top: 16px;
`;

const StyledForm = styled(Form)`
  margin-top: 28px;
  width: 100%;
`;

type FormType = {
  email: string;
};

const ForgotPassword = (): JSX.Element => {
  const { t } = useTranslate();
  const [form] = Form.useForm<FormType>();
  const [submittedEmail, setSubmittedEmail] = useState('');

  const { mutate: submitForgotPassword, isLoading: submitting } = useMutation(
    forgotPassword,
  );

  const submit = useCallback((values: unknown) => {
    submitForgotPassword(values as FormType, {
      onSuccess: () => {
        setSubmittedEmail((values as FormType).email);
      },
    });
  }, []);

  useEffect(() => {
    return () => {
      setSubmittedEmail('');
    };
  }, []);

  return (
    <Block flex center>
      <FormContainer column flex center>
        {!submittedEmail ? (
          <>
            <StyledTitle variant="h2" color="gray-900" bold>
              {t('FORGOT_PASSWORD.TITLE')}
            </StyledTitle>
            <StyledSubtitle variant="h5" color="gray-600">
              {t('FORGOT_PASSWORD.MESSAGE')}
            </StyledSubtitle>
            <StyledForm form={form} onFinish={submit}>
              <FormItem
                label="Email Address"
                name="email"
                rules={[
                  {
                    validator: (_, value) =>
                      validate((value || '').trim(), {
                        required: t('VALIDATION.FORGOT_PASSWORD.EMAIL_INVALID'),
                        isEmail: t('VALIDATION.FORGOT_PASSWORD.EMAIL_INVALID'),
                      }),
                  },
                ]}
              >
                <Input disabled={submitting} />
              </FormItem>
              <FormItem>
                <Button
                  variant="primary"
                  full
                  htmlType="submit"
                  loading={submitting}
                >
                  {t('BUTTON.SUBMIT')}
                </Button>
              </FormItem>
              <BackToLoginItem flex center>
                <Link to="/login" disabled={submitting}>
                  {t('FORGOT_PASSWORD.BTN.BACK')}
                </Link>
              </BackToLoginItem>
            </StyledForm>
          </>
        ) : (
          <>
            <AppIcon name="email_sent_color" size={45} />
            <StyledTitle variant="h2" color="gray-900" bold>
              {t('FORGOT_PASSWORD.SUCCESS.TITLE')}
            </StyledTitle>
            <StyledSubtitle variant="h5" color="gray-600">
              {t('FORGOT_PASSWORD.SUCCESS.MESSAGE', {
                submittedEmail,
              })}
            </StyledSubtitle>
          </>
        )}
      </FormContainer>
    </Block>
  );
};

export default ForgotPassword;
