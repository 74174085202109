import { AppState } from 'store/rootReducer';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { StateType } from 'models/Store';
import { apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { ApiError } from 'models/Errors';
import { ImportedRouterStatusCount } from 'models/SuperAdmin';
import axios from 'axios';

const name = 'uploadRouterFile';

type State = StateType<{
  fileId: string;
  orgId: string;
  countStatus: ImportedRouterStatusCount[];
}>;

const initialState: State = {
  data: {
    fileId: '',
    orgId: '',
    countStatus: [],
  },
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export const uploadRouterFile = createAsyncThunk(
  name,
  async ({ file }: { file: File }, { rejectWithValue, getState, signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const appState = getState() as AppState;
      const state = (appState['superAdmin'][name] as State).data;
      const postData = new FormData();
      postData.append('file', file);
      postData.append('org_id', state.orgId);
      const { data } = await apiPost({
        diffDomain: `${PROXY_DOMAIN}/api`,
        url: 'import_router/upload',
        apiVersion: 'v1',
        formData: postData,
        reduxActionName: name,
        cancelToken: source.token,
      });
      return data;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
    resetExceptOrg(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = { ...initialState.data, orgId: state.data.orgId };
    },
    setOrg(state: State, action) {
      state.data.orgId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadRouterFile.pending, (state) => {
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(uploadRouterFile.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data.fileId = action.payload.id;
        state.data.countStatus = action.payload.count_statuses;
      })
      .addCase(uploadRouterFile.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message.split(':')[0];
          state.errorId = id;
          state.errorCode = `upload_routers_file.${code}`;
        }
      });
  },
});

const selector = (state: AppState) => state.superAdmin[name];

export const selectCurrentOrg = createSelector(
  selector,
  (state: State) => state.data.orgId,
);

export const selectUploadedFileId = createSelector(
  selector,
  (state: State) => state.data.fileId,
);

export const selectCountStatus = createSelector(
  selector,
  (state: State) => state.data.countStatus,
);

export const uploadRouterFileLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const uploadRouterFileSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const uploadRouterFileFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const uploadRouterFileError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const selectOrg = createSelector(
  selector,
  (state: State) => state.data.orgId,
);

export const { reset, resetExceptOrg, setOrg } = slice.actions;

export default slice.reducer;
