import { persist } from 'zustand/middleware';
import create from 'zustand';

import { util } from 'data/utils';
import { PERSIST_KEY } from 'data/keys';
import { registerAutoResetPersist } from 'data/reseter';

import { ViewMode } from '../components/Header';

type State = {
  orgIds: string[];
  textSearch: string;
  captives: string[];
  selectedView: ViewMode;
  dateFilter: [number | null, number | null] | null;
  pagination: {
    page: number;
    size: number;
    total_elements: number;
    total_pages: number;
  };
  sort: {
    field: string;
    order: string;
  };
};

const getInitialState = (): State => {
  return {
    orgIds: [],
    textSearch: '',
    captives: [],
    selectedView: 'captive',
    dateFilter: null,
    pagination: {
      page: 1,
      size: 25,
      total_elements: 0,
      total_pages: 0,
    },
    sort: {
      field: 'login_time',
      order: '-1',
    },
  };
};

const initialState = getInitialState();

type Actions = {
  setPage: Callback<State['pagination']['page']>;
  setSize: Callback<State['pagination']['size']>;
  setTotalElements: Callback<State['pagination']['total_elements']>;
  setTotalPages: Callback<State['pagination']['total_pages']>;
  setField: Callback<State['sort']['field']>;
  setOrder: Callback<State['sort']['order']>;
};

export const userVisitFilter = create<State>()(
  persist(() => initialState, {
    name: PERSIST_KEY.CAPTIVE.USER_VISIT_FILTER,
  }),
);

registerAutoResetPersist(userVisitFilter, getInitialState());

export const reset = (date: State['dateFilter']) => {
  userVisitFilter.setState(() => initialState);
  actions.setDateFilter(date);
};

export const resetFilter = () => {
  userVisitFilter.setState((s) => ({
    ...initialState,
    textSearch: s.textSearch,
    dateFilter: s.dateFilter,
  }));
};

export const removeCaptiveFromFilter = (
  captiveId: ArrElement<State['captives']>,
) => {
  userVisitFilter.setState((s) => ({
    ...s,
    captives: s.captives.filter((c) => c !== captiveId),
  }));
};

// // use with util
const zustandUtil = util(userVisitFilter.setState, userVisitFilter.getState);

export const setPage = zustandUtil.updateState<Actions['setPage']>((s, v) => {
  s.pagination.page = v;
});

export const setSize = zustandUtil.updateState<Actions['setSize']>((s, v) => {
  s.pagination.size = v;
});

export const setTotalElements = zustandUtil.updateState<
  Actions['setTotalElements']
>((s, v) => {
  s.pagination.total_elements = v;
});

export const setTotalPages = zustandUtil.updateState<Actions['setTotalPages']>(
  (s, v) => {
    s.pagination.total_pages = v;
  },
);

export const setField = zustandUtil.updateState<Actions['setField']>((s, v) => {
  s.sort.field = v;
});

export const setOrder = zustandUtil.updateState<Actions['setOrder']>((s, v) => {
  s.sort.order = v;
});

export const actions = zustandUtil.generateSetter();
