import { css } from 'styled-components';
import { theme } from 'theme/theme';

export const customSteps = css`
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: ${theme.colors.blue_500};
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: ${theme.colors.blue_500};
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${theme.colors.grayscale_900};
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${theme.colors.grayscale_600};
  }

  .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
    color: ${theme.colors.grayscale_600};
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: ${theme.colors.grayscale_600};
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${theme.colors.blue_500};
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${theme.colors.blue_500};
  }

  /* circle hover state */
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon {
    border-color: ${theme.colors.blue_500};
  }

  /* check icon hover state */
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon
    .ant-steps-icon {
    color: ${theme.colors.blue_500};
  }

  /* title, subtitle, description hover state */
  .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-title,
  .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-subtitle,
  .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-description {
    color: ${theme.colors.blue_500};
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: ${theme.colors.blue_500};
  }
`;
