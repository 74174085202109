import { css } from 'styled-components';
import { theme } from 'theme/theme';

export const notificationStyle = css`
  .processing-toast {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    min-width: 220px !important;
    width: 220px;
  }

  .ant-notification-notice-message {
    margin-bottom: auto;
  }

  .ant-notification-notice.ant-notification-notice-closable {
    max-width: 348px;
    min-width: 282px;
    border-radius: 4px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 12px;
    line-height: 15px;
    z-index: ${theme.zIndex.toast};
  }

  .ant-notification-notice-content {
    width: 100%;
    .ant-notification-notice-message {
      margin-bottom: 0;
      .notification-header {
        align-items: start;
      }
    }
  }

  .notification-header {
    display: flex;
    align-items: start;
    svg {
      min-width: 24px;
    }
  }

  .notification-text {
    margin-left: 8px;
    flex: 1;
    width: 0;
  }

  .notification-header-text {
    font-size: 14px;
    font-family: 'Roboto';
    margin-left: 9.5px;
  }

  .ant-notification-notice-description {
    font-size: 13px;
    font-family: 'Roboto';
    margin-left: 23px;
  }

  .ant-notification-notice-close {
    top: 14px;
    right: 12px;
  }

  .ant-notification-close-icon > svg {
    width: 9px;
  }

  .notification-action {
    &&& {
      padding: 12px 8px;
    }

    .ant-notification-notice-message {
      display: none;
    }

    .ant-notification-notice-description {
      margin: 0;
    }
    .notification-action-container {
      display: flex;
      align-items: center;
      padding-right: 30px;
    }

    .notification-act-icon {
      display: flex;
      margin-right: 8px;
    }

    .notification-act-content {
      a {
        margin-left: 8px;
      }
    }

    .ant-notification-notice-close {
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
    }

    max-width: unset !important;
    width: fit-content;

    &.notification-no-internet.shake-notification {
      animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }
`;
