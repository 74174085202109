import { TinyMCE } from 'tinymce';

export const TINYMCE_APIKEY =
  'bq7nn3lb4a6qtykky97x399p630leb3pd6va9ewq97226u5f';

export const editorInitProps: Parameters<TinyMCE['init']>[0] & any = {
  content_style:
    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  image_caption: true,
  resize: false,
  quickbars_selection_toolbar:
    'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
  noneditable_noneditable_class: 'mceNonEditable',
  toolbar_mode: 'sliding',
  contextmenu: 'link image imagetools table',
  height: 500,
  plugins: [
    'preview',
    'importcss',
    'searchreplace',
    'autolink',
    'directionality',
    'code',
    'visualblocks',
    'visualchars',
    'fullscreen',
    'image',
    'link',
    'table',
    'charmap',
    'nonbreaking',
    'advlist',
    'lists',
    'wordcount',
    'charmap',
    'quickbars',
    'emoticons',
    'paste',
  ],
  imagetools_cors_hosts: ['picsum.photos'],
  toolbar_sticky: true,
  image_title: true,
  automatic_uploads: true,
  image_advtab: true,
  importcss_append: true,
  file_picker_types: 'image',
  menubar: false,
  convert_urls: true,
  relative_urls: false,
  remove_script_host: false,
  toolbar:
    'styles | bold italic underline strikethrough | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | charmap | image link | fullscreen  preview ',
};
