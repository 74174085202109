import { css } from 'styled-components';

export const CustomNotification = css`
  .notification-pot {
    padding: 12px;
    width: 270px;
    .ant-notification-notice-description {
      display: flex;
      margin-left: 0;
      align-items: center;
    }
    .ant-notification-notice-message {
      display: none;
    }
    .ant-notification-notice-content {
      display: flex;
    }
    .ant-notification-notice-close {
      top: 50%;
      transform: translateY(-50%);
    }
    .call-info {
      margin-left: 12px;
    }
  }
`;
