import { apiDelete } from 'apis/baseAPI';
import { parseError } from 'utils/strings';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';

import { ApiError } from '../../../models/Errors';
import notification from 'modules/notification';
import { _translate } from 'translate/TranslateProvider';

const name = 'deleteOrg';

type SuspendRequest = {
  org_id: string;
  name: string;
};

type State = StateType<SuspendRequest>;

const initialState: State = {
  data: {
    org_id: '',
    name: '',
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const deleteOrg = createAsyncThunk(
  name,
  async (data: { id: string; name: string }, { rejectWithValue }) => {
    try {
      const res = await apiDelete({
        url: 'portal/org/delete',
        apiVersion: 'v2',
        data: { org_id: data.id },
        reduxActionName: `${name}/delete`,
      });

      notification.success(
        _translate('ORG.DELETE.SUCCESS', { name: data.name }),
      );
      return res;
    } catch (err) {
      return rejectWithValue(parseError(err as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteOrg.pending, (state) => {
        state.status = THUNK_STATUS.LOADING;
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
      })
      .addCase(deleteOrg.fulfilled, (state) => {
        state.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(deleteOrg.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `delete_org.${code}`;
        }
      });
  },
});

const selector = (state: AppState) => state.organization[name];

export const selectLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectDeleted = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFailed = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const { reset } = slice.actions;

export default slice.reducer;
