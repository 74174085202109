import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { StateType } from 'models/Store';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { User } from 'models/User';
import { apiGet } from 'apis/baseAPI';
import { ApiError } from '../../../models/Errors';

type State = StateType<{
  user: User;
  prevUserId: string;
}>;

const name = 'userDetail';
const initialState: State = {
  data: {
    user: {} as User,
    prevUserId: '',
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.LOADING,
};

const whitelist: string[] = [];

export const getUser = createAsyncThunk(
  `${name}/getUser`,
  async (data: string, { rejectWithValue }) => {
    try {
      return await apiGet<{ data: User }>({
        url: `portal/user/profile/${encodeURIComponent(data)}`,
        apiVersion: 'v2',
        reduxActionName: `${name}/getUser`,
      });
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data.user = initialState.data.user;
    },
    setPrevUser(state, action) {
      state.data.prevUserId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data.user = action.payload.data;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `user_detail.${code}`;
        }
      });
  },
});

export const { reset, setPrevUser } = slice.actions;

const selector = (state: AppState) => state[name];

export const selectData = createSelector(selector, (state) => state.data.user);

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);
export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);
export const selectPrevUserId = createSelector(
  selector,
  (state: State) => state.data.prevUserId,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);
export default persistReducer({ key: name, storage, whitelist }, slice.reducer);
