import { IClientOptions, connect, MqttClient } from 'mqtt';

import { REACT_APP_MQTT_HOST, REACT_APP_MQTT_PORT } from 'constants/configs';
import { currentUserState } from 'data/auth/currentUser';
import { getAccessToken } from 'utils/token';
import RefreshToken from 'services/RefreshToken';
import { methodProcessHandler } from 'page/private/RouterDetail/components/Methods/MethodProcess';
import Publisher from 'services/Publisher';
import { kase } from 'utils/kase';

import { userTopicHandler } from './topicsHadler/user';
import { activeCallTopicHandler } from './topicsHadler/activeCall';
import { routerAlarmHandler } from './topicsHadler/routerAlarms';
import { callHistoryHandler } from './topicsHadler/callHistory';
import { attributeHandler } from './topicsHadler/routerAttributes';
import { routerConnectionHandler } from './topicsHadler/routerConnection';
import { propertyHandler } from './topicsHadler/routerPorperties';
import { QoS } from './config';

const CONNECTION_URL = `wss://${REACT_APP_MQTT_HOST}:${REACT_APP_MQTT_PORT}/mqtt`;

const MQTT_BASE_CONNECT_CONFIG: IClientOptions = {
  clean: true,
  connectTimeout: 10 * 1000, // milliseconds, time to wait before a connect is received
  keepalive: 1 * 90, // seconds, set 0 to disable
  protocolVersion: 4,
  reconnectPeriod: 0, // milliseconds, interval between two re-connections. Disable auto reconnect by setting to 0
  rejectUnauthorized: true,
  reschedulePings: false,
  resubscribe: true, // if connection is broken and reconnects, subscribed topics are automatically subscribed again (default true)
} as const;

const MQTT_ERR = {
  AUTH_ERR: 'Connection refused: Not authorized',
};

type Topic = string | string[];

class MQTTService extends Publisher {
  private static topics: Set<string> = new Set();
  private static client: MqttClient | null;
  private static clientId: string;
  private static firstInit = true;
  private static hasDestroyed = false; // use this for checking the MQTT was stop or offline
  static publisher = new Publisher();

  static init(userName: string, password: string, onConnected?: Noop) {
    if (MQTTService.client) {
      console.log('MQTT already connected');
      MQTTService.client = null;
    }

    MQTTService.clientId = `${userName}_${Math.random()
      .toString(16)
      .slice(2, 8)}`;

    const _config = {
      ...MQTT_BASE_CONNECT_CONFIG,
      clientId: MQTTService.clientId,
      username: userName,
      password,
    };

    // start connect then set client value
    MQTTService.client = connect(CONNECTION_URL, _config).on('connect', () => {
      if (MQTTService.firstInit) {
        onConnected && onConnected();
      }
      MQTTService.firstInit = false;
    });

    console.log(`connecting with clientId: ${MQTTService.clientId} `, _config);

    if (MQTTService.client) {
      MQTTService.startEvents();
    }
  }

  private static registerTopic(t: string | string[]) {
    if (Array.isArray(t)) {
      t.forEach(MQTTService.topics.add, MQTTService.topics);
    } else {
      MQTTService.topics.add(t);
    }
  }

  private static removeTopic(t: string | string[]) {
    if (Array.isArray(t)) {
      t.forEach(MQTTService.topics.delete, MQTTService.topics);
    } else {
      MQTTService.topics.delete(t);
    }
  }

  private static subscribeTopic = (t: Topic) => {
    MQTTService.client?.subscribe(
      t,
      {
        qos: QoS.AT_LEAST_ONE,
      },
      (err) => {
        if (err) {
          console.error(`Subscribe topic ${t} fail`, err);
          MQTTService.reNewInstant();
          return;
        }
        console.log('%c%s', 'color: #3AB0FF', `MQTT Subscribe to ${t}`);
      },
    );
  };

  static startListenTopic(t: string | string[]) {
    // save the topic here, so the application can re-subscribe when re-connect
    if (!t || !t.length) {
      return;
    }

    console.log(`start subscribe ${t}`);
    MQTTService.registerTopic(t);
    MQTTService.subscribeTopic(t);
  }

  static stopListenTopic(t: string | string[]) {
    // remove the topics, do not care about the subscribe method success or not
    MQTTService.removeTopic(t);

    MQTTService.client?.unsubscribe(t, () => {
      console.log('Unsubscribe from:', t);
    });
  }

  static destroy(cb?: any) {
    console.log('Start destroy MQTT client');

    this.hasDestroyed = true;

    if (!MQTTService.client) {
      cb && cb();
      return;
    }

    MQTTService.client.removeAllListeners();
    MQTTService.client.end(
      true,
      {
        reasonCode: 0,
        reasonString: `Shutting down MQTT Client`,
      },
      () => {
        console.log('Client end done');
      },
    );

    MQTTService.client = null;
    // MQTTService.publisher.destroy();
    cb && cb();
  }

  private static selfInit = () => {
    const id = currentUserState.getState().id;
    const _token = getAccessToken();
    console.log('Start re-init');
    MQTTService.init(id, _token, () => {
      console.log('Re-init MQTT done!!!');
    });
  };

  static reNewInstant = () => {
    console.log('Start Re-new MQTT');
    MQTTService.destroy(MQTTService.selfInit);
  };

  private static startEvents() {
    MQTTService.client
      ?.on('connect', (p) => {
        console.log('MQTT connect', p);

        // by default the MQTT lib is auto re-subscribe all topic, but in some case
        // like internet issue or the subscribe method is trigger before the connection created
        // the MQTT lib doesn't known what topics should be subscribe, so we should do it manually.
        MQTTService.startListenTopic(Array.from(MQTTService.topics));

        // check if this event fire from the MQTT is created.
        MQTTService.publisher.publish(MQTTService.hasDestroyed);
      })
      .on('reconnect', () => {
        console.log('MQTT reconnecting...');
      })
      .on('message', messageHandler)
      .on('offline', () => {
        console.log('MQTT offline -> Renew Instant');
        MQTTService.reNewInstant();
      })
      .on('close', () => {
        console.log('MQTT close -> Renew Instant');
        MQTTService.reNewInstant();
      })
      .on('end', () => {
        console.log('MQTT end');
      })
      .on('disconnect', () => {
        console.log('MQTT is disconnect, the lib should be auto reconnect!');
      })
      .on('error', (e) => {
        console.log('MQTT Error', e);

        if (e.message === MQTT_ERR.AUTH_ERR) {
          console.log('token is expired => try to get new token....');

          // destroy first, to prevent the spamming
          MQTTService.destroy();
          RefreshToken.tryRefresh()?.then(() => {
            console.log('MQTT ready to renew');
            MQTTService.selfInit();
          });
        }
      });
  }
}

export default MQTTService;

const topic_reg = {
  // should match with some topic with pattern: simplifi/60f008aee3535a006a5b3adb/router/866758040526465/simplifi/active_call
  routerOfUser: /v1\/simplifi\/\d+\w+\/router\S+/,
  activeCall: /v1\/thing\/\d+\/simplifi\/active_call/,
  historyCall: /thing\/\d+\/simplifi\/history_call/,
  alarms: /v1\/thing\/\d+\/alarm\/\S+/,
  attribute: /v1\/thing\/\d+\/attribute\/\S+/,
  connection: /v1\/thing\/\d+\/connection\/\S+/,
  property: /v1\/thing\/\d+\/property\/\S+/,
  method_reboot: /v1\/simplifi\/\d+\w+\/router\/\d+\/simplifi\/method_reboot/,
  method_factory_reset: /v1\/simplifi\/\d+\w+\/router\/\d+\/simplifi\/method_factory_reset/,
  method_fw_update: /v1\/simplifi\/\d+\w+\/router\/\d+\/simplifi\/method_fw_update/,
  method_fw_update_push: /v1\/simplifi\/\d+\w+\/router\/\d+\/simplifi\/method_fw_update_push/,
};

const is = (expr: RegExp) => (str: string): str is never => {
  return expr.test(str);
};

const isOneOf = (exprs: RegExp[]) => (t: string) => {
  return exprs.some((expr) => is(expr)(t));
};

export const messageHandler = (t: string, m: Buffer) => {
  console.log(`Receive msg from ${t}, content: ${m.toString()}`);

  import('store').then(wrapper(t, m));
};

type Handler = (_t: string, _m: Buffer, _s: any) => void;

const wrapper = (t: string, m: Buffer) => (_state: any) => {
  const run = (fn: Handler | Handler[]) => () => {
    if (typeof fn === 'function') {
      fn(t, m, _state.default);
    } else {
      fn.forEach((f) => f(t, m, _state.default));
    }
  };

  kase(t)
    .when(is(topic_reg.historyCall), run(callHistoryHandler))
    .when(is(topic_reg.activeCall), run(activeCallTopicHandler))
    .when(is(topic_reg.connection), run(routerConnectionHandler))
    .when(is(topic_reg.alarms), run(routerAlarmHandler))
    .when(is(topic_reg.attribute), run(attributeHandler))
    .when(is(topic_reg.property), run(propertyHandler))
    .when(
      isOneOf([
        topic_reg.method_reboot,
        topic_reg.method_fw_update,
        topic_reg.method_factory_reset,
        topic_reg.method_fw_update_push,
      ]),
      run(methodProcessHandler),
    )
    .when(is(topic_reg.routerOfUser), run(userTopicHandler)) // the router user topic should be check after the method handler
    .end();
};
