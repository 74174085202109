import { ComponentProps, FC } from 'react';
import { Avatar as AntdAvatar, AvatarProps } from 'antd';

import { createHslColor, createInitials } from 'utils/strings';
import styled, { CSSProp } from 'styled-components';
import AppIcon from '../../atoms/SvgIcon';
import Tooltip from 'components/atoms/Tooltip';
import TextOverFlow from '../TextOverFlow';
import { theme } from 'theme/theme';
import Icon from '../../atoms/SvgIcon';
import Block from 'components/atoms/Block';

import { _translate } from 'translate/TranslateProvider';

export type UserAvatarProps = {
  userName?: string;
  userId: string;
  src?: string;
  showUserName?: boolean;
  showTooltip?: boolean;
  size?: number;
  style?: CSSProp;
  userNameStyle?: CSSProp;
  userNameVariant?: ComponentProps<typeof TextOverFlow>['variant'];
  href?: ComponentProps<typeof TextOverFlow>['href'];
  children?: string | JSX.Element;
  color?: string;
  fontSize?: number;
  overflow?: boolean;
  isBlock?: boolean;
  alwaysShowTooltip?: boolean;
};

interface A extends Omit<AvatarProps, 'style'> {
  color?: string;
  css?: CSSProp;
  $fontSize?: number;
}

const Avatar = styled(AntdAvatar)<A>`
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.css};
  min-width: ${(props) => `${props.size}px`};
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  // fix blurry circle on firefox
  box-shadow: 0 0 1px 1px white;
  overflow: hidden;

  .ant-avatar-string {
    font-size: ${(props) => `${props.$fontSize}rem`};
  }
`;

const UsernameText = styled(TextOverFlow)`
  margin-bottom: 0;
  margin-left: 8px;
  font-size: 13px;
  line-height: 15px;
`;

const Container = styled.div<{ isBlock?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  opacity: ${(props) => (props.isBlock ? 0.5 : 1)};
`;

const StyledBlockIcon = styled(Icon)`
  left: 2px;
  position: absolute;
`;

const UserAvatar: FC<UserAvatarProps> = (props: UserAvatarProps) => {
  const {
    userId,
    userName,
    showUserName,
    showTooltip,
    src,
    size = 24,
    children,
    style,
    color,
    fontSize = 1.1,
    isBlock = false,
    userNameStyle,
    userNameVariant,
    href,
  } = props;
  const initials = createInitials(userName);
  const bgColor = !src ? color || createHslColor(`${userId}`) : '';

  if (children)
    return (
      <Avatar
        alt={_translate('WCAG.USER_AVATAR')}
        size={size}
        color={bgColor}
        css={style}
      >
        {children}
      </Avatar>
    );

  return (
    <Tooltip title={showTooltip && userName}>
      <Block flex alignItems="center" justifyContent="center">
        <Container isBlock={isBlock}>
          <a href={href}>
            {src ? (
              <Avatar
                alt={_translate('WCAG.USER_AVATAR')}
                size={size}
                src={src}
                color={bgColor}
                css={style}
              >
                {userName}
              </Avatar>
            ) : userName ? (
              <Avatar
                alt={_translate('WCAG.USER_AVATAR')}
                $fontSize={fontSize}
                size={size}
                color={bgColor}
                css={style}
              >
                {initials}
              </Avatar>
            ) : (
              <Avatar
                alt={_translate('WCAG.USER_AVATAR')}
                size={size}
                color={bgColor}
                icon={<AppIcon name="profile" size={18} />}
                css={style}
              />
            )}
          </a>
          {showUserName && userName && (
            <UsernameText
              href={href}
              variant={userNameVariant}
              css={userNameStyle}
              textContent={userName}
              width={220}
              alwaysShowTooltip
            />
          )}
          {isBlock && (
            <StyledBlockIcon
              name="block"
              size={size - 4}
              color={theme.colors.red_500}
            />
          )}
        </Container>
      </Block>
    </Tooltip>
  );
};

export default UserAvatar;
