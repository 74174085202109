import { css } from 'styled-components';
import { theme } from '../../../theme/theme';

export const commonStyles = css`
  border-radius: 4px;
  border: 1px solid ${theme.colors.grayscale_200};
  box-shadow: none;
  padding: 5px 8px; // padding 6px including border
  &:focus,
  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper-focused.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  &.ant-input:focus,
  &.ant-input-number:focus,
  &.ant-input-number-focused {
    box-shadow: none;
    border-color: ${theme.colors.blue_500};
  }
  &:hover:not(:focus, .ant-input-number-focused),
  &.ant-input:hover:not(:focus),
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-focused):hover {
    border: 1px solid ${theme.colors.grayscale_600};
  }
  &.ant-input,
  &.ant-input-affix-wrapper > input.ant-input {
    color: ${theme.colors.grayscale_900};
    &::placeholder {
      color: ${theme.colors.grayscale_600};
    }
    &[disabled] {
      color: ${theme.colors.grayscale_800};
      border-color: ${theme.colors.grayscale_200};
      background-color: ${theme.colors.grayscale_50};
      &:hover {
        border-color: ${theme.colors.grayscale_200};
      }
      &::placeholder {
        color: ${theme.colors.grayscale_200};
      }
    }
  }
`;
