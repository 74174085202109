import Block from '../../atoms/Block';
import Text from '../../atoms/Text';
import styled from 'styled-components';
import AppIcon from '../../atoms/SvgIcon';
import { theme } from 'theme/theme';

type Props = {
  isVisible?: boolean;
  errorMessage: string;
  className?: string;
};

const ErrorContainer = styled(Block)<{ isVisible?: boolean }>`
  ${(props) => ({
    display: props.isVisible ? 'flex' : 'none',
  })}
`;

const ErrorText = styled(Text)`
  color: ${(p) => p.theme.colors.red_500};
  margin-left: 8px;
`;

const FormErrorMessage = ({
  isVisible,
  errorMessage,
  className,
}: Props): JSX.Element | null => {
  return (
    <ErrorContainer className={className} isVisible={isVisible} flex center>
      <AppIcon name="error" size={20} color={theme.colors.red_500} />
      <ErrorText>{errorMessage}</ErrorText>
    </ErrorContainer>
  );
};

export default FormErrorMessage;
