/* eslint-disable @typescript-eslint/no-explicit-any */
import create from 'zustand';

type Store = ReturnType<typeof create>;
const resetSet = new Set<Noop>();

export const registerAutoResetPersist = (s: Store, _initState: any) => {
  const reset = () => s.setState(_initState, true);
  resetSet.add(reset);
};

export const resetAll = () => {
  resetSet.forEach((f) => f());
};
