import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store/rootReducer';

const name = 'potNotification';

export type State = {
  number: string;
  status: number;
  type: string;
  start: string;
  end: string;
  duration: number;
  prevStatus: number;
};

// type State = {
//   status: {
//     label: string;
//     value: number;
//   };
//   data: Record<string, PhoneMessage>;
// };

const initialState: State = {
  duration: 0,
  end: '',
  number: '',
  start: '',
  status: 0,
  type: '',
  prevStatus: 0,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state) {
      state.duration = initialState.duration;
      state.end = initialState.end;
      state.status = initialState.status;
      state.start = initialState.start;
      state.type = initialState.type;
      state.number = initialState.number;
    },
    setMessage(state, action) {
      const { number, status, end, start, type, duration } = action.payload;
      // if (data.status === status) {
      //   return;
      // }
      state.prevStatus = state.status;
      state.status = status;
      state.number = number;
      state.start = start;
      state.end = end;
      state.type = type;
      state.duration = duration;
    },
    setPhoneStatus(state, action) {
      if (action.payload === state.status) {
        return;
      }
      state.prevStatus = state.status;
      state.status = action.payload;
      // data = {
      //   ...data,
      //   status: action.payload,
      // };
    },
  },
});

export const { setMessage, setPhoneStatus, reset } = slice.actions;

const selector = (state: AppState) => state[name];

export const selectStatus = createSelector(
  selector,
  (state: State) => state.status,
);

export const selectMessage = createSelector(selector, (state: State) => state);
export default slice.reducer;
