import { Popover as AntdPopover, PopoverProps } from 'antd';
import styled from 'styled-components';

const StyledPopover = styled(AntdPopover)`
  /* width: 100%; */
  display: flex;
  cursor: pointer;
  line-height: 1;
`;

const Popover = (props: PopoverProps): JSX.Element => {
  return <StyledPopover {...props} />;
};

export default Popover;
