import { AppState } from 'store/rootReducer';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { StateType } from 'models/Store';
import { apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { ApiError } from 'models/Errors';
import {
  FileError,
  ImportedRouter,
  ImportedRouterStatus,
  ProcessedImportRouter,
} from 'models/SuperAdmin';
import { Pagination } from 'models/Table';
import { uuidV4 } from 'utils/uuid';

import { _translate } from 'translate/TranslateProvider';

const name = 'importData';

type State = StateType<{
  [fileId: string]: {
    importData: ProcessedImportRouter[];
    pagination: Pagination;
    filter: {
      statuses: ImportedRouterStatus[];
    };
    headers: string[];
  };
}>;

const initialState: State = {
  data: {},
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export const getImportData = createAsyncThunk(
  name,
  async ({ fileId }: { fileId: string }, { getState, rejectWithValue }) => {
    try {
      const appState = getState() as AppState;
      const state = (appState['superAdmin'][name] as State).data;
      const filter = {
        statuses: state[fileId]?.filter.statuses || [],
      };
      const paging = {
        page: state[fileId]?.pagination.page || 1,
        size: state[fileId]?.pagination.size || 25,
      };
      const { data } = await apiPost<{
        data: {
          routers: ImportedRouter[];
          paging: Pagination;
          headers: string[];
        };
      }>({
        diffDomain: `${PROXY_DOMAIN}/api`,
        url: `import_router/detail/${fileId}/browse`,
        apiVersion: 'v1',
        reduxActionName: name,
        data: {
          paging: {
            page: paging.page - 1,
            size: paging.size,
          },
          filter,
        },
      });
      return {
        ...data,
        fileId,
        filter,
        paging: {
          ...data.paging,
          page: paging.page, // as page from server returns += - 1, so we have to set manually
        },
      };
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
    resetPaging(state: State, action) {
      if (state.data[action.payload.fileId].pagination) {
        state.data[action.payload.fileId].pagination.page = 1;
        state.data[action.payload.fileId].pagination.total_pages = 1;
        state.data[action.payload.fileId].pagination.total_elements = 0;
      }
    },
    setPageByFileId(state: State, action) {
      state.data[action.payload.fileId].pagination.page = action.payload.page;
    },
    setSizeByFileId(state: State, action) {
      state.data[action.payload.fileId].pagination.size = action.payload.size;
    },
    setStatusFilterByFileId(state: State, action) {
      state.data[action.payload.fileId].pagination.page = 1;
      state.data[action.payload.fileId].filter.statuses =
        action.payload.statuses;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getImportData.pending, (state) => {
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(getImportData.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        const _header = action.payload.headers.slice(0, 3);
        const processedData: ProcessedImportRouter[] = action.payload.routers.map(
          (router: ImportedRouter, routerIndex: number) => {
            return {
              indexNumber:
                routerIndex +
                1 +
                action.payload.paging.size * (action.payload.paging.page - 1),
              imei: router.imei,
              sn: router.sn,
              model: router.model,
              status: router.status,
              message: parseServerError(router.message, router.imei),
              id: uuidV4(), // because row may not have imei, or the imei can be duplicated, so should generate id to use as table key
              ...action.payload.headers
                .slice(3, action.payload.headers.length) // except 3 default headers IMEI, SN# and Model
                .reduce(
                  (
                    prevObj: Record<string, string>,
                    currentHeader: string,
                    headerIndex: number,
                  ) => {
                    const templateHeader =
                      currentHeader || `_column_${headerIndex}_`;
                    if (routerIndex === 0) _header.push(templateHeader); // only add header for the first time as the first router
                    const current = {
                      [templateHeader]:
                        (action.payload.routers[routerIndex] as ImportedRouter)
                          .remain_columns[headerIndex] || '',
                    };
                    return { ...prevObj, ...current };
                  },
                  {},
                ),
            };
          },
        );
        state.data = {
          ...state.data,
          [action.payload.fileId]: {
            importData: processedData,
            pagination: action.payload.paging,
            filter: action.payload.filter,
            headers: _header,
          },
        };
      })
      .addCase(getImportData.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message.split(':')[0];
          state.errorId = id;
          state.errorCode = `get_importData.${code}`;
        }
      });
  },
});

const selector = (state: AppState) => state.superAdmin[name];

export const getImportDataLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const getImportDataSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const getImportDataFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectDataByFileId = (fileId: string) =>
  createSelector(selector, (state: State) => state.data[fileId]);

export const getImportDataError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const {
  reset,
  resetPaging,
  setPageByFileId,
  setSizeByFileId,
  setStatusFilterByFileId,
} = slice.actions;

export default slice.reducer;

const parseServerError = (error: FileError, imei: string): string => {
  if (!error) {
    return '-';
  }
  switch (error.code) {
    case 'empty_both_serial_number_and_imei':
      return _translate(
        'IMPORT_ROUTERS.ERROR.EMPTY_BOTH_SERIAL_NUMBER_AND_IMEI.MESSAGE',
      );
    case 'imei_syntax_is_invalid':
      return _translate('IMPORT_ROUTERS.ERROR.IMEI_SYNTAX_IS_INVALID.MESSAGE');
    case 'serial_number_syntax_is_invalid':
      return _translate(
        'IMPORT_ROUTERS.ERROR.SERIAL_NUMBER_SYNTAX_IS_INVALID.MESSAGE',
      );
    case 'serial_number_is_not_existing_in_system':
      return _translate(
        'IMPORT_ROUTERS.ERROR.SERIAL_NUMBER_IS_NOT_EXIST_IN_SYSTEM.MESSAGE',
      );
    case 'imei_is_duplicate':
      return _translate('IMPORT_ROUTERS.ERROR.IMEI_IS_DUPLICATE.MESSAGE');
    case 'serial_number_is_different_with_stored_one':
      return _translate(
        'IMPORT_ROUTERS.ERROR.SERIAL_NUMBER_IS_DIFFERENT_WITH_STORED_ONE.MESSAGE',
        { currentSN: error.params[0], oldSN: error.params[1], imei: imei },
      );
    case 'imei_is_in_block_org':
      return _translate('IMPORT_ROUTERS.ERROR.IMEI_IS_IN_BLOCK_ORG.MESSAGE', {
        org_name: error.params[0],
      });
    case 'imei_does_not_have_service_permission':
      return _translate(
        'IMPORT_ROUTERS.ERROR.IMEI_DOES_NOT_HAVE_SERVICE_PERMISSION.MESSAGE',
      );
    default:
      return '-';
  }
};
