import { MapConstant } from '../models/Const';

export type PhoneState =
  | 'Uninitialized'
  | 'Standby'
  | 'Offhook'
  | 'Calling'
  | 'InCall'
  | 'EndCall'
  | 'Ringing';

export type PhoneLineStatus =
  | 'NotAvailable'
  | 'Available'
  | 'FirmWareUpdating'
  | 'LineLoadExceeded';

export type PhoneStatus = 'NoSupport' | 'Online' | 'Offline';

export const PHONE_STATE: MapConstant<PhoneState> = {
  KEY_LABEL: {
    0: 'UNINITIALIZED',
    1: 'STANDBY',
    2: 'OFFHOOK',
    3: 'CALLING',
    4: 'INCALL',
    5: 'ENDCALL',
    6: 'RINGING',
  },
  LABEL_KEY: {
    UNINITIALIZED: 0,
    STANDBY: 1,
    OFFHOOK: 2,
    CALLING: 3,
    INCALL: 4,
    ENDCALL: 5,
    RINGING: 6,
  },
  OPTIONS: [
    { label: 'Uninitialized', value: 0 },
    { label: 'Standby', value: 1 },
    { label: 'Offhook', value: 2 },
    { label: 'Calling', value: 3 },
    { label: 'InCall', value: 4 },
    { label: 'End-call', value: 5 },
    { label: 'Ringing', value: 6 },
  ],
};

export const PHONE_LINE_STATUS: MapConstant<PhoneLineStatus> = {
  KEY_LABEL: {
    0: 'NOTAVAILABLE',
    1: 'AVAILABLE',
    2: 'LINELOADEXCEEDED',
    3: 'FIRMWAREUPDATING',
  },
  LABEL_KEY: {
    NOTAVAILABLE: 0,
    AVAILABLE: 1,
    LINELOADEXCEEDED: 2,
    FIRMWAREUPDATING: 3,
  },
  OPTIONS: [
    { label: 'Unavailable', value: 0 },
    { label: 'Available', value: 1 },
    { label: 'Overloaded', value: 2 },
    { label: 'Updating', value: 3 },
  ],
};

export const PHONE_STATUS: MapConstant<PhoneStatus> = {
  KEY_LABEL: {
    0: 'NOSUPPORT',
    1: 'ONLINE',
    2: 'OFFLINE',
  },
  LABEL_KEY: {
    NOSUPPORT: 0,
    ONLINE: 1,
    OFFLINE: 2,
  },
  OPTIONS: [
    { label: 'No support', value: 0 },
    { label: 'Online', value: 1 },
    { label: 'Offline', value: 2 },
  ],
};
