/* eslint-disable @typescript-eslint/no-explicit-any */
import { tokenStore } from 'data/auth/token';
import { uuidV4 } from './uuid';
import { UAParser } from 'ua-parser-js';
import { defaultHeaders } from 'constants/configs';

const STORE_PREFIX = 'si';

const getCurrentUser = (token: string): any => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const saveToStore = (key: string, value: any) =>
  localStorage.setItem(`${STORE_PREFIX}_${key}`, value);

const getFromStore = (key: string) =>
  localStorage.getItem(`${STORE_PREFIX}_${key}`);

const saveAccessToken = (v: string): void => saveToStore('access_token', v);
const getAccessToken = (): string => tokenStore.getState().accessToken;

const saveRefreshToken = (v: string): void => saveToStore('refresh_token', v);
const getRefreshToken = (): string | null => getFromStore('refresh_token');

const saveLastLogin = (): void => saveToStore('last_login', Date.now());

const saveUserId = (v: string): void => saveToStore('user_id', v);
const getUserId = (): string => getFromStore('user_id') || '';

const ua = UAParser();

const getDeviceId = (): string => {
  if (!localStorage.getItem(`${STORE_PREFIX}_device_id`)) {
    localStorage.setItem(`${STORE_PREFIX}_device_id`, uuidV4());
  }
  return String(localStorage.getItem(`${STORE_PREFIX}_device_id`));
};

const getPlatform = (): string => {
  return ua.os.name ?? 'unknown';
};

const getPlatformVersion = (): string => {
  return ua.os.version ?? 'unknown';
};

const getLocale = (): string => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.language ?? 'unknown';
  }
};

const getDevice = (): string => {
  return ua.device.type ?? 'unknown';
};

const getVersionApp = async () => {
  try {
    const vJson = await fetch(
      `${window.location.origin}/appVersion?ts=${Date.now()}`,
    );
    let v = await vJson.text();
    // format version
    v = v.slice(8, -5);
    localStorage.setItem('APP_VERSION', v);
    defaultHeaders['X-App-Version'] = v;
  } catch (error) {
    console.debug({ error });
  }
};

const isLoggedIn = (): boolean => {
  const token = getAccessToken();
  return Boolean(token);
};

const logout = (): void => {
  // use timeout to ensure all store clear
  setTimeout(() => {
    localStorage.clear();
  }, 100);
};

export {
  saveAccessToken,
  getAccessToken,
  saveRefreshToken,
  getRefreshToken,
  getCurrentUser,
  isLoggedIn,
  logout,
  saveLastLogin,
  saveUserId,
  getUserId,
  getDeviceId,
  getPlatform,
  getPlatformVersion,
  getLocale,
  getDevice,
  getVersionApp,
};
