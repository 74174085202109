/**
 * The message code will follow this pattern: {actionName}.{serverErrorCode}
 * -The actionName is name of slice action like: getUser, deleteRouter..., this is optional.
 * -The serverErrorCode is result from server like: deactivated_user...
 * Ex for error code: invite_user.deactivated_account
 */

export const SERVER_ERROR = {
  DELETED_ORG: 'deleted_org',
  NOT_FOUND_ORG: 'not_found_org',
  NOT_BE_ADMIN_OF_ORG: 'not_be_admin_of_org',
  'INVITE_USER.DEACTIVATED_ACCOUNT': 'invite_user.deactivated_account',
  'INVITE_USER.SUSPENDED_ORG': 'invite_user.suspended_org',
  'INVITE_USER.FORBIDDEN': 'invite_user.forbidden',
  EXTERNAL_ERROR: 'external_error',
  EXTERNAL_TELIT_ERROR: 'external_telit_error',
  'USER_DETAIL.FORBIDDEN': 'user_detail.forbidden',
  'CREATE_ORG.NOT_BE_ADMIN_OF_ORG': 'create_org.not_be_admin_of_org',
  'CREATE_ORG.SUSPENDED_ORG': 'create_org.suspended_org',
  'UPDATE_ORG.SUSPENDED_ORG': 'update_org.suspended_org',
  'DELETE_ORG.DELETED_ORG': 'delete_org.deleted_org',
  'DELETE_ORG.EXISTING_SUB_ORGS': 'delete_org.existing_sub_orgs',
  'DELETE_ORG.EXISTING_USERS': 'delete_org.existing_users',
  'DELETE_ORG.EXISTING_ASSETS': 'delete_org.existing_assets',
  'DELETE_ORG.EXISTING_SUBSCRIPTION': 'delete_org.existing_subscription',
  'DELETE_USER.NOT_FOUND_ACCOUNT': 'delete_user.not_found_account',
  'ROUTER.ROUTER_OFFLINE': 'router.router_offline',
  'ROUTER_DETAIL.FORBIDDEN': 'router_detail.forbidden',
  'ROUTER_DETAIL.NOT_FOUND': 'router_detail.not_found',
  'ROUTER_CHANGE_FUNCTION.ROUTER_OFFLINE':
    'router_change_function.router_offline',
  'USER_GUIDE.SLUG_IS_EXISTING': 'user_guide.slug_is_existing',
  FIRMWARE_LATEST: 'fw_latest',
  NO_SERVICE_PERMISSION: 'no_service_permission',
};

export const SERVER_ERROR_MESSAGE: Record<keyof typeof SERVER_ERROR, string> = {
  DELETED_ORG: 'MESSAGE.UNABLE_TO_PERFORM_REQUEST',
  NOT_FOUND_ORG: 'MESSAGE.UNABLE_TO_PERFORM_REQUEST',
  NOT_BE_ADMIN_OF_ORG: 'MESSAGE.NOT_BE_ADMIN_OF_ORG',
  'INVITE_USER.DEACTIVATED_ACCOUNT': 'MESSAGE.INVITE_USER_DEACTIVATED_ACCOUNT',
  'INVITE_USER.FORBIDDEN': 'MESSAGE.UNABLE_TO_PERFORM_REQUEST',
  'INVITE_USER.SUSPENDED_ORG': 'MESSAGE.UNABLE_TO_PERFORM_REQUEST',
  EXTERNAL_ERROR: 'MESSAGE.ROUTER.EXTERNAL_ERROR',
  EXTERNAL_TELIT_ERROR: 'MESSAGE.ROUTER.EXTERNAL_TELIT_ERROR',
  'USER_DETAIL.FORBIDDEN': 'MESSAGE.USER_DETAIL_FORBIDDEN',
  'CREATE_ORG.NOT_BE_ADMIN_OF_ORG': 'MESSAGE.CREATE_ORG.NOT_BE_ADMIN_OF_ORG',
  'CREATE_ORG.SUSPENDED_ORG': 'MESSAGE.CREATE_ORG.SUSPENDED_ORG',
  'UPDATE_ORG.SUSPENDED_ORG': 'MESSAGE.UPDATE_ORG.SUSPENDED_ORG',
  'DELETE_ORG.DELETED_ORG': 'MESSAGE.DELETE_ORG.DELETED_ORG',
  'DELETE_ORG.EXISTING_SUB_ORGS': 'MESSAGE.DELETE_ORG.EXISTING_SUB_ORGS',
  'DELETE_ORG.EXISTING_USERS': 'MESSAGE.DELETE_ORG.EXISTING_USERS',
  'DELETE_ORG.EXISTING_ASSETS': 'MESSAGE.DELETE_ORG.EXISTING_ASSETS',
  'DELETE_USER.NOT_FOUND_ACCOUNT': 'MESSAGE.UNABLE_TO_PERFORM_REQUEST',
  'ROUTER_DETAIL.FORBIDDEN': 'MESSAGE.UNABLE_TO_PERFORM_REQUEST',
  'ROUTER.ROUTER_OFFLINE': 'MESSAGE.ROUTER_OFFLINE',
  'ROUTER_CHANGE_FUNCTION.ROUTER_OFFLINE': 'MESSAGE.ROUTER_OFFLINE',
  'USER_GUIDE.SLUG_IS_EXISTING': 'MESSAGE.SLUG_IS_EXISTING',
  FIRMWARE_LATEST: 'MESSAGE.FIRMWARE_LATEST',
  'ROUTER_DETAIL.NOT_FOUND': 'MESSAGE.ROUTER_DETAIL.NOT_FOUND',
  'DELETE_ORG.EXISTING_SUBSCRIPTION':
    'MESSAGE.DELETE_ORG.EXISTING_SUBSCRIPTION',
  NO_SERVICE_PERMISSION: 'MESSAGE.UNABLE_TO_PERFORM_REQUEST',
};

export const getServerMessage = (key: string | undefined): string => {
  // Add default message when error code is not exist in server error above
  // It will return "Unable to perform request. Please refresh page and try again."
  if (!key || Object.values(SERVER_ERROR).every((error) => error !== key)) {
    return 'MESSAGE.UNABLE_TO_PERFORM_REQUEST';
  }

  return SERVER_ERROR_MESSAGE[
    key.toUpperCase() as keyof typeof SERVER_ERROR_MESSAGE
  ];
};
