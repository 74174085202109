import InternetConnectionDetector from 'services/InternetConnection';

import LongOfflineDialog from './LongOfflineDialog';
import OnlineDialog from './OnlineDialog';

const InternetConnectionHandler = () => {
  return (
    <>
      <LongOfflineDialog
        onAction={refreshPage}
        onClose={handleCloseLongOfflineDialog}
      />
      <OnlineDialog onAction={refreshPage} />
    </>
  );
};

const handleCloseLongOfflineDialog = () => {
  InternetConnectionDetector.resume();
};

const refreshPage = () => {
  window.location.reload();
};

export default InternetConnectionHandler;
