import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { postForm } from './baseAPI';

type Query = {
  baseUrl: string;
  version?: string;
};

export const postFormBaseQuery = ({
  baseUrl = '',
  version = 'v1',
}: Query): BaseQueryFn<
  {
    url: string;
    formData: FormData;
  },
  unknown,
  unknown
> => async ({ url, formData }) => {
  const rs = postForm({
    diffDomain: baseUrl,
    url,
    apiVersion: version,
    formData,
  });
  return { data: ((await rs) as any).data };
};
