import create from 'zustand';

const lastCommunicationState = create(() => ({ time: 0 }));

const setValue = (time: number) => {
  const _current = lastCommunicationState.getState().time;

  // only update if new time is latest
  if (_current >= time) {
    return;
  }

  lastCommunicationState.setState({ time });
};

export { lastCommunicationState, setValue };
