import { css } from 'styled-components';
import { theme } from 'theme/theme';

const checkboxStyle = css`
  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: ${theme.colors.blue_500};
  }

  .ant-checkbox-checked:not(.ant-checkbox-disabled) {
    .ant-checkbox-inner {
    background-color: ${theme.colors.blue_500};
    border-color: ${theme.colors.blue_500} !important;
    }
`;

export { checkboxStyle };
