import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import notification from 'modules/notification';

import { apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import { parseError } from 'utils/strings';
import { ApiError } from '../../../models/Errors';
import { _translate } from 'translate/TranslateProvider';

type State = Omit<StateType<unknown>, 'data'>;
type Request = {
  routerId: string;
  userId: string;
  imei: string;
};

const name = 'unbindRouter';

const initialState: State = {
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export const unbindRouter = createAsyncThunk(
  name,
  async ({ routerId, userId, imei }: Request, { rejectWithValue }) => {
    try {
      await apiPost<{ data: Request }>({
        diffDomain: `${PROXY_DOMAIN}/api`,
        url: 'router/un_bind',
        apiVersion: 'v2',
        data: {
          router_ids: [routerId],
          user_id: userId,
        },
        reduxActionName: name,
      });
      notification.success(
        _translate('PROFILE.TABLE.ROUTERS.UNBIND.SUCCESS_MESSAGE', { imei }),
      );
    } catch (e) {
      const error = parseError(e as ApiError);
      return rejectWithValue(error);
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = initialState.error;
      state.errorId = initialState.errorId;
      state.errorCode = initialState.errorCode;
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(unbindRouter.pending, (state: State) => {
        state.status = THUNK_STATUS.LOADING;
        state.error = initialState.error;
        state.errorId = initialState.errorId;
        state.errorCode = initialState.errorCode;
      })
      .addCase(unbindRouter.fulfilled, (state: State) => {
        state.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(unbindRouter.rejected, (state: State, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = code;
        }
      });
  },
});

const selector = (state: AppState) => state.router[name];

export const { reset } = slice.actions;

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export default slice.reducer;
