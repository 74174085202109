import { FC, useState, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import SplashScreen from 'page/public/SplashScreen';
import AdminLayout from 'layout';
import { tokenStore } from 'data/auth/token';
import { queryClient } from 'index';
import { API_QUERY_KEY } from 'apis/ApiQueryKey';
import { currentUserState } from 'data/auth/currentUser';
import { useGetProfile } from 'hooks/auth/useProfile';
import LoginManager from 'page/public/Login/hook/useLogin';
import { useGetQuickTipsConfig } from 'modules/QuickTips/hooks/useGetQuickTipsConfig';

import { useGetOrgListForSpecialVoicelinkConfig } from '../hooks/useGetOrgListForSpecialVoicelinkConfig';
import { portalConfigs } from '../data/portalConfigs/configs';

const PrivateRoute: FC = () => {
  const roles = currentUserState((s) => s.roles);
  const [showSplashScreen, setShowSplashScreen] = useState(
    Boolean(roles.length),
  );

  const { search, pathname } = useLocation();

  const hasToken = tokenStore((s) => Boolean(s.accessToken));
  const { isLoading } = useGetProfile();
  const loginManager = LoginManager.getInstance();
  const { refetch } = useGetQuickTipsConfig();

  useGetOrgListForSpecialVoicelinkConfig((data) => {
    portalConfigs.setState((s) => ({
      ...s,
      [data.name]: data.value,
    }));
  });

  useEffect(() => {
    if (hasToken) {
      queryClient.fetchQuery([API_QUERY_KEY.AUTH.GET_PROFILE]);
    }
  }, [hasToken]);

  useEffect(() => {
    if (roles.length) {
      setTimeout(() => {
        setShowSplashScreen(false);
        if (loginManager.isJustLoggedIn()) {
          refetch();
        }
        loginManager.setJustLoggedIn(false);
      }, 500);
    }
  }, [roles]);

  if (!hasToken) {
    const redirect =
      pathname === '/' || pathname === '/not-found'
        ? ''
        : `?redirect=${pathname}${search}`;
    return <Redirect to={`/login${redirect}`} />;
  }

  if (showSplashScreen || isLoading || roles.length === 0) {
    return <SplashScreen />;
  }

  return <Route component={AdminLayout} path="/" />;
};

export default PrivateRoute;
