import { Checkbox as AntdCheckbox } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme/theme';

const StyledCheckbox = styled(AntdCheckbox)`
  &:hover {
    .ant-checkbox-inner {
      border-width: 2px;
      border-color: ${theme.colors.blue_500};
    }
  }
  .ant-checkbox-inner {
    border-width: 2px;
    border-color: ${theme.colors.grayscale_600} !important;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.grayscale_200};
  }

  .ant-checkbox-checked:not(.ant-checkbox-disabled) {
    .ant-checkbox-inner {
      border-width: 2px;
      ::after {
        top: 40%;
        left: 12%;
        height: 11px;
      }
    }
    ::after {
      border-color: ${theme.colors.grayscale_200}!important;
      border-width: 0;
    }
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${theme.colors.grayscale_200};
    border: 0;

    ::after {
      border-color: ${theme.colors.base_50}!important;
    }
  }
`;

export default StyledCheckbox;
