import create from 'zustand';
import { persist } from 'zustand/middleware';

import { registerAutoResetPersist } from 'data/reseter';

import { PERSIST_KEY } from '../keys';

type State = {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  idToken: string;
  scope: string;
  tokenType: string;
};

type Actions = {
  setToken: Callback<State>;
  reset: Noop;
};

const initialState: State = {
  accessToken: '',
  expiresIn: 0,
  idToken: '',
  refreshToken: '',
  scope: '',
  tokenType: '',
};

const tokenState = create<State>()(
  persist(() => initialState, {
    name: PERSIST_KEY.AUTH.TOKEN,
  }),
);
registerAutoResetPersist(tokenState, initialState);

const reset: Actions['reset'] = () => tokenState.setState(() => initialState);

const setTokenStore: Actions['setToken'] = (t) => tokenState.setState(() => t);

export { tokenState as tokenStore, setTokenStore, reset };
