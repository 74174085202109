import styled from 'styled-components';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { isLoggedIn } from 'utils/token';
import Text from 'components/atoms/Text';
import { useTranslate } from 'translate/useTranslate';
import NotFoundImage from 'assets/images/404.svg';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.colors.base_50};
  border: 1px solid ${(p) => p.theme.colors.grayscale_200};
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  padding: 44px;
`;

const Message = styled(Text)`
  margin-top: 20px;
`;

const HintText = styled(Text)`
  margin-top: 8px;
`;

const Content = styled.div`
  width: 371px;
`;

const NotFound = () => {
  const { t } = useTranslate();
  const history = useHistory();
  const isAuth = isLoggedIn();

  useEffect(() => {
    if (!isAuth) {
      history.push('/login');
    }
  }, []);

  return (
    <Container>
      <Content>
        <img src={NotFoundImage} alt="React Logo" />
        <Message variant="h2" bold>
          {t('PAGE_NOT_FOUND.MESSAGE')}
        </Message>
        <HintText disabled>{t('PAGE_NOT_FOUND.HINT_TEXT')}</HintText>
      </Content>
    </Container>
  );
};

export default NotFound;
