import { useCallback } from 'react';

import ErrorDialog from 'components/molecules/ErrorDialog';
import { util } from 'data/utils';
import create from 'zustand';

type State = {
  show: boolean;
  content: string;
  onClose?: () => void;
};

const initialState: State = {
  show: false,
  content: '',
};

export const errorDialogState = create<State>(() => initialState);

const zutil = util(errorDialogState.setState, errorDialogState.getState);

const showErrorDialog = zutil.updateState<Callback<State['content']>>(
  (s, v) => {
    s.content = v;
    s.show = true;
  },
);

const hideErrorDialog = zutil.updateState<Callback<void>>((s, _) => {
  s.content = '';
  s.show = false;
});

const addOnCloseCallback = zutil.updateState<Callback<State['onClose']>>(
  (s, cb) => {
    s.onClose = cb;
  },
);

const clearOnCloseCallback = zutil.updateState<Callback<void>>((s, _) => {
  s.onClose = undefined;
});

const showDialogWithOnCloseCallback = ({
  message,
  onClose,
}: {
  message: string;
  onClose?: () => void;
}) => {
  addOnCloseCallback(onClose);
  showErrorDialog(message);
};

const ErrorDialogGlobal = (): JSX.Element => {
  const { content, show, onClose } = errorDialogState((s) => s);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
      clearOnCloseCallback();
    }
    hideErrorDialog();
  }, [onClose]);

  return (
    <ErrorDialog
      asHTML
      visible={show}
      message={content}
      onClose={handleClose}
    />
  );
};

export const actions = {
  showErrorDialog,
  hideErrorDialog,
  showDialogWithOnCloseCallback,
};

export default ErrorDialogGlobal;
