import styled from 'styled-components';

import Button from 'components/molecules/Button';
import { Modal } from 'components/molecules/Modal';
import { theme } from 'theme/theme';
import Text from 'components/atoms/Text';
import Tabs from 'components/molecules/Tabs';
import Block from 'components/atoms/Block';

export const ActionButton = styled(Button)`
  margin-left: 8px;
`;

export const PreviewModal = styled(Modal)`
  width: 100% !important;
  height: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
  max-width: 100%;
  border-radius: 0;

  .ant-modal-content {
    border-radius: 0;
    box-shadow: none;
    height: 100%;
  }

  .ant-modal-body {
    padding: 0;
    height: calc(100% - 53px);

    & > div {
      height: 100%;
    }
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-footer {
    padding: 10px 20px;
    border-top: 1px solid ${theme.colors.grayscale_200};
  }
`;

export const HiddenBlock = styled.div<{
  $hidden: boolean;
}>`
  ${(props) => (props.$hidden ? 'display: none;' : '')}
`;

export const FormRowTitle = styled(Text)`
  font-weight: 500;
  margin-bottom: 20px;
  margin-right: 10px;
`;

export const FormItemTab = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
  }
`;

export const SidePanelContainer = styled(Block)`
  width: 344px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
  border-left: 1px solid ${theme.colors.grayscale_200};
`;

export const SidePanelHeader = styled(Block)`
  border-bottom: 1px solid ${theme.colors.grayscale_200};
  padding: 20px;
`;
export const StyledItem = styled(Block)<{ $isHeader: boolean }>`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid ${theme.colors.grayscale_200};
  cursor: pointer;

  &:hover {
    * {
      color: ${theme.colors.blue_500};
    }

    svg > path {
      fill: ${theme.colors.blue_500};
    }
  }
`;
