import { ComponentProps, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';

import { currentUserState } from 'data/auth/currentUser';
import { uuidV4 } from 'utils/uuid';
import { useSetDatePickerControlArialLabel } from 'hooks/a11y/useSetDatePickerControlArialLabel';

export type EventValue = Dayjs | null;
export type RangeValue = [EventValue, EventValue] | null;

// set the dayjs default timezone to current user's timezone
// IMPORTANT: the default timezone is only effective for the dayjs.tz() function
// that mean instead of using dayjs(), we should use dayjs.tz() to get the correct timezone

const config = {
  ...dayjsGenerateConfig,
  getNow: () => {
    return dayjs().toUserTz();
  },
} as typeof dayjsGenerateConfig;

const panelOpenFormat = 'MMM DD, YYYY H:m:s';
const panelClosedFormat = 'MMM DD, YYYY HH:mm:ss';

const CustomDatePicker = generatePicker<Dayjs>(config);

export const SimpDateRangePicker = CustomDatePicker.RangePicker;

type PikerProps = ComponentProps<typeof CustomDatePicker> & {
  format?: never; // this is to prevent user from passing format
  pickerDateOnly?: boolean;
};

export const getYesterDayRange = (): [Dayjs, Dayjs] => {
  const yesterday = dayjs().toUserTz().subtract(1, 'day');
  return [yesterday.startOf('day'), yesterday.endOf('day')];
};

export const getTomorrowDayRange = (tz: string): [Dayjs, Dayjs] => {
  const tomorrow = dayjs().tz(tz).add(1, 'day');
  return [tomorrow.startOf('day'), tomorrow.endOf('day')];
};

export const generatePresets = (
  ranges: Record<string, number | (() => [Dayjs, Dayjs])>,
): Record<string, [Dayjs, Dayjs]> => {
  const end = dayjs().toUserTz().endOf('day');
  const presets = Object.entries(ranges).reduce((acc, [key, value]) => {
    if (typeof value === 'number') {
      return {
        ...acc,
        [key]: [end.subtract(value - 1, 'day').startOf('day'), end],
      };
    } else {
      // value is a function that returns a tuple of Dayjs
      return {
        ...acc,
        [key]: value(),
      };
    }
  }, {});
  return presets;
};

export const generateFuturePresets = (
  ranges: Record<string, number | ((_: any) => [Dayjs, Dayjs])>,
  tz: string,
): Record<string, [Dayjs, Dayjs]> => {
  const start = dayjs().tz(tz).startOf('day');
  const presets = Object.entries(ranges).reduce((acc, [key, value]) => {
    if (typeof value === 'number') {
      return {
        ...acc,
        [key]: [start, start.add(value - 1, 'day').endOf('day')],
      };
    } else {
      // value is a function that returns a tuple of Dayjs
      return {
        ...acc,
        [key]: value(tz),
      };
    }
  }, {});
  return presets;
};

export const generateFuturePresetsOnUserTz = (
  ranges: Record<string, number | ((_: any) => [Dayjs, Dayjs])>,
): Record<string, [Dayjs, Dayjs]> => {
  const start = dayjs().toUserTz().startOf('day');
  const presets = Object.entries(ranges).reduce((acc, [key, value]) => {
    if (typeof value === 'number') {
      return {
        ...acc,
        [key]: [start, start.add(value - 1, 'day')],
      };
    } else {
      // value is a function that returns a tuple of Dayjs
      return {
        ...acc,
        [key]: value(0),
      };
    }
  }, {});
  return presets;
};

export const SiDatePicker = ({
  pickerDateOnly = false,
  ...props
}: PikerProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const _format = isPanelOpen ? panelOpenFormat : panelClosedFormat;

  return (
    <div className="si-date-picker">
      <CustomDatePicker
        onOpenChange={setIsPanelOpen}
        format={pickerDateOnly ? 'MMM DD, YYYY' : _format}
        getPopupContainer={(trigger) => trigger.parentElement || document.body}
        {...props}
      />
    </div>
  );
};

export const SiDateRangePicker = (
  props: ComponentProps<typeof CustomDatePicker['RangePicker']> & {
    onTimeRangeChange?: (_values: RangeValue) => void;
  },
) => {
  const id = useMemo(uuidV4, []);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const _format = isPanelOpen ? panelOpenFormat : panelClosedFormat;
  const { onTimeRangeChange, className, dropdownClassName, ...rest } = props;

  const _cls = `${className || ''} date-picker-${id}`;
  const _dropdownCls = `${dropdownClassName || ''} date-picker-dropdown-${id}`;

  useSetDatePickerControlArialLabel({
    dropdownSelector: `.date-picker-dropdown-${id}`,
    dependency: [isPanelOpen, _dropdownCls],
  });

  return (
    <div className="si-date-picker">
      <CustomDatePicker.RangePicker
        allowEmpty={[true, true]}
        className={_cls}
        dropdownClassName={_dropdownCls}
        onOpenChange={(e) => {
          setIsPanelOpen(e);
          props.onOpenChange?.(e);
        }}
        getPopupContainer={(trigger) => trigger.parentElement || document.body}
        format={_format}
        onCalendarChange={(values, _formatString) => {
          if (values) {
            const correctedTimeZoneValue = values.map((v) => {
              if (!v) {
                return null;
              }
              const timeString = v?.format('YYYY-MM-DD HH:mm:ss');
              const currentUserTimezone =
                currentUserState.getState().timezone || 'Asia/Ho_Chi_Minh';
              return dayjs.tz(timeString, currentUserTimezone);
            }) as [Dayjs, Dayjs];
            onTimeRangeChange?.(correctedTimeZoneValue);
          }
        }}
        {...rest}
      />
    </div>
  );
};
