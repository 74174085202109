import styled from 'styled-components';
import { Tabs as AntdTabs } from 'antd';

import { theme } from 'theme/theme';

export const StyledTabs = styled(AntdTabs)<{ $hideOperation: boolean }>`
  align-items: center;

  .ant-tabs-nav {
    background: ${theme.colors.base_50};
    border-radius: 6px;
    border: 1px solid ${theme.colors.grayscale_200};
    padding: 1px;

    .ant-tabs-tab {
      &:hover,
      &:focus,
      &:active {
        color: ${theme.colors.blue_500};
      }
    }

    .ant-tabs-nav-wrap::before {
      box-shadow: none;
    }
    .ant-tabs-nav-wrap::after {
      box-shadow: none;
    }

    .ant-tabs-nav-operations {
      display: ${(p) => (p.$hideOperation ? 'none' : 'flex')};
    }

    .ant-tabs-tab-btn:active {
      color: ${theme.colors.blue_500};
    }
  }

  .ant-tabs-nav-list {
    justify-content: center;
    width: fit-content;
    gap: 4px;
  }

  .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    color: ${theme.colors.base_50};
    background: ${theme.colors.blue_500};
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    background: transparent;
  }

  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  &.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 4px;
    padding: 6px;
    font-size: 14px;
    line-height: 16px;
    border: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active > div {
    span {
      color: ${theme.colors.base_50};
    }

    svg {
      fill: ${theme.colors.base_50};

      path {
        fill: ${theme.colors.base_50};
      }
    }

    color: ${theme.colors.base_50};
  }

  .ant-tabs-tab {
    & > div {
      gap: 6px;
    }

    span {
      color: ${theme.colors.grayscale_900};
    }

    svg {
      fill: ${theme.colors.grayscale_900};
      path {
        fill: ${theme.colors.grayscale_900};
      }
    }
  }

  .ant-tabs-tab:hover {
    span {
      color: ${theme.colors.blue_500};
    }

    svg {
      fill: ${theme.colors.blue_500};
      path {
        fill: ${theme.colors.blue_500};
      }
    }
  }

  .ant-tabs-tab-btn {
    display: flex;
  }
`;
