import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { apiPut } from 'apis/baseAPI';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import { parseError } from 'utils/strings';
import notification from 'modules/notification';
import { OrgRolePair } from 'modules/UserInvite/components/OrgRoleSelect';
import { _translate } from 'translate/TranslateProvider';

const name = 'updateUser';

type State = StateType<unknown>;

export type UpdateUserRequest = {
  user_id: string;
  email: string;
  update_org_role_list: OrgRolePair[];
  delete_org_role_list: OrgRolePair[];
};

const initialState: State = {
  data: null,
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const updateUser = createAsyncThunk(
  `${name}/updateUser`,
  async (requestData: UpdateUserRequest, { rejectWithValue }) => {
    try {
      const updateOrgRoleList = requestData.update_org_role_list
        .filter((i) => i.org_id && i.role_name)
        .map((i) => ({
          org_id: i.org_id,
          role_name: i.role_name,
        }));

      const deleteOrgRoleList = requestData.delete_org_role_list
        .filter((i) => i.org_id && i.role_name)
        .map((i) => ({
          org_id: i.org_id,
          role_name: i.role_name,
        }));

      await apiPut({
        url: 'portal/user/profile',
        apiVersion: 'v2',
        data: {
          user_id: requestData.user_id,
          update_org_role_list: updateOrgRoleList,
          delete_org_role_list: deleteOrgRoleList,
        },
        reduxActionName: `${name}/updateUser`,
      });

      notification.success(
        _translate('PROFILE.TABLE.ROUTERS.ACTION.UPDATE.TOAST.SUCCESS', {
          email: requestData.email,
        }),
      );
      return;
    } catch (e) {
      return rejectWithValue(parseError(e));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
      state.errorCode = '';
      state.errorId = '';
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(updateUser.pending, (state) => {
        state.status = THUNK_STATUS.LOADING;
        state.errorId = undefined;
        state.errorCode = undefined;
        state.error = '';
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const { code, id, message } = action.payload as {
            code: string;
            id: string;
            message: string;
          };
          state.error = message;
          state.errorCode = `update_user.${code}`;
          state.errorId = id;
        }
      }),
});

const selector = (state: AppState) => state[name];

export const { reset } = slice.actions;

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorCode, errorId }: State) => ({ error, errorCode, errorId }),
);

export default slice.reducer;
