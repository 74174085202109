import EventPublisher from 'services/EventPublisher';
import { actions as longOfflineDialogActions } from 'modules/InternetConnectionHandler/LongOfflineDialog';
import { actions as onlineDialogAction } from 'modules/InternetConnectionHandler/OnlineDialog';
import notification from 'modules/notification';
import MQTTService from 'modules/qmtt/client';
import { action as internetConnectionStateAction } from 'data/internet/status';
import { defaultHeaders, env } from 'constants/configs';

const MAX_PING_RETRY = 30; // the maximum ping retry before show the long-offline dialog

const internetEventPublisher = new EventPublisher([
  'online',
  'offline',
  'longOffline',
]);

class TickCountdown {
  private maxRetry = 0;
  private fallback?: () => void;
  private remainingRetry = 0;
  constructor(maxRetry: number, fallback: () => void) {
    this.maxRetry = maxRetry;
    this.remainingRetry = maxRetry;
    this.fallback = fallback;
  }

  tick() {
    console.log('remain: ', this.remainingRetry);
    if (this.remainingRetry === 0) {
      this.fallback && this.fallback();
      return;
    }

    this.remainingRetry -= 1;
  }

  reset() {
    this.remainingRetry = this.maxRetry;
  }
}

class PingServer {
  // private static interval?: ReturnType<typeof setInterval>;
  private static isPinging = false;
  private static isOnline = true;
  private static pollingInterval = 0;
  private static pingWorker = new window.Worker(
    `${process.env.PUBLIC_URL}/worker/portalPing.js`,
  );

  private static longOfflineCountdown = new TickCountdown(MAX_PING_RETRY, () =>
    internetEventPublisher.publish('longOffline'),
  );

  static init(pollingInterval: number) {
    internetEventPublisher
      .on('online', () => {
        console.log('online');
        // when the application back to online, then:
        // close the toast message,
        // show the back to online dialog,
        // set status to the state,
        // renew MQTT instants
        notification.closeInternetNotification();
        onlineDialogAction.showDialog();
        MQTTService.reNewInstant();
        internetConnectionStateAction.setConnectionStatus(true);
      })
      .on('offline', () => {
        console.log('offline');
        onlineDialogAction.hideDialog();
        MQTTService.destroy();
        internetConnectionStateAction.setConnectionStatus(false);
      })
      .on('longOffline', () => {
        console.log('longOffline');
        PingServer.pause();
        longOfflineDialogActions.showDialog();
      });

    this.pingWorker.onmessage = (e) => {
      const status = e.data;
      if (status === 'online') {
        // every online event
        if (this.isOnline) {
          return;
        }
        // first online event
        this.isOnline = true;
        internetEventPublisher.publish('online');
        this.longOfflineCountdown.reset();
      } else {
        // every offline event fire
        notification.lossInternet();
        this.longOfflineCountdown.tick();
        if (!this.isOnline) {
          return;
        }

        // first offline event
        this.isOnline = false;
        internetEventPublisher.publish('offline');
      }
    };
    this.pollingInterval = pollingInterval;
    this.start();
  }

  static start() {
    if (!this.pollingInterval) {
      console.error('Init first');
      return;
    }

    if (this.isPinging) {
      return;
    }

    this.isPinging = true;

    this.pingWorker.postMessage({
      type: 'start',
      headers: defaultHeaders,
      pollingInterval: this.pollingInterval,
      url: `${env.REACT_APP_API_URL}/api/v2/portal/monitor/ping`,
    });
  }

  static pause() {
    this.pingWorker.postMessage({
      type: 'stop',
    });
    this.isPinging = false;
  }

  static resume() {
    this.longOfflineCountdown.reset();
    this.start();
  }
}

export default PingServer;
