import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { StateType } from 'models/Store';
import { apiGet } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { ConfigData, DHCPData } from 'models/LanWanStatus';
import { WanConfig } from 'models/WanConfig';
import { ApiError } from 'models/Errors';
import { setValue } from 'page/private/RouterDetail/components/BasicInformation/data/lastCommunicate';

const name = 'getWanConfig';

type RequestData = {
  routerId: string;
};

type State = StateType<Omit<WanConfig, 'type_protocol'>>;

const initialState: State = {
  data: {
    typeProtocol: 0,
    dhcp_client: {} as ConfigData,
    static_address: {
      config: {} as ConfigData,
      dhcp: {} as DHCPData,
    },
    enable_usb_3: false,
  },
  error: '',
  status: THUNK_STATUS.IDLE,
};

export const getWanConfig = createAsyncThunk(
  name,
  async (data: RequestData, { rejectWithValue, dispatch }) => {
    try {
      const { routerId } = data;
      const rs = await apiGet<ServerResponse<State['data']>>({
        diffDomain: `${PROXY_DOMAIN}/api`,
        url: `router/rpc/wan_config/${routerId}`,
        apiVersion: 'v2',
        reduxActionName: name,
      });

      setValue(rs.meta.last_communication);

      return rs;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    } finally {
      dispatch(reset());
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorCode = '';
      state.errorId = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getWanConfig.pending as never]: (state: State) => {
      state.error = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [getWanConfig.fulfilled as never]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data = action.payload.data;
    },
    [getWanConfig.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload.message;
      state.errorCode = action.payload.code;
    },
  },
});

const selector = (state: AppState) => state.router[name];

export const selectWanConfig = createSelector(
  selector,
  (state: State) => state.data,
);

export const selectWanConfigLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectWanConfigSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectWanConfigFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  (state: State) => state.errorCode,
);

export const { reset } = slice.actions;

export default slice.reducer;
