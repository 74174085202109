import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { StateType } from 'models/Store';
import { Router, CellularConfigForm } from 'models/Router';
import { THUNK_STATUS } from 'constants/status';
import { PROXY_DOMAIN } from 'constants/configs';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { apiPost } from 'apis/baseAPI';
import {
  CELLULAR_AUTHENTICATION_TYPE,
  CELLULAR_IP_FAMILY,
  CELLULAR_MODE,
} from 'constants/Router';
import { ApiError } from 'models/Errors';

type State = StateType<unknown>;

const name = 'updateCellular';

const initialState: State = {
  data: {
    updateType: '',
  },
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

type SubmitRequest = {
  apn: string;
  auth: string;
  bands: string[] | string;
  disable_roam: boolean;
  ipfamily: string;
  mode: string;
  password: string;
  pincode: string;
  username: string;
};

const toBEModel = (data: CellularConfigForm): SubmitRequest => {
  return {
    apn: data.apn,
    auth: data.auth
      ? data.auth === CELLULAR_AUTHENTICATION_TYPE.LABEL_KEY.CUSTOM
        ? data.authCustom
        : CELLULAR_AUTHENTICATION_TYPE.KEY_LABEL[data.auth].toLowerCase()
      : '',
    bands: data.bands.length ? data.bands : '',
    disable_roam: data.disableRoam,
    ipfamily: data.ipFamily
      ? CELLULAR_IP_FAMILY.KEY_LABEL[data.ipFamily].toLowerCase()
      : '',
    mode: data.mode ? CELLULAR_MODE.KEY_LABEL[data.mode].toLowerCase() : '',
    password: data.password,
    pincode: data.pinCode,
    username: data.username,
  };
};

export const submit = createAsyncThunk(
  `${name}/submit`,
  async (
    requestData: {
      routerId: string;
      data: CellularConfigForm;
    },
    { rejectWithValue },
  ) => {
    try {
      const { routerId, data } = requestData;
      const dataPost = toBEModel(data);

      await apiPost<Router>({
        url: `router/rpc/update_cell_config/${routerId}`,
        apiVersion: 'v3',
        diffDomain: `${PROXY_DOMAIN}/api`,
        headers: {
          'X-Device-Id': 'deviceid',
          'X-Device-Family': 'PC',
          'X-Device-Os': 'WIN',
          'X-Device-OS-Version': 10,
          'X-Device-Locale': 'vi_VN',
          'X-App-Version': '1.3.0',
        },
        data: dataPost,
        reduxActionName: `${name}/submit`,
      });

      return;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submit.pending, (state) => {
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(submit.fulfilled, (state) => {
        state.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(submit.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = code;
        }
      });
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectUpdateType = createSelector(
  selector,
  (state) => state.data.updateType,
);

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFail = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export default slice.reducer;
