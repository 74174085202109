import { StatusType } from '../models/Store';

export const THUNK_STATUS: {
  [P in StatusType as Uppercase<P>]: P;
} = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};
