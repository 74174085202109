import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { AppState } from 'store/rootReducer';
import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { Organization, OrganizationFormState } from 'models/Organization';
import { parseError } from 'utils/strings';
import { defaultHeaders, env } from 'constants/configs';
import { ApiError } from 'models/Errors';
import { getAccessToken } from 'utils/token';
import notification from 'modules/notification';
import { _translate } from 'translate/TranslateProvider';
import { currentUserState } from 'data/auth/currentUser';

const BASE_URL = `${env.REACT_APP_API_URL}/api`;

type State = StateType<OrganizationFormState>;

const name = 'updateOrg';

const initialState: State = {
  data: {
    logo: null,
    name: '',
    parent: null,
    desc: '',
    phone: '',
    address: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    email: '',
    allow_sub_orgs: false,
    allow_org_admin: 0,
    disallow_assets: false,
    technical_support_team_email: '',
    subscription_team_email: '',
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

type Request = {
  data: Organization;
  hasChangeService: boolean;
};

export const updateOrg = createAsyncThunk(
  name,
  async (req: Request, { rejectWithValue }) => {
    try {
      const {
        name,
        desc,
        parent,
        logo,
        id,
        email,
        phone,
        address1,
        address2,
        city,
        state,
        zipcode,
        service_captive,
        service_voicelink,
        technical_support_team_email,
        subscription_team_email,
      } = req.data;

      const data = new FormData();

      data.append('id', id);

      if (name) {
        data.append('name', name);
      }
      data.append('desc', !desc ? '' : desc.replace(/[\r]/g, ''));
      data.append('street_number', address1);
      data.append('street', address2);
      data.append('city', city);
      data.append('state', state);
      data.append('zip_code', zipcode);
      data.append('email', !email ? '' : email);
      data.append('phone', !phone ? '' : phone);
      data.append('parent', parent as string);
      data.append(
        'technical_support_team_email',
        !technical_support_team_email ? '' : technical_support_team_email,
      );
      data.append(
        'subscription_team_email',
        !subscription_team_email ? '' : subscription_team_email,
      );
      if (service_captive) {
        data.append('service_captive', service_captive);
      }
      if (service_voicelink) {
        data.append('service_voicelink', service_voicelink);
      }

      if (typeof logo === 'object' || logo === '') {
        data.append('logo', logo === '' ? null : (logo as any));
        data.append('remove_logo', (logo === '').toString());
      }

      const headers = {
        ...defaultHeaders,
        authorization: 'Bearer ' + getAccessToken(),
        mode: 'cors',
      } as any;

      const responseData = await (
        await fetch(`${BASE_URL}/v2/portal/org/update`, {
          method: 'PUT',
          headers,
          body: data,
        })
      ).json();

      const apiError: ApiError = {
        response: {
          data: {
            error: responseData.error,
          },
        },
        message: '',
      };

      if (responseData.error) {
        return rejectWithValue(parseError(apiError));
      }
      notification.success(_translate('ORG.UPDATE_ORG.SUCCESS', { name }));

      // check if has change services
      if (!req.hasChangeService) {
        return responseData;
      }
      // check if current org is belong to the user's orgs
      const orgAndRole = currentUserState.getState().orgRolePair;
      if (id in orgAndRole) {
        window.location.reload();
      }
      return responseData;
    } catch (err) {
      return rejectWithValue(parseError(err as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateOrg.pending, (state) => {
        state.status = THUNK_STATUS.LOADING;
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
      })
      .addCase(updateOrg.fulfilled, (state) => {
        state.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(updateOrg.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `update_org.${code}`;
        }
      });
  },
});

const selector = (state: AppState) => state.organization[name];

export const selectLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectLoaded = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFailed = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const { reset } = slice.actions;

export default slice.reducer;
