import { DependencyList, useEffect } from 'react';
import { theme } from 'theme/theme';
import { useTranslate } from 'translate/useTranslate';

const useSetDatePickerControlArialLabel = ({
  dropdownSelector,
  dependency,
}: {
  dropdownSelector: string;
  dependency?: DependencyList;
}) => {
  const { t } = useTranslate();
  useEffect(() => {
    const suffixEl = document.querySelector('.ant-picker-suffix');
    if (suffixEl) {
      suffixEl.setAttribute('style', `color: ${theme.colors.grayscale_600}`);
    }

    const dropdownEl = document.querySelector(dropdownSelector);
    if (!dropdownEl) {
      return;
    }
    const supPrevEl = document.querySelector(
      `${dropdownSelector} button[class="ant-picker-header-super-prev-btn"]`,
    );

    if (supPrevEl) {
      supPrevEl.setAttribute(
        'aria-label',
        t('WCAG.DATE_PICKER.HEADER.SUPER_PREV_BTN'),
      );
    }

    const prevEl = document.querySelector(
      `${dropdownSelector} button[class="ant-picker-header-prev-btn"]`,
    );
    if (prevEl) {
      prevEl.setAttribute('aria-label', t('WCAG.DATE_PICKER.HEADER.PREV_BTN'));
    }

    const superNextEl = document.querySelector(
      `${dropdownSelector} button[class="ant-picker-header-super-next-btn"]`,
    );
    if (superNextEl) {
      superNextEl.setAttribute(
        'aria-label',
        t('WCAG.DATE_PICKER.HEADER.SUPER_NEXT_BTN'),
      );
    }

    const nextEl = document.querySelector(
      `${dropdownSelector} button[class="ant-picker-header-next-btn"]`,
    );
    if (nextEl) {
      nextEl.setAttribute('aria-label', t('WCAG.DATE_PICKER.HEADER.NEXT_BTN'));
    }
  }, dependency);
};

export { useSetDatePickerControlArialLabel };
