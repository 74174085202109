// validation regex for email
const VALID_EMAIL_RECIPIENT = /^(?!.*[._+-]{2})([a-zA-Z0-9]{1,}[._+-]?){1,}[a-zA-Z0-9]{1,}$/;
const VALID_EMAIL_DOMAIN = /^(?!.*[.-]{2})([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
const VALID_EMAIL_IP = /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;
const VALID_EMAIL_IP_FORMAT = /([0-9]{1,3}\.)+[0-9]{1,3}$/;

/**
 * Validates an email address according to the following rules:
 *
 * - The email address should start with one or more alphanumeric characters.
 * - The email address can contain periods (.) but not at the start or end, nor consecutively.
 * - The email address can contain hyphens (-), but not at the start or end of a part.
 * - The domain name should consist of one or more labels separated by periods (e.g., example.com).
 * - Each domain label should start and end with an alphanumeric character.
 * - The domain should not contain consecutive periods or hyphens.
 * - The top-level domain (TLD) should be at least 2 characters long and can be up to 63 characters.
 * - The total length of the domain should not exceed 255 characters.
 * - The address part should not exceed 64 characters in length.
 * - The email should not contain any spaces, special characters (except for periods, hyphens, and underscores in allowed positions).
 * - The email should not contain consecutive special characters like ".." or "--".
 *
 * @param {unknown} email - The email address to be validated.
 * @returns {boolean} `true` if the email is valid according to the rules above, `false` otherwise.
 */
const isEmail = (email: unknown = ''): boolean => {
  if (typeof email !== 'string') {
    return false;
  }

  // TODO need to change after refactoring the validator
  if (!email) return true;

  const trimEmail = email.toString().trim();
  if (!trimEmail || trimEmail.length > 320) return false;

  const [recipientValue, domainValue] = trimEmail.split('@', 2);

  if (!recipientValue || !domainValue) return false;

  return isRecipientValid(recipientValue) && isDomainValid(domainValue);
};

const isRecipientValid = (recipient: string): boolean => {
  return (
    recipient.length >= 1 &&
    recipient.length <= 64 &&
    VALID_EMAIL_RECIPIENT.test(recipient)
  );
};

const isDomainValid = (domain: string): boolean => {
  if (domain.length < 1 || domain.length > 255) return false;

  const labels = domain.split('.', 127);

  if (labels.length > 127) return false;

  if (
    labels.length === 4 &&
    labels.every((label) => !isNaN(parseInt(label, 10)))
  ) {
    return VALID_EMAIL_IP_FORMAT.test(domain) && VALID_EMAIL_IP.test(domain);
  }

  const topLevel = labels[labels.length - 1];
  if (topLevel.length < 2 || topLevel.length > 63) return false;

  return (
    labels.every((label) => label.length >= 1 && label.length <= 63) &&
    VALID_EMAIL_DOMAIN.test(domain)
  );
};

export { isEmail };
