import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiPost } from 'apis/baseAPI';
import { SUPPORT_DOMAIN } from 'constants/configs';
import { parseError } from 'utils/strings';
import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { AppState } from 'store/rootReducer';
import { ApiError } from '../../models/Errors';
import notification from 'modules/notification';
import { _translate } from 'translate/TranslateProvider';

const name = 'support';

type SubmitData = {
  name: string;
  email: string;
  subject: string;
  content: string;
  attachments: File[];
};

type State = Omit<StateType<unknown>, 'data'>;

const initialState: State = {
  status: THUNK_STATUS.IDLE,
  error: '',
};

export const submit = createAsyncThunk(
  `${name}/submit`,
  async (
    { name, subject, content, email, attachments }: SubmitData,
    { rejectWithValue },
  ) => {
    try {
      const postData = new FormData();
      postData.append('email', email);
      postData.append('content', content);

      if (name) {
        postData.append('name', name);
      }

      if (subject) {
        postData.append('subject', subject);
      }

      if (attachments.length) {
        attachments.forEach((f) => {
          postData.append('attachments', f);
        });
      }

      await apiPost({
        diffDomain: `${SUPPORT_DOMAIN}/api`,
        apiVersion: 'v2',
        url: 'ticket/support/create',
        formData: postData,
        reduxActionName: `${name}/submit`,
      });
      notification.success(_translate('SUPPORT.SUBMIT.SUCCESS.MESSAGE'));
    } catch (e) {
      const error = parseError(e);
      return rejectWithValue(error);
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = initialState.error;
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submit.pending, (s: State) => {
        s.status = THUNK_STATUS.LOADING;
        s.error = '';
      })
      .addCase(submit.fulfilled, (s: State) => {
        s.error = '';
        s.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(submit.rejected, (state: State, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = code;
        }
      });
  },
});

const selector = (state: AppState) => state[name];

export const { reset } = slice.actions;
export const selectLoading = createSelector(
  selector,
  (s: State) => s.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (s: State) => s.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFailed = createSelector(
  selector,
  (s: State) => s.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export default slice.reducer;
