import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CanceledError,
} from 'axios';
import qs from 'qs';

import { defaultHeaders, env } from 'constants/configs';
import { logSuccess } from 'utils/logger';
import { Obj } from 'models/Object';
import { tokenStore } from 'data/auth/token';
import { setId as setUserId } from 'data/auth/currentUser';
import { getCurrentUser } from 'utils/token';
import { doLogoutAndSyncToken } from 'hooks/useLogout';
import { actions as errorDialogAction } from 'modules/errorDialog';
import { state as internetConnectionState } from 'data/internet/status';

import notification from 'modules/notification';
import { queryClient } from 'index';
import { API_QUERY_KEY } from './ApiQueryKey';
import { _translate } from 'translate/TranslateProvider';
import RefreshToken from 'services/RefreshToken';

// init empty store
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let store: any;

export const UNKNOWN_ERROR = 'UNKNOWN_ERROR' as const;

const UNKNOWN_RESPONSE = {
  response: {
    status: 0,
    data: {
      error: {
        code: UNKNOWN_ERROR,
        message: UNKNOWN_ERROR,
      },
    },
  },
};

const BASE_URL = `${env.REACT_APP_API_URL}/api`;

type RequestCallback = (_token: string) => void;

export type RequestConfig = AxiosRequestConfig & {
  diffDomain?: string;
  token?: string;
  apiVersion: string;
  actionRetryName?: string;
  isRetryAction?: boolean;
  isPrivate?: boolean;
  formData?: FormData;
  ignoreConnection?: boolean;
  reduxActionName?: string;
};

interface AppRequestConfig extends AxiosRequestConfig {
  actionRetryName?: string;
  isRetryAction?: boolean;
  diffDomain?: string;
  options?: Obj;
  headerConfig?: Obj;
  reduxActionName?: string;
}

interface AppRequestResponse extends AxiosResponse {
  config: AppRequestConfig;
}

let isAlreadyFetchingAccessToken = false;
let subscribers: RequestCallback[] = [];

const axiosInstance = axios.create();
export const abortInstance = axios.CancelToken.source();

const successHandler = <T>(response: AxiosResponse<T>): T => {
  logSuccess('Request success');
  return response.data;
};

const isTokenExpired = ({ status }: { status: number }) => status === 401;

const addSubscriber = (callback: RequestCallback) => subscribers.push(callback);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tryRefreshToken = async (error: any) => {
  try {
    const { response: errorResponse } = error;

    const refreshTokenKey = tokenStore.getState().refreshToken;
    if (!refreshTokenKey) {
      return Promise.reject(error);
    }

    // setup retry request
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((access_token) => {
        if (errorResponse) {
          errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
          errorResponse.config['isRetryAction'] = true;
          resolve(axiosInstance(errorResponse.config));
        }
      });
    });

    // call only one request to refresh token
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const response = await RefreshToken.tryRefresh();

      if (!response?.data) {
        return Promise.reject(error);
      }

      const tokenInfo = response.data.data;

      const parseAccessToken = getCurrentUser(tokenInfo.access_token);
      setUserId(parseAccessToken.sub);

      isAlreadyFetchingAccessToken = false;

      // set new token to request
      subscribers.forEach((callback) => callback(tokenInfo.access_token));
      subscribers = [];
    }
    return retryOriginalRequest;
  } catch (err) {
    isAlreadyFetchingAccessToken = false;
    doLogoutAndSyncToken();
    return Promise.reject(err);
  }
};

axiosInstance.interceptors.request.use(async (config: any) => {
  if (!store) {
    store = await import('store');
  }

  const isOnline = internetConnectionState.getState().isOnline;

  if (!isOnline && !config.ignoreConnection) {
    notification.lossInternet();

    store.default.dispatch({
      type: `${config.reduxActionName}/rejected`,
    });

    return {
      ...config,
    };
  }

  return config;
});

// interceptors to handle error
axiosInstance.interceptors.response.use(
  (response: AppRequestResponse) => {
    const {
      config: { actionRetryName, isRetryAction },
    } = response;

    if (isRetryAction && actionRetryName) {
      // store.dispatch({
      //   type: actionRetryName,
      //   payload: data,
      // });
    }
    return response;
  },
  async (error: AxiosError) => {
    const { response } = error;

    if (error instanceof CanceledError) {
      console.log('cancel');
      return Promise.reject(error);
    }

    if (!response) {
      return Promise.reject(UNKNOWN_RESPONSE);
    }

    // check if the error is service disabled
    const { data, status } = response;
    const _data = isJsonBlob(data)
      ? JSON.parse(await (data as any).text())
      : data;

    if (status === 403 && _data.error.code === 'forbidden') {
      queryClient.fetchQuery([API_QUERY_KEY.AUTH.GET_PROFILE]);
      return Promise.reject(error);
    }

    if (isTokenExpired(response)) {
      return tryRefreshToken(error);
    }

    if (status === 400 && _data.error.code === 'invalid_service') {
      queryClient.fetchQuery([API_QUERY_KEY.AUTH.GET_PROFILE]);
      errorDialogAction.showErrorDialog(_translate('MESSAGE.SERVICE_OFF'));
    }

    return Promise.reject(error);
  },
);

const httpRequest = async <T>({
  apiVersion = env.REACT_APP_API_VERSION,
  formData,
  diffDomain,
  headers,
  isPrivate = true,
  timeout = 3 * 60 * 1000,
  // cancelToken,
  ...config
}: RequestConfig) => {
  // check if api require auth but not have token, then return logout
  const token = tokenStore.getState().accessToken;
  if (isPrivate && !token) {
    return (async () => {
      if (!store) {
        store = await import('store');
      }
      // clear all local store
      doLogoutAndSyncToken();
      return Promise.reject();
    })();
  }

  return axiosInstance
    .request<T>({
      headers: {
        ...defaultHeaders,
        ...headers,
        ...(isPrivate && { Authorization: `Bearer ${token}` }),
      },
      baseURL: diffDomain
        ? `${diffDomain}/${apiVersion}`
        : `${BASE_URL}/${apiVersion}`,
      timeout,
      maxRedirects: 5,
      data: formData || config.data,
      // cancelToken,
      ...config,
    })
    .then(successHandler);
};

export const apiGet = <T>(request: RequestConfig): Promise<T> => {
  return httpRequest({
    method: 'GET',
    paramsSerializer: (params: Obj) => {
      return qs.stringify(params, {
        indices: false,
      });
    },
    ...request,
  });
};

export const apiPost = <T>({
  apiVersion = env.REACT_APP_API_VERSION,
  ...request
}: RequestConfig): Promise<T> => {
  return httpRequest({
    method: 'POST',
    apiVersion,
    ...request,
  });
};

export const apiPut = <T>(request: RequestConfig): Promise<T> => {
  return httpRequest({
    method: 'PUT',
    paramsSerializer: (params: Obj) => {
      return qs.stringify(params, {
        indices: false,
      });
    },
    ...request,
  });
};

export const apiPatch = <T>(request: RequestConfig): Promise<T> => {
  return httpRequest({
    method: 'PATCH',
    paramsSerializer: (params: Obj) => {
      return qs.stringify(params, {
        indices: false,
      });
    },
    ...request,
  });
};

export const apiDelete = <T>(request: RequestConfig): Promise<T> => {
  return httpRequest({
    method: 'DELETE',
    ...request,
  });
};

export const putForm = <T>({
  apiVersion = env.REACT_APP_API_VERSION,
  formData,
  ...request
}: RequestConfig): Promise<T> => {
  return httpRequest({
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    formData,
    apiVersion,
    ...request,
  });
};

export const postForm = <T>({
  apiVersion = env.REACT_APP_API_VERSION,
  formData,
  ...request
}: RequestConfig): Promise<T> => {
  return httpRequest({
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    formData,
    apiVersion,
    ...request,
  });
};

// util
const isJsonBlob = (data: unknown) =>
  data instanceof Blob && data.type === 'application/json';
