import { API_QUERY_KEY } from 'apis/ApiQueryKey';
import { getTimeZones } from 'apis/userProfile/getTimeZones';
import { useQuery } from 'react-query';
import { currentUserState, selectors } from 'data/auth/currentUser';

const useGetTimeZone = () => {
  const isAuth = currentUserState(selectors.selectIsAuth);
  return useQuery(
    [API_QUERY_KEY.AUTH.GET_TIME_ZONES],
    ({ signal }) => getTimeZones(signal),
    {
      select(d) {
        return d.data.timezones.map((i) => ({
          value: i.zone_id,
          label: i.format,
        }));
      },
      enabled: isAuth,
    },
  );
};

export { useGetTimeZone };
