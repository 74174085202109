import { useMutation } from 'react-query';
import { notification } from 'antd';

import { logout } from 'apis/auth/logout';
import { resetAll } from 'data/reseter';
import { CLIENT_ID } from 'constants/configs';
import { tokenStore } from 'data/auth/token';
import MethodProcessing from 'page/private/RouterDetail/components/Methods/MethodProcess';
import { queryClient } from 'index';
import MQTTService from 'modules/qmtt/client';

export const doLogout = () => {
  resetAll();
  notification.destroy();
  MethodProcessing.destroy();
  MQTTService.destroy();
  MQTTService.publisher.destroy();
  queryClient.clear();
};

export const doLogoutAndSyncToken = () => {
  doLogout();
  localStorage.clear();
};

const useLogout = () => {
  const _action = useMutation(
    () => {
      logout({
        clientId: CLIENT_ID,
        refreshToken: tokenStore.getState().refreshToken,
        token: tokenStore.getState().accessToken,
      });

      return Promise.resolve({ data: {} });
    },
    {
      onSuccess: () => {
        console.log('onsuccess');
        doLogoutAndSyncToken();
      },
      onSettled: () => {
        console.log('onSettled');
        doLogoutAndSyncToken();
      },
    },
  );

  return {
    logout: _action.mutate,
  };
};

export { useLogout };
