import styled from 'styled-components';

import { Modal } from 'components/molecules/Modal';
import SvgIcon from 'components/atoms/SvgIcon';
import Text from 'components/atoms/Text';
import Button from 'components/molecules/Button';
import { theme } from 'theme/theme';
import { useTranslate } from 'translate/useTranslate';

import Block from '../../atoms/Block';

type Props = {
  visible: boolean;
  message: string;
  messageExtraParams?: Record<string, string | number>;
  onClose: () => void;
  title?: string;
  asHTML?: boolean;
};

const Message = styled(Text)`
  margin-top: 6px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Content = styled(Block)`
  margin-left: 20px;
  flex: 1;
`;

const StyledModal = styled(Modal)`
  width: 498px !important;
  z-index: ${theme.zIndex.errorDialog};
  .ant-modal-content {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  .ant-modal-body {
    padding: 20px;
    font-size: 14px;
    line-height: 16px;
  }
`;

const ErrorDialog = ({
  visible,
  message,
  messageExtraParams,
  onClose,
  title,
  asHTML,
}: Props): JSX.Element => {
  const { t } = useTranslate();
  return (
    <StyledModal visible={visible} closable={false} footer={false}>
      <Block flex>
        <SvgIcon name="error_fill" color={theme.colors.red_500} size={46} />
        <Content flex column flexGrow={1}>
          <Text variant="h2" bold color="gray-900">
            {title || t('ERROR_DIALOG.TITLE')}
          </Text>
          {asHTML ? (
            <p
              style={{ color: theme.colors.grayscale_900 }}
              className="mt-6"
              dangerouslySetInnerHTML={{
                __html: t(message),
              }}
            ></p>
          ) : (
            <Message color="gray-900">{t(message, messageExtraParams)}</Message>
          )}
        </Content>
      </Block>
      <Actions>
        <Button variant="secondary" onClick={onClose}>
          {t('ERROR_DIALOG.BTN.CLOSE')}
        </Button>
      </Actions>
    </StyledModal>
  );
};

export default ErrorDialog;
