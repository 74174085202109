import { apiPost } from 'apis/baseAPI';
import { AUTH_URL } from 'constants/configs';

type Req = {
  token: string;
  refreshToken: string;
  clientId: string;
};

const logout = ({
  clientId,
  refreshToken,
  token,
}: Req): Promise<ServerResponse<Record<string, never>>> => {
  return apiPost({
    url: `${AUTH_URL}/logout`,
    data: {
      client_id: clientId,
      token: refreshToken,
    },
    apiVersion: 'v1',
    token,
  });
};

export { logout };
