import { createSelector, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { thunkWrapper } from 'utils/thunkWrapper';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { apiGet } from 'apis/baseAPI';
import { ApiError } from 'models/Errors';
import { setValue } from 'page/private/RouterDetail/components/BasicInformation/data/lastCommunicate';

import { WiFiChannel } from '../../../models/Router';
import { PROXY_DOMAIN } from '../../../constants/configs';

type State = StateType<{
  channel2: WiFiChannel[];
  channel5: WiFiChannel[];
}>;

const name = 'wiFiChanel';
const initialState: State = {
  data: {
    channel2: [],
    channel5: [],
  },
  error: '',
  status: THUNK_STATUS.LOADING,
};

const whitelist: string[] = [];

type WiFiChanelResp = {
  chanel2: WiFiChannel[];
  chanel5: WiFiChannel[];
};

export const getData = thunkWrapper<string>({
  name: `${name}/getData`,
  action: async ({ thunkApi, headers, requestData }) => {
    try {
      const rs = await apiGet<ServerResponse<WiFiChanelResp>>({
        url: `router/rpc/wifi_channel/${requestData}`,
        apiVersion: 'v2',
        token: headers.token,
        diffDomain: `${PROXY_DOMAIN}/api`,
        headers: {
          'X-Device-Id': 'deviceid',
          'X-Device-Family': 'PC',
          'X-Device-Os': 'WIN',
          'X-Device-OS-Version': 10,
          'X-Device-Locale': 'vi_VN',
          'X-App-Version': '1.3.0',
        },
        reduxActionName: `${name}/getData`,
      });
      setValue(rs.meta.last_communication);
      return rs;
    } catch (e) {
      return thunkApi.rejectWithValue(parseError(e as ApiError));
    }
  },
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorCode = '';
      state.errorId = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getData.pending as never]: (state: State) => {
      state.error = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [getData.fulfilled as never]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      const rs = action.payload.data || {
        chanel2: [],
        chanel5: [],
      };
      state.data = rs;
    },
    [getData.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload.message;
      state.errorCode = action.payload.code;
    },
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectData = createSelector(
  selector,
  (state: State) => state.data,
);

export const selectWifiInfoLoaded = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectWifiInfoLoadFail = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectWifiInfoReady = createSelector(
  selector,
  (state) =>
    state.status === THUNK_STATUS.SUCCEEDED ||
    state.status === THUNK_STATUS.FAILED,
);

export const selectWiFiInfoError = createSelector(
  selector,
  (state: State) => state.errorCode,
);

export default persistReducer({ key: name, storage, whitelist }, slice.reducer);
