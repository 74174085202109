import { createSelector, createSlice } from '@reduxjs/toolkit';
import { StateType, StatusType } from 'models/Store';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { THUNK_STATUS } from 'constants/status';
import { thunkWrapper } from 'utils/thunkWrapper';
import { AppState } from 'store/rootReducer';
import { apiGet } from 'apis/baseAPI';

import {
  WiFiCipher,
  WiFiEncryption,
  WiFiInformation,
  WiFiMacFilter,
  WiFiMode,
  WiFiRadio,
} from 'models/Router';
import { PROXY_DOMAIN } from 'constants/configs';
import {
  WIFI_CIPHER,
  WIFI_ENCRYPTION,
  WIFI_MAC_FILTER,
  WIFI_MODE,
} from 'constants/Router';
import { uuidV4 } from 'utils/uuid';
import { ApiError } from 'models/Errors';
import { setValue } from 'page/private/RouterDetail/components/BasicInformation/data/lastCommunicate';
import { parseError } from 'utils/strings';

const name = 'wifiInfo';

export type LoadingMode = 'both' | 'primary' | 'guest';

type WifiInfo = WiFiInformation & { loadingInfo: StatusType };

type RouterWiFi = {
  primary: WifiInfo;
  guest: WifiInfo;
  radio: WiFiRadio;
};

type WiFiResponse = {
  disabled: true;
  ssid: string;
  bssid: string;
  password: string;
  channel: number;
  frequency: string;
  encryption_status: string;
  security: string;
  cipher: string;
  ssid_hidden: false;
  wmm_mode: true;
  mac_filter_type: string;
  mac_filter_devices: {
    host_name: string;
    mac: string;
  }[];
  isolate_clients: boolean;
};

type RouterWiFiResponse = {
  primary: WiFiResponse;
  guest: WiFiResponse;
  radio: {
    mode: string;
    band: number;
    channel: number;
    width: number;
  };
};

type State = StateType<{
  wiFiInfo: RouterWiFi;
  loadingMode: LoadingMode;
}>;

const initialState: State = {
  data: {
    wiFiInfo: {
      guest: {
        ssId: '',
        channel: 0,
        cipher: 0,
        disabled: false,
        encryptionStatus: '',
        frequency: '',
        macFilterDevices: [],
        macFilterType: 0,
        password: '',
        security: 0,
        ssIdHidden: false,
        wmmMode: false,
        isolateClients: false,
        loadingInfo: THUNK_STATUS.IDLE,
      },
      primary: {
        ssId: '',
        channel: 0,
        cipher: 0,
        disabled: false,
        encryptionStatus: '',
        frequency: '',
        macFilterDevices: [],
        macFilterType: 0,
        password: '',
        security: 0,
        ssIdHidden: false,
        wmmMode: false,
        isolateClients: false,
        loadingInfo: THUNK_STATUS.IDLE,
      },
      radio: {
        band: 0,
        channel: 0,
        mode: 0,
        width: 0,
      },
    },
    loadingMode: 'both',
  },
  error: '',
  status: THUNK_STATUS.LOADING,
};

const whitelist: string[] = [];

const convertWiFiInformation = (scr: WiFiResponse): WifiInfo => {
  return {
    channel: scr.channel,
    cipher:
      WIFI_CIPHER.LABEL_KEY[
        scr?.cipher?.toUpperCase() as Uppercase<WiFiCipher>
      ],
    disabled: scr.disabled,
    encryptionStatus: scr.encryption_status || '',
    frequency: scr.frequency || '',
    macFilterDevices: (scr.mac_filter_devices || []).map((i) => ({
      hostName: i.host_name,
      mac: i.mac,
      id: uuidV4(),
    })),
    macFilterType:
      WIFI_MAC_FILTER.LABEL_KEY[
        scr.mac_filter_type?.toUpperCase() as Uppercase<WiFiMacFilter>
      ],
    password: scr.password,
    security:
      WIFI_ENCRYPTION.LABEL_KEY[
        scr.security?.toUpperCase() as Uppercase<WiFiEncryption>
      ],
    ssId: scr.ssid,
    ssIdHidden: scr.ssid_hidden,
    wmmMode: scr.wmm_mode,
    isolateClients: scr.isolate_clients,
    loadingInfo: THUNK_STATUS.SUCCEEDED,
  };
};

const convertRadio = (src: {
  mode: string;
  band: number;
  channel: number;
  width: number;
}): WiFiRadio => {
  return {
    band: src.band,
    channel: src.channel,
    mode: WIFI_MODE.LABEL_KEY[src.mode?.toUpperCase() as Uppercase<WiFiMode>],
    width: src.width,
  };
};

export const getData = thunkWrapper<{
  routerId: string;
  mode: LoadingMode;
}>({
  name: `${name}/getData`,
  action: async ({ thunkApi, headers, requestData }) => {
    try {
      const rs = await apiGet<ServerResponse<RouterWiFiResponse>>({
        url: `router/rpc/wifi_info/${requestData.routerId}`,
        apiVersion: 'v2',
        token: headers.token,
        diffDomain: `${PROXY_DOMAIN}/api`,
        reduxActionName: `${name}/getData`,
      });
      setValue(rs.meta.last_communication);
      return rs;
    } catch (e) {
      return thunkApi.rejectWithValue(parseError(e as ApiError));
    }
  },
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorCode = '';
      state.errorId = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getData.pending]: (state: State, action) => {
      state.error = '';
      const { mode } = action.meta.arg;
      state.data.loadingMode = mode;
      state.status = THUNK_STATUS.LOADING;

      if (mode !== 'primary') {
        state.data.wiFiInfo.guest.loadingInfo = THUNK_STATUS.LOADING;
      }
      if (mode !== 'guest') {
        state.data.wiFiInfo.primary.loadingInfo = THUNK_STATUS.LOADING;
      }
    },
    [getData.fulfilled]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;

      const { guest, primary, radio } = action.payload.data;
      // set State base on loading mode

      const { mode } = action.meta.arg;
      state.data.loadingMode = mode;

      if (mode !== 'primary') {
        state.data.wiFiInfo.guest = convertWiFiInformation(guest);
      }
      if (mode !== 'guest') {
        state.data.wiFiInfo.primary = convertWiFiInformation(primary);
      }

      state.data.wiFiInfo.radio = convertRadio(radio);
    },
    [getData.rejected]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload.message;
      state.errorCode = action.payload.code;
      const { loadingMode: mode } = state.data;

      if (mode !== 'primary') {
        state.data.wiFiInfo.guest.loadingInfo = THUNK_STATUS.FAILED;
      }
      if (mode !== 'guest') {
        state.data.wiFiInfo.primary.loadingInfo = THUNK_STATUS.FAILED;
      }
    },
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

// export const selectLoading = createSelector(
//   selector,
//   (data) => data.status === THUNK_STATUS.LOADING,
// );
//
export const selectRouterInfoLoadFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectLoadingMode = createSelector(
  selector,
  (state) => state.data.loadingMode,
);

export const selectLoadingPrimary = createSelector(
  selector,
  (state) => state.data.wiFiInfo.primary.loadingInfo === THUNK_STATUS.LOADING,
);

export const selectLoadFailedPrimary = createSelector(
  selector,
  (state) => state.data.wiFiInfo.primary.loadingInfo === THUNK_STATUS.FAILED,
);

export const selectLoadingGuest = createSelector(
  selector,
  (state) => state.data.wiFiInfo.guest.loadingInfo === THUNK_STATUS.LOADING,
);

export const selectLoadFailedGuest = createSelector(
  selector,
  (state) => state.data.wiFiInfo.guest.loadingInfo === THUNK_STATUS.FAILED,
);

export const selectPrimaryWifi = createSelector(
  selector,
  (state: State) => state.data.wiFiInfo.primary,
);

export const selectIsPrimaryDisabled = createSelector(
  selectPrimaryWifi,
  (data) => data.disabled,
);

export const selectGuestWifi = createSelector(
  selector,
  (state: State) => state.data.wiFiInfo.guest,
);

export const selectIsGuestDisabled = createSelector(
  selectGuestWifi,
  (d) => d.disabled,
);

export const selectWiFiRadio = createSelector(
  selector,
  (state: State) => state.data.wiFiInfo.radio,
);

export const selectRouterInfoLoaded = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectWiFiInfoError = createSelector(
  selector,
  (state: State) => state.errorCode,
);

export const selectRouterInfoReady = createSelector(
  selector,
  (state) =>
    state.status === THUNK_STATUS.SUCCEEDED ||
    state.status === THUNK_STATUS.FAILED,
);

export default persistReducer({ key: name, storage, whitelist }, slice.reducer);
