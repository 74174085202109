import intersect from 'just-intersect';

import { MenuItem } from 'layout/routes';
import { currentUserState } from 'data/auth/currentUser';

const useCheckService = (
  availableForService: MenuItem['availableForService'],
): boolean => {
  const services = currentUserState.getState().services;

  const allowByService =
    availableForService === 'ALL'
      ? true
      : Boolean(intersect(availableForService, services).length);

  return allowByService;
};

export { useCheckService };
