import { createSelector, createSlice } from '@reduxjs/toolkit';
import { apiDelete } from 'apis/baseAPI';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import { parseErrorMessage } from 'utils/strings';
import { thunkWrapper } from 'utils/thunkWrapper';
import notification from 'modules/notification';
import { _translate } from 'translate/TranslateProvider';

const name = 'deleteInvitation';

type State = StateType<unknown>;

export type UserResendRequest = {
  request_id: string;
  email: string;
};

const initialState: State = {
  data: null,
  error: '',
  status: THUNK_STATUS.IDLE,
};

export const deleteInvitationUser = thunkWrapper<UserResendRequest>({
  name: `${name}/delete`,
  withToken: true,
  action: async ({ headers, thunkApi, requestData }) => {
    {
      try {
        await apiDelete({
          url: 'portal/user/invitation/delete',
          apiVersion: 'v2',
          token: headers.token,
          data: {
            request_id: requestData.request_id,
          },
          reduxActionName: `${name}/delete`,
        });
        const email = requestData.email;
        notification.success(
          _translate('PROFILE.TABLE.ROUTERS.ACTION.DELETE.TOAST.SUCCESS', {
            email,
          }),
        );
        return;
      } catch (e) {
        return thunkApi.rejectWithValue(Error(parseErrorMessage(e)).message);
      }
    }
  },
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [deleteInvitationUser.pending as never]: (state: State) => {
      state.status = THUNK_STATUS.LOADING;
    },
    [deleteInvitationUser.fulfilled as never]: (state: State) => {
      state.status = THUNK_STATUS.SUCCEEDED;
    },
    [deleteInvitationUser.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload;
    },
  },
});

const selector = (state: AppState) => state[name];

export const { reset } = slice.actions;

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSucceed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectError = createSelector(selector, (state) => state.error);

export default slice.reducer;
