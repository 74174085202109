import { useEffect, useState } from 'react';

import Button from 'components/molecules/Button';
import Banner from './banner';
import { PreviewModal } from 'page/private/CaptivePortal/components/SplashPageV2/styled';

import { _translate } from 'translate/TranslateProvider';

type Props = {
  open: boolean;
  content: string;
  onClose: Noop;
};

const PreviewBannerModal = ({ open, content, onClose }: Props): JSX.Element => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    if (open) {
      setShowBanner(true);
    }
  }, [open]);

  return (
    <PreviewModal
      visible={open}
      destroyOnClose
      footer={
        <div className="flex justify-end">
          <Button
            key="back"
            variant="secondary"
            className="ml-8"
            onClick={onClose}
          >
            {_translate('BUTTON.EXIT_PREVIEW')}
          </Button>
        </div>
      }
    >
      {showBanner && (
        <Banner
          onClose={() => {
            setShowBanner(false);
          }}
          content={content}
        />
      )}
    </PreviewModal>
  );
};

export default PreviewBannerModal;
