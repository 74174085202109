import { UserStatusType, UserRole } from 'models/User';
import { Const } from 'models/Const';

export const USER_ROLE: Const<UserRole> = {
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  DEVELOPER: 'DEVELOPER',
  MANAGER: 'MANAGER',
  VIEWER: 'VIEWER',
  DEVICE_MANAGER: 'DEVICE_MANAGER',
  DEVICE_VIEWER: 'DEVICE_VIEWER',
  NO_ORGANIZATION: 'NO_ORGANIZATION',
  ORGANIZATIONS_BLOCK: 'ORGANIZATIONS_BLOCK',
};

export const USER_STATUS: Const<UserStatusType> = {
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED',
};

export const USER_ROLE_OPTIONS = [
  {
    label: 'Org Admin',
    value: 'ADMIN',
  },
  {
    label: 'Org Manager',
    value: 'MANAGER',
  },
  {
    label: 'Org Viewer',
    value: 'VIEWER',
  },
  {
    label: 'Device Manager',
    value: 'DEVICE_MANAGER',
  },
  {
    label: 'Device Viewer',
    value: 'DEVICE_VIEWER',
  },
];

export const USER_STATUSES_OPTIONS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Deactivated',
    value: 'DEACTIVATED',
  },
];
