/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from '@reduxjs/toolkit';
import listUser from 'features/users/list';
import inviteUser from 'features/users/invite';
import userDetail from 'features/users/detail';
import updateUser from 'features/users/update-detail';
import deleteUser from 'features/users/delete';
import changeUserStatus from 'features/users/change-status';
import invitationRequest from 'features/users/invitation-request';
import resendInvitation from 'features/users/resend-invitation';
import deleteInvitation from 'features/users/delete-invitation';
import listRouterManagers from 'features/users/list-router-managers';
import router from 'features/router';
import organization from 'features/organization';
import support from 'features/support';
import captive from 'features/captive';
import potNotification from 'features/POTNotification';
import callQueue from 'features/callQueue';
import superAdmin from 'features/superAdmin';
import apiQueryReducer from './apiQueryReducer';

const rootReducer = ((asyncReducers?: any): any =>
  combineReducers({
    listUser,
    organization,
    inviteUser,
    updateUser,
    deleteUser,
    changeUserStatus,
    userDetail,
    invitationRequest,
    resendInvitation,
    deleteInvitation,
    router,
    listRouterManagers,
    support,
    captive,
    potNotification,
    callQueue,
    superAdmin,
    ...apiQueryReducer,
    ...asyncReducers,
  }))();

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
