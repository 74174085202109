import { getSafe } from 'utils/getSafe';
import { kase } from 'utils/kase';

import { lastCommunicationHandler } from './lastCommunication';
import { MESSAGE_KEY } from '../config';
import { ServerTopic } from './types';

const LAST_COMMUNICATE_EFFECT_TOPIC = {
  wan_ip: true,
  ssid_name: true,
  slic_fw_ver: true,
  cell_band: true,
  slic_model: true,
  msisdn: true,
  phone_name: true,
  slic_unit_id: true,
  slic_power_cfg: true,
  ups_beeper_status: true,
  ups_status: true,
  firmware_version: true,
  regtoken: true,
  cell_mode: true,
  call_queue: true,
  connected_devs: true,
  cell_ip: true,
  apn: true,
  cell_operator: true,
  mcc: true,
  mnc: true,
} as const;

const attributeHandler = (t: string, m: Buffer, store: any) => {
  const serverPayload = JSON.parse(m.toString()) as ServerTopic;
  const payload = getSafe<number>(() => parseInt(String(serverPayload.value)));

  const key = t.split('/').slice(-1)[0];

  // check to update last communication
  if (key in LAST_COMMUNICATE_EFFECT_TOPIC) {
    lastCommunicationHandler(t, m);
  }

  kase(key)
    .when(MESSAGE_KEY.PHONE_NAME, () => {
      store.dispatch({
        type: 'routerDetail/setPhoneName',
        payload,
      });
    })
    .when(MESSAGE_KEY.PHONE_NUMBER, () => {
      store.dispatch({
        type: 'routerDetail/setPhoneNumber',
        payload,
      });
    })
    .when(MESSAGE_KEY.SLIC_MODEL, () => {
      store.dispatch({
        type: 'routerDetail/setPhoneModel',
        payload: payload,
      });
    })
    .when(MESSAGE_KEY.SLIC_FW_VER, () => {
      store.dispatch({
        type: 'routerDetail/setPhoneFirmware',
        payload: serverPayload.value,
      });
    })
    .when(MESSAGE_KEY.CALL_QUEUE, () => {
      store.dispatch({
        type: 'callQueue/setCallQueue',
        payload: { ...serverPayload, since: 0, ts: 0 },
      });
    });
};

export { attributeHandler };
