import { createApi } from '@reduxjs/toolkit/query/react';
import { env } from 'constants/configs';

import { API_QUERY_KEY } from './ApiQueryKey';
import { axiosBaseQueryPOST } from './axiosBaseQuery';

interface Request {
  failover: number[];
  imei: string;
  lineStatus: number[];
  orgIds: string[];
  routerStatus: string;
}

interface RouterItem {
  coordinates: [number, number];
  id: string;
}

type Response = {
  things: RouterItem[];
};

const voiceLinkMapDataApi = createApi({
  reducerPath: API_QUERY_KEY.VOICELINK.GET_MAP_DATA,
  baseQuery: axiosBaseQueryPOST({
    baseUrl: `${env.REACT_APP_PROXY_DOMAIN}/api`,
    version: 'v3',
  }),
  endpoints: (build) => ({
    getData: build.query({
      query: (data: Request) => {
        const requestData = {
          fail_over_status: data.failover,
          imei: data.imei,
          line_status: data.lineStatus,
          org_id: data.orgIds,
          router_status: data.routerStatus,
        };
        return {
          url: 'router/search_map_lite',
          data: {
            filter: requestData,
          },
        };
      },
      transformResponse: (r: Response) => {
        if (!r) {
          return [];
        }

        return r.things;
      },
    }),
  }),
});

export const { useGetDataQuery } = voiceLinkMapDataApi;

export { voiceLinkMapDataApi };
