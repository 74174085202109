import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { apiPost } from 'apis/baseAPI';
import { env } from 'constants/configs';
import { THUNK_STATUS } from 'constants/status';
import { ApiError } from 'models/Errors';
import { StateType } from 'models/Store';
import { Pagination } from 'models/Table';
import { GLOBAL_ACTION } from 'store/config';
import { AppState } from 'store/rootReducer';
import { parseErrorMessage } from 'utils/strings';

import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routerFilter } from 'page/private/CaptivePortal/data/routerFilter';

const name = 'routers';

export interface RouterCaptive {
  portal_id: string;
  thing_id: string;
  imei: string;
  unitid: string;
  house_name: string;
  house_address: string;
  organization_info: OrganizationInfo;
  is_applied_config: boolean;
  firmware: string;
  connected: boolean;
  portal_captive: PortalCaptive;
}

interface OrganizationInfo {
  name: string;
  logo: string;
  nameLo: string;
  org_id: string;
}

export interface PortalCaptive {
  id: string;
  status: string;
  readonly: boolean;
  portal_name: string;
  organization_info: OrganizationInfo;
  bg_img: string;
  bg_color: string;
}

type State = StateType<{
  routers: RouterCaptive[];
  pagination: Pagination;
  sort: {
    field: string;
    order?: string;
  };
}>;

const initialState: State = {
  data: {
    routers: [],
    pagination: {
      page: 1,
      size: 25,
      total_elements: 0,
      total_pages: 0,
    },
    sort: {
      field: '',
      order: '',
    },
  },
  error: '',
  status: THUNK_STATUS.IDLE,
  errorCode: '',
  errorId: '',
};

export const getData = createAsyncThunk(
  name,
  async (_, { rejectWithValue }) => {
    try {
      const {
        textSearch,
        orgIds,
        captives,
        pagination,
        sort,
      } = routerFilter.getState();
      const sorting = sort.field
        ? `${sort.order ? sort.field : initialState.data.sort.field}:${
            sort.order ? (sort.order === 'ascend' ? 1 : -1) : -1
          }`
        : {};

      const resp = await apiPost<{
        data: {
          configurations: RouterCaptive[];
          paging: Pagination;
        };
      }>({
        diffDomain: `${env.REACT_APP_CAPTIVE_PORTAL_URL}/api`,
        url: 'portal/captive/configuration/search',
        apiVersion: 'v1',
        reduxActionName: name,
        data: {
          filter: {
            search_text: textSearch,
            org_ids: orgIds,
            portal_ids: captives,
          },
          sort: { sort: sort.field ? sorting : '' },
          paging: {
            page: pagination.page - 1,
            size: pagination.size,
          },
        },
      });
      return resp;
    } catch (e) {
      return rejectWithValue(Error(parseErrorMessage(e as ApiError)).message);
    }
  },
);

const BASE_PORTAL: PortalCaptive = {
  bg_color: '',
  bg_img: '',
  id: '',
  organization_info: {
    logo: '',
    name: '',
    nameLo: '',
    org_id: '',
  },
  portal_name: '',
  readonly: false,
  status: 'DRAFT',
};

const mapData = (d: RouterCaptive): RouterCaptive => ({
  ...d,
  portal_captive: d.portal_captive || BASE_PORTAL,
});

const resetState = (state: State) => {
  state.error = '';
  state.status = THUNK_STATUS.IDLE;
  state.data = initialState.data;
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (s) => resetState(s),
    resetData(state) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data.routers = [];
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.data.pagination.size = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.data.pagination.page = action.payload;
    },
    setSort(state, action) {
      state.data.sort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state) => {
        state.error = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data.routers = action.payload.data.configurations.map(mapData);
      })
      .addCase(getData.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `captive_list.${code}`;
        }
      })
      .addCase(GLOBAL_ACTION.LOGGED_OUT, (state) => {
        resetState(state);
      });
  },
});

export const routerCaptiveActions = slice.actions;

const selector = (state: AppState) => state.captive[name] as State;

const selectData = createSelector(selector, (state) => {
  return state.data.routers;
});

const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

const selectFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

const selectPagination = createSelector(selector, (s) => s.data.pagination);

const selectSort = createSelector(selector, (s) => {
  return {
    ...s.data.sort,
    order: s.data.sort.order || '-1',
  };
});

export const routerCaptiveSelector = {
  selectData,
  selectLoading,
  selectFailed,
  selectSuccess,
  selectError,
  selectPagination,
  selectSort,
};

const SetTransform = createTransform(
  (inboundState: { pagination: Pagination }) => {
    const { pagination } = inboundState;
    return pagination;
  },

  (outboundState) => {
    return {
      ...initialState.data,
      pagination: outboundState,
    };
  },

  { whitelist: ['data'] },
);

export default persistReducer(
  { key: name, storage, transforms: [SetTransform] },
  slice.reducer,
);
