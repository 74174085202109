/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { AxiosError } from 'axios';
import { apiGet, apiPost } from './baseAPI';

export const axiosBaseQueryGET = ({
  baseUrl = '',
  version = 'v1',
}: {
  baseUrl: string;
  version: string;
}): BaseQueryFn<
  {
    url: string;
  },
  unknown,
  unknown
> => async ({ url }) => {
  try {
    const rs = apiGet({
      diffDomain: baseUrl,
      url,
      apiVersion: version,
    });
    return { data: ((await rs) as any).data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};

export const axiosBaseQueryPOST = ({
  baseUrl = '',
  version = 'v1',
}: {
  baseUrl: string;
  version: string;
}): BaseQueryFn<
  {
    url: string;
    data: Record<string, any>;
  },
  unknown,
  unknown
> => async ({ url, data }) => {
  try {
    const rs = apiPost({
      diffDomain: baseUrl,
      url,
      apiVersion: version,
      data,
    });
    return { data: ((await rs) as any).data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};
