import { StateType } from 'models/Store';
import { User } from 'models/User';
import { THUNK_STATUS } from 'constants/status';
import { thunkWrapper } from 'utils/thunkWrapper';
import { apiGet } from 'apis/baseAPI';
import { parseErrorMessage } from 'utils/strings';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store/rootReducer';

const name = 'listRouterManagers';

type State = StateType<User[]>;

type RequestData = {
  organizationId: string;
  searchText: string;
};

const initialState = {
  data: [],
  error: '',
  status: THUNK_STATUS.IDLE,
} as State;

export const getRouterManagers = thunkWrapper({
  name: `${name}/getManagers`,
  action: async ({ thunkApi, headers, requestData }) => {
    try {
      const { searchText } = requestData as RequestData;

      return await apiGet({
        url: 'portal/user/list/router-managers',
        apiVersion: 'v2',
        token: headers.token,
        params: {
          search_text: searchText,
        },
        reduxActionName: `${name}/getManagers`,
      });
    } catch (e) {
      return thunkApi.rejectWithValue(Error(parseErrorMessage(e)).message);
    }
  },
});
const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: {
    [getRouterManagers.pending]: (state: State) => {
      state.error = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [getRouterManagers.fulfilled]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data = action.payload.data.map(
        (item: User & { user_id: string }) => ({
          ...item,
          id: item.user_id,
        }),
      );
    },
    [getRouterManagers.rejected]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload;
    },
  },
});

const selector = (state: AppState) => state[name];

export const selectRouterUsers = createSelector(
  selector,
  (state: State) => state.data,
);

export const selectSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export default slice.reducer;
