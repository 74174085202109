const useIsAllowRouterServiceSubscription = (
  serviceSubscription?: any[],
): boolean => {
  if (!serviceSubscription) {
    return true;
  }

  return serviceSubscription.find((x) => x.service === 'ROUTER')?.allow ?? true;
};

export { useIsAllowRouterServiceSubscription };
