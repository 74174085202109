import { UserRole } from 'models/User';

const hasPermission = (
  curRole: Uppercase<UserRole>[] = [],
  rolePool: Uppercase<UserRole>[],
): boolean => {
  return rolePool.some((i) => curRole.includes(i));
};

export const hasAllPermission = (
  curRole: string[],
  rolePool: Uppercase<UserRole>[],
): boolean => {
  return rolePool.every((i) => curRole.includes(i));
};

export const hasSomePermission = (
  curRole: string[],
  rolePool: Uppercase<UserRole>[],
): boolean => {
  return rolePool.some((i) => curRole.includes(i));
};

export { hasPermission };
