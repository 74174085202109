import { css } from 'styled-components';

export const DatePickerCustomStyle = css`
  .si-date-picker {
    .ant-picker {
      max-width: 360px;
      border-radius: 4px;
      .ant-picker-suffix .anticon-calendar {
        color: var(--Colors-Grayscale-600);
      }
    }
    .ant-picker-dropdown-range {
      padding: 0;
      .ant-picker-range-arrow {
        display: none;
      }
    }
    .ant-picker-input input {
      color: var(--Colors-Grayscale-900);
    }
    .ant-picker-input-placeholder input,
    .ant-picker-input > input::placeholder {
      color: var(--Colors-Grayscale-600);
    }
    .ant-picker-range .ant-picker-active-bar {
      background: var(--Colors-Blue-500);
    }
    .ant-picker:hover,
    .ant-picker-focused {
      border-color: var(--Colors-Blue-500);
    }

    .ant-picker-header-view {
      color: var(--Colors-Grayscale-900);
    }

    .ant-picker-header-view button:hover {
      color: var(--Colors-Blue-500);
    }

    .ant-picker-cell-selected.ant-picker-cell-in-view,
    .ant-picker-cell-range-start.ant-picker-cell-in-view,
    .ant-picker-cell-range-end.ant-picker-cell-in-view {
      .ant-picker-cell-inner {
        border: none;
        background: var(--Colors-Blue-500);
        color: white;
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
      border-top: 1px dashed var(--Colors-Blue-500);
      border-bottom: 1px dashed var(--Colors-Blue-500);
    }

    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
    tr
      > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
      border-right: 1px dashed var(--Colors-Blue-500);
    }

    tr
      > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
    tr
      > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
      border-left: 1px dashed var(--Colors-Blue-500);
    }

    ant-picker-cell-range-start.ant-picker-cell-today:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end) {
      .ant-picker-cell-inner {
        color: var(--Colors-Blue-500);
        &:before {
          border-color: var(--Colors-Blue-500);
        }
      }
    }

    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
      color: var(--Colors-Blue-500);
    }

    .ant-picker-cell-today.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        color: white;
        &:before {
          border: none;
        }
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border-color: var(--Colors-Blue-500);
    }

    .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
      color: var(--Colors-Blue-500);
      background: white;
      border-color: var(--Colors-Grayscale-200);
      cursor: pointer;
    }

    .ant-picker-ok {
      button {
        background-color: var(--Colors-Blue-500);
        border-width: 0;
        text-shadow: none;
        box-shadow: none;
        text-transform: uppercase;

        &[disabled] {
          background: #f5f5f5;
          border-color: #d9d9d9;
        }
      }
    }

    .ant-picker-today-btn {
      color: var(--Colors-Blue-500);
      font-size: 12px;
      padding: 4px 8px;
      border: 1px solid var(--Colors-Grayscale-200);
      border-radius: 2px;
    }
  }

  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-picker {
    border-color: var(--Colors-Red-500);
  }
`;
