import { apiPost } from 'apis/baseAPI';
import { env } from 'constants/configs';
import { AppConfigs } from 'hooks/useGetAppConfig';

type Payload = {
  type: string;
  name: string;
  value: string;
  desc: string;
};

type Resp = {
  data: AppConfigs;
};

const setAppConfig = (req: Payload): Promise<Resp> => {
  return apiPost({
    diffDomain: `${env.REACT_APP_AUTH_URL}/api`,
    apiVersion: 'v1',
    url: '/app-configuration/config',
    data: req,
  });
};

export { setAppConfig };
