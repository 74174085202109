import { PERSIST_KEY } from 'data/keys';
import create from 'zustand';
import { persist } from 'zustand/middleware';

// Configuration
export const { fallBackLanguage, languages } = {
  fallBackLanguage: 'en',
  languages: { en: 'English', es_MX: 'Mexican Spanish' },
};

type State = {
  language: string;
};

const initialState: State = {
  language: localStorage.getItem('language') || 'en',
};

type Action = {
  setLanguage: Callback<State['language']>;
};

export const languageStore = create<State>()(
  persist(() => initialState, { name: PERSIST_KEY.APP_LANGUAGE }),
);

export const setLanguage: Action['setLanguage'] = (l) =>
  languageStore.setState(() => ({ language: l }));
