import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { theme } from 'theme/theme';

import AppIcon, { IconType } from '../../atoms/SvgIcon';
import Block from '../../atoms/Block';

type Props = RRLinkProps & {
  suffixIcon?: IconType;
  className?: string;
  disabled?: boolean;
  containerStyle?: CSSProp;
  containerClass?: string;
};

const StyledLink = styled(RRLink)<RRLinkProps & { $disabled?: boolean }>`
  color: ${theme.colors.blue_500};
  font-size: 14px;
  line-height: 20px;
  &:hover {
    text-decoration: underline;
    color: ${theme.colors.blue_500};
  }
  ${(props) =>
    props.$disabled
      ? {
          color: theme.colors.grayscale_200,
          pointerEvents: 'none',
        }
      : null}
`;

const StyledContainer = styled(Block)<{ $disabled?: boolean }>`
  ${(props) =>
    props.$disabled
      ? {
          cursor: 'not-allowed',
        }
      : null}
`;

const Link = ({
  suffixIcon,
  disabled,
  containerClass,
  containerStyle,
  ...rest
}: Props): JSX.Element => {
  return (
    <StyledContainer
      className={containerClass}
      css={containerStyle}
      $disabled={disabled}
      flex
    >
      <StyledLink $disabled={disabled} {...rest} />
      {suffixIcon && (
        <AppIcon
          name={suffixIcon}
          size={20}
          color={disabled ? theme.colors.grayscale_200 : theme.colors.blue_500}
        />
      )}
    </StyledContainer>
  );
};

export default Link;
