import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { parseErrorMessage } from 'utils/strings';
import { apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { AppState } from 'store/rootReducer';
import { CallHistory } from 'models/POT';
import { Pagination } from 'models/Table';

type State = StateType<{
  data: CallHistory[];
  routerId: string;
  pagination: Pagination;
  sort: {
    field: string;
    order: 'ascend' | 'descend';
  };
  filter: {
    preset: string;
    start: string;
    end: string;
  };
  group?: '' | 'day' | 'hour';
}>;

const name = 'callHistory';

const initialState: State = {
  data: {
    data: [],
    routerId: '',
    pagination: {
      page: 1,
      size: 25,
      total_elements: 0,
      total_pages: 0,
    },
    sort: {
      field: 'date',
      order: 'descend',
    },
    filter: {
      preset: 'today',
      start: '',
      end: '',
    },
    group: 'day',
  },
  error: '',
  status: THUNK_STATUS.IDLE,
};

interface ResponseType {
  call_history: CallHistory[];
  paging: {
    total: number;
    number: number;
    size: number;
  };
}

export const callHistory = createAsyncThunk(
  name,
  async (
    requestData: {
      routerId: string;
      start?: number;
      end?: number;
      field?: string;
      dirc?: string;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { routerId } = requestData;
      const appState = getState() as AppState;

      let startDate;
      let endDate;
      if (requestData.start) {
        startDate = requestData.start;
      } else {
        startDate = (appState.router[name] as State).data.filter.start;
      }
      if (requestData.end) {
        endDate = requestData.end;
      } else {
        endDate = (appState.router[name] as State).data.filter.end;
      }

      const { group } = (appState.router[name] as State).data;
      const { page, size } = (appState.router[name] as State).data.pagination;
      const { field: sortField, order: direction } = (appState.router[
        name
      ] as State).data.sort;
      return await apiPost<{ data: ResponseType }>({
        url: `router/call_history/${routerId}`,
        apiVersion: 'v3',
        diffDomain: `${PROXY_DOMAIN}/api`,
        reduxActionName: name,
        data: {
          start: startDate,
          end: endDate,
          group,
          page: page - 1,
          size,
          order: requestData.field ? requestData.field : sortField,
          direction: requestData.dirc
            ? requestData.dirc
            : direction === 'descend'
            ? 'desc'
            : 'asc',
        },
      });
    } catch (e) {
      return rejectWithValue(Error(parseErrorMessage(e)).message);
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
    setDateRange(state, action) {
      const { dateRanges, preset } = action.payload;
      state.data.filter.preset = preset;
      state.data.filter.start = dateRanges[0];
      state.data.filter.end = dateRanges[1];
    },
    setPage(state, action) {
      state.data.pagination.page = action.payload;
    },
    setPageSize(state, action) {
      state.data.pagination.size = action.payload;
    },
    setSort(state, action) {
      state.data.sort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(callHistory.pending, (state) => {
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(callHistory.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data.data = action.payload.data.call_history;
        state.data.pagination.total_elements = action.payload.data.paging.total;
      })
      .addCase(callHistory.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        state.error = action.payload as string;
      });
  },
});

export const {
  reset,
  setSort,
  setDateRange,
  setPage,
  setPageSize,
} = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectLoaded = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectSummaryCallHistory = createSelector(
  selector,
  (state: State) =>
    state.data && state.data.data.length > 5
      ? [...state.data.data].slice(0, 5)
      : state.data.data,
);

export const selectCallHistory = createSelector(
  selector,
  (state: State) => state.data.data,
);
export const selectIsEmptyData = createSelector(
  selector,
  (state: State) => state.data.data.length === 0,
);
export const selectCallHistoryPagination = createSelector(
  selector,
  (state) => state.data.pagination,
);
export const selectCallHistorySort = createSelector(
  selector,
  (state) => state.data.sort,
);

export const selectFilter = createSelector(
  selector,
  (state: State) => state.data.filter,
);

export default slice.reducer;
