import NotAllowSvg from 'assets/images/Not_Allow.svg';
import Text from 'components/atoms/Text';
import styled from 'styled-components';

import { theme } from 'theme/theme';
import { useTranslate } from 'translate/useTranslate';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.base_50};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  text-align: center;
  height: 100%;
`;

const UnavailbleService = (): JSX.Element => {
  const { t } = useTranslate();
  return (
    <Container>
      <div>
        <img className="mb-20" src={NotAllowSvg} alt="Not allow" />
        <Text color="dark" bold variant="h2">
          {t('UNAVAILABE_SERVICE.MSG_1')}
        </Text>
        <Text color="dark" bold variant="h2">
          {t('UNAVAILABE_SERVICE.MSG_2')}
        </Text>
      </div>
    </Container>
  );
};

export default UnavailbleService;
