import ProgressingDialog from 'components/molecules/ProgressingDialog';
import { util } from 'data/utils';
import create from 'zustand';

import MethodProcess from './MethodProcess';

type State = {
  processingImei: string;
  type: 'error' | 'loading';
};

const initialState: State = {
  processingImei: '',
  type: 'loading',
};
const methodProcessDialog = create<State>(() => initialState);
const zutil = util(methodProcessDialog.setState, methodProcessDialog.getState);

const showDialog = zutil.updateState<Callback<State['processingImei']>>(
  (s, v) => {
    s.processingImei = v;
    s.type = 'loading';
  },
);

const hideDialog = () => methodProcessDialog.setState(() => initialState);

const processingImei = () => methodProcessDialog.getState().processingImei;

export { showDialog, hideDialog, processingImei };

const MethodProcessingDialog = (): JSX.Element => {
  const { processingImei, type } = methodProcessDialog((s) => s);

  return (
    <ProgressingDialog
      destroyOnClose
      type={type}
      visible={Boolean(processingImei)}
      onRetry={undefined}
      onClose={() => MethodProcess.leaveDialog()}
    />
  );
};

export default MethodProcessingDialog;
