import { checkPermission } from 'data/auth/currentUser';
import { UserRole } from 'models/User';

type TipsContent = {
  title: string;
  content: string;
  availableFor?: Uppercase<UserRole>[];
};

export const getNextQuickTipsIdx = (index?: number): number => {
  let idx = 0;
  if (index != undefined && index < TipsContent.length - 1) {
    idx = index + 1;
  }

  const availableFor = TipsContent[idx].availableFor;
  if (availableFor) {
    const hasPermission = checkPermission(availableFor);
    if (!hasPermission) {
      // check permission on next idx
      return getNextQuickTipsIdx(idx);
    } else {
      return idx;
    }
  } else {
    return idx;
  }
};

export const getAvailableQuickTips = (): TipsContent[] => {
  const tips = TipsContent.filter((i) => {
    const availableFor = i.availableFor;
    if (availableFor) {
      return checkPermission(availableFor);
    } else {
      return i;
    }
  });
  return tips;
};

export const TipsContent: TipsContent[] = [
  {
    title: 'Easily access Portal instructions',
    content:
      'Access the user guide by clicking the question icon at the top right corner of the screen, next to your avatar.',
  },
  {
    title: 'View history in Router Details',
    content:
      'In <b>Router Details</b>, hover over values in <b>Basic Information</b> to see history.',
  },
  {
    title: 'Configure your timezone',
    content:
      'Customize the timezone according to your preferences by accessing <b>My Profile</b> settings.',
  },
  {
    title: 'Configure email notifications for your router',
    content:
      "Stay informed about your router's status by adding your email address to the <b>Notification</b> section located in <b>Router Details → Advanced Information</b>.",
    availableFor: ['ADMIN', 'MANAGER', 'DEVICE_MANAGER'],
  },
  {
    title: 'Searching multiple IMEIs',
    content:
      'You can search for multiple routers simultaneously by entering a list of IMEIs separated by commas.',
  },
  {
    title: 'Use Router Actions to send commands to your routers',
    content:
      'From the <b>Router Actions</b> pane, you can command your router, such as <b>Update Firmware, Factory Reset,</b> etc.',
    availableFor: ['ADMIN', 'MANAGER', 'DEVICE_MANAGER'],
  },
  {
    title: 'Use the Global Identification (GID) field to name your router',
    content:
      'Give your router a name to make it easy to remember. You can search for a router by entering its GID.',
    availableFor: ['ADMIN', 'MANAGER', 'DEVICE_MANAGER'],
  },
  {
    title: 'Easily change your router’s WiFi configuration from Portal',
    content:
      'You can manage your WiFi configuration (e.g., WiFi name, WiFi password, Band, etc.) from the <b>Primary WiFi/Guest WiFi</b> section located in <b>Router Details → Advanced Information</b>.',
    availableFor: ['ADMIN', 'MANAGER', 'DEVICE_MANAGER'],
  },
  {
    title: 'Find your router instantly on the Map',
    content:
      'Locate your router from the <b>Map</b> pane situated in <b>Router Details</b>.',
  },
  {
    title: 'Configuring table display',
    content:
      'Click the <b>Columns</b> button at the <b>top right of each table</b> to show/hide or reorder columns. Your customized view will be saved for future use.',
  },
  {
    title: 'View expired assets',
    content:
      'In the <b>Subscription Summary</b>, use the <b>Expiration Date Tracking</b> table to view the list of assets that expired within a specific date range.',
    availableFor: ['ADMIN'],
  },
  {
    title: 'Monitor router usage',
    content:
      'Go to <b>Properties</b> on the <b>Router Details</b> page to view various charts that provide insights into your router’s performance.',
  },
];
