import Routing from 'routes';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import { FC, useEffect } from 'react';
import PreLoadIcons from 'modules/PreLoadIcons';
import CustomStyled from 'theme/customStyled';
import GoogleFontLoader from 'modules/GoogleFontLoader';
import ServerMaintainingDialog from 'modules/ServerMaintain';
import { versionDetection } from 'versionDetection';
import InternetConnectionHandler from 'modules/InternetConnectionHandler';
import { useShareTokenWithOtherTabs } from 'hooks/auth/useShareTokenWithOrtherTabs';

import './App.css';
import TranslateProvider from './translate/TranslateProvider';
import { theme } from './theme/theme';
import PreLoader from './modules/PreLoader/PreLoader';
import QuickTipsDialog from 'modules/QuickTips/QuickTipsDialog';

versionDetection();

const App: FC = () => {
  useEffect(() => {
    document.body.dataset.theme = 'light';
  }, [theme]);

  useShareTokenWithOtherTabs();

  return (
    <>
      <Helmet defer={false}>
        <title>Simplifi Portal</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <TranslateProvider>
          <PreLoadIcons />
          <InternetConnectionHandler />
          <PreLoader />
          <Routing />
          <CustomStyled />
          <ServerMaintainingDialog />
          <QuickTipsDialog />
        </TranslateProvider>
      </ThemeProvider>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Nanum Myeongjo',
            weights: [400, 700],
          },
          {
            font: 'Abhaya Libre',
            weights: [400, 700],
          },
          {
            font: 'Domine',
            weights: [400, 700],
          },
          {
            font: 'El Messiri',
            weights: [400, 700],
          },
        ]}
        subsets={['cyrillic-ext', 'greek']}
      />
    </>
  );
};

export default App;
