import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CaptiveViewMode,
  ViewMode,
} from 'page/private/CaptivePortal/components/Header';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { AppState } from 'store/rootReducer';
import { GLOBAL_ACTION } from 'store/config';

const name = 'filters';

export type State = {
  orgIds: string[];
  searchText: string;
  captives: string[];
  viewMode: ViewMode;
  captiveViewMode: CaptiveViewMode;
  dateFilter: {
    from: string;
    to: string;
    preset: string;
  };
};

const initialState: State = {
  orgIds: [],
  searchText: '',
  captives: [],
  captiveViewMode: 'cards',
  dateFilter: {
    preset: 'custom',
    from: '',
    to: '',
  },
  viewMode: 'captive',
};

const resetState = (state: State) => {
  const { dateFilter, viewMode, captiveViewMode } = initialState;
  state.orgIds = [];
  state.captives = [];
  state.searchText = '';
  state.captiveViewMode = captiveViewMode;
  state.dateFilter = dateFilter;
  state.viewMode = viewMode;
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: resetState,
    resetFilter(state) {
      state.orgIds = [];
      state.captives = [];
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setOrgIds(state, action: PayloadAction<string[]>) {
      state.orgIds = action.payload;
    },
    setCaptives(state, action: PayloadAction<string[]>) {
      state.captives = action.payload;
    },
    setViewMode(state, action: PayloadAction<ViewMode>) {
      state.viewMode = action.payload;
    },
    setCaptiveViewMode(state, action: PayloadAction<CaptiveViewMode>) {
      state.captiveViewMode = action.payload;
    },
    setDateRange(state, action) {
      const dateRanges = action.payload.dateRanges;
      state.dateFilter.preset = action.payload.preset;
      state.dateFilter.from = dateRanges[0];
      state.dateFilter.to = dateRanges[1];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GLOBAL_ACTION.LOGGED_OUT, (state) => {
      resetState(state);
    });
  },
});

const selector = (state: AppState) => state['captive'][name] as State;

const selectOrgIds = createSelector(selector, (s) => s.orgIds);
const selectSearchText = createSelector(selector, (s) => s.searchText);
const selectCaptives = createSelector(selector, (s) => s.captives);
const selectView = createSelector(
  selector,
  ({ captiveViewMode, viewMode }) => ({ viewMode, captiveViewMode }),
);
const selectDateRange = createSelector(selector, (s) => s.dateFilter);

export const captiveFilterActions = slice.actions;
export const captiveFilterSelector = {
  selectOrgIds,
  selectSearchText,
  selectCaptives,
  selectView,
  selectDateRange,
};

export default persistReducer({ key: name, storage }, slice.reducer);

// export default slice.reducer;
