import { AppState } from 'store/rootReducer';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { StateType } from 'models/Store';
import { apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { ApiError } from 'models/Errors';

const name = 'processUploadedFile';

type State = StateType<{
  orgName: string;
  processedTime: number;
}>;

const initialState: State = {
  data: {
    orgName: '',
    processedTime: -1,
  },
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export const processFile = createAsyncThunk(
  name,
  async ({ fileId }: { fileId: string }, { rejectWithValue }) => {
    try {
      const { data } = await apiPost({
        diffDomain: `${PROXY_DOMAIN}/api`,
        url: `import_router/process/${fileId}`,
        apiVersion: 'v1',
        reduxActionName: name,
      });
      return data;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processFile.pending, (state) => {
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(processFile.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data.orgName = action.payload.organization_info.name;
        state.data.processedTime = action.payload.uploaded_date;
      })
      .addCase(processFile.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message.split(':')[0];
          state.errorId = id;
          state.errorCode = `process_file.${code}`;
        }
      });
  },
});

const selector = (state: AppState) => state.superAdmin[name];

export const selectProcessFileLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectProcessFileSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectProcessFileFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectProcessOrgName = createSelector(
  selector,
  (state: State) => state.data.orgName,
);

export const selectProcessTime = createSelector(
  selector,
  (state: State) => state.data.processedTime,
);

export const selectProcessFileError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);
export const { reset } = slice.actions;

export default slice.reducer;
