import { QueryFunction, useQuery } from 'react-query';

import { API_QUERY_KEY } from 'apis/ApiQueryKey';
import { getQuickTipsConfig } from 'apis/userProfile/getQuickTipsConfig';
import { actions } from 'modules/QuickTips/QuickTipsDialog';
import { getNextQuickTipsIdx } from '../data/quickTipsData';
import { updateQuickTipsConfig } from 'apis/userProfile/updateQuickTipsConfig';

const useGetQuickTipsConfig = () => {
  return useQuery({
    queryKey: [API_QUERY_KEY.QUICKTIPS.GET_CONFIG],
    queryFn: getConfigQuickTips,
    refetchOnMount: false,
    enabled: false,
    onSuccess: (d) => {
      // if the show_when_login is false, do nothing
      if (d.show_when_login === false) {
        return;
      }

      // show the quick-tip as default
      actions.showDialog();
      updateQuickTipsConfig({ index: d.index, show_when_login: true });
    },
    onError: () => {
      // show error
    },
  });
};

export { useGetQuickTipsConfig };

type QuickTipConfigs = {
  index: number;
  show_when_login: boolean;
};

const getConfigQuickTips: QueryFunction<QuickTipConfigs> = async ({
  queryKey: [_],
}) => {
  const rs = await getQuickTipsConfig();
  if (!rs.data) {
    return {
      index: 0,
      show_when_login: true,
    };
  } else {
    return {
      ...rs.data,
      index: getNextQuickTipsIdx(rs.data.index),
    };
  }
};
