import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { AppState } from 'store/rootReducer';
import { ApiError } from 'models/Errors';
import { parseError } from 'utils/strings';
import notification from 'modules/notification';
import { apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { _translate } from 'translate/TranslateProvider';
import { LocationType } from 'page/private/RouterDetail/components/BasicInformation/components/Location/ChangeLocationModalContent';
import { actions as errorAction } from 'modules/errorDialog';
import { getServerMessage } from 'constants/ServerError';

type RequestData = {
  lat?: number;
  lng?: number;
  heading?: number;
  altitude?: number;
  speed?: number;
  fix_acc?: string;
  fix_type?: string;
  street_number?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zip_code?: string;
};

type State = StateType<RequestData>;

const name = 'updateLocation';

const initialState: State = {
  data: {} as RequestData,
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export const updateLocation = createAsyncThunk(
  name,
  async (
    data: Camelize<RequestData> & { routerId: string; mode: LocationType },
    { rejectWithValue },
  ) => {
    try {
      const { mode, routerId, streetNumber, zipCode, ..._location } = data;

      const formData =
        mode === 'lat-lng'
          ? {
              lat: _location.lat,
              lng: _location.lng,
            }
          : {
              ..._location,
              fix_type: 'manual',
              street_number: streetNumber,
              zip_code: zipCode,
            };

      await apiPost<RequestData>({
        url: `router/update_location/${routerId}`,
        apiVersion: 'v2',
        diffDomain: `${PROXY_DOMAIN}/api`,
        data: formData,
        reduxActionName: name,
      });
      return notification.success(
        _translate('ROUTER_DETAIL.BASIC_INFO.LOCATION.TOAST.SUCCESS'),
      );
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const selector = (state: AppState) => state.router[name];

export const selectLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateLocation.pending, (state) => {
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(updateLocation.fulfilled, (state) => {
        state.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(updateLocation.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = code;
          errorAction.showErrorDialog(getServerMessage(code));
        }
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;
