import { QueryFunction, useQuery } from 'react-query';

import { API_QUERY_KEY } from 'apis/ApiQueryKey';
import { getOrgListForSpecialVoicelinkConfig } from 'apis/appConfig/getAppConfig';

export type AppConfigs = {
  id: string;
  type: string;
  name: string;
  value: string;
  desc: string;
  created_date: number;
  modified_date: number;
};

const useGetOrgListForSpecialVoicelinkConfig = (
  onSuccess?: (_d: AppConfigs) => void,
) => {
  return useQuery({
    queryKey: [
      API_QUERY_KEY.APPCONFIG.GET_ORG_LIST_FOR_SPECIAL_VOICELINK_CONFIG,
    ],
    queryFn: getConfig,
    refetchOnMount: true,
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });
};

export { useGetOrgListForSpecialVoicelinkConfig };

const getConfig: QueryFunction<AppConfigs> = async () => {
  const rs = await getOrgListForSpecialVoicelinkConfig();
  return rs.data.configs[0];
};
