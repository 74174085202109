import React, { FC, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import { SiderProps } from 'antd/es/layout';

import AppIcon from 'components/atoms/SvgIcon';
import { useTranslate } from 'translate/useTranslate';
import { IconType } from 'components/atoms/SvgIcon';
import { checkPermission } from 'data/auth/currentUser';
import { getCssVar, theme } from 'theme/theme';

import routes, { MenuItem } from './routes';

const StyledSider = styled(Layout.Sider)<SiderProps>`
  background: ${(p) => p.theme.colors.grayscale_50};
  height: 100%;
  position: initial;

  .ant-menu-root {
    box-shadow: 5px 0 4px -5px #888;
  }

  .ant-menu-item {
    display: flex;
    justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'start')};
    align-items: center;
    border-radius: 4px;
    width: ${({ collapsed }) => (collapsed ? '70%' : '85%')};
    font-size: 15px;
    font-weight: 700;

    &:after {
      border-right: none;
    }

    &:hover {
      background: ${theme.colors.base_50};
    }

    & > a {
      display: flex;
      align-items: center;

      & > svg {
        /* & > path {
          fill: black;
        } */
      }
    }
    & > span {
      color: ${theme.colors.grayscale_600};
      &:hover {
        color: ${theme.colors.grayscale_600};
      }
    }

    &-selected {
      background-color: ${theme.colors.blue_500} !important;

      & > a {
        display: flex;
        align-items: center;
        color: white;

        & > svg {
          & > path {
            fill: white;
          }
        }
      }
      & > span {
        color: ${theme.colors.base_50};
      }
    }
  }
`;

const getKey = (path: string): string[] => {
  const menuItem = routes.find((i) =>
    i.keys?.some((x: RegExp) => x.test(path)),
  );
  if (menuItem) {
    return [menuItem.url];
  }
  return [path];
};

const StyledMenuItem = styled(Menu.Item)`
  &&& {
    width: 72px;
    height: 72px;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 24px;
    margin: 0;
  }
`;

const ItemLabel = styled.span`
  line-height: 12px;
  margin-top: 4px;
  font-size: 10px;
  font-weight: normal;
`;

const StyledMenu = styled(Menu)`
  width: 72px;
`;

const SideMenuItem = ({
  url,
  icon,
  titleKey,
  component,
  availableFor,
  ariaLabel,
}: MenuItem) => {
  const { t } = useTranslate() || {};
  const onHover = useCallback(() => component?.preload?.(), []);

  if (!t || !checkPermission(availableFor)) {
    return null;
  }

  return (
    <StyledMenuItem
      key={url}
      title={
        // use span here to ignore default antd tooltip
        <span>{t(titleKey ?? '')}</span>
      }
    >
      <Link
        to={url}
        className="nav-text"
        onMouseOver={onHover}
        aria-label={ariaLabel}
      >
        <AppIcon
          name={icon as IconType}
          size={30}
          color={theme.colors.grayscale_600}
        />
      </Link>
      <ItemLabel>{t(titleKey || '')}</ItemLabel>
    </StyledMenuItem>
  );
};

const RoutesOnMenu = routes.filter((i) => i.showMenu);
const SideMenu: FC<SiderProps> = () => {
  const { pathname } = useLocation();
  const selectedKeys = useMemo<string[]>(() => getKey(pathname), [pathname]);

  return (
    <StyledSider
      trigger={null}
      collapsed={false}
      breakpoint="lg"
      collapsedWidth="72"
      width={72}
    >
      <StyledMenu
        mode="inline"
        defaultSelectedKeys={[pathname]}
        selectedKeys={selectedKeys}
        defaultOpenKeys={['application']}
        style={{
          height: '100%',
          borderRight: 0,
          backgroundColor: getCssVar(theme.colors.grayscale_50),
        }}
      >
        {RoutesOnMenu.map(SideMenuItem)}
      </StyledMenu>
    </StyledSider>
  );
};

export default React.memo(SideMenu);
