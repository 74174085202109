import common from 'translate/translation/en/common.json';
import component from 'translate/translation/en/component.json';
import constants from 'translate/translation/en/constants.json';
import message from 'translate/translation/en/message.json';
import validation from 'translate/translation/en/validation.json';
import sideMenu from 'translate/translation/en/sideMenu.json';
import usersList from 'translate/translation/en/usersList.json';
import tags from 'translate/translation/en/tags.json';
import userInvite from 'translate/translation/en/userInvite.json';
import userDetail from 'translate/translation/en/userDetail.json';
import orgForm from 'translate/translation/en/organizationForm.json';
import orgs from 'translate/translation/en/organization.json';
import userProfile from 'translate/translation/en/userProfile.json';
import routerList from 'translate/translation/en/routerList.json';
import routerStatusTag from 'translate/translation/en/routerStatusTag.json';
import routerFailoverTag from 'translate/translation/en/routerFailoverTag.json';
import routerRSSITag from 'translate/translation/en/routerRSSITag.json';
import routerDetail from 'translate/translation/en/routerDetail.json';
import errorDialog from 'translate/translation/en/errorDialog.json';
import routerLocationHistory from 'translate/translation/en/routerLocationHistory.json';
import pageNotFound from 'translate/translation/en/pageNotFound.json';
import roles from 'translate/translation/en/roles.json';
import routerInterface from 'translate/translation/en/routerInterface.json';
import logIn from 'translate/translation/en/logIn.json';
import routerMap from 'translate/translation/en/routerMap.json';
import progresingDialog from 'translate/translation/en/progressingDialog.json';
import toastMessage from 'translate/translation/en/toastMessage.json';
import support from 'translate/translation/en/support.json';
import notAllow from 'translate/translation/en/notAllow.json';
import captivePortal from 'translate/translation/en/captivePortal.json';
import phoneLineStatus from 'translate/translation/en/phoneLineStatus.json';
import phoneCallStatus from 'translate/translation/en/phoneCallStatus.json';
import importRouters from 'translate/translation/en/importRouters.json';
import forgotPassword from 'translate/translation/en/forgotPassword.json';
import superAdmin from 'translate/translation/en/superAdmin.json';
import voiceLinks from 'translate/translation/en/voiceLinks.json';
import userGuide from 'translate/translation/en/userGuide.json';
import callHistoryDetails from 'translate/translation/en/callHistoryDetails.json';
import updateFirmware from 'translate/translation/en/updateFirmware.json';
import developer from 'translate/translation/en/developer.json';
import internetConnection from 'translate/translation/en/internetConnection.json';
import apiLog from 'translate/translation/en/apiLog.json';
import rpcLog from 'translate/translation/en/rpcLog.json';
import welcome from 'translate/translation/en/welcome.json';
import unvailableService from 'translate/translation/en/unvailableService.json';
import appHeader from 'translate/translation/en/appHeader.json';
import subscription from 'translate/translation/en/subscription.json';
import subscriber from 'translate/translation/en/subscriber.json';
import subscriptionPlan from 'translate/translation/en/subscriptionPlan.json';
import packageConfig from 'translate/translation/en/PackageConfig.json';
import travelLog from 'translate/translation/en/travelLog.json';
import notificationBanner from 'translate/translation/en/notificationBanner.json';
import wcag from 'translate/translation/en/wcag.json';
import subscriptionSummary from 'translate/translation/en/subscriptionSummary.json';
import eventReport from 'translate/translation/en/eventReport.json';
import routerBulkAction from 'translate/translation/en/routerBulkAction.json';
import dateTime from 'translate/translation/en/dateTime.json';

export default {
  ...common,
  ...component,
  ...message,
  ...validation,
  ...constants,
  ...sideMenu,
  ...usersList,
  ...tags,
  ...userInvite,
  ...userDetail,
  ...orgForm,
  ...orgs,
  ...userProfile,
  ...routerList,
  ...routerStatusTag,
  ...routerFailoverTag,
  ...routerRSSITag,
  ...routerDetail,
  ...errorDialog,
  ...routerLocationHistory,
  ...pageNotFound,
  ...roles,
  ...routerInterface,
  ...logIn,
  ...routerMap,
  ...progresingDialog,
  ...toastMessage,
  ...support,
  ...notAllow,
  ...captivePortal,
  ...phoneLineStatus,
  ...phoneCallStatus,
  ...importRouters,
  ...forgotPassword,
  ...superAdmin,
  ...voiceLinks,
  ...userGuide,
  ...callHistoryDetails,
  ...updateFirmware,
  ...developer,
  ...internetConnection,
  ...apiLog,
  ...rpcLog,
  ...unvailableService,
  ...welcome,
  ...appHeader,
  ...subscription,
  ...subscriber,
  ...subscriptionPlan,
  ...packageConfig,
  ...travelLog,
  ...notificationBanner,
  ...wcag,
  ...subscriptionSummary,
  ...eventReport,
  ...routerBulkAction,
  ...dateTime,
};
