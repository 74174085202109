type FnCallback<Input, Return> = (_input: Input) => Return;

type Predicate<Input> = (_input: Input) => boolean;

type Pattern<Input> = Predicate<Input> | Input;

type API<Input, Result> = {
  when: <Return>(
    _pattern: Pattern<Input>,
    _callback: FnCallback<Input, Return>,
  ) => API<Input, Result | void>;

  otherwise: <Return>(_callback: Callback<Input, Return>) => Result | void;

  end: () => Result;
};

function isPredicate<Input>(
  pattern: Pattern<Input>,
): pattern is Predicate<Input> {
  return typeof pattern === 'function';
}

export function kase<Input>(input: Input) {
  return kaseFactory<Input, undefined>(input, false, undefined);
}

function kaseFactory<Input, Result>(
  input: Input,
  isMatched: boolean,
  result: Result,
) {
  const api = {} as API<Input, Result>;

  function when<Return>(
    pattern: Pattern<Input>,
    callback: Callback<Input, Return>,
  ) {
    if (isMatched) return api;

    const predicate = isPredicate<Input>(pattern)
      ? () => pattern(input)
      : () => pattern === input;

    if (predicate()) {
      const result = callback(input);

      return kaseFactory<Input, typeof result>(input, true, result);
    }

    return api;
  }

  function otherwise<Return>(callback: Callback<Input, Return>) {
    if (isMatched) return result;

    return callback(input);
  }

  function end() {
    return result;
  }

  api.when = when;
  api.otherwise = otherwise;
  api.end = end;

  return api;
}
