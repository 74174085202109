import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { getAccessToken } from 'utils/token';

export type RequestAction<K> = {
  requestData: K;
  thunkApi: {
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
    extra: unknown;
    getState: () => unknown;
    rejectWithValue: (_value: unknown) => unknown;
    requestId: string;
    signal: AbortSignal;
  };
  headers: {
    token?: string;
  };
};

type ThunkConfig<K> = {
  name: string;
  action: (_config: RequestAction<K>) => Promise<unknown>;
  withToken?: boolean;
};

export const thunkWrapper = <K>({
  name,
  action,
  withToken = true,
}: ThunkConfig<K>) => {
  return createAsyncThunk(name, async (requestData: K, thunkApi) => {
    const token = getAccessToken();
    return action({
      requestData,
      thunkApi,
      headers: withToken ? { token } : {},
    });
  });
};
