import { getSafe } from 'utils/getSafe';
import { kase } from 'utils/kase';
import {
  setActiveIntf,
  setFailOver,
  setWwanStatus,
} from 'page/private/RouterDetail/components/BasicInformation/data/basicInformation';

import { MESSAGE_KEY } from '../config';
import { ServerTopic } from './types';
import { lastCommunicationHandler } from './lastCommunication';

const LC_EFFECT_TOPICS = {
  phone_state: true,
  active_intf: true,
  wwan_status: true,
  failover: true,
  slic_state: true,
};

const routerAlarmHandler = (t: string, m: Buffer, store: any) => {
  const serverPayload = JSON.parse(m.toString()) as ServerTopic;
  const payload = getSafe<number>(() => parseInt(String(serverPayload.value)));

  const key = t.split('/').slice(-1)[0];

  // check to update last communication
  if (key in LC_EFFECT_TOPICS) {
    lastCommunicationHandler(t, m);
  }

  kase(key)
    .when(MESSAGE_KEY.SLIC, () => {
      store.dispatch({
        type: 'routerDetail/setLineStatus',
        payload,
      });
    })
    .when(MESSAGE_KEY.FAILOVER, () => {
      setFailOver({
        since: serverPayload.ts,
        state: payload || 0,
        ts: serverPayload.ts,
      });
    })
    .when(MESSAGE_KEY.ACTIVE_INTF, () => {
      setActiveIntf({
        since: serverPayload.ts,
        state: payload || 0,
        ts: serverPayload.ts,
      });
    })
    .when(MESSAGE_KEY.WWAN_STATUS, () => {
      setWwanStatus({
        since: serverPayload.ts,
        state: payload || 0,
        ts: serverPayload.ts,
      });
    });
};

export { routerAlarmHandler };
