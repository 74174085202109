import { css } from 'styled-components';
import { theme } from './theme';

const selectStyle = css`
  .ant-select-selector {
    border-radius: 4px !important;
  }

  .ant-select-dropdown {
    border-radius: 6px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 700;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: ${theme.colors.grayscale_800};
  }
`;

export { selectStyle };
