import { createApi } from '@reduxjs/toolkit/query/react';
import { env } from 'constants/configs';
import { MappedOrg, Organization } from 'models/Organization';
import arrayToTree from 'utils/arrayToObject';
import { API_QUERY_KEY } from './ApiQueryKey';
import { axiosBaseQueryGET } from './axiosBaseQuery';

const captiveOrganizationsApi = createApi({
  reducerPath: API_QUERY_KEY.CAPTIVE.GET_ORG_FILTER_ALL,
  baseQuery: axiosBaseQueryGET({
    baseUrl: `${env.REACT_APP_API_URL}/api`,
    version: 'v2',
  }),
  endpoints: (build) => ({
    getListOrg: build.query({
      query: () => ({
        url: 'portal/org/group/org/all',
      }),
      transformResponse: (r: MappedOrg[]) => {
        const convertedOrgs: MappedOrg[] = r.map((i: Organization) => ({
          ...i,
          key: i.id,
          title: i.name,
          disabled: i.suspended,
        }));
        const mappedData = arrayToTree<MappedOrg>(convertedOrgs);
        return mappedData || [];
      },
    }),
  }),
});

export const { useGetListOrgQuery } = captiveOrganizationsApi;

export { captiveOrganizationsApi };
