import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import { Form } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { validate } from 'validators';
import dayjs from 'dayjs';

import Button from 'components/molecules/Button';
import FormItem from 'components/molecules/FormItem';
import Input from 'components/molecules/Input';
import Block from 'components/atoms/Block';
import Text from 'components/atoms/Text';
import FormErrorMessage from 'components/molecules/FormErrorMessage';
import FormContainer from 'components/atoms/PublicPageFormContainer';
import { useTranslate } from 'translate/useTranslate';
import { GLOBAL_ACTION } from 'store/config';
import { DEFAULT_ROUTE } from 'layout/routes';
import Link from 'components/molecules/Link';
import notification from 'modules/notification';
import { tokenStore } from 'data/auth/token';
import { state as internetConnectionState } from 'data/internet/status';

import { API_QUERY_KEY } from 'apis/ApiQueryKey';
import { getAppConfig } from 'apis/appConfig/getAppConfig';
import { useLogin } from './hook/useLogin';
import { useGetRedirectUrl } from '../../../hooks/useGetRedirectUrl';
import { setConfigNotificationBanner } from 'page/private/SuperAdmin/pages/NotificationBanner/hooks/useGetAppNotificationBanner';
import {
  APP_CONFIG_NOTIFICATION_BANNER,
  DEFAULT_TIMEZONE_NOTIFICATION_BANNER,
  NotificationBannerConfig,
} from 'page/private/SuperAdmin/pages/NotificationBanner';

const StyledTitle = styled(Text)`
  margin-bottom: 28px;
`;

const ForgotPasswordItem = styled(Block)`
  padding-top: 16px;
`;

const StyledErrorMessage = styled(FormErrorMessage)`
  margin-bottom: 38px;
  margin-top: -5px;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const Login = (props: RouteComponentProps): JSX.Element => {
  const { history } = props;
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const hasToken = tokenStore((s) => Boolean(s.accessToken));

  const redirectUrl = useGetRedirectUrl();

  const { mutate } = useMutation({
    mutationKey: [API_QUERY_KEY.APPCONFIG.GET_CONFIG],
    mutationFn: getAppConfig,
    onSuccess: (rs) => {
      const data = rs.data.configs[0];
      if (!data || !data.value) {
        return;
      }
      const config = JSON.parse(data.value) as NotificationBannerConfig;
      if (config) {
        if (!config.isShow) {
          setConfigNotificationBanner(config);
        } else {
          const current = dayjs().unix();
          const start = dayjs(config.date[0])
            .tz(DEFAULT_TIMEZONE_NOTIFICATION_BANNER, true)
            .unix();
          const end = dayjs(config.date[1])
            .tz(DEFAULT_TIMEZONE_NOTIFICATION_BANNER, true)
            .unix();
          if (current >= start && current <= end) {
            setConfigNotificationBanner({ ...config, isShow: true });
          } else {
            setConfigNotificationBanner({ ...config, isShow: false });
          }
        }
      }
    },
  });

  const { login, isLoading, isError } = useLogin(() => {
    mutate({
      type: APP_CONFIG_NOTIFICATION_BANNER.TYPE,
      name: APP_CONFIG_NOTIFICATION_BANNER.NAME,
    });
    history.push(redirectUrl || DEFAULT_ROUTE);
  });

  const [form] = Form.useForm();

  const doLogin = useCallback((values) => {
    const isOnline = internetConnectionState.getState().isOnline;
    if (!isOnline) {
      notification.lossInternet();
      return;
    }
    login(values);
  }, []);

  useEffect(() => {
    dispatch({
      type: GLOBAL_ACTION.LOGGED_OUT,
      payload: null,
    });
  }, []);

  useEffect(() => {
    if (hasToken) {
      history.push(redirectUrl || DEFAULT_ROUTE);
    }
  }, [hasToken, isLoading]);

  useEffect(() => {
    if (isError) {
      form.setFields([
        {
          name: 'username',
          errors: [''],
        },
        {
          name: 'password',
          errors: [''],
        },
      ]);
    } else {
      form.setFields([
        {
          name: 'username',
          errors: undefined,
        },
        {
          name: 'password',
          errors: undefined,
        },
      ]);
    }
  }, [isError]);

  return (
    <Block flex center>
      <FormContainer column flex center>
        <StyledTitle variant="h2" color="gray-900" bold>
          {t('LOGIN.TITLE')}
        </StyledTitle>
        <StyledForm
          form={form}
          initialValues={{
            username: '',
            password: '',
          }}
          onFinish={doLogin}
        >
          <FormItem
            label={t('LOGIN.LABEL.EMAIL')}
            name="username"
            rules={[
              {
                validator: (_, value) =>
                  validate(value, {
                    required: '',
                  }),
              },
            ]}
          >
            <Input disabled={isLoading} />
          </FormItem>
          <FormItem
            label={t('LOGIN.LABEL.PASSWORD')}
            name="password"
            rules={[
              {
                validator: (_, value) =>
                  validate(value, {
                    required: '',
                  }),
              },
            ]}
          >
            <Input variant="password" disabled={isLoading} />
          </FormItem>
          <StyledErrorMessage
            isVisible={isError}
            errorMessage={t('LOGIN.ERROR_MSG')}
          />
          <FormItem>
            <Button
              variant="primary"
              full
              htmlType="submit"
              loading={isLoading}
            >
              {t('LOGIN.BUTTON.SUBMIT')}
            </Button>
          </FormItem>
          <ForgotPasswordItem flex center>
            <Link to="/forgot-password" disabled={isLoading}>
              {t('LOGIN.BUTTON.FORGOT_PASSWORD')}
            </Link>
          </ForgotPasswordItem>
        </StyledForm>
      </FormContainer>
    </Block>
  );
};

export default Login;
