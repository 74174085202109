import { setConnectionStatus } from 'page/private/RouterDetail/components/BasicInformation/data/basicInformation';

import { MESSAGE_KEY } from '../config';
import { ServerTopic } from './types';

const routerConnectionHandler = (t: string, m: Buffer) => {
  const serverPayload = JSON.parse(m.toString()) as ServerTopic;
  const key = t.split('/').slice(-1)[0];

  setConnectionStatus({
    ts: serverPayload.ts,
    value: key === MESSAGE_KEY.CONNECT,
  });
};

export { routerConnectionHandler };
