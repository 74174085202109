import { util } from 'data/utils';
import { Router } from 'models/Router';
import { getSafe } from 'utils/getSafe';
import { pickSome } from 'utils/pickSome';
import create from 'zustand';

type BasicInformation = Pick<
  Router,
  | 'gid'
  | 'readonly'
  | 'firmware_version_number'
  | 'min_disconnect_timer'
  | 'id'
  | 'serial_number'
> & {
  firmware_version: Router['attrs']['firmware_version'];
  status: {
    value: boolean;
    ts: number;
  };
  active_intf: Router['alarms']['active_intf'];
  failover: Router['alarms']['failover'];
  wwan_status: Router['alarms']['wwan_status'];
  serial_number: Router['serial_number'];
};

const DEFAULT_ALARM_DATA = {
  since: 0,
  state: 0,
  ts: 0,
};

const initialState: BasicInformation = {
  gid: '',
  readonly: true,
  firmware_version_number: 0,
  status: {
    value: true,
    ts: 0,
  },
  firmware_version: {
    since: 0,
    ts: 0,
    value: '',
  },
  id: '',
  active_intf: DEFAULT_ALARM_DATA,
  failover: DEFAULT_ALARM_DATA,
  wwan_status: DEFAULT_ALARM_DATA,
  serial_number: '',
  min_disconnect_timer: 0,
};

const basicInformation = create(() => initialState);
const stateUtil = util(basicInformation.setState, basicInformation.getState);
const setters = stateUtil.generateSetter();

const setBasicInformation = stateUtil.updateState<Callback<BasicInformation>>(
  (current, newValue) => {
    // check if the connection status is newest

    const status = getSafe<
      BasicInformation['status'],
      BasicInformation['status']
    >(
      () =>
        current.status.ts < newValue.status.ts
          ? newValue.status
          : current.status,
      current.status,
    );

    const active_intf = getSafe<
      BasicInformation['active_intf'],
      BasicInformation['active_intf']
    >(
      () =>
        current.active_intf.ts < newValue.active_intf.ts
          ? newValue.active_intf
          : current.active_intf,
      current.active_intf,
    );

    const failover = getSafe<
      BasicInformation['failover'],
      BasicInformation['failover']
    >(
      () =>
        current.failover.ts < newValue.failover.ts
          ? newValue.failover
          : current.failover,
      current.failover,
    );

    const wwan_status = getSafe<
      BasicInformation['wwan_status'],
      BasicInformation['wwan_status']
    >(
      () =>
        current.wwan_status.ts < newValue.wwan_status.ts
          ? newValue.wwan_status
          : current.wwan_status,
      current.wwan_status,
    );

    const values = {
      ...newValue,
      status,
      active_intf,
      failover,
      wwan_status,
    };

    current = values;
  },
);

const selectRouterInformation = (s: BasicInformation) =>
  pickSome(s, [
    'firmware_version_number',
    'gid',
    'readonly',
    'status',
    'firmware_version',
    'id',
    'serial_number',
    'min_disconnect_timer',
  ]);

const selectRouterAlarm = (s: BasicInformation) =>
  pickSome(s, ['active_intf', 'status', 'failover', 'wwan_status']);

const setActiveIntf = stateUtil.updateState<
  Callback<BasicInformation['active_intf']>
>((s, v) => {
  if (s.active_intf.ts >= v.ts) {
    return;
  }

  s.active_intf = v;
});

const setFailOver = stateUtil.updateState<
  Callback<BasicInformation['failover']>
>((s, v) => {
  if (s.failover.ts >= v.ts) {
    return;
  }
  s.failover = v;
});

const setWwanStatus = stateUtil.updateState<
  Callback<BasicInformation['wwan_status']>
>((s, v) => {
  console.log('update wwan', v, s.wwan_status);
  if (s.wwan_status.ts >= v.ts) {
    return;
  }
  s.wwan_status = v;
});

const setConnectionStatus = stateUtil.updateState<
  Callback<BasicInformation['status']>
>((s, v) => {
  if (s.status.ts >= v.ts) {
    return;
  }

  s.status = v;
});

const selector = { selectRouterInformation, selectRouterAlarm };

export {
  basicInformation,
  setters,
  selector,
  setBasicInformation,
  setActiveIntf,
  setFailOver,
  setWwanStatus,
  setConnectionStatus,
};
