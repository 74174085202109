import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiPost } from 'apis/baseAPI';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import { parseErrorMessage } from 'utils/strings';
import { PROXY_DOMAIN } from 'constants/configs';
import { ApiError } from 'models/Errors';
import notification from 'modules/notification';
import { _translate } from 'translate/TranslateProvider';

const name = 'notificationEmail';

type State = StateType<unknown>;

export type NotificationEmailRequest = {
  routerID: string;
  emails: string[];
  notificationEmailEnable: boolean;
};

const initialState: State = {
  data: null,
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

const requestHeader = {
  'X-Device-Id': 'deviceid',
  'X-Device-Family': 'PC',
  'X-Device-Os': 'WIN',
  'X-Device-OS-Version': 10,
  'X-Device-Locale': 'vi_VN',
  'X-App-Version': '1.3.0',
};

export const ApplyNotificationEmail = createAsyncThunk(
  name,
  async (data: NotificationEmailRequest, { rejectWithValue }) => {
    try {
      const { routerID, emails, notificationEmailEnable } = data;
      await apiPost({
        url: `router/apply_notify_email/${routerID}`,
        apiVersion: 'v2',
        data: {
          emails: emails,
          email_alert_enabled: notificationEmailEnable,
          tags: [
            'send_failover_email',
            'send_armed_email',
            'send_eth_active_email',
          ],
        },
        headers: requestHeader,
        diffDomain: `${PROXY_DOMAIN}/api`,
        reduxActionName: name,
      });
      notification.success(
        _translate('ROUTER_DETAIL.ADVANCED_INFO.EDIT.TOAST.SUCCESS'),
      );
      return;
    } catch (e) {
      return rejectWithValue(parseErrorMessage(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [ApplyNotificationEmail.pending as never]: (state: State) => {
      state.status = THUNK_STATUS.LOADING;
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
    },
    [ApplyNotificationEmail.fulfilled as never]: (state: State) => {
      state.status = THUNK_STATUS.SUCCEEDED;
    },
    [ApplyNotificationEmail.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      if (!action.payload) {
        state.error = 'MESSAGE.UNKNOWN_ERROR';
      } else {
        const {
          code,
          id,
          message,
        } = action.payload as ApiError['response']['data']['error'];
        state.error = message;
        state.errorId = id;
        state.errorCode = code;
      }
    },
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectVersion = createSelector(
  selector,
  (state: State) => state.data,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export default slice.reducer;
