import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { apiPost } from 'apis/baseAPI';
import { Router } from 'models/Router';
import { PROXY_DOMAIN } from 'constants/configs';
import notification from 'modules/notification';
import { ApiError } from 'models/Errors';
import { _translate } from 'translate/TranslateProvider';

type State = StateType<{
  user_ids: string[];
}>;

const name = 'updateManagers';
const initialState: State = {
  data: {
    user_ids: [],
  },
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export const updateManagers = createAsyncThunk(
  `${name}/update`,
  async (
    _data: { routerId: string; userIds: string[] },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { routerId, userIds } = _data;
      await apiPost<Router>({
        url: `router/add_bulk_users/${routerId}`,
        apiVersion: 'v2',
        diffDomain: `${PROXY_DOMAIN}/api`,
        data: {
          user_ids: userIds,
        },
        reduxActionName: `${name}/update`,
      });
      return notification.success(
        _translate('ROUTER_DETAIL.ADVANCED_INFO.EDIT.TOAST.SUCCESS'),
      );
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    } finally {
      dispatch(reset());
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [updateManagers.pending as never]: (state: State) => {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [updateManagers.fulfilled as never]: (state: State) => {
      state.status = THUNK_STATUS.SUCCEEDED;
    },
    [updateManagers.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      if (!action.payload) {
        state.error = 'MESSAGE.UNKNOWN_ERROR';
      } else {
        const {
          code,
          id,
          message,
        } = action.payload as ApiError['response']['data']['error'];
        state.error = message;
        state.errorId = id;
        state.errorCode = code;
      }
    },
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectUpdatingManagers = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectUpdateManagersSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectUpdateManagersFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectUpdateManagersError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export default slice.reducer;
