import React from 'react';
import styled, { css } from 'styled-components';
import { _translate } from 'translate/TranslateProvider';

type Props = {
  logoUrl: string;
  circle?: boolean;
  size?: number;
  objectFit?: 'fill' | 'contain' | 'none' | 'scale-down' | 'cover';
};

const StyledImg = styled.img<Pick<Props, 'circle' | 'size' | 'objectFit'>>`
  ${(props) =>
    props.circle &&
    css`
      border-radius: 50%;
    `};

  ${(props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
    `};

  ${(props) =>
    props.objectFit &&
    css`
      object-fit: ${props.objectFit};
    `};
`;

const AppLogo: React.FC<Props> = ({
  size,
  logoUrl,
  objectFit,
  circle = false,
}: Props) => {
  return (
    <StyledImg
      alt={_translate('WCAG.SIMPLIFI_LOGO')}
      src={logoUrl}
      circle={circle}
      size={size}
      objectFit={objectFit}
    />
  );
};

export default AppLogo;
