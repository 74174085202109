import { refreshToken } from 'apis/auth/refreshToken';
import { currentUserState } from 'data/auth/currentUser';
import { setTokenStore, tokenStore } from 'data/auth/token';
import { doLogoutAndSyncToken } from 'hooks/useLogout';

const tryRefreshToken = () => {
  const userId = currentUserState.getState().id;
  const refreshTokenKey = tokenStore.getState().refreshToken;

  return refreshToken({ refreshTokenKey, userId });
};

class RefreshToken {
  private static _refreshFnc?: ReturnType<typeof tryRefreshToken>;

  static tryRefresh() {
    if (RefreshToken._refreshFnc) {
      return RefreshToken._refreshFnc;
    }
    RefreshToken._refreshFnc = tryRefreshToken();
    RefreshToken._refreshFnc
      .then((r) => {
        console.log('Refresh token success -> store new token');
        const tokenInfo = r.data.data;
        setTokenStore({
          accessToken: tokenInfo.access_token,
          expiresIn: tokenInfo.expires_in,
          idToken: tokenInfo.id_token,
          refreshToken: tokenInfo.refresh_token,
          scope: tokenInfo.scope,
          tokenType: tokenInfo.token_type,
        });
      })
      .catch((e) => {
        console.error('Fail to refresh token -> logout', e);
        doLogoutAndSyncToken();
      })
      .finally(() => {
        console.log('Refresh completed, cleanup!');
        RefreshToken._refreshFnc = undefined;
      });
    return RefreshToken._refreshFnc;
  }
}

export default RefreshToken;
