import styled from 'styled-components';
import React, { useEffect } from 'react';

import { TextProps } from 'components/atoms/Text';
import Tooltip from 'components/atoms/Tooltip';
import Text from 'components/atoms/Text';

export type TextOverflowProps = TextProps & {
  textContent?: string | number | null;
  width?: number;
  href?: string;
  alwaysShowTooltip?: boolean;
};

const StyledText = styled(Text)<{ width?: number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => (props.width ? `max-width: ${props.width}px` : 'width: 100%')}
`;

const TextOverFlow = ({
  width = 500,
  textContent = '',
  variant = 'span',
  showEmptyPlaceholder = true,
  alwaysShowTooltip = false,
  ...props
}: TextOverflowProps) => {
  const [isOverflow, setIsOverflow] = React.useState(false);
  const textRef = React.useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, offsetWidth } = textRef.current;
      if (scrollWidth > width || offsetWidth > width) {
        setIsOverflow(true);
      }
    }
  }, [textRef.current, width]);

  const tooltipContent = alwaysShowTooltip
    ? textContent
    : isOverflow
    ? textContent
    : null;

  return (
    <Tooltip title={tooltipContent}>
      <StyledText variant={variant} ref={textRef} width={width} {...props}>
        {textContent || (showEmptyPlaceholder && '-')}
      </StyledText>
    </Tooltip>
  );
};

export default TextOverFlow;
