import { combineReducers } from '@reduxjs/toolkit';
import createOrg from './create';
import deleteOrg from './delete';
import detailOrg from './detail';
import listOrg from './list';
import listOrgModify from './list-modify';
import suspendOrg from './suspend';
import updateOrg from './update';
import listOrgAdmin from './list-org-admin';

export default combineReducers({
  createOrg,
  deleteOrg,
  detailOrg,
  listOrg,
  listOrgAdmin,
  listOrgModify,
  suspendOrg,
  updateOrg,
});
