import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from 'store/rootReducer';
import { parseErrorMessage } from 'utils/strings';
import { GLOBAL_ACTION } from 'store/config';
import { apiPost } from 'apis/baseAPI';
import { ApiError } from 'models/Errors';
import { env } from 'constants/configs';
import { Pagination } from 'models/Table';
import { CaptivePortal } from 'page/private/CaptivePortal/components/CaptiveGrid/useGridData';

import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  captiveListFilter,
  setTotalElements as setTotalElementsList,
  setTotalPages as setTotalPagesList,
} from 'page/private/CaptivePortal/data/captiveListFilter';
import { CaptiveViewMode } from 'page/private/CaptivePortal/components/Header';
import {
  captiveGridFilter,
  setTotalElements as setTotalElementsCard,
  setTotalPages as setTotalPagesCard,
} from 'page/private/CaptivePortal/data/captiveGridFilter';

type State = StateType<{
  data: CaptivePortal[];
  pagination: Pagination;
}>;

const name = 'listCaptive';

const initialState: State = {
  data: {
    data: [],
    pagination: {
      page: 1,
      size: 25,
      total_elements: 0,
      total_pages: 0,
    },
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const listCaptive = createAsyncThunk(
  name,
  async (captiveView: CaptiveViewMode, { rejectWithValue }) => {
    try {
      const filter =
        captiveView == 'cards'
          ? captiveGridFilter.getState()
          : captiveListFilter.getState();
      const pagination = filter.pagination;

      const resp = await apiPost<{
        data: {
          paging: State['data']['pagination'];
          portal_captives: CaptivePortal[];
        };
      }>({
        diffDomain: `${env.REACT_APP_CAPTIVE_PORTAL_URL}/api`,
        url: 'portal/captive/search',
        apiVersion: 'v1',
        reduxActionName: name,
        data: {
          filter: {
            search_text: filter.textSearch,
            org_ids: filter.orgIds,
          },
          paging: {
            page: pagination.page - 1,
            size: pagination.size,
          },
        },
      });
      if (captiveView == 'cards') {
        setTotalElementsCard(resp.data.paging.total_elements);
        setTotalPagesCard(resp.data.paging.total_pages);
      } else {
        setTotalElementsList(resp.data.paging.total_elements);
        setTotalPagesList(resp.data.paging.total_pages);
      }
      return resp;
    } catch (e) {
      return rejectWithValue(Error(parseErrorMessage(e as ApiError)).message);
    }
  },
);

const resetState = (state: State) => {
  state.error = '';
  state.status = THUNK_STATUS.IDLE;
  state.data = initialState.data;
  state.errorId = '';
  state.errorCode = '';
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (s) => {
      s.error = '';
      s.status = THUNK_STATUS.IDLE;
      s.data = initialState.data;
      s.errorId = '';
      s.errorCode = '';
    },
    resetData(state) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data.data = [];
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.data.pagination.size = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.data.pagination.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCaptive.pending, (state) => {
        state.error = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(listCaptive.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data.data = action.payload.data.portal_captives;
      })
      .addCase(listCaptive.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `captive_list.${code}`;
        }
      })
      .addCase(GLOBAL_ACTION.LOGGED_OUT, (state) => {
        resetState(state);
      });
  },
});

export const { reset, resetData, setPage } = slice.actions;

export const captiveListActions = slice.actions;

const selector = (state: AppState) => state.captive[name] as State;

export const selectCaptives = createSelector(selector, (state) => {
  return state.data.data;
});

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const selectPagination = createSelector(selector, (s) => {
  return s.data.pagination;
});

const SetTransform = createTransform(
  (inboundState: { pagination: Pagination }) => {
    const { pagination } = inboundState;
    return pagination;
  },

  (outboundState) => {
    return { pagination: outboundState, data: [] };
  },

  { whitelist: ['data'] },
);

export default persistReducer(
  { key: name, storage, transforms: [SetTransform] },
  slice.reducer,
);
