import { css } from 'styled-components';

const utilStyle = css`
  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .fixed-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .vertical-top {
    vertical-align: top;
  }

  .w-full {
    width: 100%;
  }

  .h-full {
    height: 100%;
  }

  .text-gray {
    color: var(--Colors-Grayscale-600);
  }

  .table-overflow-hidden {
    .ant-table-body {
      overflow: hidden !important;
    }
  }

  .auto-scroll {
    overflow: auto;
  }
`;

export { utilStyle };
