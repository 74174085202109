import { forwardRef, PropsWithChildren } from 'react';
import styled, { CSSProp } from 'styled-components';

export type BlockProps = PropsWithChildren<{
  flex?: boolean;
  className?: string;
  css?: CSSProp;
  container?: boolean;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'center';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline';
  center?: boolean;
  column?: boolean;
  flexGrow?: number;
  gap?: number;
  onClick?: Noop;
  onMouseEnter?: Noop;
  onMouseLeave?: Noop;
}>;

type Ref = HTMLDivElement;

const StyledBlock = styled.div<BlockProps>`
  display: ${(props) => (props.flex ? 'flex' : 'block')};
  width: ${(props) => (props.container ? '100%' : 'auto')};
  height: ${(props) => (props.container ? '100%' : 'auto')};
  justify-content: ${(props) =>
    props.center ? 'center' : props.justifyContent || 'unset'};
  align-items: ${(props) =>
    props.center ? 'center' : props.alignItems || 'unset'};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  flex-grow: ${(props) => props.flexGrow || 'unset'};
  ${(p) => (p.gap ? `gap: ${p.gap}px;` : '')}
  ${(props) => props.css};
`;

const Block = forwardRef<Ref, BlockProps>((props: BlockProps, ref) => {
  const { children, css, ...rest } = props;
  return (
    <StyledBlock ref={ref} css={css} {...rest}>
      {children}
    </StyledBlock>
  );
});

export default Block;
