/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import { captiveOrganizationsApi } from 'apis/captiveOrganizations';
import { voiceLinkMapDataApi } from 'apis/voiceLinkMapData';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import { uploadFileApiSlice } from '../apis/uploadFile';

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

// const resetStateMiddleWare: Middleware = (s, t) => {
//   return
// }

const middlewares = new MiddlewareArray();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const queryApiMiddlewares = [
  captiveOrganizationsApi.middleware,
  voiceLinkMapDataApi.middleware,
  uploadFileApiSlice.middleware,
];

const store = configureStore({
  reducer: (state, action) => ({
    ...persistedReducer(state, action),
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [
          'dialog/openDialog',
          'dialog/closeDialog',
          'message/showMessage',
          'message/hideMessage',
          'createOrg/create',
          'updateOrg/update',
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
      },
    })
      .concat([...queryApiMiddlewares])
      .concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

// store.asyncReducers = {};

export const persistor = persistStore(store);

// export const injectReducer = (key, reducer) => {
//   if (store.asyncReducers[key]) {
//     return false;
//   }
//   store.asyncReducers[key] = reducer;
//   store.replaceReducer(createReducer(store.asyncReducers));
//   return store;
// };

// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch = (): Dispatch => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
