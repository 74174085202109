import React from 'react';
import styled from 'styled-components';
import { Button as AntdButton, ButtonProps } from 'antd';

import AppIcon, { IconType } from 'components/atoms/SvgIcon';
import { theme } from 'theme/theme';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'link';

type Props = Pick<
  ButtonProps,
  | 'onClick'
  | 'children'
  | 'loading'
  | 'className'
  | 'disabled'
  | 'style'
  | 'htmlType'
> & {
  variant?: ButtonType;
  full?: boolean;
  size?: 'small' | 'medium';
  prefixIcon?: IconType;
  suffixIcon?: IconType;
  allowPropagation?: boolean;
};

const handleStyleColor = (type: Props['variant']) => {
  switch (type) {
    case 'secondary':
      return {
        default: {
          background: theme.colors.base_50,
          color: theme.colors.blue_500,
          borderColor: theme.colors.grayscale_200,
        },
        hover: {
          background: theme.colors.grayscale_50,
          color: theme.colors.blue_500,
          borderColor: theme.colors.grayscale_200,
        },
        disabled: {
          background: theme.colors.grayscale_50,
          color: theme.colors.grayscale_200,
          borderColor: theme.colors.grayscale_200,
        },
      };
    case 'tertiary':
      return {
        default: {
          background: theme.colors.base_50,
          color: theme.colors.blue_500,
          borderColor: theme.colors.transparent,
        },
        hover: {
          color: theme.colors.blue_500,
          background: theme.colors.grayscale_50,
          borderColor: theme.colors.transparent,
        },
        disabled: {
          background: theme.colors.transparent,
          color: theme.colors.grayscale_200,
          borderColor: theme.colors.transparent,
        },
      };
    case 'danger':
      return {
        default: {
          background: theme.colors.base_50,
          color: theme.colors.red_500,
          borderColor: theme.colors.grayscale_200,
        },
        hover: {
          color: theme.colors.red_500,
          background: theme.colors.red_50,
          borderColor: theme.colors.grayscale_200,
        },
        disabled: {
          background: theme.colors.grayscale_50,
          color: theme.colors.grayscale_200,
          borderColor: theme.colors.grayscale_200,
        },
      };
    case 'link':
      return {
        default: {
          background: 'none',
          color: theme.colors.blue_500,
          borderColor: 'none',
        },
        hover: {
          color: theme.colors.blue_600,
          background: 'none',
          borderColor: 'none',
        },
        disabled: {
          background: 'none',
          color: theme.colors.grayscale_200,
          borderColor: 'none',
        },
      };
    case 'primary':
    default:
      return {
        default: {
          background: theme.colors.blue_500,
          color: theme.colors.base_50,
          borderColor: theme.colors.blue_500,
        },
        hover: {
          background: theme.colors.blue_600,
          color: theme.colors.base_50,
          borderColor: theme.colors.blue_600,
        },
        disabled: {
          background: theme.colors.grayscale_200,
          color: theme.colors.grayscale_50,
          borderColor: theme.colors.grayscale_200,
        },
      };
  }
};

const StyledButton = styled(AntdButton)<
  Props & { $full: boolean; $size: 'small' | 'medium' }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => ({
    ...handleStyleColor(props.variant).default,
  })}
  border-radius: 4px;
  font-weight: 400;
  padding: ${(props) =>
    props.variant === 'link'
      ? '0'
      : props.$size === 'small'
      ? '4px 8px'
      : '6px 12px'};
  height: ${(props) =>
    props.variant === 'link' ? '0' : props.$size === 'small' ? '28px' : '32px'};
  box-shadow: none;

  svg {
    &:first-child:not(.suffix-icon) {
      margin-right: 4px;
    }

    &.suffix-icon {
      margin-left: 4px;
    }

    & > path {
      fill: ${(props) => handleStyleColor(props.variant).default.color};
    }
  }

  &:hover {
    ${(props) => ({
      ...handleStyleColor(props.variant).hover,
      borderColor:
        handleStyleColor(props.variant).default.borderColor ??
        handleStyleColor(props.variant).hover.color,
    })}
    & > svg > path {
      fill: ${(props) => handleStyleColor(props.variant).hover.color};
    }
  }

  &:focus {
    ${(props) => ({
      ...handleStyleColor(props.variant).hover,
    })}
  }

  &:disabled,
  :disabled:hover {
    ${(props) => ({
      ...handleStyleColor(props.variant).disabled,
    })}
    svg {
      & > path {
        fill: ${(props) => handleStyleColor(props.variant).disabled.color};
      }
    }
  }

  width: ${(props) => (props.$full ? '100%' : 'auto')};

  & > span {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    display: flex;
    align-items: center;
  }
`;

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      full = false,
      size = 'medium',
      prefixIcon,
      suffixIcon,
      onClick,
      allowPropagation = true,
      ...rest
    },
    ref,
  ) => {
    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // Fix SER-2342, this is know issue of Ant design;
      // link issue: https://github.com/ant-design/ant-design/issues/12908
      e.currentTarget.blur();
      // prevent event bubbling to parent component, e.g. Collapse panel
      if (!allowPropagation) {
        e.stopPropagation();
      }
      onClick && onClick(e);
    };

    return (
      <StyledButton
        ref={ref}
        $full={full}
        $size={size}
        icon={prefixIcon && <AppIcon name={prefixIcon} size={20} />}
        type="text"
        onClick={handleClick}
        {...rest}
      >
        <span>
          {children}
          {suffixIcon && (
            <AppIcon className="suffix-icon" name={suffixIcon} size={20} />
          )}
        </span>
      </StyledButton>
    );
  },
);

export default Button;
