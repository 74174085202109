import create from 'zustand';
import { persist } from 'zustand/middleware';
import { PERSIST_KEY } from 'data/keys';
import { registerAutoResetPersist } from '../reseter';

export interface State {
  [key: string]: string;
}

const initialState: State = {};

const portalConfigs = create<State>()(
  persist(() => initialState, {
    name: PERSIST_KEY.PORTAL_CONFIG,
  }),
);
registerAutoResetPersist(portalConfigs, initialState);

export { portalConfigs };
