import create from 'zustand';
import { useCallback } from 'react';

import DialogWithIcon from 'components/molecules/DialogWithIcon';
import SvgIcon from 'components/atoms/SvgIcon';
import { theme } from 'theme/theme';
import { useTranslate } from 'translate/useTranslate';
import Text from 'components/atoms/Text';

type Props = {
  onClose?: Noop;
  onAction?: Noop;
};

const OnlineDialog = ({ onClose, onAction }: Props) => {
  const { t } = useTranslate();
  const visible = state((s) => s.visible);

  const handleClose = useCallback(() => {
    hideDialog();
    onClose && onClose();
  }, []);

  const handleAction = useCallback(() => {
    hideDialog();
    onAction && onAction();
  }, []);

  return (
    <DialogWithIcon
      title={t('INTERNET_CONNECTION.ONLINE_DIALOG.TITLE')}
      dialogIcon={
        <SvgIcon name="wifi" size={46} color={theme.colors.blue_500} />
      }
      visible={visible}
      onClose={handleClose}
      actionName={t('INTERNET_CONNECTION.ONLINE_DIALOG.ACTION')}
      onConfirm={handleAction}
      cancelName={t('BUTTON.CLOSE')}
      zIndex={1040}
    >
      <Text>{t('INTERNET_CONNECTION.ONLINE_DIALOG.MESSAGE')}</Text>
    </DialogWithIcon>
  );
};

export default OnlineDialog;

type State = {
  visible: boolean;
};

const initialState: State = {
  visible: false,
};

const state = create<State>(() => initialState);

const showDialog = () => state.setState((s) => ({ ...s, visible: true }));
const hideDialog = () => state.setState((s) => ({ ...s, visible: false }));

const actions = {
  showDialog,
  hideDialog,
};

export { actions };
