import create from 'zustand';

import { CallHistory } from 'models/POT';

type State = {
  callHistory: CallHistory[];
};

const initialState: State = {
  callHistory: [],
};

export const state = create<State>(() => initialState);

const reset = () => state.setState(() => initialState);

const setCallHistory = (callHistory: CallHistory[]) =>
  state.setState(() => ({ callHistory }));

const addCallHistory = (call: CallHistory) =>
  state.setState((s) => {
    const currentHistory = [...s.callHistory];
    if (currentHistory.length === 5) {
      currentHistory.pop(); // remove the last one if currently has 5 calls
    }
    currentHistory.unshift(call);
    return { callHistory: currentHistory };
  });

export const actions = {
  setCallHistory,
  addCallHistory,
  reset,
};
