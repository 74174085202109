type Cb = () => void;
class EventPublisher<Event extends string> {
  private events: Event[];
  private callbackHandler: Record<Event, Cb[]>;

  constructor(e: Event[]) {
    this.events = e;
    this.callbackHandler = {} as Record<Event, Cb[]>;
  }

  publish(e: Event) {
    this.callbackHandler[e]?.forEach((cb) => cb());
  }

  on(_e: Event, cb: Cb) {
    console.log('listen: ', _e);
    if (!this.callbackHandler[_e]) {
      this.callbackHandler[_e] = [];
    }
    this.callbackHandler[_e].push(cb);
    return this;
  }

  off(_e: Event, cb: Cb) {
    this.callbackHandler[_e] = this.callbackHandler[_e].filter((c) => c !== cb);
    return this;
  }
}

export default EventPublisher;
