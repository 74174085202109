import { apiPost } from 'apis/baseAPI';
import { env } from 'constants/configs';

type Payload = {
  index: number;
  show_when_login: boolean;
};

const updateQuickTipsConfig = (req: Payload) => {
  return apiPost({
    diffDomain: `${env.REACT_APP_AUTH_URL}/api`,
    apiVersion: 'v1',
    url: 'quick-tips-config/put',
    data: req,
  });
};

export { updateQuickTipsConfig };
