const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');

const { fontWeight, fontSize, fontStyle } = getComputedStyle(document.body);

context.font = `${fontStyle} ${fontWeight} ${fontSize} Roboto, sans-serif`;

export function getTextContentWidth(text) {
  return context.measureText(text).width;
}
