import { apiPost } from 'apis/baseAPI';
import { BASE_AUTH_URL, CLIENT_ID } from 'constants/configs';

import { TokenResponse } from './model';

type Req = {
  username: string;
  password: string;
};

const login = async ({
  password,
  username,
}: Req): Promise<ServerResponse<TokenResponse>> => {
  const params = new URLSearchParams();
  params.append('username', username || '');
  params.append('password', password || '');
  params.append('grant_type', 'password');
  params.append('scope', 'offline_access openid profile email');
  params.append('client_id', CLIENT_ID);

  return await apiPost({
    method: 'POST',
    url: '/oauth/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    diffDomain: `${BASE_AUTH_URL}/api`,
    apiVersion: 'v2',
    data: params,
    isPrivate: false,
  });
};

export { login };
