import { Modal } from 'components/molecules/Modal';
import styled, { css } from 'styled-components';
import { PropsWithChildren, useMemo } from 'react';
import { Col, ModalProps, Row } from 'antd';

import { theme } from 'theme/theme';

import SvgIcon from '../../atoms/SvgIcon';
import Text from '../../atoms/Text';
import { useTranslate } from '../../../translate/useTranslate';
import Button from '../Button';

type ProgressingType = 'loading' | 'error' | 'succeed';

const StyledModal = styled(Modal)<{ type?: ProgressingType }>`
  .ant-modal-content {
    ${(props) =>
      props.type === 'succeed' &&
      css`
        width: 440px;
        margin: 0 auto;
      `}
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const RetryButton = styled(Button)`
  margin-left: 8px;
`;

const StyledText = styled(Text)<{ type: ProgressingType }>`
  color: ${(props) =>
    props.type === 'loading'
      ? theme.colors.grayscale_900
      : props.type === 'error'
      ? theme.colors.red_500
      : theme.colors.green_700};
`;

const Message = styled(Text)`
  margin-top: 8px;
`;

type ProgressingDialogProps = ModalProps &
  PropsWithChildren<{
    visible: boolean;
    type: ProgressingType;
    onClose: () => void;
    onRetry?: () => void | undefined;
    title?: string;
    message?: string | React.ReactNode;
    closeText?: string;
  }>;

const ProgressingDialog = ({
  visible,
  type,
  onClose,
  onRetry,
  title,
  message,
  closeText,
  children,
  ...rest
}: ProgressingDialogProps): JSX.Element => {
  const { t } = useTranslate();

  const renderText = (textType: string) =>
    useMemo(() => {
      return t(
        `PROGRESSING.TYPE.${type.toUpperCase()}.${textType.toUpperCase()}`,
      );
    }, [type]);

  return (
    <>
      <StyledModal
        destroyOnClose
        type={type}
        visible={visible}
        closable={false}
        footer={false}
        {...rest}
      >
        <Row>
          <Col span={3}>
            <SvgIcon
              name={
                type === 'loading'
                  ? 'DEFAULT_LOADING'
                  : type === 'succeed'
                  ? 'check_circle_fill'
                  : 'error_fill'
              }
              color={
                type === 'error' ? theme.colors.red_500 : theme.colors.green_700
              }
              size={type === 'loading' ? 46 : 38}
            />
          </Col>
          <Col span={20}>
            <Title>
              <StyledText type={type} variant="h2" bold>
                {title || renderText('title')}
              </StyledText>
            </Title>
            {children ? (
              children
            ) : (
              <Message>
                {children || message || (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: renderText('message').replace(/\n/g, '<br />'),
                    }}
                  />
                )}
              </Message>
            )}
          </Col>
        </Row>
        <Actions>
          <Button variant="secondary" onClick={onClose}>
            {closeText || renderText('close')}
          </Button>
          {type === 'error' && onRetry !== undefined && (
            <RetryButton variant="primary" onClick={onRetry}>
              {t('PROGRESSING.TYPE.ERROR.ACTION')}
            </RetryButton>
          )}
        </Actions>
      </StyledModal>
    </>
  );
};

export default ProgressingDialog;
