/* eslint-disable @typescript-eslint/ban-types */

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

/**
 * Returns a new object that contains only the specified keys from the input object.
 * @param input - An object that represents the input data.
 * @param keys - An array of strings that specifies the keys to be picked from the input object.
 * @returns An object that contains only the specified keys from the input object.
 */
export const pickSome = <
  TInput extends Record<string, unknown>,
  TKey extends keyof TInput,
  TOutput extends Prettify<Pick<TInput, Array<TKey>[number]>>
>(
  input: TInput,
  keys: TKey[],
): TOutput => {
  const rs = {} as Partial<TInput>;
  for (const k of keys) {
    rs[k] = input[k];
  }
  return (rs as unknown) as TOutput;
};
