import React from 'react';
import loadable, { LoadableComponent } from '@loadable/component';

import { IconProps } from './types';
interface Props {
  name: IconType;
  color?: string;
  size?: number;
  [key: string]: unknown;
}

export type IconType = keyof typeof ICONS;

const loadIcon = (name: string): LoadableComponent<React.FC<IconProps>> =>
  loadable(() => import(`./icons/${name}`));

export const ICONS = {
  LION: loadIcon('lion'),
  DEFAULT_LOADING: loadIcon('DefaultLoading'),
  LOADING: loadIcon('loading'),
  LOADING_SQUARE: loadIcon('loadingSquare'),
  menu: loadIcon('Menu'),
  organization: loadIcon('Organization'),
  check_circle_fill: loadIcon('CheckCircleFill'),
  info_fill: loadIcon('InfoFill'),
  warning_fill: loadIcon('WarningFill'),
  error_fill: loadIcon('ErrorFill'),
  sort: loadIcon('Sort'),
  chevron_left: loadIcon('ChevronLeft'),
  chevron_right: loadIcon('ChevronRight'),
  chevron_down: loadIcon('ChevronDown'),
  chevron_up: loadIcon('ChevronUp'),
  eye_fill: loadIcon('EyeFill'),
  eye_flash_fill: loadIcon('EyeFlashFill'),
  close: loadIcon('Close'),
  search: loadIcon('Search'),
  email: loadIcon('Email'),
  collapse_content: loadIcon('CollapseContent'),
  expand_content: loadIcon('ExpandContent'),
  DASHBOARD: loadIcon('Dashboard'),
  captive_user_fill: loadIcon('CaptiveUserFill'),
  router_fill: loadIcon('RouterFill'),
  edit: loadIcon('Edit'),
  import_router: loadIcon('ImportRouter'),
  connected: loadIcon('Connected'),
  disconnected: loadIcon('Disconnected'),
  dot_fill: loadIcon('DotFill'),
  location_on_fill: loadIcon('LocationOnFill'),
  refresh: loadIcon('Refresh'),
  usb: loadIcon('USB'),
  block: loadIcon('Block'),
  exit: loadIcon('Exit'),
  email_sent_color: loadIcon('EmailSentColor'),
  unbind: loadIcon('Unbind'),
  wifi: loadIcon('Wifi'),
  wifi_off: loadIcon('WifiOff'),
  export: loadIcon('Export'),
  help: loadIcon('Help'),
  attachment: loadIcon('Attachment'),
  failover: loadIcon('FailOver'),
  profile: loadIcon('Profile'),
  phone: loadIcon('Phone'),
  outgoing_color: loadIcon('OutgoingColor'),
  incoming_color: loadIcon('IncomingColor'),
  missed_call_color: loadIcon('MissedCallColor'),
  check: loadIcon('Check'),
  upload_photo_fill: loadIcon('UploadPhotoFill'),
  uninitialized: loadIcon('Uninitialized'),
  off_hook_color: loadIcon('OffHookColor'),
  calling_color: loadIcon('CallingColor'),
  thumb_up_fill: loadIcon('ThumbUpFill'),
  thumb_down_fill: loadIcon('ThumbDownFill'),
  firmware: loadIcon('Firmware'),
  exceeded: loadIcon('Exceeded'),
  list_fill: loadIcon('ListFill'),
  map_fill: loadIcon('MapFill'),
  settings: loadIcon('Settings'),
  settings_fill: loadIcon('SettingsFill'),
  laptop: loadIcon('Laptop'),
  iphone: loadIcon('IPhone'),
  captive_portal: loadIcon('CaptivePortal'),
  grid: loadIcon('Grid'),
  arrow_downward: loadIcon('ArrowDownward'),
  arrow_upward: loadIcon('ArrowUpward'),
  no_data_color: loadIcon('NoDataColor'),
  voicelink_fill: loadIcon('VoicelinkFill'),
  drag_indicator: loadIcon('DragIndicator'),
  vertical_menu: loadIcon('VerticalMenu'),
  copy: loadIcon('Copy'),
  error: loadIcon('Error'),
  info: loadIcon('Info'),
  check_circle: loadIcon('CheckCircle'),
  warning: loadIcon('Warning'),
  open_new_tab: loadIcon('OpenNewTab'),
  queue: loadIcon('Queue'),
  history: loadIcon('History'),
  report: loadIcon('Report'),
  arrow_back: loadIcon('ArrowBack'),
  arrow_forward: loadIcon('ArrowForward'),
  developer: loadIcon('Developer'),
  delete: loadIcon('Delete'),
  input_color: loadIcon('InputColor'),
  checklist_color: loadIcon('ChecklistColor'),
  radio_color: loadIcon('RadioColor'),
  chart: loadIcon('Chart'),
  table: loadIcon('Table'),
  signal_cellular: loadIcon('SignalCellular'),
  align_left: loadIcon('AlignLeft'),
  align_center: loadIcon('AlignCenter'),
  align_right: loadIcon('AlignRight'),
  unblock: loadIcon('Unblock'),
  pending: loadIcon('Pending'),
  lan_equipment: loadIcon('LanEquipment'),
  quick_tips_color: loadIcon('QuickTipsColor'),
  drag_handle: loadIcon('DragHandle'),
  print: loadIcon('Print'),
  plane: loadIcon('Plane'),
  bell: loadIcon('Bell'),
  file_fill: loadIcon('FileFill'),
  file: loadIcon('File'),
  calendar: loadIcon('Calendar'),
  link: loadIcon('Link'),
  mouse_move: loadIcon('MouseMove'),
  rating: loadIcon('Rating'),
  dot_small_fill: loadIcon('DotSmallFill'),
  report_fill: loadIcon('ReportFill'),
  no_subscription_agreement_color: loadIcon('NoSubscriptionAgreementColor'),
  location_red_color: loadIcon('LocationRedColor'),
  location_blue_color: loadIcon('LocationBlueColor'),
} as const;

const AppIcon: React.FC<Props> = ({
  name,
  size = 32,
  color = 'white',
  ...rest
}: Props) => {
  const Icon = name && ICONS[name];
  if (!Icon) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Icon width={size} height={size} fill={color} {...rest} />;
};

export default AppIcon;
