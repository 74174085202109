import styled from 'styled-components';

import Block from 'components/atoms/Block';
import Tag from 'components/atoms/Tag';
import IconButton from 'components/molecules/IconButton';

import { _translate } from 'translate/TranslateProvider';
import { theme } from 'theme/theme';

type Props = {
  content: string;
  onClose: Noop;
};

const Banner = ({ content, onClose }: Props): JSX.Element => {
  return (
    <Block css={{ backgroundColor: theme.colors.base_50 }}>
      <Content>
        <Tag
          text={_translate('NOTIFICATION_BANNER.NEW.LABEL')}
          background={theme.colors.green_100}
          textColor={theme.colors.green_700}
          style={{ alignSelf: 'center' }}
        />
        <span
          style={{ flexGrow: 1, alignSelf: 'center' }}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        <IconButton
          aria-label="Close notification banner"
          onClick={onClose}
          icon="close"
          color={theme.colors.grayscale_600}
          size={20}
        />
      </Content>
    </Block>
  );
};

export default Banner;

const Content = styled.div`
  line-height: initial;
  padding: 8px 20px;
  border-bottom: 1px solid ${theme.colors.grayscale_200};
  display: flex;
  gap: 20px;
  p {
    margin-bottom: 0;
    color: ${theme.colors.grayscale_900};
  }
`;
