export type Item<T> = T & {
  id: number | string;
  parent?: number | string | null;
  children?: Item<T>[];
};

const arrayToTree = <T>(items: Item<T>[]): Item<T>[] => {
  const rootItems = [] as Item<T>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lookup = {} as Record<string | number, any>;
  const idMapp = items.map((i) => i.id);

  for (const item of items) {
    const itemId = item['id'];
    const parentId = item['parent'];
    if (!lookup[itemId]) {
      lookup[itemId] = {
        id: '',
        parentId: '',
        children: [],
      };
    }
    lookup[itemId] = {
      ...item,
      ['children']: lookup[itemId]['children'],
    };

    const TreeItem = lookup[itemId];
    if (
      parentId === null ||
      parentId === undefined ||
      parentId === '' ||
      !idMapp.includes(parentId)
    ) {
      rootItems.push(TreeItem);
    } else {
      if (!lookup[parentId]) {
        lookup[parentId] = { ['children']: [] };
      }
      lookup[parentId]['children'].push(TreeItem);
    }
  }

  return rootItems;
};

export default arrayToTree;
