import Banner from 'page/private/SuperAdmin/pages/NotificationBanner/components/banner';
import {
  useAppConfigBanner,
  closeBanner,
} from 'page/private/SuperAdmin/pages/NotificationBanner/hooks/useGetAppNotificationBanner';

const AppBanner = () => {
  const banner = useAppConfigBanner();
  if (!banner || banner.config.isShow === false) {
    return <></>;
  }

  return <Banner content={banner.config.content} onClose={closeBanner} />;
};

export default AppBanner;
