import { actions } from 'modules/ServerMaintain';

const INTERVAL_TIME = 30 * 60 * 1000;

const getVersion = async () => {
  try {
    const vJson = await fetch(
      `${window.location.origin}/version?ts=${Date.now()}`,
    );
    const v = await vJson.json();
    return v;
  } catch (error) {
    console.log({ error });
  }
};

let initialVersion = 0;

getVersion().then((v) => {
  initialVersion = v;
});

const versionDetection = () => {
  setInterval(async () => {
    const _v = await getVersion();
    console.log(
      '%c%s%c%s',
      client_s,
      `Client: ${initialVersion}`,
      server_s,
      `Server: ${_v}`,
    );
    if (_v && initialVersion !== _v) {
      console.log('%c%s', new_s, 'HAS NEW VERSION!!!!');
      actions.showDialog();
    }
  }, INTERVAL_TIME);
};

export { versionDetection };

const new_s = [
  'background: #F96167',
  'color: #FCE77D',
  'font-size: 12px',
  'padding: 4px',
].join(';');

const client_s = [
  'background: #A04EF6',
  'color: #E7D045',
  'font-size: 12px',
  'padding: 4px',
  'font-weight: bold',
].join(';');

const server_s = [
  'background: #E7D045',
  'color: #A04EF6',
  'font-size: 12px',
  'padding: 4px',
].join(';');
