class Publisher {
  private subscribers: AnyFunction[] = [];

  subscribe(cb: AnyFunction) {
    this.subscribers.push(cb);
  }

  unsubscribe(cb: AnyFunction) {
    this.subscribers = this.subscribers.filter((i) => i !== cb);
  }

  publish(m?: unknown) {
    this.subscribers.forEach((c) => c(m));
  }

  destroy() {
    this.subscribers = [];
  }
}

export default Publisher;
