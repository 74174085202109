import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { thunkWrapper } from 'utils/thunkWrapper';
import { parseErrorMessage } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { User } from 'models/User';
import { Pagination } from 'models/Table';
import { apiGet, apiPost } from 'apis/baseAPI';
import { OrgsTree } from 'models/Organization';
import { GLOBAL_ACTION } from 'store/config';
import { ApiError } from 'models/Errors';
import { getTextContentWidth } from 'utils/getTextContentWidth';

type UserState = StateType<{
  users: User[];
  pagination: Pagination;
  sort: {
    field: string;
    order?: string;
  };
  filter: {
    orgs: OrgsTree[];
    roles: string[];
    status: string[];
    searchText: string;
    noneOrg: boolean;
  };
}>;

const name = 'listUser';
export const initialState = {
  data: {
    users: [],
    pagination: {
      page: 1,
      size: 25,
      total_elements: 0,
      total_pages: 0,
    },
    sort: {
      field: 'last_login',
      order: '-1',
    },
    filter: {
      orgs: [],
      roles: [],
      status: [],
      searchText: '',
      noneOrg: false,
    },
  },
  error: '',
  status: THUNK_STATUS.LOADING,
} as UserState;

export const getUsers1c = thunkWrapper({
  name: `${name}/getUsers`,
  action: async ({ thunkApi, headers }) => {
    try {
      const appState = thunkApi.getState() as AppState;
      const { orgs, roles, status, searchText } = (appState[
        name
      ] as UserState).data.filter;
      const { page, size } = (appState[name] as UserState).data.pagination;
      const { field, order } = (appState[name] as UserState).data.sort;
      const sort = `${order ? field : initialState.data.sort.field}:${
        order ? (order === 'ascend' ? 1 : -1) : -1
      }`;

      return await apiGet({
        url: 'portal/user/list',
        apiVersion: 'v2',
        params: {
          org_ids: orgs.map((i) => i.id),
          roles,
          status,
          search_text: searchText.trim(),
          page: page - 1,
          size,
          sort,
        },
        token: headers.token,
        reduxActionName: `${name}/getUsers`,
      });
    } catch (e) {
      return thunkApi.rejectWithValue(
        Error(parseErrorMessage(e as ApiError)).message,
      );
    }
  },
});

export const getUsers = createAsyncThunk(
  `${name}/getUsers`,
  async (data, { getState, rejectWithValue }) => {
    try {
      const appState = getState() as AppState;
      const { orgs, roles, status, searchText } = (appState[
        name
      ] as UserState).data.filter;
      const { page, size } = (appState[name] as UserState).data.pagination;
      const { field, order } = (appState[name] as UserState).data.sort;

      const sort = `${order ? field : initialState.data.sort.field}:${
        order ? (order === 'ascend' ? 1 : -1) : -1
      }`;

      return apiPost({
        url: 'portal/user/list',
        apiVersion: 'v3',
        reduxActionName: `${name}/getUsers`,
        data: {
          page: page - 1,
          size,
          roles,
          org_ids: orgs,
          status,
          search_text: searchText,
          sort,
        },
      });
    } catch (e) {
      return rejectWithValue(Error(parseErrorMessage(e as ApiError)).message);
    }
  },
);

const resetState = (state: UserState) => {
  state.error = '';
  state.status = THUNK_STATUS.IDLE;
  state.data = initialState.data;
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (s: UserState) => resetState(s),
    resetData(state: UserState) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data.users = [];
    },
    setFilterOrgIds(state, action) {
      state.data.filter.orgs = action.payload;
    },
    setFilterRoles(state, action) {
      state.data.filter.roles = action.payload;
    },
    setFilterStatus(state, action) {
      state.data.filter.status = action.payload;
    },
    setSearchText(state, action) {
      state.data.filter.searchText = action.payload;
    },
    clearSearchText(state, action) {
      state.data.filter.searchText = action.payload;
    },
    clearFilter(state) {
      state.data.filter.orgs = [];
      state.data.filter.roles = [];
      state.data.filter.status = [];
    },
    setPage(state, action) {
      state.data.pagination.page = action.payload;
    },
    setPageSize(state, action) {
      state.data.pagination.size = action.payload;
    },
    setSort(state, action) {
      state.data.sort = action.payload;
    },
  },
  extraReducers: {
    [getUsers.pending as never]: (state: UserState) => {
      state.error = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [getUsers.fulfilled as never]: (state: UserState, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data.users = action.payload.data;
      state.data.pagination.total_pages = action.payload.pagination.total_pages;
      state.data.pagination.total_elements =
        action.payload.pagination.total_elements;
    },
    [getUsers.rejected as never]: (state: UserState, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload;
    },
    [GLOBAL_ACTION.LOGGED_OUT as string]: (s: UserState) => resetState(s),
  },
});

export const {
  reset,
  setFilterOrgIds,
  setFilterRoles,
  setFilterStatus,
  clearFilter,
  setSearchText,
  clearSearchText,
  setPage,
  setPageSize,
  setSort,
  resetData,
} = slice.actions;

const selector = (state: AppState) => state[name];

export const selectUsers = createSelector(
  selector,
  // (data: UserState) => data.data.users,
  (state: UserState) => {
    const users = state.data.users;
    return users.map((u) => {
      return {
        ...u,
        _nameWidth: getTextContentWidth(u.name),
        _emailWidth: getTextContentWidth(u.email),
      };
    });
    // return users;
  },
);
export const selectUsersPagination = createSelector(
  selector,
  (state: UserState) => state.data.pagination,
);
export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);
export const selectError = createSelector(selector, (state) => state.error);
export const selectFilter = createSelector(
  selector,
  (state) => state.data.filter,
);
export const selectSearchText = createSelector(
  selector,
  (state) => state.data.searchText,
);
export const selectUserSort = createSelector(
  selector,
  (state) => state.data.sort,
);

const SetTransform = createTransform(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (inboundState: any, _key) => {
    const { ...rest } = inboundState;
    return rest;
  },

  (outboundState, _key) => {
    return { ...outboundState, users: [] };
  },

  { whitelist: ['data'] },
);

export default persistReducer(
  { key: name, storage, transforms: [SetTransform] },
  slice.reducer,
);
