import { css } from 'styled-components';

import { avatarGroupStyle, popoverStyle } from './popoverStyle';
import { customSteps } from './simplifiCustom/steps';
import { customCollapse } from './simplifiCustom/collapse';

const customStyles = css`
  body {
    .s-popover {
      ${popoverStyle}
    }
    .avatar-group-popup {
      ${avatarGroupStyle}
    }
  }

  .simplifi-custom-steps {
    ${customSteps}
  }

  .simplifi-custom-collapse {
    ${customCollapse}
  }

  .ant-popover-inner {
    border-radius: 6px;
  }

  .routers-map {
    border-radius: 12px;
  }

  /* use dark color for the table content */
  .ant-table .ant-table-cell {
    color: #444;
  }

  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }
`;

export default customStyles;
