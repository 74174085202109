import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { StateType } from 'models/Store';
import { CellularStatus } from 'models/Router';
import { THUNK_STATUS } from 'constants/status';
import { PROXY_DOMAIN } from 'constants/configs';

import { parseErrorMessage } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { apiGet } from 'apis/baseAPI';
import { ApiError } from 'models/Errors';

type State = StateType<CellularStatus>;

const name = 'getCellularStatus';
const initialState: State = {
  data: {
    band: '',
    firmware: '',
    imei: '',
    imsi: '',
    operator: '',
    registration: '',
    rssi: 0,
    sim: '',
    spn: '',
  },
  error: '',
  status: THUNK_STATUS.LOADING,
};

const whitelist: string[] = [];

export const getData = createAsyncThunk(
  `${name}/getData`,
  async (id: number, { rejectWithValue }) => {
    try {
      return await apiGet({
        url: `/router/rpc/cellular_info/${id}`,
        apiVersion: 'v2',
        diffDomain: `${PROXY_DOMAIN}/api`,
        headers: {
          'X-Device-Id': 'deviceid',
          'X-Device-Family': 'PC',
          'X-Device-Os': 'WIN',
          'X-Device-OS-Version': 10,
          'X-Device-Locale': 'vi_VN',
          'X-App-Version': '1.3.0',
        },
        reduxActionName: `${name}/getData`,
      });
    } catch (e) {
      return rejectWithValue(Error(parseErrorMessage(e as ApiError)).message);
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getData.pending as never]: (state: State) => {
      state.error = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [getData.fulfilled as never]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data = action.payload.data;
    },
    [getData.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload;
    },
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectLoadFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectData = createSelector(selector, (state) => state.data);

export default persistReducer({ key: name, storage, whitelist }, slice.reducer);
