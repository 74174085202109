import styled from 'styled-components';
import Block, { BlockProps } from '../Block';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<
  {
    className?: string;
  } & BlockProps
>;

const StyledContainer = styled(Block)`
  margin-top: 80px;
  max-width: 50%;
  width: 374px;
  background: ${(p) => p.theme.colors.base_50};
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 40px;
`;

const PublicPageFormContainer = (props: Props): JSX.Element => {
  return <StyledContainer {...props} />;
};

export default PublicPageFormContainer;
