export const PERSIST_KEY = {
  USER: {
    USERS_FILTERS: 'USER/USERS_FILTERS',
    DETAIL: {
      MANAGED_ROUTERS: 'USER/DETAIL/MANAGED_ROUTERS',
    },
  },
  USER_LIST_FILTER: 'USER_LIST_FILTER',
  DEVICE: 'DEVICE',
  APP_LANGUAGE: 'APP_LANGUAGE',
  AUTH: {
    TOKEN: 'TOKEN',
    CURRENT_USER: 'CURRENT_USER',
  },
  PROFILE: {
    TAB: 'PROFILE/TAB',
    MANAGED_ROUTERS: 'PROFILE/MANAGED_ROUTERS',
    ORGANIZATIONS: 'PROFILE/ORGANIZATIONS',
  },
  USER_GUIDE: {
    FILTERS: 'USER_GUIDE/FILTERS',
  },
  CAPTIVE: {
    COMMON_FILTER: 'CAPTIVE/COMMON_FILTER',
    PAGING: 'CAPTIVE/PAGING',
    SORTING: 'CAPTIVE/SORTING',
    USER_VISIT_FILTER: 'CAPTIVE/USER_VISIT_FILTER',
    ROUTER_FILTER: 'CAPTIVE/ROUTER_FILTER',
    CAPTIVE_GRID: 'CAPTIVE/CAPTIVE_GRID',
    CAPTIVE_LIST: 'CAPTIVE/CAPTIVE_LIST',
  },
  ROUTER: {
    LIST: 'ROUTER/LIST',
    VIEWMODE: 'ROUTER/VIEWMODE',
    STATUS_HISTORY: 'ROUTER/STATUS_HISTORY',
  },
  ORGANIZATIONS: {
    SEARCH: 'ORGANIZATIONS/SEARCH',
  },
  APP_CONFIG: {
    NOTIFICATION_BANNER: 'APP/NOTIFICATION_BANNER',
  },
  PORTAL_CONFIG: 'PORTAL_CONFIG',
};
