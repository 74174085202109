import { notification } from 'antd';

import SvgIcon from 'components/atoms/SvgIcon';
import { PHONE_STATE } from 'constants/POT';
import { theme } from 'theme/theme';
import { logDebugger } from 'utils/helpers/debuggLog';
import Text from 'components/atoms/Text';

import { MESSAGE_KEY, MESSAGE_TYPE } from '../config';

const userTopicHandler = (topic: string, m: Buffer) => {
  // reject if message from current router imei

  const [, , , , routerImei, type, key] = topic.split('/');

  // close message if phone data change from 'ringing' to others
  if (type === MESSAGE_TYPE.ALARM && key === MESSAGE_KEY.PHONE_STATE) {
    if (parseInt(m.toString()) !== PHONE_STATE.LABEL_KEY.RINGING) {
      console.log(
        `%c ${routerImei} close...`,
        `color: ${theme.colors.orange_700}; font-size: 16`,
      );
      notification.close(routerImei);
    }
  }

  if (type !== MESSAGE_TYPE.SIMPLIFI) {
    return;
  }
  if (key !== MESSAGE_KEY.ACTIVE_CALL) {
    return;
  }

  const { call_queue, phone_state } = JSON.parse(m.toString()) as {
    ts: number;
    call_queue?: {
      ts: number;
      id: string;
      state: string;
      outgoing: boolean;
      number: string;
    };
    phone_state: number;
  };

  // close toast if status is not 'Ringing'
  if (!call_queue || phone_state !== PHONE_STATE.LABEL_KEY.RINGING) {
    notification.close(routerImei);
    console.log(
      `%c ${routerImei} close...`,
      `color: ${theme.colors.orange_700}; font-size: 16`,
    );
    return;
  }

  logDebugger(`Calling from ${routerImei}`, '#4c9f70');

  // Show toast message
  notification.open({
    message: '',
    description: (
      <>
        <SvgIcon name="phone" size={40} color={theme.colors.grayscale_600} />
        <div className="call-info">
          <Text bold variant="h4">
            {call_queue.number}
          </Text>
          <Text css={{ marginTop: 4 }} color="gray-600">
            IMEI: {routerImei}
          </Text>
        </div>
      </>
    ),
    duration: 0,
    className: 'notification-pot',
    closeIcon: (
      <SvgIcon color={theme.colors.grayscale_600} name="close" size={20} />
    ),
    key: routerImei,
  });
};

export { userTopicHandler };
