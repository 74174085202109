import create from 'zustand';

type State = {
  isOnline: boolean;
};

const initialState: State = {
  isOnline: true,
};

const state = create<State>(() => initialState);

const setConnectionStatus = (isOnline: State['isOnline']) =>
  state.setState(() => ({ isOnline }));

const action = { setConnectionStatus };

export { state, action };
