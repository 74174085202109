import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiPost } from 'apis/baseAPI';
import { Router } from 'models/Router';
import { PROXY_DOMAIN } from 'constants/configs';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import notification from 'modules/notification';
import { ApiError } from 'models/Errors';
import { parseError } from 'utils/strings';
import { _translate } from 'translate/TranslateProvider';
import { SERVER_ERROR } from 'constants/ServerError';

const name = 'updateOrganization';

type State = StateType<Record<string, never>>;

const initialState: State = {
  data: {},
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export type UpdateOrgRequest = {
  router_id: string;
  old_org_id: string;
  org_id: string;
};

export const routerUpdateOrg = createAsyncThunk(
  `${name}/updateOrg`,
  async (_data: UpdateOrgRequest, { rejectWithValue }) => {
    try {
      const { router_id, old_org_id, org_id } = _data;
      const rs = await apiPost<{ data: Router }>({
        url: `router/update_org/${router_id}`,
        apiVersion: 'v2',
        diffDomain: `${PROXY_DOMAIN}/api`,
        data: {
          old_org_id: old_org_id,
          org_id: org_id,
        },
        reduxActionName: `${name}/updateOrg`,
      });
      notification.success(
        _translate('ROUTER_DETAIL.ADVANCED_INFO.EDIT.TOAST.SUCCESS'),
      );
      return rs;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
    },
  },
  extraReducers: {
    [routerUpdateOrg.pending as never]: (state: State) => {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [routerUpdateOrg.fulfilled as never]: (state: State) => {
      state.status = THUNK_STATUS.SUCCEEDED;
    },
    [routerUpdateOrg.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      if (!action.payload) {
        state.error = 'MESSAGE.UNKNOWN_ERROR';
      } else {
        const {
          id,
          code,
        } = action.payload as ApiError['response']['data']['error'];
        if (code === 'not_allowed') {
          state.error = _translate('MESSAGE.MOVE_ORG_TO_DIFFERENT_BRANCH');
          state.errorCode = code;
        } else if (
          code === 'blocked_org' ||
          code === SERVER_ERROR.NO_SERVICE_PERMISSION
        ) {
          state.error = _translate('MESSAGE.UNABLE_TO_PERFORM_REQUEST');
          state.errorCode = code;
        } else {
          state.error =
            'The selected organization no longer exists or suspended';
          state.errorCode = `update_organization_invalid`;
        }
        state.errorId = id;
      }
    },
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSucceed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFailed = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export default slice.reducer;
