import React from 'react';
import { Form, FormItemProps } from 'antd';
import Text, { TextColorVariant } from 'components/atoms/Text';
import Block, { BlockProps } from 'components/atoms/Block';
import styled from 'styled-components';
import { theme } from 'theme/theme';
import AppIcon, { IconType } from 'components/atoms/SvgIcon';

const { Item } = Form;

type Props = FormItemProps & {
  hideError?: boolean;
  hideHelp?: boolean;
  helperText?: React.ReactNode;
  helperTextColor?: TextColorVariant;
  noValidate?: boolean;
  helperIcon?: IconType;
  containerProps?: BlockProps;
};

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const StyledContainer = styled(Block)<{
  $hideError: boolean;
}>`
  &.no-validate .ant-input,
  &.no-validate .ant-input-affix-wrapper,
  &.no-validate
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #d9d9d9 !important;
  }
  &.no-validate
    .ant-select.ant-select-open:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #40a9ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const StyledItem = styled(Item)<{
  $hideError: boolean;
  $hideHepl: boolean;
}>`
  &.ant-form-item.ant-form-item-has-error + .ant-form-item-helper-text {
    opacity: 0;
  }
  &.ant-form-item:not(.ant-form-item-with-help) {
    margin-bottom: ${(p) => (p.$hideHepl ? '0' : '20px')};
  }
  .ant-form-item-label {
    padding: 0 0 4px;
    line-height: 16px;
    label {
      height: 16px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: ${theme.colors.grayscale_900};
      &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
      }
    }
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    min-height: 16px;
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
    ${(props) => (props.$hideError ? 'display: none;' : '')}
  }

  .ant-form-item-explain {
    color: ${theme.colors.red_500};
  }

  .ant-form-item-control-input {
    min-height: 28px;
  }

  &.ant-form-item-has-error div:not(.no-validate) .ant-input,
  &.ant-form-item-has-error div:not(.no-validate) .ant-input-affix-wrapper,
  &.ant-form-item-has-error
    div:not(.no-validate)
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${theme.colors.red_500} !important;
  }
`;

const HelperTextContainer = styled(Block)`
  margin-top: -16px;
  margin-bottom: 4px;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
`;

const HelperTextIcon = styled(AppIcon)`
  margin-right: 4px;
`;

const StyledAsterisk = styled(Text)`
  margin-left: 2px;
  color: ${theme.colors.red_500};
  font-weight: 500;
  width: 7px;
`;

const FormItem = ({
  required,
  label,
  helperText,
  helperTextColor,
  helperIcon,
  className,
  containerProps,
  hideError = false,
  noValidate = false,
  hideHelp = false,
  ...rest
}: Props): JSX.Element => {
  const _label = required ? (
    <Block flex>
      {label}
      <StyledAsterisk>*</StyledAsterisk>
    </Block>
  ) : (
    label
  );

  return (
    <StyledContainer
      flex
      column
      className={`${noValidate ? 'no-validate' : ''} ${className || ''} `}
      $hideError={hideError}
      {...containerProps}
    >
      <StyledItem
        $hideHepl={hideHelp}
        $hideError={hideError}
        {...layout}
        label={_label}
        {...rest}
      />
      {helperText && (
        <HelperTextContainer
          className="ant-form-item-helper-text"
          flex
          alignItems="center"
        >
          {helperIcon && (
            <HelperTextIcon
              name={helperIcon}
              size={20}
              color={
                helperTextColor ? helperTextColor : theme.colors.grayscale_600
              }
            />
          )}
          <Text
            variant="caption"
            color={helperTextColor ? helperTextColor : 'gray-600'}
          >
            {helperText}
          </Text>
        </HelperTextContainer>
      )}
    </StyledContainer>
  );
};

export default FormItem;
