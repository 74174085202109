import { CSSProperties, ReactNode } from 'react';
import {
  Tabs as AntdTabs,
  TabsProps as AntdTabsProps,
  TabPaneProps as AntdTabPaneProps,
} from 'antd';

import { UserRole } from 'models/User';
import { IconType } from 'components/atoms/SvgIcon';
import SVGIcon from 'components/atoms/SvgIcon';
import { checkPermission } from 'data/auth/currentUser';
import LineSkeleton from 'components/atoms/Skeleton/LineSkeleton';

import { StyledTabs } from './styled';

const { TabPane } = AntdTabs;

export interface TabsProps extends AntdTabsProps {
  tabs: TabPaneProps[];
  hideOperation?: boolean;
  isLoading?: boolean;
  loadingCss?: CSSProperties;
  value?: string;
}

export interface TabPaneProps extends AntdTabPaneProps {
  availableFor?: Uppercase<UserRole>[];
  tabIcon?: IconType;
  hideOperation?: boolean;
}

const TabTile = ({
  tab,
  tabIcon,
}: {
  tab: ReactNode;
  tabIcon?: IconType;
}): JSX.Element => {
  if (!tabIcon) {
    return tab as JSX.Element;
  }

  return (
    <>
      <SVGIcon name={tabIcon} size={16} />
      {tab}
    </>
  );
};

const Tabs = ({
  tabs,
  hideOperation = false,
  isLoading,
  loadingCss,
  activeKey,
  value,
  ...rest
}: TabsProps): JSX.Element => {
  if (isLoading)
    return (
      <div style={loadingCss}>
        {tabs.map(({ tabKey }) => (
          <div key={tabKey} className="mr-8">
            <LineSkeleton width={110} height={26} />
          </div>
        ))}
      </div>
    );

  return (
    <StyledTabs
      $hideOperation={hideOperation}
      activeKey={value || activeKey}
      {...rest}
      type="card"
    >
      {tabs.map(
        ({
          tabKey,
          tab,
          tabIcon,
          availableFor,
          children,
          ...rest
        }: TabPaneProps) =>
          !availableFor || checkPermission(availableFor) ? ( // don't require permission, or have the role needed
            <TabPane
              key={tabKey}
              tab={<TabTile tab={tab} tabIcon={tabIcon} />}
              {...rest}
            >
              {children}
            </TabPane>
          ) : null,
      )}
    </StyledTabs>
  );
};

export default Tabs;
