/* eslint-disable @typescript-eslint/no-explicit-any */
import produce, { Draft } from 'immer';
import { State, GetState, SetState } from 'zustand';

type Func = (..._args: any) => any;
type Cb<T1, T2> = (_t1: T1, _t2: T2) => void;

/**
 * Build a type to create a setter from object
 * {
 *    name: string;
 * }
 * ->
 * {
 *    setName: (c: string) => void
 * }
 */
type WithSetter<T> = {
  [P in keyof T as `set${Capitalize<P & string>}`]: (_v: T[P]) => void;
};
type SetterKey<T> = T extends string ? `set${Capitalize<T>}` : never;

// cappitalize string, ex: thing -> Thing
const capitalize = <S extends string>(s: S): Capitalize<S> =>
  (s[0].toUpperCase() + s.slice(1)) as Capitalize<S>;

// add 'set' prefix, ex: Thing -> setThing
const generateKey = <S extends string>(s: S): SetterKey<S> =>
  `set${capitalize(s)}` as SetterKey<S>;

export const util = <S extends State>(set: SetState<S>, get: GetState<S>) => {
  const _update = <P extends Func>(cb: Cb<Draft<S>, Parameters<P>[0]>) => (
    p: Parameters<P>[0],
  ) => {
    set(() => produce(get(), (d) => cb(d, p)));
  };

  const generateSetter = () => {
    const _setters: Record<string, any> = {};

    const _v = get();
    Object.keys(_v).forEach((_k: string) => {
      const _key = generateKey(_k as string);
      _setters[_key] = (p: any) =>
        _update((s, v) => {
          s[_k as keyof Draft<S>] = v;
        })(p);
    });

    // TODO implement function to set props by key;
    // ex: setKey('key', newValue),
    // use: setKey('name', 'John')
    // use: setKey('info.phone', 1231313)
    // use: setKey('info', {phone: 1212, address: 'abc'})

    return _setters as WithSetter<S>;
  };

  return {
    updateState: _update,
    generateSetter,
  };
};
