import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { apiGet } from 'apis/baseAPI';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import { parseError } from 'utils/strings';
import { Invitation } from 'models/Invitation';
import { Pagination } from 'models/Table';
import { ApiError } from 'models/Errors';
import { GLOBAL_ACTION } from 'store/config';

const name = 'invitationRequest';

type State = StateType<{
  invitations: Invitation[];
  pagination: Pagination;
  sort: {
    field: string;
    order?: string;
  };
}>;

const initialState: State = {
  data: {
    invitations: [],
    pagination: {
      page: 1,
      size: 25,
      total_elements: 0,
      total_pages: 0,
    },
    sort: {
      field: 'invitation_date',
      order: '-1',
    },
  },
  error: '',
  status: THUNK_STATUS.IDLE,
};

export const getData = createAsyncThunk(
  `${name}/listPending`,
  async (data, { getState, rejectWithValue }) => {
    try {
      const appState = getState() as AppState;

      const { field, order } = (appState[name] as State).data.sort;
      const fieldSort = field === 'expired_date' ? 'invitation_date' : field;
      const sort = `${order ? fieldSort : initialState.data.sort.field}:${
        order ? (order === 'ascend' ? 1 : -1) : -1
      }`;
      const { page, size } = (appState[name] as State).data.pagination;

      return await apiGet({
        url: 'portal/user/invitation/page',
        apiVersion: 'v2',
        params: {
          page: page - 1,
          size: size,
          sort: sort,
        },
        reduxActionName: `${name}/listPending`,
      });
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const resetState = (state: State) => {
  state.error = '';
  state.status = THUNK_STATUS.IDLE;
  state.data = initialState.data;
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      resetState(state);
    },
    setSort(state, action) {
      state.data.sort = action.payload;
    },
    setPage(state, action) {
      state.data.pagination.page = action.payload;
    },
    setPageSize(state, action) {
      state.data.pagination.size = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (s: State) => {
        s.status = THUNK_STATUS.LOADING;
      })
      .addCase(getData.fulfilled, (state: State, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        const data = action.payload as any;
        state.data.invitations = data.data;
        state.data.pagination.total_elements = data.pagination.total_elements;
      })
      .addCase(getData.rejected, (state: State, action) => {
        state.status = THUNK_STATUS.FAILED;
        state.error = action.payload as string;
      })
      .addCase(GLOBAL_ACTION.LOGGED_OUT, (state: State) => resetState(state));
  },
});

const selector = (state: AppState) => state[name] as State;

export const { reset, setSort, setPage, setPageSize } = slice.actions;

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectPendingInvitations = createSelector(
  selector,
  (state: State) => state.data.invitations,
);

export const selectPendingInvitationSort = createSelector(
  selector,
  (state) => state.data.sort,
);

export const selectInviteListPagination = createSelector(
  selector,
  (state) => state.data.pagination,
);

export const selectError = createSelector(selector, (state) => state.error);

// export default slice.reducer;

const SetTransform = createTransform(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (inboundState: any, _key) => {
    const { users, ...rest } = inboundState;
    return rest;
  },

  (outboundState, _key) => {
    return { ...outboundState, users: [] };
  },

  { whitelist: ['data'] },
);

export default persistReducer(
  { key: name, storage, transforms: [SetTransform] },
  slice.reducer,
);
