import { MapConstant } from 'models/Const';
import {
  CellularAuthenticationType,
  CellularIpFamily,
  CellularMode,
  Firewall,
  NetMask,
  Protocol,
  RouterFailover,
  RouterInterface,
  RouterRSSI,
  RouterStatus,
  TimeOffset,
  WiFiBand,
  WiFiCipher,
  WiFiEncryption,
  WiFiMacFilter,
  WiFiMode,
  WiFiWidth,
} from 'models/Router';

export const ROUTER_FAILOVER_STATUS: MapConstant<RouterFailover> = {
  KEY_LABEL: {
    0: 'ACTIVE',
    1: 'AVAILABLE',
    2: 'UNAVAILABLE',
  },
  LABEL_KEY: {
    ACTIVE: 0,
    AVAILABLE: 1,
    UNAVAILABLE: 2,
  },
  OPTIONS: [
    {
      value: 0,
      label: 'Active',
    },
    {
      value: 1,
      label: 'Available',
    },
    {
      value: 2,
      label: 'Unavailable',
    },
  ],
};

export const getFailoverLabel = (key?: number) => {
  if (key === undefined || key === null) {
    return undefined;
  }
  return ROUTER_FAILOVER_STATUS['KEY_LABEL'][key];
};

export const RSSI_STATUS: MapConstant<RouterRSSI> = {
  KEY_LABEL: {
    0: 'AMAZING',
    1: 'VERY GOOD',
    2: 'OK',
    3: 'NOT GOOD',
    4: 'UNUSABLE',
    5: 'OFFLINE',
  },
  LABEL_KEY: {
    AMAZING: 0,
    'VERY GOOD': 1,
    OK: 2,
    'NOT GOOD': 3,
    UNUSABLE: 4,
    OFFLINE: 5,
  },
  OPTIONS: [
    {
      value: 0,
      label: 'Amazing',
    },
    {
      value: 1,
      label: 'Very Good',
    },
    {
      value: 2,
      label: 'Ok',
    },
    {
      value: 3,
      label: 'Not Good',
    },
    {
      value: 4,
      label: 'Unusable',
    },
    {
      value: 5,
      label: 'Offline',
    },
  ],
};

export const ROUTER_STATUS: MapConstant<RouterStatus> = {
  KEY_LABEL: {
    1: 'CONNECTED',
    0: 'DISCONNECTED',
  },
  LABEL_KEY: {
    CONNECTED: 1,
    DISCONNECTED: 0,
  },
  OPTIONS: [
    {
      value: 0,
      label: 'Disconnected',
    },
    {
      value: 1,
      label: 'Connected',
    },
  ],
};

export const ROUTER_INTERFACE: MapConstant<RouterInterface> = {
  KEY_LABEL: {
    1: 'WIRED-WAN',
    2: 'CELLULAR',
  },
  LABEL_KEY: {
    'WIRED-WAN': 1,
    CELLULAR: 2,
  },
  OPTIONS: [
    {
      value: 1,
      label: 'Wired WAN',
    },
    {
      value: 1,
      label: 'Cellular',
    },
  ],
};

export const getInterfaceLabel = (key?: number) => {
  if (key === undefined || key === null) {
    return undefined;
  }
  return ROUTER_INTERFACE['KEY_LABEL'][key];
};

export const WIFI_MODE: MapConstant<WiFiMode> = {
  KEY_LABEL: {
    1: 'LEGACY',
    2: 'N',
    3: 'AC',
  },
  LABEL_KEY: {
    LEGACY: 1,
    N: 2,
    AC: 3,
  },
  OPTIONS: [
    {
      value: 1,
      label: 'Legacy',
    },
    {
      value: 2,
      label: 'N',
    },
    {
      value: 3,
      label: 'AC',
    },
  ],
};

export const WIFI_WIDTH: MapConstant<WiFiWidth> = {
  KEY_LABEL: {
    20: '20 MHZ',
    40: '40 MHZ',
    80: '80 MHZ',
  },
  LABEL_KEY: {
    '20 MHZ': 20,
    '40 MHZ': 40,
    '80 MHZ': 80,
  },
  OPTIONS: [
    {
      value: 20,
      label: '20 MHz',
    },
    {
      value: 40,
      label: '40 MHz',
    },
    {
      value: 80,
      label: '80 MHz',
    },
  ],
};

export const WIFI_BAND: MapConstant<WiFiBand> = {
  KEY_LABEL: {
    2: '2.4GHZ',
    5: '5GHZ',
  },
  LABEL_KEY: {
    '2.4GHZ': 2,
    '5GHZ': 5,
  },
  OPTIONS: [
    {
      value: 2,
      label: '2.4GHz',
    },
    {
      value: 5,
      label: '5GHz',
    },
  ],
};

export const WIFI_ENCRYPTION: MapConstant<WiFiEncryption> = {
  KEY_LABEL: {
    1: 'NONE',
    2: 'PSK',
    3: 'PSK2',
    4: 'PSK-MIXED',
  },
  LABEL_KEY: {
    NONE: 1,
    PSK: 2,
    PSK2: 3,
    'PSK-MIXED': 4,
  },
  OPTIONS: [
    {
      value: 1,
      label: 'None',
    },
    {
      value: 2,
      label: 'WPA-PSK',
    },
    {
      value: 3,
      label: 'WPA2-PSK',
    },
    {
      value: 4,
      label: 'WPA-PSK/WPA2-PSK Mixed Mode',
    },
  ],
};

export const WIFI_CIPHER: MapConstant<WiFiCipher> = {
  KEY_LABEL: {
    1: 'AUTO',
    2: 'CCMP',
    3: 'TKIP',
    4: 'TKIP+CCMP',
  },
  LABEL_KEY: {
    AUTO: 1,
    CCMP: 2,
    TKIP: 3,
    'TKIP+CCMP': 4,
  },
  OPTIONS: [
    {
      value: 1,
      label: 'Auto',
    },
    {
      value: 2,
      label: 'Force CCMP (AES)',
    },
    {
      value: 3,
      label: 'Force TKIP',
    },
    {
      value: 4,
      label: 'Force TKIP and CCMP (AES)',
    },
  ],
};

export const WIFI_MAC_FILTER: MapConstant<WiFiMacFilter> = {
  KEY_LABEL: {
    1: 'DISABLED',
    2: 'ALLOW',
    3: 'DENY',
  },
  LABEL_KEY: {
    DISABLED: 1,
    ALLOW: 2,
    DENY: 3,
  },
  OPTIONS: [
    {
      value: 1,
      label: 'Disable',
    },
    {
      value: 2,
      label: 'Allow listed only',
    },
    {
      value: 3,
      label: 'Allow all except listed',
    },
  ],
};

export const CELLULAR_AUTHENTICATION_TYPE: MapConstant<CellularAuthenticationType> = {
  KEY_LABEL: {
    1: 'NONE',
    2: 'CHAP',
    3: 'PAP',
    4: 'BOTH',
    5: 'CUSTOM',
  },
  LABEL_KEY: {
    NONE: 1,
    CHAP: 2,
    PAP: 3,
    BOTH: 4,
    CUSTOM: 5,
  },
  OPTIONS: [
    {
      value: 1,
      label: 'NONE',
    },
    {
      value: 2,
      label: 'CHAP',
    },
    {
      value: 3,
      label: 'PAP',
    },
    {
      value: 4,
      label: 'PAP/CHAP',
    },
    {
      value: 5,
      label: 'Custom',
    },
  ],
};

export const CELLULAR_IP_FAMILY: MapConstant<CellularIpFamily> = {
  KEY_LABEL: {
    1: 'IPV4',
    2: 'IPV4V6',
  },
  LABEL_KEY: {
    IPV4: 1,
    IPV4V6: 2,
  },
  OPTIONS: [
    {
      value: 1,
      label: 'IPv4',
    },
    {
      value: 2,
      label: 'IPv4/IPv6 Dual Stack',
    },
  ],
};

export const CELLULAR_MODE: MapConstant<CellularMode> = {
  KEY_LABEL: {
    1: 'AUTO',
    2: '3G-ONLY',
    3: '4G-ONLY',
  },
  LABEL_KEY: {
    AUTO: 1,
    '3G-ONLY': 2,
    '4G-ONLY': 3,
  },
  OPTIONS: [
    {
      value: 1,
      label: 'Automatic',
    },
    {
      value: 2,
      label: '3G Only',
    },
    {
      value: 3,
      label: '4G Only',
    },
  ],
};

export const PROTOCOLS: MapConstant<Protocol> = {
  KEY_LABEL: {
    1: 'STATIC',
    2: 'DHCP',
    3: 'PPPOE',
  },
  LABEL_KEY: {
    STATIC: 1,
    DHCP: 2,
    PPPOE: 3,
  },
  OPTIONS: [
    {
      label: 'Static Address',
      value: 'static',
    },
    {
      label: 'DHCP Client',
      value: 'dhcp',
    },
    {
      label: 'PPPoE',
      value: 'pppoe',
    },
  ],
};

export const FIREWALLS: MapConstant<Firewall> = {
  KEY_LABEL: {
    1: 'GUEST',
    2: 'LAN',
    3: 'VPN',
    4: 'WAN',
  },
  LABEL_KEY: {
    GUEST: 1,
    LAN: 2,
    VPN: 3,
    WAN: 4,
  },
  OPTIONS: [
    {
      label: 'Guest',
      value: 1,
    },
    {
      label: 'LAN',
      value: 2,
    },
    {
      label: 'VPN',
      value: 3,
    },
    {
      label: 'WAN',
      value: 4,
    },
  ],
};

export const IPV6_ASSIGNMENT: MapConstant<any> = {
  KEY_LABEL: {
    1: '64',
    2: 'disabled',
  },
  LABEL_KEY: {
    '64': 1,
    disabled: 2,
  },
  OPTIONS: [
    {
      label: '64',
      value: 1,
    },
    {
      label: 'Disable',
      value: 2,
    },
  ],
};

export const LEASE_TIME: MapConstant<TimeOffset> = {
  KEY_LABEL: {
    1: 'DAY',
    2: 'HOUR',
    3: 'MINUTE',
    4: 'SECOND',
  },
  LABEL_KEY: {
    DAY: 1,
    HOUR: 2,
    MINUTE: 3,
    SECOND: 4,
  },
  OPTIONS: [
    {
      label: 'Days',
      value: 'd',
    },
    {
      label: 'Hours',
      value: 'h',
    },
    {
      label: 'Minutes',
      value: 'm',
    },
    {
      label: 'Seconds',
      value: 's',
    },
  ],
};

export const NETMASK: MapConstant<NetMask> = {
  KEY_LABEL: {
    1: '255.255.255.0',
    2: '255.255.0.0',
    3: '255.0.0.0',
  },
  LABEL_KEY: {
    '255.255.255.0': 1,
    '255.255.0.0': 2,
    '255.0.0.0': 3,
  },
  OPTIONS: [
    {
      label: '255.255.255.0',
      value: '255.255.255.0',
    },
    {
      label: '255.255.0.0',
      value: '255.255.0.0',
    },
    {
      label: '255.0.0.0',
      value: '255.0.0.0',
    },
  ],
};

export const MIN_DISCONNECT_TIMER = {
  FIRMWARE_SUPPORT: 20801,
  MIN: 15,
  MAX: 5400,
};
