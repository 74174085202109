export const MESSAGE_TYPE = {
  ALARM: 'alarm',
  ATTRIBUTE: 'attribute',
  PROPERTY: 'property',
  CONNECTION: 'connection',
  SIMPLIFI: 'simplifi',
} as const;

export const MESSAGE_KEY = {
  ACTIVE_INTF: 'active_intf',
  WANN: 'wann',
  WWAN_STATUS: 'wwan_status',
  FAILOVER: 'failover',
  CELL_OPERATOR: 'cell_operator',
  CELL_BAND: 'cell_band',
  WIFI_IP: 'wifi_ip',
  SSID_NAME: 'ssid_name',
  FIRMWARE_VERSION: 'firmware_version',
  WAN_IP: 'wan_ip',
  RSSI: 'rssi',
  CONNECTED_DEVICES: 'connected_devices',
  WAN_DATAUSAGE: 'wan_datausage',
  DATAUSAGE: 'datausage',
  DISCONNECT: 'disconnect',
  CONNECT: 'connect',
  SLIC_CALL_STATUS: 'slic_call_status',
  PHONE_STATE: 'phone_state',
  SLIC: 'slic_state',
  PHONE_NAME: 'phone_name',
  PHONE_NUMBER: 'msisdn',
  SLIC_MODEL: 'slic_model',
  SLIC_FW_VER: 'slic_fw_ver',
  SLIC_LONGI_CURRENT: 'slic_longi_current',
  SLIC_LOOP_RESISTANCE: 'slic_loop_resistance',
  SLIC_TIP_RING_VOLT: 'slic_tip_ring_volt',
  CALL_QUEUE: 'call_queue',
  ACTIVE_CALL: 'active_call',
  HISTORY_CALL: 'history_call',
  METHODS: {
    REBOOT: 'method_reboot',
    FACTORY_RESET: 'method_factory_reset',
    FW_UPDATE: 'method_fw_update',
    PUSH_UPDATE: 'method_fw_update_push',
  },
} as const;

export const PHONE_STATUS = {
  UNINITIALIZED: 'Uninitialized',
  STANDBY: 'Standby',
  OFF_HOOK: 'Offhook',
  CALLING: 'Calling',
  IN_CALL: 'In-call',
  END_CALL: 'End-call',
  RINGING: 'Ringing',
};

export const QoS = {
  AT_MOST_ONCE: 0,
  AT_LEAST_ONE: 1,
  EXACTLY_ONCE: 2,
} as const;
