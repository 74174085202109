import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import arrayToTree from 'utils/arrayToObject';
import { Organization, TreeOrg } from 'models/Organization';
import { apiGet } from 'apis/baseAPI';
import { GLOBAL_ACTION } from 'store/config';
import { ApiError, BaseError, ErrorEmpty } from 'models/Errors';
import { currentUserState } from 'data/auth/currentUser';

const name = 'listOrg';

type OrgsSelectState = StateType<{
  disabledAction: boolean;
  // includeService: boolean;
  orgs: TreeOrg[];
  rawOrgs: Organization[];
}>;

const initialState = {
  data: {
    disabledAction: false,
    // includeService: false,
    orgs: [],
    rawOrgs: [],
  },
  error: '',
  status: THUNK_STATUS.IDLE,
} as OrgsSelectState;

type Request = {
  disabledAction: boolean;
  includeService: boolean;
};

export const getOrgs = createAsyncThunk<
  {
    data: {
      orgs: Organization[];
      disabledAction: boolean;
    };
  },
  Request | undefined,
  {
    rejectValue: BaseError;
  }
>(
  `${name}/getOrgs`,
  async (
    { disabledAction, includeService } = {
      disabledAction: false,
      includeService: false,
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiGet<{ data: Organization[] }>({
        url: `portal/org/list${includeService ? `?service_info=true` : ``}`,
        apiVersion: 'v2',
        reduxActionName: `${name}/getOrgs`,
      });

      return {
        data: {
          orgs: data,
          disabledAction: disabledAction,
        },
      };
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError) || ErrorEmpty);
    }
  },
);

const resetState = (state: OrgsSelectState) => {
  state.error = '';
  state.status = THUNK_STATUS.IDLE;
  state.data = initialState.data;
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state: OrgsSelectState) => resetState(state),
  },
  extraReducers: {
    [getOrgs.pending as never]: (state: OrgsSelectState) => {
      state.status = THUNK_STATUS.LOADING;
    },
    [getOrgs.fulfilled as never]: (state: OrgsSelectState, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data.rawOrgs = action.payload.data.orgs;

      const withVoiceLink = currentUserState
        .getState()
        .allowed_services.find((i) => i.service === 'VOICELINK');

      const convertedOrgs: TreeOrg[] = action.payload.data.orgs.map(
        (i: Organization) => ({
          ...i,
          key: i.id,
          title: i.name,
          disabled: action.payload.data.disabledAction ? i.suspended : false,
          service_on: Boolean(withVoiceLink?.orgId[i.id]),
        }),
      );
      state.data.orgs = arrayToTree<TreeOrg>(convertedOrgs);
    },
    [getOrgs.rejected as never]: (state: OrgsSelectState, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload;
    },
    [GLOBAL_ACTION.LOGGED_OUT]: (s: OrgsSelectState) => resetState(s),
  },
});

const selector = (state: AppState) => state.organization[name];

export const { reset } = slice.actions;

export const selectData = createSelector(
  selector,
  (state: OrgsSelectState) => state.data.orgs,
);

export const selectOriginalOrgs = createSelector(
  selector,
  (state: OrgsSelectState) => state.data.rawOrgs,
);

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);
export const selectError = createSelector(selector, (state) => state.error);

export default slice.reducer;
