interface Property {
  [key: string]: string;
}

const TYPE: Property = {
  m: 'margin',
  p: 'padding',
};

const UNITS = [
  '0',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '16',
  '20',
  '24',
  '28',
  '32',
  '40',
  '48',
  'auto',
] as const;
type UnitType = typeof UNITS[number];
const SPACING_UNIT:
  | Record<UnitType, `${UnitType}px`>
  | Record<string, never> = UNITS.reduce(
  (prev, cur) => ({
    ...prev,
    [cur.toString()]: cur === 'auto' ? cur : `${cur}px`,
  }),
  {},
);

const DIRECTIONS = {
  none: (t: string, u: UnitType) =>
    `.${t}-${u} {${TYPE[t]} ${SPACING_UNIT[u]} !important}`,
  x: (t: string, u: UnitType) =>
    `.${t}x-${u} {${TYPE[t]}: 0 ${SPACING_UNIT[u]}}`,
  y: (t: string, u: UnitType) =>
    `.${t}y-${u} {${TYPE[t]}: ${SPACING_UNIT[u]} 0}`,
  t: (t: string, u: UnitType) =>
    `.${t}t-${u} {${TYPE[t]}-top: ${SPACING_UNIT[u]}}`,
  r: (t: string, u: UnitType) =>
    `.${t}r-${u} {${TYPE[t]}-right: ${SPACING_UNIT[u]}}`,
  b: (t: string, u: UnitType) =>
    `.${t}b-${u} {${TYPE[t]}-bottom: ${SPACING_UNIT[u]}}`,
  l: (t: string, u: UnitType) =>
    `.${t}l-${u} {${TYPE[t]}-left: ${SPACING_UNIT[u]}}`,
  // eslint-disable-next-line no-unused-vars
} as Record<string, (t: string, u: string) => string>;

const utilitySpacing = Object.keys(TYPE).reduce((tPrev: string, t: string) => {
  return Object.keys(DIRECTIONS).reduce((dPrev: string, d: string) => {
    return Object.keys(SPACING_UNIT).reduce((sPrev: string, u: string) => {
      return sPrev + '\n' + DIRECTIONS[d](t, u) + ';';
    }, dPrev);
  }, tPrev);
}, '');

const utilityGap = UNITS.reduce((prev, cur) => {
  return (prev += '\n' + `.gap-${cur} { gap: ${cur}px; }`);
}, '');

export { utilitySpacing, utilityGap };
