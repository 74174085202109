import { useEffect } from 'react';

import { tokenStore } from 'data/auth/token';

import { doLogout } from '../useLogout';

const handleTokenEvent = (event: StorageEvent) => {
  const { key } = event;
  if (!key || key !== 'TOKEN') {
    return;
  }
  console.debug('old token', tokenStore.getState());
  if (!localStorage.getItem('TOKEN')) {
    // if user logout from a tab, log out all other tabs
    doLogout();
  } else {
    tokenStore.persist.rehydrate().then(() => {
      console.debug('new token', tokenStore.getState());
    });
  }
};

const useShareTokenWithOtherTabs = () => {
  useEffect(() => {
    window.addEventListener('storage', handleTokenEvent);

    return () => {
      window.removeEventListener('storage', handleTokenEvent);
    };
  }, []);
};

export { useShareTokenWithOtherTabs };
