import { useContext } from 'react';
import { TranslateContext, TranslateProviderValue } from './TranslateProvider';

export const useTranslate = (): TranslateProviderValue => {
  const translate = useContext(TranslateContext);
  if (!translate) {
    throw new Error('useTranslate must be used within a TranslateProvider');
  }
  return translate;
};
