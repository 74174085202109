import { createGlobalStyle } from 'styled-components';

import { notificationStyle } from './notificationStyle';
import skeletonStyle from './skeletonStyle';
import { checkboxStyle } from './checkboxStyle';
import { selectStyle } from './selectStyle';

const CustomStyled = createGlobalStyle`
  ${notificationStyle} ${skeletonStyle} ${checkboxStyle} ${selectStyle}
`;

export default CustomStyled;
