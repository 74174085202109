import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiPut } from 'apis/baseAPI';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import { parseError } from 'utils/strings';
import { User } from 'models/User';
import notification from 'modules/notification';
import { _translate } from 'translate/TranslateProvider';

const name = 'changeUserStatus';

type State = StateType<unknown>;

type Request = Pick<User, 'id' | 'email'> & {
  active: boolean;
};

const initialState: State = {
  data: null,
  error: '',
  status: THUNK_STATUS.IDLE,
  errorId: undefined,
  errorCode: undefined,
};

export const changeStatus = createAsyncThunk(
  `${name}/changeUserStatus`,
  async ({ email, id, active }: Request, { rejectWithValue }) => {
    try {
      await apiPut({
        url: 'portal/user/deactivate-user',
        apiVersion: 'v2',
        data: { user_id: id, deactivate_flag: !active },
        reduxActionName: `${name}/changeUserStatus`,
      });
      notification.success(
        _translate(
          active
            ? 'USER_DETAIL.UPDATE_DETAIL.ACTIVATED.SUCCESS.MESSAGE'
            : 'USER_DETAIL.UPDATE_DETAIL.DEACTIVATED.SUCCESS.MESSAGE',
          { email },
        ),
      );

      return;
    } catch (e) {
      return rejectWithValue(parseError(e));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
      state.errorCode = undefined;
      state.errorId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeStatus.pending, (state) => {
        state.status = THUNK_STATUS.LOADING;
        state.errorId = undefined;
        state.errorCode = undefined;
        state.error = '';
      })
      .addCase(changeStatus.fulfilled, (state) => {
        state.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(changeStatus.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const { code, id, message } = action.payload as {
            code: string;
            id: string;
            message: string;
          };
          state.error = message;
          state.errorCode = code;
          state.errorId = id;
        }
      });
  },
});

const selector = (state: AppState) => state[name];

export const { reset } = slice.actions;

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorCode, errorId }: State) => ({ error, errorCode, errorId }),
);

export default slice.reducer;
