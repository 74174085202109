import { apiGet } from 'apis/baseAPI';
import { env } from 'constants/configs';

interface TimeZone {
  format: string;
  offset: string;
  zone_id: string;
}

type Res = ServerResponse<{
  timezones: TimeZone[];
}>;

const getTimeZones = (signal?: AbortSignal): Promise<Res> => {
  return apiGet({
    diffDomain: `${env.REACT_APP_AUTH_URL}/api`,
    url: 'timezone/list',
    signal,
    apiVersion: 'v1',
  });
};

export { getTimeZones };
