export const API_QUERY_KEY = {
  CAPTIVE: {
    GET_LIST: 'CAPTIVE/GET_LIST',
    GET_ORG_FILTER_ALL: 'CAPTIVE/GET_ORG_FILTER_ALL',
    GET_DETAIL: 'CAPTIVE/GET_DETAIL',
    CREATE_CAPTIVE_PORTAL: 'CAPTIVE/CREATE_CAPTIVE_PORTAL',
    UPDATE_CAPTIVE_PORTAL: 'CAPTIVE/UPDATE_CAPTIVE_PORTAL',
    DELETE_CAPTIVE_PORTAL: 'CAPTIVE/DELETE_CAPTIVE_PORTAL',
    UPDATE_HOUSES: 'CAPTIVE/UPDATE_HOUSES',
    GET_SELECT_ABLE_CAPTIVE: 'CAPTIVE/GET_SELECT_ABLE_CAPTIVE',
    GET_CAPTIVE_DETAILS: 'CAPTIVE/GET_CAPTIVEDETAILS',
    GET_LIST_SELECT: 'CAPTIVE/GET_LIST_SELECT',
    GET_AVAILABLE_ORG: 'CAPTIVE/GET_AVAILABLE_ORG',
    GET_CAPTIVE_ROUTERS: 'CAPTIVE/GET_CAPTIVE_ROUTERS',
  },
  VOICELINK: {
    GET_MAP_DATA: 'VOICELINK/GET_MAP_DATA',
    GET_CALL_DETAILS: 'VOICELINK/GET_CALL_DETAILS',
    GET_CHART_DATA: 'VOICELINK/GET_CHART_DATA',
    MAP: {
      GET_DETAIL: 'VOICELINK/MAP/GET_DETAIL',
      GET_ROUTERS: 'VOICELINK/MAP/GET_ROUTERS',
    },
  },
  PAGE: {
    UPLOAD_FILE: 'PAGE/UPLOAD_FILE',
  },
  ROUTER: {
    CHANGE_FUNCTION: 'ROUTER/CHANGE_FUNCTION',
    WAN: {
      UPDATE_CONFIG: 'ROUTER/WAN/UPDATE_CONFIG',
    },
    CALL_HISTORY: {
      EXPORT: 'ROUTER/CALL_HISTORY/EXPORT',
    },
    MANAGERS: {
      GET_MANAGERS: 'ROUTER/MANAGERS/GET_MANAGERS',
    },
    METHOD: {
      REBOOT: 'ROUTER/METHOD/REBOOT',
      UPDATE_FIRMWARE: 'UPDATE_FIRMWARE',
      PUSH_UPDATE: 'PUSH_UPDATE',
      FACTORY_RESET: 'FACTORY_RESET',
      SWITCH_LAN_AND_WAN: 'SWITCH_LAN_AND_WAN',
      PUSH_UPDATE_VOICELINK: 'ROUTER/METHOD/PUSH_UPDATE_VOICELINK',
    },
    LIST_ON_MAP: 'ROUTER/LIST_ON_MAP',
    LIST: 'ROUTER/LIST',
    EXPORT_LIST: 'ROUTER/EXPORT_LIST',
    CHART_DATA: 'ROUTER/CHAR_DATA',
    MAP: {
      GET_DETAIL: 'ROUTER/MAP/GET_DETAIL',
    },
    VOICE_LINK: {
      GET_CALL_SUMMARY: 'ROUTER/VOICE_LINK/GET_CALL_SUMMARY',
      GET_CALL_SUMMARY_DETAIL: 'ROUTER/VOICE_LINK/GET_CALL_SUMMARY_DETAIL',
      GET_CALL_QUEUE: 'ROUTER/VOICE_LINK/GET_CALL_QUEUE',
      GET_PHONE_LINE_RESISTANCE_STATUS:
        'ROUTER/VOICE_LINK/GET_PHONE_LINE_RESISTANCE_STATUS',
      GET_PHONE_LINE_RING_VOLT_STATUS:
        'ROUTER/VOICE_LINK/GET_PHONE_LINE_RING_VOLT_STATUS',
      GET_CALL_HISTORY: 'ROUTER/VOICE_LINK/GET_CALL_HISTORY',
    },
    UPDATE_GID: 'ROUTER/UPDATE_GID',
    UPDATE_KAT: 'ROUTER/UPDATE_KAT',
    UPDATE_MDT: 'ROUTER/UPDATE_MDT',
    GET_MODEM_STATUS: 'ROUTER/GET_MODEM_STATUS',
    GET_CELL_DATA_USAGE: 'GET_CELL_DATA_USAGE',
    BASIC_INFORMATION: {
      GET_DATA_LAN_USAGE_CHART:
        'ROUTER/BASIC_INFORMATION/GET_DATA_LAN_USAGE_CHART',
      GET_DATA_WAN_USAGE_CHART:
        'ROUTER/BASIC_INFORMATION/GET_DATA_WAN_USAGE_CHART',
      GET_LATEST_HISTORY: 'ROUTER/BASIC_INFORMATION/GET_LATEST_HISTORY',
      GET_STATUS_HISTORY: 'ROUTER/BASIC_INFORMATION/GET_STATUS_HISTORY',
      EXPORT_STATUS_HISTORY: 'ROUTER/BASIC_INFORMATION/EXPORT_STATUS_HISTORY',
      GET_CURRENT_ROUTER_LOCATION:
        'ROUTER/BASIC_INFORMATION/GET_CURRENT_ROUTER_LOCATION',
    },
    PROPERTIES: {
      EXPORT_TABLE: 'ROUTER/PROPERTIES/EXPORT_TABLE',
      GET_RSSI_SIGNAL: 'ROUTER/PROPERTIES/GET_RSSI_SIGNAL',
      GET_CONNECTED_DEVICES: 'ROUTER/PROPERTIES/GET_CONNECTED_DEVICES',
    },
    NOTIFICATION: {
      GETCONFIG: 'ROUTER/NOTIFICATION/GET_CONFIG',
    },
    special_config: {
      get_config: 'router/special_config/get_config',
      save_config: 'router/special_config/save_config',
    },
  },
  USER_GUIDE: {
    GET_PAGES: 'USER_GUIDE/GET_PAGES',
    SEARCH_PAGES: 'USER_GUIDE/SEARCH_PAGES',
    BROWSE_PAGES: 'USER_GUIDE/BROWSE_PAGES',
    MOVE_PAGES: 'USER_GUIDE/MOVE_PAGES',
    CREATE_PAGE: 'USER_GUIDE/CREATE_PAGE',
    UPDATE_PAGE: 'USER_GUIDE/UPDATE_PAGE',
    PUBLIC_PAGE: 'USER_GUIDE/PUBLIC_PAGE',
    DETAIL_PAGE: {
      GET_BY_ID: 'USER_GUIDE/DETAIL_PAGE/GET_BY_ID',
      GET_BY_SLUG: 'USER_GUIDE/DETAIL_PAGE/GET_BY_SLUG',
    },
  },
  USER: {
    INVITE_USER: 'USER/INVITE_USER',
    GET_LIST: 'USER/GET_LIST',
    GET_MANAGED_ROUTERS: 'USER/GET_MANAGED_ROUTERS',
  },
  ORGANIZATION: {
    ROOT: 'ORGANIZATION',
    GET_BRANCH_ORG: 'ORGANIZATION/GET_BRANCH_ORG',
  },
  AUTH: {
    GET_PROFILE: 'AUTH/GET_PROFILE',
    GET_TIME_ZONES: 'GET_TIME_ZONES',
  },
  UPDATE_FIRMWARE: {
    UPLOAD_DATA: 'UPDATE_FIRMWARE/UPLOAD_DATA',
    BROWSE_FILE: 'UPDATE_FIRMWARE/BROWSE_FILE',
    GET_TEMPLATE: 'UPDATE_FIRMWARE/GET_TEMPLATE',
    SET_SCHEDULE: 'UPDATE_FIRMWARE/SET_SCHEDULE',
    DOWNLOAD_FILE_DETAIL: 'UPDATE_FIRMWARE/DOWNLOAD_FILE_DETAIL',
    GET_SCHEDULED_FILES: 'UPDATE_FIRMWARE/GET_SCHEDULED_FILES',
    GET_LOG_DETAIL: 'UPDATE_FIRMWARE/GET_LOG_DETAIL',
    GET_FILE_DETAIL: 'UPDATE_FIRMWARE/GET_FILE_DETAIL',
  },
  DEVELOPER: {
    GET_LOG: {
      LIST: 'DEVELOPER/GET_LOG/LIST',
      BROWSE: 'DEVELOPER/GET_LOG/BROWSE',
      DOWNLOAD: 'DEVELOPER/GET_LOG/DOWNLOAD',
    },
    RPC_METHOD: {
      EXECUTE: 'DEVELOPER/RPC_METHOD/EXECUTE',
    },
    API_LOG: {
      GET_LOG: 'DEVELOPER/API_LOG/GET_LOG',
      EXPORT_LOG: 'DEVELOPER/API_LOG/EXPORT_LOG',
    },
  },
  ADMIN: {
    CONFIGURATION_MANAGEMENT: {
      LIST: 'ADMIN/CONFIGURATION_MANAGEMENT/LIST',
      CREATE: 'ADMIN/CONFIGURATION_MANAGEMENT/CREATE',
      UPDATE: 'ADMIN/CONFIGURATION_MANAGEMENT/UPDATE',
      DELETE: 'ADMIN/CONFIGURATION_MANAGEMENT/DELETE',
      PACKAGE_DETAILS: 'ADMIN/CONFIGURATION_MANAGEMENT/PACKAGE_DETAILS',
      DETAIL: 'ADMIN/CONFIGURATION_MANAGEMENT/DETAIL',
      BROWSE_PACKGE_ROUTER:
        'ADMIN/CONFIGURATION_MANAGEMENT/BROWSE_PACKGE_ROUTER',
      BROWSE_ROUTER_LIST: 'ADMIN/CONFIGURATION_MANAGEMENT/ROUTER_LIST',
      BROWSE_ROUTER_LIST_FULL_INFO:
        'ADMIN/CONFIGURATION_MANAGEMENT/BROWSE_ROUTER_LIST_FULL_INFO',
      ADD_CONFIG_ROUTERS: 'ADMIN/CONFIGURATION_MANAGEMENT/ADD_CONFIG_ROUTERS',
      GET_TREE_CONFIG: 'ADMIN/CONFIGURATION_MANAGEMENT/GET_TREE_CONFIG',
      DETAILS: 'ADMIN/CONFIGURATION_MANAGEMENT/DETAILS',
      REMOVE_CONFIG_ROUTERS:
        'ADMIN/CONFIGURATION_MANAGEMENT/REMOVE_CONFIG_ROUTERS',
    },
    TRAVEL_LOG: {
      LIST: 'ADMIN/TRAVEL_LOG/LIST',
      DETAIL: 'ADMIN/TRAVEL_LOG/DETAIL',
      EXPORT: 'ADMIN/TRAVEL_LOG/EXPORT',
    },
  },
  USER_PROFILE: {
    MANAGED_ROUTERS: {
      GET_ROUTER_LIST: 'USER_PROFILE/MANAGED_ROUTERS/GET_ROUTER_LIST',
      GET_ORG_LIST: 'USER_PROFILE/MANAGED_ROUTERS/GET_ORG_LIST',
      UNBIND_ROUTER: 'USER_PROFILE/MANAGED_ROUTERS/UNBIND_ROUTER',
    },
  },
  GENERAL: {
    GET_ORG_LIST: 'GENERAL/GET_ORG_LIST',
  },
  SUBSCRIPTION_PLAN: {
    BROWSE: 'SUBSCRIPTION_PLAN/BROWSE',
    BROWSE_ALL: 'SUBSCRIPTION_PLAN/BROWSE_ALL',
    GET_DETAIL: 'SUBSCRIPTION_PLAN/GET_DETAIL',
  },
  SUBSCRIPTION: {
    CREATE_SUBSCRIBER: 'SUBSCRIPTION/CREATE_SUBSCRIBER',
    GET_AVAILABLE_ROUTERS: 'SUBSCRIPTION/GET_AVAILABLE_ROUTERS',
    GET_NUMBERS_OF_AVAILABLE_ROUTERS:
      'SUBSCRIPTION/GET_NUMBERS_OF_AVAILABLE_ROUTERS',
    CREATE_SUBSCRIPTION: 'SUBSCRIPTION/CREATE_SUBSCRIPTION',
    SEARCH_SUBSCRIBER: 'SUBSCRIPTION/SEARCH_SUBSCRIBER',
    GET_SUBSCRIPTION_DETAILS: 'SUBSCRIPTION/GET_SUBSCRIPTION_DETAILS',
    SEARCH_SUBSCRIPTION: 'SUBSCRIPTION/SEARCH_SUBSCRIPTION',
    EXPORT_SUBSCRIPTION: 'SUBSCRIPTION/EXPORT_SUBSCRIPTION',
    BROWSE_PROVISION_ASSETS: 'SUBSCRIPTION/BROWSE_PROVISION_ASSETS',
    DETAIL: {
      GET_AVAILABLE_ASSETS: 'SUBSCRIPTION/DETAIL/GET_AVAILABLE_ASSETS',
      ASSET_LOG: 'SUBSCRIPTION/DETAIL/ASSET_LOG',
      GET_BULK_ACTION_ASSETS: 'SUBSCRIPTION/DETAIL/GET_BULK_ACTION_ASSETS',
      SUBMIT_BULK_ACTION_ASSETS:
        'SUBSCRIPTION/DETAIL/SUBMIT_BULK_ACTION_ASSETS',
    },
  },
  SUBSCRIBER: {
    GET_LIST_ORG_FILTER: 'SUBSCRIBER/GET_LIST_ORG_FILTER',
    GET_LIST_ORG_CREATE: 'SUBSCRIBER/GET_LIST_ORG_CREATE',
    GET_LIST: 'SUBSCRIBER/GET_LIST',
  },
  SUBSCRIPTION_SUMMARY: {
    OVERVIEW: 'SUBSCRIPTION_SUMMARY/OVERVIEW',
    EXPORT_OVERVIEW: 'SUBSCRIPTION_SUMMARY/EXPORT_OVERVIEW',
    ASSET_EXPIRE_SOON: 'SUBSCRIPTION_SUMMARY/ASSET_EXPIRE_SOON',
    SUBSCRIPTION_LIST_FILTER: 'SUBSCRIPTION_SUMMARY/SUBSCRIPTION_LIST_FILTER',
    EXPORT_EXPIRE_SOON: 'SUBSCRIPTION_SUMMARY/EXPORT_EXPIRE_SOON',
    EXPIRATION_TRACKING_LIST: 'SUBSCRIPTION_SUMMARY/EXPIRATION_TRACKING_LIST',
    EXPIRATION_TRACKING_LIST_EXPORT:
      'SUBSCRIPTION_SUMMARY/EXPIRATION_TRACKING_LIST_EXPORT',
  },
  QUICKTIPS: {
    GET_CONFIG: 'QUICKTIPS/GET_CONFIG',
    UPDATE_CONFIG: 'QUICKTIPS/UPDATE_CONFIG',
  },
  APPCONFIG: {
    GET_CONFIG_ADMIN: 'APPCONFIG/GET_CONFIG_ADMIN',
    GET_CONFIG: 'APPCONFIG/GET_CONFIG',
    SET_CONFIG: 'APPCONFIG/SET_CONFIG',
    GET_ORG_LIST_FOR_SPECIAL_VOICELINK_CONFIG:
      'APPCONFIG/GET_ORG_LIST_FOR_SPECIAL_VOICELINK_CONFIG',
  },
  event_report: {
    get_list: 'event_report/get_list',
  },
  bulk_action: {
    get_org: 'bulk_action/get_org',
    browse_router: 'bulk_action/browse_router',
    submit: 'bulk_action/submit',
    browse_list_job: 'bulk_action/browse_list_job',
    browse_job_detail: 'bulk_action/browse_job_detail',
    browse_job_routers: 'bulk_action/browse_job_routers',
  },
  super_admin: {
    get_import_router_log: 'super_admin/get_import_router_log',
    get_import_router_log_detail: 'super_admin/get_import_router_log_detail',
    get_import_router_log_data: 'super_admin/get_import_router_log_data',
  },
} as const;
