import { notification } from 'antd';

import SvgIcon from 'components/atoms/SvgIcon';
import { _translate } from 'translate/TranslateProvider';
import { theme } from 'theme/theme';

const LOADING_TOAST_KEY = 'method-process-loading';

const toastLoading = (count: number) =>
  notification.open({
    key: LOADING_TOAST_KEY,
    message: (
      <>
        <span className="notification-header">
          <SvgIcon name="DEFAULT_LOADING" size={24} />
          <span className="notification-header-text">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  count > 1
                    ? _translate('PROGRESSING.TOAST.LOADING.MULTIPLE', {
                        count,
                      })
                    : _translate('PROGRESSING.TOAST.LOADING'),
              }}
            ></div>
          </span>
        </span>
      </>
    ),
    closeIcon: (
      <SvgIcon color={theme.colors.grayscale_600} name="close" size={16} />
    ),
    className: 'processing-toast',
    placement: 'bottomRight',
    duration: 0,
  });

const hideToastLoading = () => notification.close(LOADING_TOAST_KEY);

export { hideToastLoading, toastLoading };
