import { apiGet } from 'apis/baseAPI';
import { Profile } from '../model';

const getProfile = (): Promise<ServerResponse<Profile>> => {
  return apiGet({
    url: 'portal/account/profile?service_info=true&config_info=true',
    apiVersion: 'v2',
  });
};

export { getProfile };
