import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { EmptyProps } from 'models/Const';
import { StateType } from 'models/Store';
import {
  CellularAuthenticationType,
  CellularConfig,
  CellularIpFamily,
  CellularMode,
} from 'models/Router';
import { THUNK_STATUS } from 'constants/status';
import { PROXY_DOMAIN } from 'constants/configs';
import {
  CELLULAR_AUTHENTICATION_TYPE,
  CELLULAR_IP_FAMILY,
  CELLULAR_MODE,
} from 'constants/Router';

import { AppState } from 'store/rootReducer';
import { apiGet } from 'apis/baseAPI';
import { parseError } from 'utils/strings';
import { ApiError } from 'models/Errors';
import { setValue } from 'page/private/RouterDetail/components/BasicInformation/data/lastCommunicate';

type State = StateType<EmptyProps<CellularConfig>>;

type CellularConfigResponse = {
  anonymous: boolean;
  name: string;
  type: string;
  apn: string;
  auth: string;
  bands: string[];
  delay: string;
  device: string;
  disable_roam: boolean;
  ipfamily: string;
  metric: string;
  mode: string;
  password: string;
  pincode: string;
  proto: string;
  username: string;
};

const name = 'getCellularConfig';
const initialState: State = {
  data: {
    anonymous: false,
    name: '',
    type: '',
    apn: '',
    auth: '',
    bands: [],
    delay: '',
    device: '',
    disableRoam: '',
    ipFamily: '',
    metric: '',
    mode: '',
    password: '',
    pinCode: '',
    proto: '',
    username: '',
  },
  error: '',
  status: THUNK_STATUS.LOADING,
};

const whitelist: string[] = [];

const getAuthCustom = (auth: string) => {
  if (!auth) {
    return '';
  }
  if (
    auth.toUpperCase() ===
    CELLULAR_AUTHENTICATION_TYPE.KEY_LABEL[
      CELLULAR_AUTHENTICATION_TYPE.LABEL_KEY.CUSTOM
    ]
  ) {
    return auth;
  }
  if (!(auth.toUpperCase() in CELLULAR_AUTHENTICATION_TYPE.LABEL_KEY)) {
    return auth;
  }
  return '';
};

const getAuthType = (auth: string): string | number | undefined => {
  if (!auth) {
    return undefined;
  }
  if (auth.toUpperCase() in CELLULAR_AUTHENTICATION_TYPE.LABEL_KEY) {
    return CELLULAR_AUTHENTICATION_TYPE.LABEL_KEY[
      auth.toUpperCase() as CellularAuthenticationType
    ];
  }
  return CELLULAR_AUTHENTICATION_TYPE.LABEL_KEY.NONE;
};

const convertToFE = (
  data: CellularConfigResponse,
): EmptyProps<CellularConfig> => {
  return {
    anonymous: data.anonymous,
    apn: data.apn || '',
    auth: getAuthType(data.auth),
    authCustom: getAuthCustom(data.auth),
    bands: data.bands,
    delay: data.delay,
    device: data.device,
    disableRoam: data.disable_roam,
    ipFamily: data.ipfamily
      ? CELLULAR_IP_FAMILY.LABEL_KEY[
          data.ipfamily.toUpperCase() as CellularIpFamily
        ]
      : CELLULAR_IP_FAMILY.LABEL_KEY.IPV4,
    metric: data.metric,
    mode: data.mode
      ? CELLULAR_MODE.LABEL_KEY[data.mode.toUpperCase() as CellularMode]
      : CELLULAR_MODE.LABEL_KEY.AUTO,
    name: data.name,
    password: data.password || '',
    pinCode: data.pincode || '',
    proto: data.proto || '',
    type: data.type || '',
    username: data.username || '',
  };
};

export const getData = createAsyncThunk(
  `${name}/getData`,
  async (id: string, { rejectWithValue }) => {
    try {
      const rs = await apiGet<ServerResponse<CellularConfigResponse>>({
        url: `/router/rpc/cell_config/${id}`,
        apiVersion: 'v2',
        diffDomain: `${PROXY_DOMAIN}/api`,
        headers: {
          'X-Device-Id': 'deviceid',
          'X-Device-Family': 'PC',
          'X-Device-Os': 'WIN',
          'X-Device-OS-Version': 10,
          'X-Device-Locale': 'vi_VN',
          'X-App-Version': '1.3.0',
        },
        reduxActionName: `${name}/getData`,
      });
      setValue(rs.meta.last_communication);
      return rs;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorCode = '';
      state.errorId = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getData.pending as never]: (state: State) => {
      state.error = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [getData.fulfilled as never]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data = convertToFE(action.payload.data);
    },
    [getData.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload.message;
      state.errorCode = action.payload.code;
    },
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectLoadedFail = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectData = createSelector(selector, (state) => state.data);

export const selectError = createSelector(
  selector,
  (state: State) => state.error,
);

export const selectErrorCode = createSelector(
  selector,
  (state: State) => state.errorCode,
);

export default persistReducer({ key: name, storage, whitelist }, slice.reducer);
