import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiPut } from 'apis/baseAPI';
import { parseError } from 'utils/strings';
import { THUNK_STATUS } from 'constants/status';
import { StateType } from 'models/Store';
import { AppState } from 'store/rootReducer';
import { ApiError, BaseError, ErrorEmpty } from 'models/Errors';
import notification from 'modules/notification';
import { _translate } from 'translate/TranslateProvider';

const name = 'suspendOrg';

type SuspendRequest = {
  org_id: string;
  name: string;
  suspend: boolean;
};

type State = StateType<SuspendRequest>;

const initialState: State = {
  data: {
    org_id: '',
    name: '',
    suspend: false,
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const suspendOrg = createAsyncThunk<
  void,
  SuspendRequest,
  {
    rejectValue: BaseError;
  }
>(`${name}/suspend`, async (requestData, { rejectWithValue }) => {
  try {
    const { org_id, suspend, name } = requestData;
    await apiPut({
      url: 'portal/org/suspend',
      apiVersion: 'v2',
      data: { org_id, suspend },
      reduxActionName: `${name}/suspend`,
    });

    notification.success(
      _translate(suspend ? 'ORG.BLOCK.SUCCESS' : 'ORG.UNBLOCK.SUCCESS', {
        name,
      }),
    );
    return;
  } catch (err) {
    return rejectWithValue(parseError(err as ApiError) || ErrorEmpty);
  }
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [suspendOrg.pending as never]: (state: State) => {
      state.status = THUNK_STATUS.LOADING;
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
    },
    [suspendOrg.fulfilled as never]: (state: State) => {
      state.status = THUNK_STATUS.SUCCEEDED;
    },
    [suspendOrg.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      if (!action.payload) {
        state.error = 'MESSAGE.UNKNOWN_ERROR';
      } else {
        const {
          code,
          id,
          message,
        } = action.payload as ApiError['response']['data']['error'];
        state.error = message;
        state.errorId = id;
        state.errorCode = `block_org.${code}`;
      }
    },
  },
});

const selector = (state: AppState) => state.organization[name];

export const selectLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuspendSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectSuspendedFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectSuspendError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const { reset } = slice.actions;

export default slice.reducer;
