import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { AppState } from 'store/rootReducer';
import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { OrganizationFormState } from 'models/Organization';
import { parseError } from 'utils/strings';
import { defaultHeaders, env } from 'constants/configs';
import { ApiError } from 'models/Errors';
import { getAccessToken } from 'utils/token';

import { _translate } from 'translate/TranslateProvider';
import notification from 'modules/notification';

const BASE_URL = `${env.REACT_APP_API_URL}/api`;

type State = StateType<OrganizationFormState>;

const name = 'createOrg';

const initialState: State = {
  data: {
    id: '',
    logo: null,
    name: '',
    parent: null,
    desc: '',
    phone: '',
    address: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    email: '',
    allow_sub_orgs: false,
    allow_org_admin: 0,
    disallow_assets: false,
    service_captive: 'ACTIVE',
    service_voicelink: 'ACTIVE',
    subscription_team_email: '',
    technical_support_team_email: '',
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const createOrg = createAsyncThunk(
  name,
  async (requestData: OrganizationFormState, { rejectWithValue }) => {
    try {
      const {
        name,
        desc,
        parent,
        logo,
        phone,
        email,
        address1,
        address2,
        city,
        state,
        zipcode,
        service_captive,
        service_voicelink,
        subscription_team_email,
        technical_support_team_email,
      } = requestData;

      const data = new FormData();
      data.append('name', name);
      data.append('desc', desc.replace(/[\r]/g, ''));
      data.append('parent', parent as string);
      data.append('logo', logo as any);
      data.append('phone', phone);
      data.append('email', email);
      data.append('street_number', address1);
      data.append('street', address2);
      data.append('city', city);
      data.append('state', state);
      data.append('zip_code', zipcode);
      data.append('subscription_team_email', subscription_team_email);
      data.append('technical_support_team_email', technical_support_team_email);

      if (service_captive) {
        data.append('service_captive', service_captive);
      }
      if (service_voicelink) {
        data.append('service_voicelink', service_voicelink);
      }

      const headers = {
        ...defaultHeaders,
        authorization: 'Bearer ' + getAccessToken(),
        mode: 'cors',
      } as any;

      const responseData = await (
        await fetch(`${BASE_URL}/v2/portal/org/create`, {
          method: 'POST',
          headers,
          body: data,
        })
      ).json();

      const apiError: ApiError = {
        response: {
          data: {
            error: responseData.error,
          },
        },
        message: '',
      };

      if (responseData.error) {
        return rejectWithValue(parseError(apiError));
      }
      notification.success(
        _translate('ORG.CREATE_ORG.SUCCESS', {
          name,
        }),
      );
      return responseData;
    } catch (err) {
      return rejectWithValue(parseError(err as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrg.pending, (state) => {
        state.status = THUNK_STATUS.LOADING;
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
      })
      .addCase(createOrg.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data = action.payload.data;
      })
      .addCase(createOrg.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `create_org.${code}`;
        }
      });
  },
});

const selector = (state: AppState) => state.organization[name];

export const selectLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectLoaded = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFailed = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const selectCreatedData = createSelector(
  selector,
  (state: State) => state.data,
);

export const { reset } = slice.actions;

export default slice.reducer;
