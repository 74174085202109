import { StateType } from 'models/Store';
import storage from 'redux-persist/lib/storage';
import { createTransform, persistReducer } from 'redux-persist';

import { THUNK_STATUS } from 'constants/status';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AppState } from 'store/rootReducer';
import { parseErrorMessage } from 'utils/strings';
import { GLOBAL_ACTION } from 'store/config';
import { Pagination } from 'models/Table';
import { apiPost } from 'apis/baseAPI';
import { ApiError } from 'models/Errors';
import { env } from 'constants/configs';
import { CaptivePortal } from 'models/Captive';
import {
  userVisitFilter,
  setTotalElements,
  setTotalPages,
} from 'page/private/CaptivePortal/data/userVisitFilter';

export type UserVisitBy = 'org' | 'captive';

type State = StateType<{
  id: string;
  orgName: string;
  portalName: string;
  data: CaptivePortal[];
  pagination: Pagination;
  sort: {
    field: string;
    order?: string;
  };
  statusExport: boolean;
}>;

const name = 'listCaptiveDetail';

const initialState: State = {
  data: {
    id: '',
    orgName: '',
    portalName: '',
    data: [],
    pagination: {
      page: 1,
      size: 25,
      total_elements: 0,
      total_pages: 0,
    },
    sort: {
      field: 'login_time',
      order: '-1',
    },
    statusExport: false,
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const listCaptiveDetail = createAsyncThunk(
  name,
  async (_, { rejectWithValue }) => {
    try {
      const {
        captives,
        orgIds,
        textSearch,
        dateFilter,
        pagination,
        sort,
      } = userVisitFilter.getState();

      const [dateFrom, dateTo] = dateFilter || ['', ''];

      const sorting = `${
        sort.order ? sort.field : initialState.data.sort.field
      }:${sort.order ? (sort.order === 'ascend' ? 1 : -1) : -1}`;

      const resp = await apiPost<State['data']>({
        diffDomain: `${env.REACT_APP_CAPTIVE_PORTAL_URL}/api`,
        url: 'portal/captive/page',
        apiVersion: 'v6',
        data: {
          filter: {
            portal_ids: captives,
            org_ids: orgIds,
            search_text: textSearch.trim(),
            from: dateFrom || '',
            to: dateTo || '',
          },
          sort: {
            sort: sorting,
          },
          paging: {
            page: pagination.page - 1,
            size: pagination.size,
          },
        },
        reduxActionName: name,
      });
      setTotalElements(resp.pagination.total_elements);
      setTotalPages(resp.pagination.total_pages);
      return resp;
    } catch (e) {
      return rejectWithValue(Error(parseErrorMessage(e as ApiError)).message);
    }
  },
);

const resetState = (state: State) => {
  state.error = '';
  state.status = THUNK_STATUS.IDLE;
  state.data = initialState.data;
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (s) => resetState(s),
    resetData(state) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data.statusExport = false;
      state.data.data = [];
    },
    setPortalName(state, action) {
      state.data.portalName = action.payload;
    },
    setOrgName(state, action) {
      state.data.orgName = action.payload;
    },
    setPage(state, action) {
      state.data.pagination.page = action.payload;
    },
    setPageSize(state, action) {
      state.data.pagination.size = action.payload;
    },
    setSort(state, action) {
      state.data.sort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCaptiveDetail.pending, (state) => {
        state.error = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(listCaptiveDetail.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data.data = action.payload.data;
        state.data.pagination.total_elements =
          action.payload.pagination.total_elements;
      })
      .addCase(listCaptiveDetail.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `captive_list.${code}`;
        }
      })
      .addCase(GLOBAL_ACTION.LOGGED_OUT, (state) => {
        resetState(state);
      });
  },
});

export const {
  reset,
  setOrgName,
  setPage,
  setPageSize,
  setSort,
  resetData,
  setPortalName,
} = slice.actions;

export const captiveRecordActions = slice.actions;

const selector = (state: AppState) => state.captive[name];

export const selectCaptives = createSelector(selector, (state: State) => {
  return state.data.data;
});

export const selectOrgName = createSelector(
  selector,
  (state: State) => state.data.orgName,
);

export const selectPortalName = createSelector(
  selector,
  (state) => state.data.portalName,
);

export const selectCaptivesPagination = createSelector(
  selector,
  (state) => state.data.pagination,
);
export const selectCaptivesSort = createSelector(
  selector,
  (state) => state.data.sort,
);

export const selectIsEmptyData = createSelector(
  selector,
  (state: State) => state.data.data.length === 0,
);

export const selectFilter = createSelector(
  selector,
  (state) => state.data.filter,
);

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFailed = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.FAILED,
);

export const selectExporting = createSelector(
  selector,
  (state) => state.data.statusExport,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const captiveRecordSelect = {
  selectCaptives,
  selectCaptivesPagination,
  selectCaptivesSort,
  selectError,
  selectExporting,
  selectFailed,
  selectFilter,
  selectIsEmptyData,
  selectLoading,
  selectOrgName,
  selectPortalName,
  selectSuccess,
};

const SetTransform = createTransform(
  (inboundState: { pagination: Pagination }) => {
    const { pagination } = inboundState;
    return pagination;
  },

  (outboundState) => {
    return {
      ...initialState.data,
      pagination: outboundState,
    };
  },

  { whitelist: ['data'] },
);

export default persistReducer(
  { key: name, storage, transforms: [SetTransform] },
  slice.reducer,
);
