import { notification } from 'antd';

import { _translate } from 'translate/TranslateProvider';
import { theme } from 'theme/theme';
import SvgIcon, { IconType } from 'components/atoms/SvgIcon';
import Text from 'components/atoms/Text';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const CLOSE_ICON_SIZE = 16;
const NOTIFICATION_ICON_SIZE = 24;
const INTERNET_TOAST_KEY = 'key';

const NotificationMap = {
  success: {
    icon: 'check_circle_fill',
    title: 'Success',
    color: theme.colors.green_700,
  },
  error: {
    icon: 'error_fill',
    title: 'Error',
    color: theme.colors.red_500,
  },
  info: {
    icon: 'info_fill',
    title: 'Info',
    color: theme.colors.blue_600,
  },
  warning: {
    icon: 'warning_fill',
    title: 'Warning',
    color: theme.colors.orange_700,
  },
} as Record<
  NotificationType,
  {
    icon: IconType;
    title: `${Capitalize<NotificationType>}`;
    color: string;
  }
>;

const openNotification = (message: string, type: NotificationType): void => {
  notification.open({
    message: (
      <span className="notification-header">
        <SvgIcon
          color={NotificationMap[type].color}
          name={NotificationMap[type].icon}
          size={NOTIFICATION_ICON_SIZE}
        />
        <div
          dangerouslySetInnerHTML={{ __html: message }}
          className="notification-text"
        ></div>
      </span>
    ),
    description: '',
    duration: 3,
    top: 75,
    closeIcon: (
      <SvgIcon
        color={theme.colors.grayscale_600}
        name="close"
        size={CLOSE_ICON_SIZE}
      />
    ),
  });
};

let isShowInternetNotification = false;

const openLossInternetNotification = (): void => {
  if (isShowInternetNotification) {
    // toast is visible - shake the toast
    const noInternetToast = document.getElementsByClassName(
      'notification-no-internet',
    )[0];
    if (!noInternetToast) return;
    if (!noInternetToast.classList.contains('shake-notification')) {
      noInternetToast.classList.add('shake-notification');
      setTimeout(() => {
        noInternetToast.classList.remove('shake-notification');
      }, 1000);
    }
  } else {
    // toast is hidden - show the toast
    isShowInternetNotification = true;
    notification.open({
      message: '',
      description: (
        <div className="notification-action-container">
          <div className="notification-act-icon">
            <SvgIcon
              name="wifi_off"
              size={NOTIFICATION_ICON_SIZE}
              color={theme.colors.orange_700}
            />
          </div>
          <div className="notification-act-content">
            <Text variant="span">
              {_translate('TOAST_MESSAGE.LOSS_INTERNET_MESSAGE')}
            </Text>
          </div>
        </div>
      ),
      duration: 0,
      top: 75,
      className: 'notification-action notification-no-internet',
      closeIcon: (
        <SvgIcon
          color={theme.colors.grayscale_600}
          name="close"
          size={CLOSE_ICON_SIZE}
        />
      ),
      key: INTERNET_TOAST_KEY,
      onClose: function () {
        isShowInternetNotification = false;
      },
    });
  }
};

const success = (message: string): void => openNotification(message, 'success');
const info = (message: string): void => openNotification(message, 'info');
const warning = (message: string): void => openNotification(message, 'warning');
const error = (message: string): void => openNotification(message, 'error');
const lossInternet = (): void => openLossInternetNotification();
const closeInternetNotification = (): void => {
  isShowInternetNotification = false;
  notification.close(INTERNET_TOAST_KEY);
};

export default {
  success,
  info,
  warning,
  error,
  lossInternet,
  closeInternetNotification,
};
