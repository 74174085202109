import React from 'react';

import SvgIcon from 'components/atoms/SvgIcon';
import { theme } from 'theme/theme';

/**
 * Preload icons use for dialog or notifications
 */
const PreLoadIcons = (): JSX.Element => {
  return (
    <React.Suspense fallback={null}>
      <div hidden>
        <SvgIcon name="check_circle_fill" color={theme.colors.green_700} />
        <SvgIcon name="info_fill" color={theme.colors.blue_300} />
        <SvgIcon name="warning_fill" color={theme.colors.orange_550} />
        <SvgIcon name="error_fill" color={theme.colors.red_500} />
        <SvgIcon name="close" color={theme.colors.grayscale_600} />
        <SvgIcon name="wifi" color={theme.colors.grayscale_600} />
        <SvgIcon name="wifi_off" color={theme.colors.grayscale_600} />
      </div>
    </React.Suspense>
  );
};

export default PreLoadIcons;
