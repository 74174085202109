import create from 'zustand';

import { Modal } from 'components/molecules/Modal';
import Text from 'components/atoms/Text';
import Button from 'components/molecules/Button';

type State = {
  isShow: boolean;
};

const initialState: State = {
  isShow: false,
};

const state = create<State>(() => initialState);

const showDialog = () => state.setState(() => ({ isShow: true }));
const closeDialog = () => state.setState(() => ({ isShow: false }));

const ServerMaintainingDialog = () => {
  const isShow = state((s) => s.isShow);
  return (
    <Modal
      width={750}
      title="System Updated"
      closable={false}
      visible={isShow}
      footer={
        <div className="flex">
          <Button
            variant="secondary"
            className="ml-auto"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
        </div>
      }
    >
      <div
        style={{ margin: '40px 80px' }}
        className="flex flex-col items-center"
      >
        <img
          alt="Server Maintaining"
          src="https://cdn.proxy.simplifi.io/public/server_maintain.gif"
        />
        <Text className="mt-10" color="dark" variant="h2" bold>
          Simplifi Portal is being updated.
        </Text>
        <Text className="mt-10" color="gray-600">
          Please refresh the page or try again after a few minutes.
        </Text>
      </div>
    </Modal>
  );
};

export const actions = {
  showDialog,
  closeDialog,
};

export default ServerMaintainingDialog;
