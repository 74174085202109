import en from './en';
import es_MX from './es_MX';
import { TranslationType } from '../TranslateProvider';

const translations: TranslationType = {
  en,
  es_MX,
};

export default translations;
