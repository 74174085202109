export const getSafe = <R, D = null>(
  fn: AnyFunction,
  defaultValue: unknown = null,
) => {
  try {
    return fn() as R;
  } catch (e) {
    console.debug('Cannot get value', fn);
    return defaultValue as D;
  }
};
