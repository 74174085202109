import { AppState } from 'store/rootReducer';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { StateType } from 'models/Store';
import { apiGet, apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { ApiError } from 'models/Errors';

const name = 'downloadFile';

type State = StateType<{ fileId: string }>;

const initialState: State = {
  data: {
    fileId: '',
  },
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export const downloadFile = createAsyncThunk(
  name,
  async (
    { fileId, fileName }: { fileId: string; fileName?: string },
    { rejectWithValue },
  ) => {
    try {
      let file;
      if (!fileId)
        file = await apiGet({
          diffDomain: `${PROXY_DOMAIN}/api`,
          url: 'import_router/template',
          apiVersion: 'v1',
          responseType: 'blob',
          reduxActionName: name,
        });
      else
        file = await apiPost({
          diffDomain: `${PROXY_DOMAIN}/api`,
          url: `import_router/detail/${fileId}/download`,
          apiVersion: 'v1',
          responseType: 'blob',
          data: {
            filter: {
              statuses: [],
            },
            paging: {
              page: 0,
              size: 25,
            },
          },
          reduxActionName: name,
        });
      return {
        file,
        fileName,
      };
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadFile.pending, (state, action) => {
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
        state.status = THUNK_STATUS.LOADING;
        state.data.fileId = action.meta.arg.fileId;
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data.fileId = '';
        const url = window.URL.createObjectURL(action.payload.file);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          action.payload.fileName || 'ImportRoutersTemplate' + '.xlsx',
        );
        document.body.appendChild(link);
        link.click();
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message.split(':')[0];
          state.errorId = id;
          state.errorCode = `download_router_file.${code}`;
        }
      });
  },
});

const selector = (state: AppState) => state.superAdmin[name];

export const downloadFileLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const downloadFileSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const downloadFileFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectDownloadingFileId = createSelector(
  selector,
  (state: State) => state.data.fileId,
);

export const downloadFileError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const { reset } = slice.actions;

export default slice.reducer;
