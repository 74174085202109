import { css } from 'styled-components';
import { theme } from './theme';

export const treeSelectStyle = css`
  .ant-select-show-search {
    border-radius: 6px;
  }

  .ant-select-selection-item {
    border-radius: 6px !important;
    color: ${theme.colors.grayscale_900};
  }

  .ant-select-tree-switcher {
    display: flex;
    align-items: center;
    margin-left: 8px;

    .ant-select-tree-switcher-icon {
      display: flex;
    }
  }

  .ant-select-tree-node-selected {
    background-color: ${theme.colors.blue_50} !important;
  }

  .ant-tree-select-dropdown
    .ant-select-tree-list-holder-inner
    .ant-select-tree-treenode {
    padding-right: 8px;
  }

  .ant-tree-select-dropdown
    .ant-select-tree-list-holder-inner
    .ant-select-tree-treenode
    .ant-select-tree-node-content-wrapper {
    display: flex;
  }

  .ant-select-tree
    .ant-select-tree-treenode:not(.ant-select-tree
      .ant-select-tree-treenode-disabled).filter-node
    .ant-select-tree-title {
    padding: 2px 4px;
    background-color: ${theme.colors.blue_50};
  }

  .ant-select-open .ant-select-selector {
    & img {
      background: ${theme.colors.base_50};
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
    }
    & svg {
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
    }
  }
`;
