import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { StateType } from 'models/Store';
import { RouterLocationHistory } from 'models/Router';
import { THUNK_STATUS } from 'constants/status';
import { PROXY_DOMAIN } from 'constants/configs';
import { parseErrorMessage } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { apiPost } from 'apis/baseAPI';
import { ApiError } from 'models/Errors';

type State = StateType<{
  locations: RouterLocationHistory[];
  lastUsedRouterId: string;
}>;

const name = 'locationHistory';
const initialState: State = {
  data: {
    locations: [],
    lastUsedRouterId: '',
  },
  error: '',
  status: THUNK_STATUS.LOADING,
};

const whitelist: string[] = [];

export const getLocationHistory = createAsyncThunk(
  `${name}/getLocationHistory`,
  async (_data: { router_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await apiPost({
        url: `router/charts/${_data.router_id}/location/location`,
        apiVersion: 'v2',
        data: {
          start: 0,
          end: 99999999999999,
        },
        diffDomain: `${PROXY_DOMAIN}/api`,
        reduxActionName: `${name}/getLocationHistory`,
      });
      return data;
    } catch (e) {
      return rejectWithValue(Error(parseErrorMessage(e as ApiError)).message);
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getLocationHistory.pending as never]: (state: State) => {
      state.error = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [getLocationHistory.fulfilled as never]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data.locations = action.payload || [];
    },
    [getLocationHistory.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload;
    },
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectRouterLocationHistory = createSelector(
  selector,
  (state) => state.data.locations,
);
export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export default persistReducer({ key: name, storage, whitelist }, slice.reducer);
