import { USER_ROLE } from 'constants/User';
import { UserRole } from 'models/User';
import { PERSIST_KEY } from 'data/keys';
import { registerAutoResetPersist } from 'data/reseter';
import { hasPermission } from 'utils/hasPermission';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { getSafe } from 'utils/getSafe';

const SIMPLIFI_ORG_ID = '579eab9270944101716e51b1';

export interface State {
  id: string;
  email: string;
  name: string;
  status: string;
  picture: string;
  mobile_phone: string;
  last_login: number;
  roles: string[];
  org_role_list: OrgRoleList[];
  timezone: string;
  orgRolePair: OrgRolePair;
  services: string[];
  allow_deactivate: boolean;
  allowed_services: AllowedService[];
  time?: any;
  user_configs: UserViewConfig[];
}

interface OrgRoleList {
  readonly: boolean;
  suspended: boolean;
  system: boolean;
  org_id: string;
  org_key: string;
  org_logo: string;
  org_name: string;
  role_name: string;
  disallow_assets: boolean;
  allow_org_admin: number;
  allow_sub_orgs: boolean;
  created_date: number;
  services: Service[];
}

// setup global state
type ColumnKey = string;
type ShowHide = 0 | 1;
export type ColumnView = `${ColumnKey}:${ShowHide}`;
type UIViewConfig<TView> = Record<ConfigVersion, TView>;
export type ConfigVersion = `v${number}`;
export type TableColumnConfig = UIViewConfig<ColumnView[]>;

const VIEW_CONFIG_TYPE = {
  table_view: 'table_view',
} as const;

// the name pattern: service_viewName_
const VIEW_CONFIG_NAME = {
  'router_service-list_router': 'router_service-list_router',
  'voicelink_service-list_router': 'voicelink_service-list_route',
  'manage_user-list_user': 'manage_user-list_user',
  'manage_user-user_managed_router': 'manage_user-user_managed_router',
  'captive_service-list_captive': 'captive_service-list_captive',
  'captive_service-list_user_visit': 'captive_service-list_user_visit',
  'captive_service-list_routers': 'captive_service-list_routers',
  'user_profile-managed_router': 'user_profile-managed_router',
  'sa_service-sa_list': 'sa_service-sa_list',
  'sa_service-subscriber_list': 'sa_service-subscriber_list',
  'subscription_summary-list_expire_soon':
    'subscription_summary-list_expire_soon',
  'bulk_action-list': 'bulk_action-list',
  'bulk_action-detail_list': 'bulk_action-detail_list',
  'bulk_action-router_list': 'bulk_action-router_list',
};

export type UserViewConfig = {
  type: keyof typeof VIEW_CONFIG_TYPE;
  name: keyof typeof VIEW_CONFIG_NAME;
  value: string; // as Record<ConfigVersion, TableColumnView>;
  desc: string;
};

interface Service {
  org_id: string;
  service: string;
  status: string;
}

interface OrgRolePair {
  [key: string]: string;
}

interface AllowedService {
  service: string;
  orgId: Record<string, true>;
}

type Action = {
  setId: Callback<State['id']>;
};

const initialState: State = {
  id: '',
  allow_deactivate: false,
  email: '',
  last_login: 0,
  mobile_phone: '',
  name: '',
  org_role_list: [],
  orgRolePair: {},
  picture: '',
  roles: [],
  services: [],
  status: '',
  timezone: '',
  allowed_services: [],
  user_configs: [],
};

const currentUserState = create<State>()(
  persist(() => initialState, {
    name: PERSIST_KEY.AUTH.CURRENT_USER,
  }),
);
registerAutoResetPersist(currentUserState, initialState);

// selector
const selectState = (s: State) => s;
const selectIsNotAllow = (s: State) => {
  return s.roles.some(
    (r) =>
      r === USER_ROLE.ORGANIZATIONS_BLOCK || r === USER_ROLE.NO_ORGANIZATION,
  );
};
const selectIsAuth = (s: State) => s.roles.length > 0;
const selectHasOrgRoleOnSimplifi = (state: State) => {
  return state.org_role_list.some(
    (i) =>
      i.org_id === SIMPLIFI_ORG_ID &&
      [USER_ROLE.ADMIN, USER_ROLE.MANAGER, USER_ROLE.VIEWER].some(
        (r) => r === i.role_name,
      ),
  );
};

export const selectors = {
  selectState,
  selectIsNotAllow,
  selectIsAuth,
  selectHasOrgRoleOnSimplifi,
};

// getter
const checkPermission = (roles: Uppercase<UserRole>[]) => {
  const _rolls = currentUserState.getState().roles;
  if (roles.includes(USER_ROLE.NO_ORGANIZATION)) {
    return hasPermission([USER_ROLE.NO_ORGANIZATION], roles);
  }
  if (isAllOrgBlocked()) {
    return hasPermission([USER_ROLE.ORGANIZATIONS_BLOCK], roles);
  }
  return hasPermission((_rolls as Uppercase<UserRole>[]) || [], roles);
};

export const getViewConfig = ({
  type,
  name,
  version = 'v1',
}: {
  type: UserViewConfig['type'];
  name: UserViewConfig['name'];
  version?: ConfigVersion;
}): ColumnView[] | undefined => {
  return getSafe(() => {
    const config = currentUserState.getState().user_configs;

    if (!config) {
      return undefined;
    }

    const configValue = config.find((i) => i.type === type && i.name === name);

    return configValue
      ? (JSON.parse(configValue.value) as TableColumnConfig)[version]
      : undefined;
  });
};

// setters
const setId: Action['setId'] = (id) =>
  currentUserState.setState((s) => ({
    ...s,
    id,
  }));

export { currentUserState, setId, checkPermission };

// util
const isBlockedOrg = (o: State['org_role_list'][0]) => o.suspended;
const isAllOrgBlocked = () =>
  currentUserState.getState().org_role_list.every(isBlockedOrg);
