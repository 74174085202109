import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import AppIcon, { IconType } from 'components/atoms/SvgIcon';
import { theme } from 'theme/theme';

type IconButtonVariant = 'primary' | 'secondary' | 'danger';

type IconButtonSize = 'small' | 'medium';

const ICON_SIZE = {
  small: 20,
  medium: 30,
};

const getIconSize = (s: IconButtonSize | number): number => {
  if (typeof s === 'number') {
    return s;
  }

  return ICON_SIZE[s];
};

export type Props = {
  disabled?: boolean;
  variant?: IconButtonVariant;
  size?: IconButtonSize | number;
  color?: string;
  icon: IconType;
  type?: 'button' | 'submit' | 'reset';
} & HTMLAttributes<HTMLButtonElement>;

const handleStyleColor = (type: IconButtonVariant, color?: string) => {
  if (color)
    return {
      default: {
        color,
      },
      hover: {
        color,
      },
    };
  switch (type) {
    case 'secondary':
      return {
        default: {
          color: theme.colors.grayscale_600,
        },
        hover: {
          color: theme.colors.blue_300,
        },
      };
    case 'danger':
      return {
        default: {
          color: theme.colors.red_500,
        },
        hover: {
          color: theme.colors.red_500,
          background: theme.colors.red_50,
        },
      };
    case 'primary':
    default:
      return {
        default: {
          color: theme.colors.blue_500,
        },
        hover: {
          color: theme.colors.blue_300,
        },
      };
  }
};

const StyledIconButton = styled.button<{
  $variant: IconButtonVariant;
  $color?: string;
}>`
  background: transparent;
  border-width: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  ${(props) => ({
    ...handleStyleColor(props.$variant, props.$color).default,
  })}

  svg {
    & > path,
    & > rect {
      fill: ${(props) =>
        handleStyleColor(props.$variant, props.$color).default.color};
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    ${(props) => ({
      ...handleStyleColor(props.$variant, props.$color).hover,
    })}

    & > svg > path, & > svg > rect {
      fill: ${(props) =>
        handleStyleColor(props.$variant, props.$color).hover.color};
    }
  }

  &:focus {
    ${(props) => ({
      ...handleStyleColor(props.$variant, props.$color).hover,
    })}
  }

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    background: transparent;
    svg > path {
      fill: ${theme.colors.grayscale_200};
    }
  }
`;

const IconButton: React.FC<Props> = ({
  size = 'small',
  disabled = false,
  variant = 'primary',
  icon,
  color,
  type,
  ...rest
}: Props) => {
  return (
    <StyledIconButton
      disabled={disabled}
      $variant={variant}
      $color={color}
      type={type}
      {...rest}
    >
      {icon && <AppIcon name={icon} size={getIconSize(size)} />}
    </StyledIconButton>
  );
};

export default IconButton;
