import { persist } from 'zustand/middleware';
import create from 'zustand';

import { util } from 'data/utils';
import { PERSIST_KEY } from 'data/keys';
import { registerAutoResetPersist } from 'data/reseter';

type State = {
  orgIds: string[];
  textSearch: string;
  captives: string[];
  pagination: {
    page: number;
    size: number;
    total_elements: number;
    total_pages: number;
  };
  sort: {
    field: string;
    order: string;
  };
};

const initialState: State = {
  orgIds: [],
  textSearch: '',
  captives: [],
  pagination: {
    page: 1,
    size: 25,
    total_elements: 0,
    total_pages: 0,
  },
  sort: {
    field: '',
    order: '',
  },
};

type Actions = {
  setPage: Callback<State['pagination']['page']>;
  setSize: Callback<State['pagination']['size']>;
  setTotalElements: Callback<State['pagination']['total_elements']>;
  setTotalPages: Callback<State['pagination']['total_pages']>;
  setField: Callback<State['sort']['field']>;
  setOrder: Callback<State['sort']['order']>;
  setElement: Callback<
    Pick<State['pagination'], 'total_elements' | 'total_pages'>
  >;
  setSort: Callback<State['sort']>;
  setCaptives: Callback<State['captives']>;
  setTextSearch: Callback<State['textSearch']>;
  setOrgIDs: Callback<State['orgIds']>;
};

export const routerFilter = create<State>()(
  persist(() => initialState, {
    name: PERSIST_KEY.CAPTIVE.ROUTER_FILTER,
  }),
);
registerAutoResetPersist(routerFilter, initialState);

export const reset = () => {
  routerFilter.setState(() => initialState);
};

export const resetFilter = () =>
  routerFilter.setState((s) => ({
    ...initialState,
    textSearch: s.textSearch,
  }));

export const removeCaptiveFromFilter = (
  captiveId: ArrElement<State['captives']>,
) =>
  routerFilter.setState((s) => ({
    ...s,
    captives: s.captives.filter((c) => c !== captiveId),
  }));

// // use with util
const zustandUtil = util(routerFilter.setState, routerFilter.getState);

const setPage = zustandUtil.updateState<Actions['setPage']>((s, v) => {
  s.pagination.page = v;
});

const setSize = zustandUtil.updateState<Actions['setSize']>((s, v) => {
  s.pagination.page = initialState.pagination.page;
  s.pagination.size = v;
});

const setTotalElements = zustandUtil.updateState<Actions['setTotalElements']>(
  (s, v) => {
    s.pagination.total_elements = v;
  },
);

const setTotalPages = zustandUtil.updateState<Actions['setTotalPages']>(
  (s, v) => {
    s.pagination.total_pages = v;
  },
);

const setSort = zustandUtil.updateState<Actions['setSort']>((s, v) => {
  s.pagination.page = initialState.pagination.page;
  s.sort = v;
});

const setElements = zustandUtil.updateState<Actions['setElement']>((s, v) => {
  s.pagination.total_pages = v.total_pages;
  s.pagination.total_elements = v.total_elements;
});

const setCaptives = zustandUtil.updateState<Actions['setCaptives']>((s, v) => {
  s.pagination.page = initialState.pagination.page;
  s.captives = v;
});

const setSearchText = zustandUtil.updateState<Actions['setTextSearch']>(
  (s, v) => {
    s.pagination.page = initialState.pagination.page;
    s.textSearch = v;
  },
);

const setOrgIds = zustandUtil.updateState<Actions['setOrgIDs']>((s, v) => {
  s.pagination.page = initialState.pagination.page;
  s.orgIds = v;
});

export const actions = {
  setCaptives,
  setElements,
  setSort,
  setTotalPages,
  setTotalElements,
  setPage,
  setSize,
  setSearchText,
  setOrgIds,
};
