import { ApiError } from '../models/Errors';
import dayjs from '../modules/dayjs';
import { _translate } from '../translate/TranslateProvider';

export const parseErrorMessage = (error: ApiError): string => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  )
    return error.response.data.error.message;
  if (error.message) return error.message;
  return 'Uncaught Error';
};

export const parseError = (
  error?: ApiError | null,
): ApiError['response']['data']['error'] | undefined => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  )
    return error.response.data.error;
  return undefined;
};

export const createInitials = (name = ''): string => {
  if (name.length === 0) return name;
  const split = name.split(/\s+/);
  return split
    .filter((_, index) => index === 0 || index === split.length - 1)
    .map((str) => str[0])
    .join('')
    .toUpperCase();
};

export const createHslColor = (
  str: string,
  saturation = 50,
  lightness = 50,
): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
};

export const capitalizeText = (text: string): string => {
  if (text === undefined || text == null) {
    return '';
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const isASCII = (string: string): boolean => {
  if (string === undefined || string == null) {
    return false;
  }
  return string.split('').every((s) => s.charCodeAt(0) <= 127);
};

export const formatTime = (
  time: number | Date,
  format = 'YYYY-MM-DD HH:mm:ss ZZ',
): string => {
  if (!dayjs(time).isValid() && time !== undefined) return '-';
  else return dayjs(time).utc().format(format);
};

export const getLastXDaysLabel = (
  numberOfDays = 7,
  userTimezone?: string,
): string[] => {
  const d = dayjs()
    .tz(userTimezone || '')
    .utc(true);
  return [...Array(numberOfDays)]
    .map((_, i) => {
      return d.subtract(i, 'day').format('D');
    })
    .reverse();
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1): string => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = !si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};
