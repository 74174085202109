import { apiGet } from 'apis/baseAPI';
import { env } from 'constants/configs';

type Res = ServerResponse<{
  index: number;
  show_when_login: boolean;
}>;

const getQuickTipsConfig = (signal?: AbortSignal): Promise<Res> => {
  return apiGet({
    diffDomain: `${env.REACT_APP_AUTH_URL}/api`,
    url: 'quick-tips-config/get',
    signal,
    apiVersion: 'v1',
  });
};

export { getQuickTipsConfig };
