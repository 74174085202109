import { createApi } from '@reduxjs/toolkit/query/react';
import { API_QUERY_KEY } from './ApiQueryKey';
import { SUPPORT_DOMAIN } from '../constants/configs';
import { postFormBaseQuery } from './fetchBaseQuery';

const uploadFileApiSlice = createApi({
  reducerPath: API_QUERY_KEY.PAGE.UPLOAD_FILE,
  baseQuery: postFormBaseQuery({
    baseUrl: `${SUPPORT_DOMAIN}/api`,
    version: 'v1',
  }),
  endpoints: (build) => ({
    postFile: build.mutation({
      query: (formData) => {
        const form = new FormData();
        form.append('file', formData);
        return {
          url: 'page/upload-file',
          formData: form,
        };
      },
    }),
  }),
});

export const { usePostFileMutation } = uploadFileApiSlice;

export { uploadFileApiSlice };
