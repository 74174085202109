import { notification } from 'antd';

import { PHONE_STATE } from 'constants/POT';
import SvgIcon from 'components/atoms/SvgIcon';
import Text from 'components/atoms/Text';
import { theme } from 'theme/theme';

const activeCallTopicHandler = (t: string, m: Buffer, store: any) => {
  const data: {
    phone_state: number;
    ts: number;
    call_queue: any;
    server_ts: number;
  } = JSON.parse(m.toString());

  const [, , imei] = t.split('/');

  // close notification
  if (data.phone_state !== PHONE_STATE.LABEL_KEY.RINGING) {
    notification.close(imei);
  }

  if (data.call_queue && data.phone_state === PHONE_STATE.LABEL_KEY.RINGING) {
    notification.open({
      message: '',
      description: (
        <>
          <SvgIcon name="phone" size={40} color={theme.colors.grayscale_600} />
          <div className="call-info">
            <Text bold variant="h4">
              {data.call_queue.number}
            </Text>
            <Text css={{ marginTop: 4 }} color="gray-600">
              IMEI: {imei}
            </Text>
          </div>
        </>
      ),
      duration: 0,
      className: 'notification-pot',
      closeIcon: (
        <SvgIcon color={theme.colors.grayscale_600} name="close" size={20} />
      ),
      key: imei,
    });
  }

  if (!shouldUpdatePhoneState(data.phone_state)) {
    return;
  }

  store.dispatch({
    type: 'routerDetail/setCallStatus',
    payload: data.phone_state,
  });

  store.dispatch({
    type: 'callQueue/setQueue',
    payload: data,
  });
};

export { activeCallTopicHandler };

const shouldUpdatePhoneState = (state: number): boolean => {
  return [
    PHONE_STATE.LABEL_KEY.CALLING,
    PHONE_STATE.LABEL_KEY.INCALL,
    PHONE_STATE.LABEL_KEY.OFFHOOK,
    PHONE_STATE.LABEL_KEY.RINGING,
    PHONE_STATE.LABEL_KEY.STANDBY,
    PHONE_STATE.LABEL_KEY.UNINITIALIZED,
    PHONE_STATE.LABEL_KEY.ENDCALL,
  ].includes(state);
};
