import notification from 'modules/notification';
import { MESSAGE_KEY, MESSAGE_TYPE } from 'modules/qmtt/config';
import { _translate } from 'translate/TranslateProvider';

import {
  showDialog,
  hideDialog,
  processingImei,
} from './MethodProcessingDialog';

import { hideToastLoading, toastLoading } from './MethodProcessingToast';

type Action = typeof MESSAGE_KEY.METHODS[keyof typeof MESSAGE_KEY.METHODS];
type SimplfiTopic = typeof MESSAGE_TYPE.SIMPLIFI;
type MethodAction = `v1/thing/${string}/${SimplfiTopic}/${Action}`;

class MethodProcessing {
  // the action pattern is <action>-<routerId>
  private static actions: Set<MethodAction> = new Set();
  private static callBackAction: Callback<string> | undefined;

  static startAction(action: Action, imei: string) {
    const actionTopic: MethodAction = `v1/thing/${imei}/simplifi/${action}`;

    console.log('START ACTION', action);

    // add to action pool
    MethodProcessing.actions.add(actionTopic);

    // handle for dialog
    showDialog(imei);
  }

  static onEvent(action: MethodAction, imei: string) {
    if (MethodProcessing.actions.size < 1) {
      return;
    }
    MethodProcessing.endAction(action, imei);
    notification.success(
      _translate('PROGRESSING.TOAST.SUCCESS', {
        imei,
      }),
    );
    // trigger callback
    if (MethodProcessing.callBackAction) {
      MethodProcessing.callBackAction(imei);
    }
  }

  static endAction(action: MethodAction, imei: string) {
    // remove action from pool
    MethodProcessing.actions.delete(action);

    // check if the process dialog is for event imei -> close
    if (processingImei() === imei) {
      hideDialog();
      return;
    }

    // handle toast message
    const _actionSize = MethodProcessing.actions.size;

    if (_actionSize === 0) {
      hideToastLoading();
      return;
    }

    if (_actionSize === 1 && processingImei()) {
      hideToastLoading();
      return;
    }

    toastLoading(_actionSize - 1);
  }

  static leaveDialog() {
    hideDialog();
    toastLoading(MethodProcessing.actions.size);
  }

  static registerCallback(cb: Callback<string>) {
    MethodProcessing.callBackAction = cb;
  }

  static removeCallback() {
    MethodProcessing.callBackAction = undefined;
  }

  static destroy() {
    MethodProcessing.actions.clear();
    MethodProcessing.callBackAction = undefined;
  }
}

export const methodProcessHandler = (t: string, _m: Buffer) => {
  const [_version, _t, _user, _o, imei, _o2, action] = t.split('/');
  const actionTopic: MethodAction = `v1/thing/${imei}/simplifi/${
    action as Action
  }`;
  MethodProcessing.onEvent(actionTopic, imei);
};

export default MethodProcessing;
