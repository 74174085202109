import {
  ErrorBoundary,
  ErrorBoundaryPropsWithComponent,
  FallbackProps,
} from 'react-error-boundary';
import { PropsWithChildren, useCallback } from 'react';

import { state as internetConnectionState } from 'data/internet/status';

const ErrorPage = (props: FallbackProps) => {
  const { resetErrorBoundary } = props;

  const isOnline = internetConnectionState.getState().isOnline;
  if (isOnline) {
    resetErrorBoundary();
  }

  return <></>;
};

const InternetErrorBoundary = (
  props: PropsWithChildren<
    Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent'>
  >,
): JSX.Element => {
  const fallbackRender = useCallback(
    (props: FallbackProps) => <ErrorPage {...props} />,
    [],
  );

  return <ErrorBoundary fallbackRender={fallbackRender} {...props} />;
};

export default InternetErrorBoundary;
