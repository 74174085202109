import downloadFile from './downloadFile';
import uploadRouterFile from './uploadRouterFile';
import importData from './importData';
import processUploadedFile from './processUploadedFile';
import { combineReducers } from 'redux';

export default combineReducers({
  uploadRouterFile,
  downloadFile,
  importData,
  processUploadedFile,
});
