import { persist } from 'zustand/middleware';
import create from 'zustand';

import { util } from 'data/utils';
import { PERSIST_KEY } from 'data/keys';
import { registerAutoResetPersist } from 'data/reseter';

type State = {
  orgIds: string[];
  textSearch: string;
  pagination: {
    page: number;
    size: number;
    total_elements: number;
    total_pages: number;
  };
};

const initialState: State = {
  orgIds: [],
  textSearch: '',
  pagination: {
    page: 1,
    size: 25,
    total_elements: 0,
    total_pages: 0,
  },
};

type Actions = {
  setPage: Callback<State['pagination']['page']>;
  setSize: Callback<State['pagination']['size']>;
  setTotalElements: Callback<State['pagination']['total_elements']>;
  setTotalPages: Callback<State['pagination']['total_pages']>;
};

export const captiveListFilter = create<State>()(
  persist(() => initialState, {
    name: PERSIST_KEY.CAPTIVE.CAPTIVE_LIST,
  }),
);
registerAutoResetPersist(captiveListFilter, initialState);

export const reset = () => {
  captiveListFilter.setState(() => initialState);
};

export const resetPagination = () => {
  captiveListFilter.setState((s) => ({
    ...s,
    pagination: initialState.pagination,
  }));
};

export const resetFilter = () =>
  captiveListFilter.setState((s) => ({
    ...initialState,
    textSearch: s.textSearch,
  }));

// // use with util
const zustandUtil = util(
  captiveListFilter.setState,
  captiveListFilter.getState,
);

export const setPage = zustandUtil.updateState<Actions['setPage']>((s, v) => {
  s.pagination.page = v;
});

export const setSize = zustandUtil.updateState<Actions['setSize']>((s, v) => {
  s.pagination.size = v;
});

export const setTotalElements = zustandUtil.updateState<
  Actions['setTotalElements']
>((s, v) => {
  s.pagination.total_elements = v;
});

export const setTotalPages = zustandUtil.updateState<Actions['setTotalPages']>(
  (s, v) => {
    s.pagination.total_pages = v;
  },
);

export const actions = zustandUtil.generateSetter();
