import { css } from 'styled-components';
import { theme } from 'theme/theme';

export const customCollapse = css`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-collapse-item {
    border: 1px solid ${theme.colors.grayscale_200};
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ant-collapse-item:last-child,
  .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 10px;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 10px 20px 10px 10px !important;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
  }

  .ant-collapse-item > .ant-collapse-header::before {
    display: none;
  }

  .ant-collapse-item > .ant-collapse-header::after {
    display: none;
  }

  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 40px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-top: 0;
  }
`;
