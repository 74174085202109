import { AppState } from 'store/rootReducer';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { StateType } from 'models/Store';
import { Device } from 'models/Device';
import { apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { ApiError } from 'models/Errors';
import notification from 'modules/notification';
import { _translate } from 'translate/TranslateProvider';

const name = 'blockDevice';

type RequestData = {
  routerId: string;
  devices: Device[];
};

type State = StateType<{
  blockedDevices: Device[];
}>;

const initialState: State = {
  data: {
    blockedDevices: [],
  },
  error: '',
  errorId: '',
  errorCode: '',
  status: THUNK_STATUS.IDLE,
};

export const blockClientDevice = createAsyncThunk(
  name,
  async (requestData: RequestData, { rejectWithValue }) => {
    try {
      const { routerId, devices } = requestData;
      const data = await apiPost<{ data: { blocked_devices: Device[] } }>({
        diffDomain: `${PROXY_DOMAIN}/api`,
        url: `router/rpc/block_client_device/${routerId}`,
        apiVersion: 'v2',
        data: {
          devices,
        },
        reduxActionName: name,
      });
      notification.success(
        _translate(
          'ROUTER_DETAIL.ADVANCED_INFO.CONNECTED_DEVICES.TOAST.DEVICES_BLOCKED.SUCCESS',
        ),
      );
      return data;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(blockClientDevice.pending, (state) => {
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(blockClientDevice.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data.blockedDevices = action.payload.data.blocked_devices;
      })
      .addCase(blockClientDevice.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message.split(':')[0];
          state.errorId = id;
          state.errorCode = code;
        }
      });
  },
});

const selector = (state: AppState) => state.router[name];

export const blockClientDeviceLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const blockClientDeviceSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const blockClientDeviceFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const blockClientDeviceError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const { reset } = slice.actions;

export default slice.reducer;
