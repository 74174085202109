import create from 'zustand';

import { util } from 'data/utils';

type ChartData = {
  time: number;
  value: number;
};

type State = {
  timeStampRanges: number[];
  resistanceData: ChartData[];
  tipRingVoltData: ChartData[];
  timeLabel: string[];
};

const initialState: State = {
  timeStampRanges: [],
  resistanceData: [],
  tipRingVoltData: [],
  timeLabel: [],
};

export const state = create<State>(() => initialState);

const zUtil = util(state.setState, state.getState);
const setters = zUtil.generateSetter();

const addResistanceData = (data: string) =>
  state.setState((s) => {
    const currentTime = new Date(
      new Date().setHours(
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
        0,
      ),
    ).getTime();

    return {
      ...s,
      resistanceData: [
        ...s.resistanceData,
        {
          time: currentTime,
          value: JSON.parse(data),
        },
      ],
    };
  });

const addTipRingVoltData = (data: string) =>
  state.setState((s) => {
    const currentTime = new Date(
      new Date().setHours(
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
        0,
      ),
    ).getTime();

    return {
      ...s,
      tipRingVoltData: [
        ...s.tipRingVoltData,
        {
          time: currentTime,
          value: JSON.parse(data),
        },
      ],
    };
  });

const reset = () => {
  state.setState(() => initialState);
};

export const actions = {
  ...setters,
  addResistanceData,
  addTipRingVoltData,
  reset,
};

const resistanceChartData = (s: State) => s.resistanceData;
const tipRingVoltChartData = (s: State) => s.tipRingVoltData;
const timeLabels = (s: State) => s.timeLabel;
const ranges = (s: State) => s.timeStampRanges;

export const select = {
  resistanceChartData,
  tipRingVoltChartData,
  timeLabels,
  ranges,
};
