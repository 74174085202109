import { MouseEventHandler, PropsWithChildren } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

import Block from 'components/atoms/Block';
import { theme } from 'theme/theme';
import { useTranslate } from 'translate/useTranslate';

import Button from '../Button';
import Text from '../../atoms/Text';

const StyledContent = styled(Block)`
  margin: 0 0 20px 20px;
  color: ${theme.colors.grayscale_900};
`;

const StyledTitle = styled(Text)`
  margin-bottom: 6px;
  color: ${theme.colors.grayscale_900};
`;

const StyledMessage = styled(Block)`
  white-space: pre-line;
  line-height: 18px;
  word-break: break-word;
`;

const StyledModal = styled(Modal)<{ $maxWidth: string }>`
  min-width: 498px;
  max-width: ${(p) => p.$maxWidth};
  width: auto !important;
  .ant-modal-content {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  .ant-modal-body {
    padding: 20px;
    font-size: 14px;
    line-height: 16px;
  }
`;

type Props = {
  visible: boolean;
  isConfirming?: boolean;
  title: string;
  message?: string;
  actionName?: string;
  cancelName?: string;
  actionDisable?: boolean;
  onClose?: MouseEventHandler | undefined;
  onConfirm?: MouseEventHandler | undefined;
  maxWidth?: string;
  dialogIcon: JSX.Element;
  zIndex?: number;
  isShowCancel?: boolean;
  disableCancel?: boolean;
};

const DialogWithIcon = ({
  maxWidth = '498px',
  visible,
  message,
  onClose,
  onConfirm,
  title,
  children,
  actionName,
  cancelName,
  isConfirming,
  actionDisable,
  dialogIcon,
  zIndex = 1000, // 1000 is default z-index for modal,
  isShowCancel = true,
  disableCancel,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslate();
  return (
    <StyledModal
      $maxWidth={maxWidth}
      centered
      visible={visible}
      closable={false}
      footer={false}
      destroyOnClose
      maskStyle={{ background: 'rgba(0, 0, 0, 0.3)' }}
      wrapProps={{ style: { zIndex: zIndex } }}
    >
      <Block flex>
        <Block>{dialogIcon}</Block>
        <StyledContent flexGrow={1} flex column>
          <StyledTitle variant="h2" bold>
            {title}
          </StyledTitle>
          <StyledMessage>
            {children
              ? children
              : message && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: message,
                    }}
                  />
                )}
          </StyledMessage>
        </StyledContent>
      </Block>
      <Block flex justifyContent="flex-end">
        <Block flex>
          {isShowCancel && (
            <Button
              variant="secondary"
              onClick={onClose}
              disabled={disableCancel}
            >
              {cancelName ?? t('BUTTON.CANCEL')}
            </Button>
          )}
          <Button
            variant={isShowCancel ? 'primary' : 'secondary'}
            onClick={onConfirm}
            loading={isConfirming}
            disabled={actionDisable}
            className="ml-8"
          >
            {actionName ?? t('BUTTON.YES')}
          </Button>
        </Block>
      </Block>
    </StyledModal>
  );
};

export default DialogWithIcon;
