import { css } from 'styled-components';

const skeletonStyle = css`
  .skeleton-animation {
    animation: Pulsate 2.5s linear infinite;
  }

  @keyframes Pulsate {
    from {
      opacity: 1;
    }
    75% {
      opacity: 0.66;
    }
    50% {
      opacity: 0.33;
    }
    25% {
      opacity: 0.66;
    }
    to {
      opacity: 1;
    }
  }
`;

export default skeletonStyle;
