import { PropsWithChildren } from 'react';

import { currentUserState } from 'data/auth/currentUser';
import { hasAllPermission, hasSomePermission } from 'utils/hasPermission';
import { UserRole } from 'models/User';

type Props = PropsWithChildren<{
  availableFor: Uppercase<UserRole>[];
  hidden?: boolean;
  rule?: 'some' | 'every';
}>;

const RolePermission = ({
  availableFor,
  children,
  hidden = false,
  rule = 'some',
}: Props): JSX.Element => {
  const currentRoles = currentUserState((s) => s.roles);

  // const _hasPermission = hasPermission(
  //   currentRoles as Parameters<typeof hasPermission>[0],
  //   availableFor,
  // );

  const _hasPermission =
    rule === 'some'
      ? hasSomePermission(currentRoles, availableFor)
      : hasAllPermission(currentRoles, availableFor);

  if (_hasPermission && !hidden) {
    return <>{children}</>;
  }
  return <></>;
};

export default RolePermission;
