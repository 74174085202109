import { useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import Block from 'components/atoms/Block';
import Text from 'components/atoms/Text';
import Checkbox from 'components/molecules/Checkbox';
import EditorV2 from 'components/organism/EditorV2';
import {
  SiDateRangePicker,
  generateFuturePresets,
  getTomorrowDayRange,
} from 'components/organism/DatePicker';
import Button from 'components/molecules/Button';
import FormItem from 'components/molecules/FormItem';
import { _translate } from 'translate/TranslateProvider';
import { theme } from 'theme/theme';
import { useGetTimeZone } from 'page/private/UserProfile/hooks/useGetTimeZones';
import { validate } from 'validators';
import notification from 'modules/notification';
import { setAppConfig } from 'apis/appConfig/setAppConfig';
import { API_QUERY_KEY } from 'apis/ApiQueryKey';
import { queryClient } from 'index';

import PreviewBannerModal from './components/previewModal';
import { useGetAppNotificationBanner } from './hooks/useGetAppNotificationBanner';

export const DEFAULT_TIMEZONE_NOTIFICATION_BANNER = 'America/New_York' as const;

export const APP_CONFIG_NOTIFICATION_BANNER = {
  TYPE: 'portal',
  NAME: 'notification_banner_config',
  DESC: 'Notification Banner config',
} as const;

const Footer = styled.div`
  padding: 10px 20px;
  border-top: 1px solid ${theme.colors.grayscale_200};
  display: flex;
  justify-content: end;
  gap: 8px;
  height: 52px;
`;

type NotificationForm = {
  date: [Dayjs, Dayjs];
  content: string;
  isChecked: boolean;
};

export type NotificationBannerConfig = {
  date: [string, string];
  content: string;
  isShow: boolean;
};

const NotificationBanner = (): JSX.Element => {
  const datePreset = generateFuturePresets(
    {
      Today: 1,
      Tomorrow: () => getTomorrowDayRange(DEFAULT_TIMEZONE_NOTIFICATION_BANNER),
      'Next 7 days': 7,
      'Next 14 days': 14,
    },
    DEFAULT_TIMEZONE_NOTIFICATION_BANNER,
  );

  const [form] = Form.useForm<NotificationForm>();
  const [previewVisible, togglePreviewVisible] = useReducer((s) => !s, false);

  const { mutate, isLoading } = useMutation({
    mutationFn: setAppConfig,
    onSuccess() {
      notification.success(
        _translate('NOTIFICATION_BANNER.UPDATE_SUCCESS.MESSAGE'),
      );
      queryClient.refetchQueries([API_QUERY_KEY.APPCONFIG.SET_CONFIG]);
    },
  });

  const { data: banner } = useGetAppNotificationBanner({
    type: APP_CONFIG_NOTIFICATION_BANNER.TYPE,
    name: APP_CONFIG_NOTIFICATION_BANNER.NAME,
  });

  useEffect(() => {
    if (!banner || !banner.value) {
      return;
    }
    const config = JSON.parse(banner.value) as NotificationBannerConfig;
    form.setFieldsValue({
      date: [dayjs(config.date[0]), dayjs(config.date[1])],
      content: config.content,
      isChecked: config.isShow,
    });
  }, [banner]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const start = values.date[0].format('YYYY-MM-DD HH:mm:ss');
      const end = values.date[1].format('YYYY-MM-DD HH:mm:ss');
      await mutate({
        type: APP_CONFIG_NOTIFICATION_BANNER.TYPE,
        name: APP_CONFIG_NOTIFICATION_BANNER.NAME,
        value: JSON.stringify({
          isShow: values.isChecked,
          date: [start, end],
          content: values.content,
        }),
        desc: APP_CONFIG_NOTIFICATION_BANNER.DESC,
      });
    } catch (err) {
      console.log({ err });
    }
  };

  const { data: timezones = [] } = useGetTimeZone();

  const handleChangeDatePicker = () => {};
  return (
    <Block css={{ height: '100%', overflowY: 'auto' }}>
      <Form className="p-20" form={form}>
        <Text variant="h1" bold>
          {_translate('NOTIFICATION_BANNER.TITLE')}
        </Text>
        <FormItem name="isChecked" valuePropName="checked" hideHelp>
          <Checkbox style={{ padding: '8px 0', margin: '2px' }}>
            <Text color="dark">
              {_translate('NOTIFICATION_BANNER.SHOW_BANNER.CHECKBOX')}
            </Text>
          </Checkbox>
        </FormItem>

        <Block flex alignItems="baseline" gap={10} className="ml-24">
          <Text className="ml-4" color="dark">
            {_translate('NOTIFICATION_BANNER.DATE_PICKER.LABEL')}
          </Text>
          <FormItem
            name="date"
            rules={[
              {
                validator: (_, value) =>
                  validate(value, {
                    required: _translate('VALIDATION.FIELD_REQUIRED'),
                  }),
              },
            ]}
          >
            <SiDateRangePicker
              onChange={handleChangeDatePicker}
              style={{ width: 360 }}
              ranges={datePreset}
              showTime
            />
          </FormItem>
          <Text color="dark">
            {
              timezones.find(
                (tz) => tz.value === DEFAULT_TIMEZONE_NOTIFICATION_BANNER,
              )?.label
            }
          </Text>
        </Block>
        <Block flex gap={10} column>
          <FormItem
            name="content"
            label={
              <Text
                bold
                css={{ marginBottom: '10px', fontSize: '18px' }}
                color="dark"
              >
                {_translate('NOTIFICATION_BANNER.CONTENT.LABEL')}
              </Text>
            }
            required
            rules={[
              {
                validator: (_, value) =>
                  validate(value, {
                    required: _translate('VALIDATION.FIELD_REQUIRED'),
                  }),
              },
            ]}
          >
            <EditorV2
              height={'calc(100vh - 410px)'}
              init={{
                plugins: [
                  // plugins with no preview
                  'importcss',
                  'searchreplace',
                  'autolink',
                  'directionality',
                  'code',
                  'visualblocks',
                  'visualchars',
                  'fullscreen',
                  'image',
                  'link',
                  'table',
                  'charmap',
                  'nonbreaking',
                  'advlist',
                  'lists',
                  'wordcount',
                  'charmap',
                  'quickbars',
                  'emoticons',
                  'paste',
                ],
              }}
            />
          </FormItem>
        </Block>
      </Form>
      <Footer>
        <div style={{ gap: 10 }} className="flex justify-end">
          <Button key="back" variant="secondary" onClick={togglePreviewVisible}>
            {_translate('BUTTON.PREVIEW')}
          </Button>
          <Button
            key="submit"
            loading={isLoading}
            variant="primary"
            onClick={handleSave}
          >
            {_translate('BUTTON.SAVE')}
          </Button>
        </div>
      </Footer>

      <PreviewBannerModal
        open={previewVisible}
        content={form.getFieldValue('content')}
        onClose={togglePreviewVisible}
      ></PreviewBannerModal>
    </Block>
  );
};

export default NotificationBanner;
