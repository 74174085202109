import React from 'react';
import { PropsWithChildren } from 'react';
import styled, { CSSProp } from 'styled-components';
import { theme } from '../../../theme/theme';

export type TextProps = PropsWithChildren<{
  css?: CSSProp;
  bold?: boolean;
  semiBold?: boolean;
  italic?: boolean;
  variant?: TextVariant;
  color?: TextColorVariant;
  disabled?: boolean;
  onClick?: (_event: React.MouseEvent<HTMLElement>) => void;
  showEmptyPlaceholder?: boolean;
  emptyPlaceholder?: string;
  breakAll?: boolean;
  className?: string;
  noWrap?: boolean;
  href?: string;
}>;

export type TextVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'paragraph'
  | 'caption'
  | 'note'
  | 'link'
  | 'span';

export type TextColorVariant =
  | 'primary'
  | 'disabled'
  | 'gray-900'
  | 'gray-800'
  | 'gray-700'
  | 'gray-600'
  | 'gray-200'
  | 'black'
  | 'red'
  | 'dark'
  | 'green-700'
  | 'red-500';

const handleStyle = (variant: TextProps['variant'], disabled: boolean) => {
  switch (variant) {
    case 'h1':
      return {
        fontSize: '26px',
        lineHeight: '30px',
        color: theme.colors.grayscale_950,
      };
    case 'h2':
      return {
        fontSize: '20px',
        lineHeight: '23px',
        color: theme.colors.grayscale_950,
      };
    case 'h3':
      return {
        fontSize: '18px',
        lineHeight: '21px',
        color: theme.colors.grayscale_950,
      };
    case 'h4':
      return {
        fontSize: '16px',
        lineHeight: '19px',
        color: theme.colors.grayscale_950,
      };
    case 'h5':
      return {
        fontSize: '14px',
        lineHeight: '16px',
        color: theme.colors.grayscale_950,
      };
    case 'caption':
      return {
        fontSize: '12px',
        lineHeight: '14px',
        padding: 0,
        color: theme.colors.grayscale_950,
      };
    case 'note':
      return {
        fontSize: '10px',
        lineHeight: '12px',
        color: theme.colors.grayscale_950,
      };
    case 'link':
      return {
        fontSize: '14px',
        lineHeight: '16px',
        color: theme.colors.blue_500,
        display: 'inline-block',
        '&:hover': {
          cursor: disabled ? 'not-allowed' : 'pointer',
          textDecoration: disabled ? 'none' : 'underline',
          color: theme.colors.blue_500,
        },
      };
    case 'span':
    case 'paragraph':
    default:
      return {
        fontSize: '14px',
        lineHeight: '16px',
      };
  }
};

const handleColor = (colorVariant: TextProps['color'], disabled: boolean) => {
  if (disabled)
    return {
      color: theme.colors.grayscale_600,
    };
  switch (colorVariant) {
    case 'primary':
      return {
        color: theme.colors.blue_500,
      };
    case 'disabled':
      return {
        color: theme.colors.grayscale_600,
      };
    case 'gray-900':
      return {
        color: theme.colors.grayscale_900,
      };
    case 'gray-800':
      return {
        color: theme.colors.grayscale_800,
      };
    case 'gray-700':
      return {
        color: theme.colors.grayscale_700,
      };
    case 'gray-600':
      return {
        color: theme.colors.grayscale_600,
      };
    case 'gray-200':
      return {
        color: theme.colors.grayscale_200,
      };
    case 'red':
      return {
        color: theme.colors.red_500,
      };
    case 'black':
      return {
        color: theme.colors.grayscale_950,
      };
    case 'dark':
      return {
        color: theme.colors.grayscale_900,
      };
    case 'green-700':
      return {
        color: theme.colors.green_700,
      };
    case 'red-500':
      return {
        color: theme.colors.red_500,
      };
    default:
      return {};
  }
};

const StyledText = styled.p<TextProps>`
  margin-bottom: 0;
  word-break: ${({ breakAll }) => (breakAll ? 'break-all' : '')};
  ${(props) => ({
    fontWeight: props.bold ? 'bold' : props.semiBold ? 500 : 'normal',
    fontStyle: props.italic ? 'italic' : 'normal',
    ...handleStyle(props.variant, Boolean(props.disabled)),
    ...handleColor(props.color, Boolean(props.disabled)),
    pointerEvents: props.disabled ? 'none' : 'all',
    whiteSpace: props.noWrap ? 'nowrap' : 'inherit',
  })}
  ${(props) => props.css}
`;

const renderAsTag = (variant?: TextVariant) => {
  switch (variant) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'span':
      return variant;
    case 'link':
      return 'a';
    default:
      return 'p';
  }
};

const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
  (props: TextProps, ref) => {
    const {
      children,
      css,
      emptyPlaceholder = '-',
      showEmptyPlaceholder = false,
      ...rest
    } = props;

    return (
      <StyledText ref={ref} css={css} as={renderAsTag(props.variant)} {...rest}>
        {children || (showEmptyPlaceholder && emptyPlaceholder)}
      </StyledText>
    );
  },
);

export default Text;
