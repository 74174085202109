// If the exact url is includes in this list, then the menu will show
const whiteList = [
  '/super-admin/user-guide/new',
  '/super-admin/user-guide/edit',
];

const isMatch = (text: string) => (s: string | RegExp): boolean => {
  if (typeof s === 'string') {
    return s === text;
  }
  return s.test(text);
};

const isPageWithoutMenu = (s: string): boolean => {
  if (whiteList.some((v) => s.includes(v))) {
    return false;
  }

  return [
    '/support',
    '/not-allow',
    '/user-guide',
    /\/user-guide\/\S+/gm,
    /\/user-guide-search/gm,
  ].some(isMatch(s));
};

export { isPageWithoutMenu };
