import { css } from 'styled-components';

export const tooltipStyle = css`
  .ant-tooltip-inner {
    background-color: var(--Colors-Grayscale-600);
    border-radius: 6px;
  }

  .ant-tooltip-arrow-content {
    background-color: var(--Colors-Grayscale-600);
  }
`;
