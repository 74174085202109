import { postForm } from 'apis/baseAPI';
import { SUPPORT_DOMAIN } from 'constants/configs';

const uploadFile = (
  f: File | Blob,
): Promise<{ data: { id: string; url: string } }> => {
  const form = new FormData();
  form.append('file', f);

  return postForm({
    url: 'page/upload-file',
    diffDomain: `${SUPPORT_DOMAIN}/api`,
    apiVersion: 'v1',
    data: form,
  });
};

export { uploadFile };
