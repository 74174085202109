import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { Protocol } from 'models/Router';
import { apiPost } from 'apis/baseAPI';
import { PROXY_DOMAIN } from 'constants/configs';
import { ConfigData, DHCPData, RequestLanWanData } from 'models/LanWanStatus';
import notification from 'modules/notification';
import { ApiError } from 'models/Errors';
import { _translate } from 'translate/TranslateProvider';

export type RequestData = RequestLanWanData & {
  routerId?: string;
  requestType: Protocol;
};

type State = StateType<RequestData>;

const name = 'updateGuest';

const initialState: State = {
  data: {
    routerId: '',
    requestType: 'static',
    config: {} as ConfigData,
    dhcp: {} as DHCPData,
    enable_usb_3: false,
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const updateGuestConfig = createAsyncThunk(
  name,
  async (_data: RequestData, { rejectWithValue }) => {
    try {
      const { config, dhcp, requestType, routerId } = _data;
      let mapValues: any;
      if (requestType === 'static') {
        const { proto, ipaddr, netmask, gateway, broadcast, dns, mtu } = config;
        const { ignore, leasetime, dynamicdhcp, start, limit } = dhcp;
        mapValues = {
          static_address: {
            config: {
              proto: proto.toLowerCase(),
              ipaddr,
              netmask,
              gateway,
              broadcast,
              dns,
              mtu,
            },
          },
        };
        if (ignore) {
          mapValues.static_address.dhcp = {
            ignore,
            leasetime: null,
            dynamicdhcp: true,
            start: '',
            limit: '',
          };
        } else {
          mapValues.static_address.dhcp = {
            ignore,
            leasetime,
            dynamicdhcp,
            start,
            limit,
          };
        }
      } else {
        const {
          proto,
          hostname,
          broadcast,
          defaultroute,
          peerdns,
          dns,
          mtu,
        } = config;
        mapValues = {
          dhcp_client: {
            proto: proto.toLowerCase(),
            hostname,
            broadcast,
            defaultroute,
            peerdns,
            dns,
            mtu,
          },
        };
      }
      const response = await apiPost({
        url: `router/rpc/update_guest_config/${routerId}`,
        apiVersion: 'v2',
        diffDomain: `${PROXY_DOMAIN}/api`,
        data: mapValues,
        reduxActionName: name,
      });
      notification.success(
        _translate('ROUTER_DETAIL.ADVANCED_INFO.EDIT.TOAST.SUCCESS'),
      );

      return response;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorCode = '';
      state.errorId = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [updateGuestConfig.pending as never]: (state: State) => {
      state.error = '';
      state.errorCode = '';
      state.errorId = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [updateGuestConfig.fulfilled as never]: (state: State) => {
      state.status = THUNK_STATUS.SUCCEEDED;
    },
    [updateGuestConfig.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      if (!action.payload) {
        state.error = 'MESSAGE.UNKNOWN_ERROR';
      } else {
        const {
          code,
          id,
          message,
        } = action.payload as ApiError['response']['data']['error'];
        state.error = message.split(':')[1];
        state.errorId = id;
        state.errorCode = code;
      }
    },
  },
});

const selector = (state: AppState) => state.router[name];

export const selectUpdateGuestLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectUpdateGuestFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectUpdateGuestSuccess = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const { reset } = slice.actions;

export default slice.reducer;
