import { combineReducers } from '@reduxjs/toolkit';

import listCaptiveDetail from './records';
import listCaptive from './list';
import routerList from './list-routers';
import filters from './filters';
import routers from './routers';

export default combineReducers({
  listCaptive,
  listCaptiveDetail,
  routerList,
  filters,
  routers,
});
