import { createContext, FC, PropsWithChildren } from 'react';

import translations from './translation';
import { flattenObject } from '../utils/objects';
import {
  fallBackLanguage,
  languages,
  languageStore,
  setLanguage as setStore,
} from './state';

export type TranslationType = {
  [key: string]: { [key: string]: string };
};

export interface TranslateProviderValue {
  currentLanguage: string;
  setLanguage: (_language: string) => void;
  languages: { [key: string]: string };
  t: (_label: string, _interpolation?: Record<string, unknown>) => string;
}

export const _translate = (
  label: string,
  interpolation?: Record<string, unknown>,
): string => {
  const _l = languageStore.getState().language;
  let translation =
    translations[_l] && translations[_l][label]
      ? translations[_l][label]
      : translations[fallBackLanguage] && translations[fallBackLanguage][label]
      ? translations[fallBackLanguage][label]
      : label;
  if (interpolation) {
    const flatten = flattenObject(interpolation);
    Object.keys(flatten).forEach((key) => {
      translation = translation.replace(`{{${key}}}`, flatten[key]);
    });
  }
  return translation;
};

// Contexts
export const TranslateContext = createContext<TranslateProviderValue | null>(
  null,
);

const TranslateProvider: FC = (
  props: PropsWithChildren<Record<never, never>>,
) => {
  const _cur = languageStore((s) => s.language);

  const value = {
    currentLanguage: _cur,
    setLanguage: setStore,
    languages,
    t: _translate,
  };

  return (
    <TranslateContext.Provider value={value}>
      {translations !== {} && props.children}
    </TranslateContext.Provider>
  );
};

export default TranslateProvider;
