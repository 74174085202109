import { apiGet } from 'apis/baseAPI';
import { env } from 'constants/configs';

type Payload = {
  type: string;
  name: string;
};

export type AppConfigs = {
  id: string;
  type: string;
  name: string;
  value: string;
  desc: string;
  created_date: number;
  modified_date: number;
};

type Resp = {
  data: Config;
};

type Config = {
  configs: [AppConfigs];
};

const APP_CONFIG_TYPE = {
  special_voicelink_config: {
    type: 'portal',
    name: 'special_voicelink_config',
  },
};

const getAppConfig = (req: Payload): Promise<Resp> => {
  return apiGet({
    diffDomain: `${env.REACT_APP_AUTH_URL}/api`,
    apiVersion: 'v1',
    url: `/app-configuration/config?types=${req.type}&names=${req.name}`,
  });
};

const getOrgListForSpecialVoicelinkConfig = () => {
  return getAppConfig(APP_CONFIG_TYPE['special_voicelink_config']);
};

export { getAppConfig, getOrgListForSpecialVoicelinkConfig };
