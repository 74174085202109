import { Router, Switch } from 'react-router-dom';
import { FC } from 'react';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Login from 'page/public/Login';
import ForgotPassword from 'page/public/ForgotPassword';
import { history } from './history';

const Routing: FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute renderComponent={Login} path="/login" />
        <PublicRoute
          renderComponent={ForgotPassword}
          path="/forgot-password"
          exact
        />
        <PrivateRoute />
      </Switch>
    </Router>
  );
};

export default Routing;
