import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { currentUserState } from '../../data/auth/currentUser';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);

dayjs.prototype.formatWithCurrentTimezone = function (template: string) {
  const userTz = currentUserState.getState().timezone;
  return this.tz(userTz).format(template);
};

dayjs.prototype.toUserTz = function () {
  const userTz = currentUserState.getState().timezone;
  if (userTz) {
    // Tiket [SER-6970]: Due to known issue with timezone 0 of dayjs
    // https://github.com/iamkun/dayjs/issues/2037
    // We need to check the timezone = 0 and parse it to UTC manually.
    if (isOffsetZero(userTz)) {
      return this.utc();
    } else {
      return this.tz(userTz);
    }
  }
  return this;
};

const isOffsetZero = (tz: string) => {
  const offset = dayjs().tz(tz).utcOffset();
  return offset === 0;
};

declare module 'dayjs' {
  interface Dayjs {
    formatWithCurrentTimezone: (_f: string) => string;
    toUserTz: () => Dayjs;
  }
}

export default dayjs;
