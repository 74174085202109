import { StateType } from 'models/Store';
import { Pagination } from 'models/Table';
import { THUNK_STATUS } from 'constants/status';
import { Router } from 'models/Router';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AppState } from 'store/rootReducer';
import { apiPost } from 'apis/baseAPI';
import { env } from 'constants/configs';
import { parseErrorMessage } from 'utils/strings';
import { ApiError } from 'models/Errors';

type State = StateType<{
  things: Router[];
  pagination: Pagination;
  filter: {
    orgs: string[];
    userId: string;
    failover: number[];
    status: number[];
    searchText: string;
    lineStatus: number[];
    captives: string[];
  };
}>;

const name = 'routerList';

const initialState: State = {
  data: {
    things: [],
    pagination: {
      page: 1,
      size: 25,
      total_elements: 0,
      total_pages: 0,
    },
    filter: {
      userId: '',
      orgs: [],
      failover: [],
      status: [],
      lineStatus: [],
      captives: [],
      searchText: '',
    },
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const getCaptiveRouters = createAsyncThunk(
  name,
  async (
    data: { orgId: string; portalId?: string },
    { getState, rejectWithValue },
  ) => {
    try {
      const { orgId, portalId } = data;

      const appState = getState() as AppState;

      const { userId, failover, lineStatus, searchText, captives } = (appState
        .captive[name] as State).data.filter;
      const { page, size } = (appState.captive[name] as State).data.pagination;

      const requestData = {
        filter: {
          search_text: searchText,
          org_id: [orgId],
          user_id: userId,
          fail_over_status: failover,
          line_status: lineStatus,
          filter_portal_ids: captives,
        },
        paging: {
          page: page - 1,
          size: size,
        },
      };

      if (portalId) {
        (requestData.filter as any).portal_id = portalId;
      }

      return await apiPost<State['data']>({
        url: 'portal/captive/router/search',
        apiVersion: 'v1',
        data: requestData,
        diffDomain: `${env.REACT_APP_CAPTIVE_PORTAL_URL}/api`,
        reduxActionName: name,
      });
    } catch (e) {
      return rejectWithValue(Error(parseErrorMessage(e as ApiError)).message);
    }
  },
);

const resetState = (state: State) => {
  state.error = '';
  state.errorId = '';
  state.errorCode = '';
  state.status = THUNK_STATUS.IDLE;
  state.data = initialState.data;
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state: State) => resetState(state),
    setPage(state, action) {
      state.data.pagination.page = action.payload;
    },
    setPageSize(state, action) {
      state.data.pagination.size = action.payload;
    },
    setSearchText(state, action) {
      state.data.filter.searchText = action.payload;
    },
    setCaptive(state, action) {
      state.data.filter.captives = action.payload;
    },
    clearSearchText(state, action) {
      state.data.filter.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCaptiveRouters.pending, (state) => {
        state.error = '';
        state.status = THUNK_STATUS.LOADING;
      })
      .addCase(getCaptiveRouters.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        const data = (action.payload as any).data as any;
        const _mapped = data.things.map((i: any) => ({
          ...i,
          organization_info: i.organizations,
        }));
        state.data.things = [..._mapped];
        state.data.pagination.total_elements = data.paging.total_elements;
      })
      .addCase(getCaptiveRouters.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `captive_list_router.${code}`;
        }
      });
  },
});

export const {
  reset,
  setPage,
  setPageSize,
  setSearchText,
  clearSearchText,
  setCaptive,
} = slice.actions;

const selector = (state: AppState) => state.captive[name] as State;

export const selectRouters = createSelector(
  selector,
  (state: State) => state.data.things,
);

export const selectRoutersPagination = createSelector(
  selector,
  (state) => state.data.pagination,
);

export const selectLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectLoaded = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectFilter = createSelector(
  selector,
  (state) => state.data.filter,
);

export default slice.reducer;
