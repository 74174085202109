import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { StateType } from 'models/Store';
import { Organization } from 'models/Organization';
import { THUNK_STATUS } from 'constants/status';
import { apiGet } from 'apis/baseAPI';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { GLOBAL_ACTION } from 'store/config';
import { ApiError, BaseError, ErrorEmpty } from 'models/Errors';
import { Key } from 'react';

const name = 'detailOrg';

type State = StateType<Organization & { selectedKeys?: Key[] }>;

const initialState: State = {
  data: {
    id: '',
    logo: null,
    name: '',
    parent: null,
    desc: '',
    phone: '',
    email: '',
    address: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    suspended: false,
    readonly: false,
    allow_sub_orgs: false,
    disallow_assets: false,
    allow_org_admin: 0,
    selectedKeys: [],
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const getData = createAsyncThunk<
  {
    data: Organization;
  },
  string,
  {
    extra: {
      jwt: string;
    };
    rejectValue: BaseError;
  }
>(`${name}/getOrg`, async (id, { rejectWithValue }) => {
  try {
    return await apiGet({
      url: `portal/org/detail/${id}`,
      apiVersion: 'v2',
      reduxActionName: `${name}/getOrg`,
    });
  } catch (e) {
    return rejectWithValue(parseError(e as ApiError) || ErrorEmpty);
  }
});

const resetState = (state: State) => {
  state.error = '';
  state.errorId = '';
  state.errorCode = '';
  state.status = THUNK_STATUS.IDLE;
  state.data = initialState.data;
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state: State) => resetState(state),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state) => {
        state.status = THUNK_STATUS.LOADING;
        state.error = '';
        state.errorId = '';
        state.errorCode = '';
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.status = THUNK_STATUS.SUCCEEDED;
        state.data = action.payload.data;
        state.data.desc = action.payload.data.desc
          ? action.payload.data.desc.replace(/[\r]/g, '')
          : '';
        state.data.address1 = action.payload.data.street_number || '';
        state.data.address2 = action.payload.data.street || '';
        state.data.zipcode = action.payload.data.zip_code || '';
      })
      .addCase(getData.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const {
            code,
            id,
            message,
          } = action.payload as ApiError['response']['data']['error'];
          state.error = message;
          state.errorId = id;
          state.errorCode = `detail_org.${code}`;
        }
      })
      .addCase(GLOBAL_ACTION.LOGGED_OUT, (s) => resetState(s));
  },
});

const selector = (state: AppState) => state.organization[name];

export const selectLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectOrgDetail = createSelector(
  selector,
  (state: State) => state.data,
);

export const selectOrgLoaded = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectOrgLoadedFail = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectSelectedKeys = createSelector(
  selector,
  (state: State) => state.data.selectedKeys ?? [],
);

export const selectOrgError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export const { reset } = slice.actions;

export default slice.reducer;
