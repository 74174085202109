export const flattenObject = (
  ob: Record<string, any>,
): Record<string, string> => {
  const toReturn: Record<string, string> = {};

  for (const i in ob) {
    if (!Object.prototype.hasOwnProperty.call(ob, i)) continue;

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!Object.prototype.hasOwnProperty.call(flatObject, x)) continue;
        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const deepMerge = (
  objFrom: Record<string, any>,
  objTo: Record<string, any>,
): Record<string, any> =>
  Object.keys(objFrom).reduce(
    (merged, key) => {
      merged[key] =
        objFrom[key] instanceof Object &&
        !Array.isArray(objFrom[key]) &&
        typeof objFrom[key] !== 'function'
          ? deepMerge(objFrom[key], merged[key] ?? {})
          : objFrom[key];
      return merged;
    },
    { ...objTo },
  );
