import { useEffect } from 'react';
import { Layout } from 'antd';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AppHeader from 'components/organism/AppHeader';
import NotFound from 'page/error/NotFound';
import { isPageWithoutMenu } from 'constants/Layout';
import { useCheckService } from 'hooks/useCheckService';
import {
  checkPermission,
  currentUserState,
  selectors,
} from 'data/auth/currentUser';
import InternetErrorBoundary from 'components/organism/InternetErrorBoundary';
import ErrorDialogGlobal from 'modules/errorDialog';
import UnavailableService from 'page/error/UnavailableService';

import routes, { DEFAULT_ROUTE, MenuItem } from './routes';
import MainContent from './MainContent';
import SideMenu from './SideMenu';

import { useGetRedirectUrl } from '../hooks/useGetRedirectUrl';

const { Content } = Layout;

const StyledApp = styled(Layout)`
  height: 100%;
`;

const StyledAppContent = styled(Layout)`
  overflow: hidden;
`;

const StyleContent = styled(Content)<{
  $hasMenu: boolean;
  $isAllow: boolean;
}>`
  overflow: auto;
  padding: ${(p) => (p.$isAllow && p.$hasMenu ? '20px 20px 20px' : 0)};
  min-height: 280px;
`;

const RouteItem = (props: MenuItem) => {
  const {
    url,
    component,
    availableFor,
    availableForService,
    exact = true,
  } = props;

  const hasPermission = checkPermission(availableFor);
  if (!hasPermission) {
    return null;
  }

  const allowByService = useCheckService(availableForService);
  if (!allowByService) {
    return (
      <Route
        key={url}
        path={url}
        exact={exact}
        component={UnavailableService}
      />
    );
  }

  // @ts-expect-error routeProperties do not exists on X - it accepts no props at all
  return <Route key={url} path={url} exact={exact} component={component} />;
};

const AdminLayout = () => {
  const history = useHistory();

  const currentUser = currentUserState((s) => s);

  const isNotAllow = currentUserState(selectors.selectIsNotAllow);

  const redirectUrl = useGetRedirectUrl();

  useEffect(() => {
    if (!isNotAllow || history.location.pathname === '/profile') {
      return;
    }
    history.push('/not-allow');
  }, [currentUser]);

  return (
    <>
      <StyledApp>
        <AppHeader />
        <StyledAppContent>
          <InternetErrorBoundary>
            <MainContent>
              {!isPageWithoutMenu(location.pathname) && !isNotAllow && (
                <SideMenu />
              )}
              <StyleContent
                $isAllow={!isNotAllow}
                $hasMenu={!isPageWithoutMenu(location.pathname)}
              >
                <Switch>
                  {routes.map(RouteItem)}
                  {!isNotAllow ? (
                    <Redirect
                      from="/"
                      to={redirectUrl || DEFAULT_ROUTE}
                      exact
                    />
                  ) : (
                    <Redirect from="/" to="/not-allow" />
                  )}
                  <Route component={NotFound} />
                </Switch>
              </StyleContent>
            </MainContent>
            <ErrorDialogGlobal />
          </InternetErrorBoundary>
        </StyledAppContent>
      </StyledApp>
    </>
  );
};

export default AdminLayout;
