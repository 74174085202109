import React from 'react';
import styled from 'styled-components';
import { Tag as AntdTag, TagProps } from 'antd';

import AppIcon from '../SvgIcon';
import { theme } from '../../../theme/theme';

export type Props = TagProps & {
  text?: string;
  textColor?: string;
  iconColor?: string;
  background?: string;
  content?: string | React.ReactElement;
};

const StyledTag = styled(AntdTag)<{ $textColor: string; $background: string }>`
  border-radius: 4px;
  font-size: 14px;
  padding: 0 6px;
  border: none;
  color: ${(props) => props.$textColor};
  background: ${(props) => props.$background};
  .ant-tag-close-icon {
    margin-left: 6px;
    vertical-align: center;
  }
`;

const Tag: React.FC<Props> = ({
  textColor = theme.colors.grayscale_900,
  background = theme.colors.grayscale_50,
  iconColor = theme.colors.grayscale_900,
  text,
  children,
  content,
  ...rest
}: Props) => {
  return (
    <StyledTag
      $textColor={textColor}
      $background={background}
      {...rest}
      closeIcon={<AppIcon name="close" color={iconColor} size={10} />}
    >
      {text || content || children}
    </StyledTag>
  );
};

export default Tag;
