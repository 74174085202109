import { ElementRef, useEffect, useRef, useState } from 'react';
import create from 'zustand';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import Text from 'components/atoms/Text';
import { Modal } from 'components/molecules/Modal';
import Button from 'components/molecules/Button';
import Block from 'components/atoms/Block';
import Icon from 'components/atoms/SvgIcon';
import Checkbox from 'components/molecules/Checkbox';
import { useTranslate } from 'translate/useTranslate';
import { theme } from 'theme/theme';
import ErrorDialog from 'components/molecules/ErrorDialog';

import { updateQuickTipsConfig } from 'apis/userProfile/updateQuickTipsConfig';
import { _translate } from 'translate/TranslateProvider';
import { API_QUERY_KEY } from 'apis/ApiQueryKey';
import { useGetQuickTipsConfig } from './hooks/useGetQuickTipsConfig';
import {
  TipsContent,
  getAvailableQuickTips,
  getNextQuickTipsIdx,
} from './data/quickTipsData';

const StyledContent = styled(Block)`
  margin: 20px 0 20px 0px;
  color: ${theme.colors.grayscale_900};
`;

const StyledTitle = styled(Text)`
  color: ${theme.colors.grayscale_900};
  margin-bottom: 8px;
`;

const StyledTip = styled.div`
  color: ${theme.colors.grayscale_900};
  font-size: 14px;
`;

const QuickTipsDialog = () => {
  const isShow = state((s) => s.isShow);
  const { t } = useTranslate();
  const [idxTip, setIdxTip] = useState(0);
  const [showError, setShowError] = useState(false);
  const [currentIdx, setCurrentIdx] = useState(1);
  const { mutate, isLoading } = useMutation({
    mutationKey: [API_QUERY_KEY.QUICKTIPS.GET_CONFIG],
    mutationFn: updateQuickTipsConfig,
  });

  const { data } = useGetQuickTipsConfig();

  const myRef = useRef<ElementRef<'div'>>(null);
  const checkboxRef = useRef<any>(null);
  const availableQuickTips = getAvailableQuickTips();

  const handleNext = () => {
    const index = getNextQuickTipsIdx(idxTip);
    updateQuickTips(index, checkboxRef.current.input.checked);
    setCurrentIdx(
      availableQuickTips.findIndex((i) => i.title == TipsContent[index].title) +
        1,
    );
  };

  const updateQuickTips = (idx: number, showOnLogin: boolean) => {
    mutate(
      { index: idx, show_when_login: showOnLogin },
      {
        onSuccess: () => {
          setIdxTip(idx);
        },
        onError: () => {
          setShowError(true);
        },
      },
    );
  };

  useEffect(() => {
    // auto forcus to the quick-tip dialog to prevent the user click somewhere
    if (!myRef.current) {
      return;
    }
    myRef.current?.focus();
  }, [myRef.current]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setIdxTip(data.index);
    setCurrentIdx(
      availableQuickTips.findIndex(
        (i) => i.title == TipsContent[data.index].title,
      ) + 1,
    );
  }, [data]);

  const handleCheckBoxChange = (e: CheckboxChangeEvent) => {
    updateQuickTipsConfig({
      index: idxTip,
      show_when_login: e.target.checked,
    });
  };

  return (
    <>
      <Modal
        width={520}
        centered
        visible={isShow}
        closable={false}
        footer={false}
        destroyOnClose
        maskStyle={{ background: 'rgba(0, 0, 0, 0.3)' }}
        wrapProps={{ style: { zIndex: 2000 } }}
      >
        <Block ref={myRef}>
          <Block flex justifyContent="center">
            <Block flex css={{ marginLeft: 'auto' }}>
              <Icon name="quick_tips_color" size={88} />
            </Block>
            <Text color="gray-600" css={{ marginLeft: 'auto' }}>
              {currentIdx}/{availableQuickTips.length}
            </Text>
          </Block>
          <StyledContent flexGrow={1} flex column>
            <StyledTitle variant="h1" bold>
              {TipsContent[idxTip].title}
            </StyledTitle>
            <Block>
              <StyledTip
                dangerouslySetInnerHTML={{
                  __html: TipsContent[idxTip].content,
                }}
              />
            </Block>
          </StyledContent>
        </Block>
        <Block flex justifyContent="space-between" alignItems="center">
          <Block>
            <Checkbox
              ref={checkboxRef}
              defaultChecked
              onChange={handleCheckBoxChange}
              className="mr-10"
            >
              {t('CHECKBOX.SHOW_TIPS')}
            </Checkbox>
          </Block>
          <Block flex justifyContent="flex-end">
            <Button
              variant="secondary"
              onClick={() => handleNext()}
              disabled={isLoading}
            >
              {t('BUTTON.NEXT_TIP')}
            </Button>
            <Button variant="primary" className="ml-10" onClick={closeDialog}>
              {t('BUTTON.OK')}
            </Button>
          </Block>
        </Block>
      </Modal>
      <ErrorDialog
        visible={showError}
        onClose={() => {
          setShowError(false);
        }}
        message={_translate('MESSAGE.UNABLE_TO_PERFORM_REQUEST')}
      />
    </>
  );
};

export default QuickTipsDialog;

type State = {
  isShow: boolean;
};

const initialState: State = {
  isShow: false,
};

const state = create<State>(() => initialState);

const showDialog = () => state.setState(() => ({ isShow: true }));
const closeDialog = () => state.setState(() => ({ isShow: false }));

export const actions = {
  showDialog,
  closeDialog,
};
