import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { THUNK_STATUS } from 'constants/status';
import { PROXY_DOMAIN } from 'constants/configs';
import { StateType } from 'models/Store';
import { Device } from 'models/Device';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { apiGet } from 'apis/baseAPI';
import { WithId } from 'models/WithId';
import { uuidV4 } from 'utils/uuid';
import { ApiError } from 'models/Errors';
import { setValue } from 'page/private/RouterDetail/components/BasicInformation/data/lastCommunicate';

const name = 'listConnectedDevices';

type RequestData = {
  routerId: string;
};

type State = StateType<{
  devices: WithId<Device>[];
}>;

const initialState: State = {
  data: {
    devices: [],
  },
  error: '',
  status: THUNK_STATUS.IDLE,
};

export const getConnectedDevices = createAsyncThunk(
  name,
  async (data: RequestData, { rejectWithValue }) => {
    try {
      const { routerId } = data;
      const rs = await apiGet<ServerResponse<{ devices: Device[] }>>({
        diffDomain: `${PROXY_DOMAIN}/api`,
        url: `router/rpc/connected_devices/${routerId}`,
        apiVersion: 'v2',
        reduxActionName: name,
      });

      setValue(rs.meta.last_communication);
      return rs;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorCode = '';
      state.errorId = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getConnectedDevices.pending as never]: (state: State) => {
      state.error = '';
      state.status = THUNK_STATUS.LOADING;
    },
    [getConnectedDevices.fulfilled as never]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      state.data.devices = action.payload.data.devices.map((i: Device) => ({
        ...i,
        id: uuidV4(),
      }));
    },
    [getConnectedDevices.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload.message;
      state.errorCode = action.payload.code;
    },
  },
});

const selector = (state: AppState) => state.router[name];

export const selectConnectedDevices = createSelector(
  selector,
  (state: State) => state.data.devices,
);

export const selectConnectedDevicesLoading = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.LOADING,
);

export const selectConnectedDevicesLoadFailed = createSelector(
  selector,
  (state: State) => state.status === THUNK_STATUS.FAILED,
);

export const selectErrorConnectedDevices = createSelector(
  selector,
  (state: State) => state.errorCode,
);

export const { reset } = slice.actions;

export default slice.reducer;
