import { Tooltip as AntdTooltip, TooltipProps } from 'antd';

const Tooltip = ({
  children,
  title,
  placement = 'topLeft',
  ...props
}: TooltipProps): JSX.Element => {
  return (
    <AntdTooltip {...props} title={title} placement={placement}>
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
