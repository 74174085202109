const theme = {
  colors: {
    branding_500: 'var(--Colors-Branding-500)',
    blue_50: 'var(--Colors-Blue-50)',
    blue_300: 'var(--Colors-Blue-300)',
    blue_500: 'var(--Colors-Blue-500)',
    blue_600: 'var(--Colors-Blue-600)',
    red_50: 'var(--Colors-Red-50)',
    red_500: 'var(--Colors-Red-500)',
    grayscale_25: 'var(--Colors-Grayscale-25)',
    grayscale_50: 'var(--Colors-Grayscale-50)',
    grayscale_75: 'var(--Colors-Grayscale-75)',
    grayscale_200: 'var(--Colors-Grayscale-200)',
    grayscale_600: 'var(--Colors-Grayscale-600)',
    grayscale_700: 'var(--Colors-Grayscale-700)',
    grayscale_800: 'var(--Colors-Grayscale-800)',
    grayscale_900: 'var(--Colors-Grayscale-900)',
    green_100: 'var(--Colors-Green-100)',
    green_700: 'var(--Colors-Green_700)',
    orange_700: 'var(--Colors-Orange-700)',
    orange_550: 'var(--Colors-Orange-550)',
    orange_100: 'var(--Colors-Orange-100)',
    yellow: 'var(--colors-yellow)',
    grayscale_950: 'var(--Colors-Grayscale-950)',
    base_50: 'var(--Colors-Base-50)',
    grey5: 'var(--colors-grey5)',
    grey6: 'var(--colors-grey6)',
    lightyellow: 'var(--colors-lightyellow)',
    lightgreen: 'var(--colors-lightgreen)',
    lightred: 'var(--colors-lightred)',
    darkblue: 'var(--colors-darkblue)',
    blue_a10: 'var(--colors-blue_a10)',
    yellow_50: 'var(--Colors-yellow_50)',
    yellow_a2: 'var(--colors-yellow_a2)',
    yellow_a10: 'var(--colors-yellow_a10)',
    transparent: 'var(--colors-transparent)',
  },
  media: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1440,
  },
  container: {
    xs: 0,
    sm: 540,
    md: 720,
    lg: 936,
    xl: 1248,
  },
  mediaPx: {
    xs: 0,
    sm: '576px',
    md: '768px',
    lg: '1024px',
    xl: '1440px',
  },
  gutter: {
    xs: 16,
    sm: 16,
    md: 24,
    lg: 24,
    xl: 24,
  },
  columns: {
    xs: 4,
    sm: 4,
    md: 8,
    lg: 12,
    xl: 12,
  },
  zIndex: {
    overlay: 5,
    popOver: 10,
    skeleton: 50,
    dialog: 100,
    errorDialog: 150,
    toast: 200,
  },
} as const;

export type SimpifiColor = keyof typeof theme.colors;

const devices = {
  xs: `(min-width: ${theme.mediaPx.xs})`,
  sm: `(min-width: ${theme.mediaPx.sm})`,
  md: `(min-width: ${theme.mediaPx.md})`,
  lg: `(min-width: ${theme.mediaPx.lg})`,
  xl: `(min-width: ${theme.mediaPx.xl})`,
};

const devicesMaxWidth = {
  xs: `(max-width: ${theme.mediaPx.xs})`,
  sm: `(max-width: ${theme.mediaPx.sm})`,
  md: `(max-width: ${theme.mediaPx.md})`,
  lg: `(max-width: ${theme.mediaPx.lg})`,
  xl: `(max-width: ${theme.mediaPx.xl})`,
};

const getCssVar = (name: string) => {
  const _n = name.startsWith('var') ? name.slice(4, -1) : name;
  return getComputedStyle(document.body).getPropertyValue(_n).trim();
};

export { theme, devices, devicesMaxWidth, getCssVar };
