import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiDelete } from 'apis/baseAPI';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { ApiError } from 'models/Errors';
import notification from 'modules/notification';
import { _translate } from 'translate/TranslateProvider';

const name = 'deleteUser';

type DeleteRequest = {
  userId: string;
  email: string;
};

type State = StateType<unknown>;

const initialState: State = {
  data: null,
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export const deleteUser = createAsyncThunk(
  name,
  async ({ userId, email }: DeleteRequest, { rejectWithValue }) => {
    try {
      await apiDelete({
        url: `portal/user/profile/${encodeURIComponent(userId)}`,
        apiVersion: 'v2',
        reduxActionName: name,
      });
      notification.success(
        _translate('USER_DETAIL.UPDATE_DETAIL.DELETE.SUCCESS.MESSAGE', {
          email,
        }),
      );
      return;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
      state.errorCode = undefined;
      state.errorId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUser.pending, (state) => {
        state.status = THUNK_STATUS.LOADING;
        state.errorId = undefined;
        state.errorCode = undefined;
        state.error = '';
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.status = THUNK_STATUS.SUCCEEDED;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = THUNK_STATUS.FAILED;
        if (!action.payload) {
          state.error = 'MESSAGE.UNKNOWN_ERROR';
        } else {
          const { code, id, message } = action.payload as {
            code: string;
            id: string;
            message: string;
          };
          state.error = message;
          state.errorCode = `delete_user.${code}`;
          state.errorId = id;
        }
      });
  },
});

const selector = (state: AppState) => state[name];

export const { reset } = slice.actions;

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);

export const selectError = createSelector(
  selector,
  ({ error, errorCode, errorId }: State) => ({ error, errorCode, errorId }),
);

export default slice.reducer;
