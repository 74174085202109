import React, { useEffect, useRef } from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor, TinyMCE } from 'tinymce';

import { uploadFile } from 'apis/cdn/upload';
import { editorInitProps, TINYMCE_APIKEY } from 'constants/EditorV2';

interface EditorV2Props extends Omit<IAllProps, 'onChange'> {
  height?: string | number;
  onChange?: Callback<string, TinyMCEEditor>;
  init?: Parameters<TinyMCE['init']>[0] & {
    selector?: undefined;
    target?: undefined;
  };
}

const EditorV2 = ({
  height = 500,
  value,
  onChange,
  init,
  ...rest
}: EditorV2Props) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Editor
      onInit={(_evt, editor) => {
        editorRef.current = editor;
        const statusPathEl = document.getElementsByClassName(
          'tox-statusbar__path',
        )[0];
        if (!statusPathEl) {
          return;
        }
        observer.observe(statusPathEl, observerConfig);
      }}
      apiKey={TINYMCE_APIKEY}
      onEditorChange={onChange}
      value={value}
      init={{
        ...editorInitProps,
        height,
        images_upload_handler: async (blobInfo) => {
          return new Promise((resolve, reject) => {
            const { blob } = blobInfo;
            const file = blob();
            uploadFile(file)
              .then((data) => {
                resolve(data.data.url);
              })
              .catch((e) => {
                console.error(e);
                reject({ remove: true });
              });
          });
        },
        ...init,
      }}
      {...rest}
    />
  );
};

const observer = new MutationObserver((mutationsList, _observer) => {
  mutationsList.forEach(() => {
    // find all element that contains 'tox-statusbar__path-item' class, then remove the attribute
    // role="button" from them
    const pathItems = document.querySelectorAll('.tox-statusbar__path-item');
    pathItems.forEach((item) => {
      item.removeAttribute('aria-level');
      item.removeAttribute('aria-disabled');
    });
  });
});

// Configure and start observing the target element
const observerConfig = {
  attributes: false, // Observe attribute changes
  childList: true, // Observe additions/removals of child nodes
  subtree: true, // Observe changes in the entire subtree of the target element
};

export default EditorV2;
