import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { StateType, StatusType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import { apiPost } from 'apis/baseAPI';
import {
  Router,
  WiFiEncryption,
  WiFiCipher,
  WiFiMacFilter,
  WiFiMode,
  WifiInformationForm,
} from 'models/Router';
import { PROXY_DOMAIN } from 'constants/configs';
import {
  WIFI_CIPHER,
  WIFI_ENCRYPTION,
  WIFI_MAC_FILTER,
  WIFI_MODE,
} from 'constants/Router';
import notification from 'modules/notification';
import { ApiError } from '../../../models/Errors';
import { _translate } from 'translate/TranslateProvider';

type State = StateType<{
  updateType: UpdateType | '';
  loadingInfo: {
    guest: StatusType;
    primary: StatusType;
  };
}>;

const name = 'updateWiFiInformation';

const initialState: State = {
  data: {
    updateType: '',
    loadingInfo: {
      guest: 'idle',
      primary: 'idle',
    },
  },
  error: '',
  errorCode: '',
  errorId: '',
  status: THUNK_STATUS.IDLE,
};

export type UpdateType = 'guest' | 'primary';

type SubmitRequest = {
  section: UpdateType;
  values: {
    disabled: boolean;
    ssid: string;
    password?: string;
    security: WiFiEncryption;
    cipher?: WiFiCipher;
    ssid_hidden: boolean;
    wmm_mode: boolean;
    mac_filter_type: WiFiMacFilter;
    mac_filter_devices: {
      mac: string;
      host_name: string;
    }[];
    radio: {
      mode: WiFiMode;
      band: number;
      channel: number;
      width: number;
    };
    isolate_clients: boolean;
  };
};

const toBEModel = (
  data: WifiInformationForm,
  section: UpdateType,
): SubmitRequest => {
  const information = {
    disabled: data.disabled,
    isolate_clients: data.isolateClients,
    mac_filter_devices: data.macFilterDevices.map((i) => ({
      mac: i.mac,
      host_name: i.hostName,
    })),
    mac_filter_type: WIFI_MAC_FILTER.KEY_LABEL[
      data.macFilterType
    ].toLowerCase() as WiFiMacFilter,

    radio: {
      band: data.band,
      channel: data.channel,
      mode:
        (WIFI_MODE.KEY_LABEL[data.mode] as Uppercase<WiFiMode>) === 'LEGACY'
          ? 'Legacy'
          : (WIFI_MODE.KEY_LABEL[data.mode] as WiFiMode),
      width: data.width,
    },
    security: WIFI_ENCRYPTION.KEY_LABEL[
      data.security
    ].toLowerCase() as WiFiEncryption,
    ssid: data.ssId,
    ssid_hidden: data.ssIdHidden,
    wmm_mode: data.wmmMode,
  };

  // Remove Cipher and Password if Encryption is None
  if (data.security === WIFI_ENCRYPTION.LABEL_KEY.NONE) {
    return {
      section,
      values: information,
    };
  }
  return {
    section,
    values: {
      ...information,
      password: data.password,
      cipher: WIFI_CIPHER.KEY_LABEL[data.cipher].toLowerCase() as WiFiCipher,
    },
  };
};

export const submit = createAsyncThunk(
  `${name}/submit`,
  async (
    {
      data,
      routerId,
      section,
    }: {
      routerId: string;
      section: UpdateType;
      data: WifiInformationForm;
    },
    { rejectWithValue },
  ) => {
    try {
      const dataPost = toBEModel(data, section);

      const rs = await apiPost<Router>({
        url: `router/rpc/update_wifi_config/${routerId}`,
        apiVersion: 'v2',
        diffDomain: `${PROXY_DOMAIN}/api`,
        data: dataPost,
        reduxActionName: `${name}/submit`,
      });
      notification.info(
        _translate('ROUTER_DETAIL.ADVANCED_INFO.UPDATE_WIFI_SUCCESS'),
      );

      return rs;
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError));
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: State) {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [submit.pending as never]: (state: State, action) => {
      state.error = '';
      state.errorId = '';
      state.errorCode = '';
      const { section } = action.meta.arg;
      state.data.updateType = section;
      if (section === 'primary') {
        state.data.loadingInfo.primary = 'loading';
      } else {
        state.data.loadingInfo.guest = 'loading';
      }
      state.status = THUNK_STATUS.LOADING;
    },
    [submit.fulfilled as never]: (state: State, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      const { section } = action.meta.arg;
      if (section === 'primary') {
        state.data.loadingInfo.primary = 'succeeded';
      } else {
        state.data.loadingInfo.guest = 'succeeded';
      }
    },
    [submit.rejected as never]: (state: State, action) => {
      state.status = THUNK_STATUS.FAILED;
      if (!action.payload) {
        state.error = 'MESSAGE.UNKNOWN_ERROR';
        state.data.loadingInfo.primary = state.data.loadingInfo.guest = 'idle';
      } else {
        const {
          code,
          id,
          message,
        } = action.payload as ApiError['response']['data']['error'];
        state.error = message;
        state.errorId = id;
        state.errorCode = code;
        const { section } = action.meta.arg;
        if (section === 'primary') {
          state.data.loadingInfo.primary = 'failed';
        } else {
          state.data.loadingInfo.guest = 'failed';
        }
      }
    },
  },
});

export const { reset } = slice.actions;

const selector = (state: AppState) => state.router[name];

export const selectUpdateType = createSelector(
  selector,
  (state) => state.data.updateType,
);

export const selectLoading = createSelector(
  selector,
  (state) => state.data.loadingInfo.primary === 'loading',
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.data.loadingInfo.primary === 'succeeded',
);

export const selectFail = createSelector(
  selector,
  (state) => state.data.loadingInfo.primary === 'failed',
);

export const selectLoadingGuest = createSelector(
  selector,
  (state) => state.data.loadingInfo.guest === 'loading',
);

export const selectSuccessGuest = createSelector(
  selector,
  (state) => state.data.loadingInfo.guest === 'succeeded',
);

export const selectFailGuest = createSelector(
  selector,
  (state) => state.data.loadingInfo.guest === 'failed',
);

export const selectError = createSelector(
  selector,
  ({ error, errorId, errorCode }) => ({ error, errorId, errorCode }),
);

export default slice.reducer;
