import styled from 'styled-components';

import AppIcon from 'components/atoms/SvgIcon';
import { useTranslate } from 'translate/useTranslate';

const SplashContent = styled.div`
  height: 100%;
  background: rgb(53, 54, 58);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WelcomeText = styled.span`
  font-size: 3rem;
  color: white;
`;

const SplashScreen: React.FC = () => {
  const { t } = useTranslate();
  return (
    <SplashContent>
      <WelcomeText>{t('WELCOME.MSG')}</WelcomeText>
      <AppIcon name="LOADING_SQUARE" size={64} />
    </SplashContent>
  );
};

export default SplashScreen;
