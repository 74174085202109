import { API_QUERY_KEY } from 'apis/ApiQueryKey';
import { getProfile } from 'apis/auth/profile/get';
import { USER_ROLE } from 'constants/User';
import MQTTService from 'modules/qmtt/client';
import { useQuery } from 'react-query';

import { currentUserState, State } from 'data/auth/currentUser';
import { getAccessToken } from 'utils/token';

const useGetProfile = () =>
  useQuery([API_QUERY_KEY.AUTH.GET_PROFILE], getProfile, {
    select: ({ data }): State => {
      const { org_role_list, zone_id, roles, allowed_services, ...rest } = data;

      const _org_role_list = org_role_list || [];
      const _allowed_services = allowed_services || [];

      let _roles = Array.from(new Set(_org_role_list.map((i) => i.role_name)));

      const isAllBlocked = org_role_list.every(isBlockedOrg);

      if (org_role_list.length > 0 && isAllBlocked) {
        _roles = [USER_ROLE.ORGANIZATIONS_BLOCK];
      }

      if (_roles.length === 0) {
        _roles = [USER_ROLE.NO_ORGANIZATION];
      }

      const orgRolePair: Record<string, string> = _org_role_list.reduce(
        (prev, i) => ({
          ...prev,
          [i.org_id]: i.role_name,
        }),
        {},
      );

      const _allowedServices = _allowed_services.map((i) => ({
        service: i.service,
        orgId: toObject(i.org_ids),
      }));

      const _availableService = _allowedServices
        .filter((i) => Object.keys(i.orgId).length > 0)
        .map((i) => i.service);

      const _state = {
        ...rest,
        roles: [...roles, ..._roles],
        org_role_list,
        timezone: zone_id,
        orgRolePair,
        services: [..._availableService, 'ROUTER'], // service ROUTER is default
        allow_deactivate: false,
        allowed_services: _allowedServices,
      };
      return _state;
    },
    onSuccess(data: State) {
      // set profile to data
      currentUserState.setState(() => data);
      // Check and start mqtt service
      MQTTService.init(data.id, getAccessToken(), () => {
        console.log('Init MQTT after login!!!');
        MQTTService.startListenTopic(`v1/simplifi/${data.id.split('|')[1]}/#`);
      });
    },
  });

export { useGetProfile };

// util
type Awaited<T> = T extends Promise<infer K> ? K : T;
type ProfileResp = Awaited<ReturnType<typeof getProfile>>['data'];
type OrgRole = ArrElement<ProfileResp['org_role_list']>;

const isBlockedOrg = (org: OrgRole) => org.suspended;

const toObject = (s: string[]): Record<string, true> =>
  s.reduce((prev, cur) => {
    prev[cur] = true;
    return prev;
  }, {} as Record<string, true>);
