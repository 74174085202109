import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { StateType } from 'models/Store';
import { THUNK_STATUS } from 'constants/status';
import { parseError } from 'utils/strings';
import { AppState } from 'store/rootReducer';
import arrayToTree from 'utils/arrayToObject';
import { MappedOrg, Organization } from 'models/Organization';
import { apiGet } from 'apis/baseAPI';
import { ApiError, ErrorEmpty } from 'models/Errors';

const name = 'listOrgAdmin';

type OrgsSelectState = StateType<{
  orgs: MappedOrg[];
}>;

const initialState = {
  data: {
    orgs: [],
  },
  error: '',
  status: THUNK_STATUS.LOADING,
} as OrgsSelectState;

export const getOrgs = createAsyncThunk(
  `${name}/getOrgs`,
  async (_, { rejectWithValue }) => {
    try {
      return await apiGet<Organization[]>({
        url: 'portal/org/admin/list',
        apiVersion: 'v2',
        reduxActionName: `${name}/getOrgs`,
      });
    } catch (e) {
      return rejectWithValue(parseError(e as ApiError) || ErrorEmpty);
    }
  },
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state: OrgsSelectState) {
      state.error = '';
      state.status = THUNK_STATUS.IDLE;
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getOrgs.pending as never]: (state: OrgsSelectState) => {
      state.status = THUNK_STATUS.LOADING;
    },
    [getOrgs.fulfilled as never]: (state: OrgsSelectState, action) => {
      state.status = THUNK_STATUS.SUCCEEDED;
      const convertedOrgs: MappedOrg[] = action.payload.data.map(
        (i: Organization) => ({
          ...i,
          key: i.id,
          title: '',
        }),
      );
      state.data.orgs = arrayToTree<MappedOrg>(convertedOrgs);
    },
    [getOrgs.rejected as never]: (state: OrgsSelectState, action) => {
      state.status = THUNK_STATUS.FAILED;
      state.error = action.payload;
    },
  },
});

const selector = (state: AppState) =>
  state.organization[name] as OrgsSelectState;

export const { reset } = slice.actions;

export const selectData = createSelector(selector, (state) => state.data.orgs);

export const selectLoading = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.LOADING,
);

export const selectSuccess = createSelector(
  selector,
  (state) => state.status === THUNK_STATUS.SUCCEEDED,
);
export const selectError = createSelector(selector, (state) => state.error);

export default slice.reducer;
