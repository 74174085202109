import { apiPost } from '../baseAPI';
import { APP_DOMAIN } from '../../constants/configs';

export const forgotPassword = ({ email }: { email: string }) => {
  email = email.trim();
  return apiPost({
    method: 'POST',
    url: `portal/account/reset_password/init`,
    headers: {
      'Content-Type': 'application/json',
    },
    apiVersion: 'v2',
    data: {
      email,
    },
    diffDomain: `${APP_DOMAIN}/api`,
    isPrivate: false,
  });
};
