import { actions as voicelinkCallHistory } from 'page/private/VoicelinkDetail/components/PotSection/data/realtimeCallHistory';
import { actions as routerCallHistory } from 'page/private/RouterDetail/components/PotSection/data/realtimeCallHistory';
import { queryClient } from 'index';
import { API_QUERY_KEY } from 'apis/ApiQueryKey';

const callHistoryHandler = (_t: string, m: Buffer) => {
  const payload = JSON.parse(m.toString());
  voicelinkCallHistory.addCallHistory(JSON.parse(payload.value));
  routerCallHistory.addCallHistory(JSON.parse(payload.value));

  queryClient.refetchQueries([
    API_QUERY_KEY.ROUTER.VOICE_LINK.GET_CALL_SUMMARY,
  ]);
};

export { callHistoryHandler };
