import { ChangeEvent, forwardRef, Ref } from 'react';
import { Input as AntdInput, InputProps } from 'antd';
import styled, { css } from 'styled-components';
import { commonStyles } from './styled';

import AppIcon from 'components/atoms/SvgIcon';
import { theme } from 'theme/theme';

const { Password } = AntdInput;

type InputVariant = 'text' | 'password' | 'search';

type InputSize = 'small' | 'normal';

export type Props = Omit<InputProps, 'size'> & {
  variant?: InputVariant;
  inputSize?: InputSize;
  onClear?: () => void;
  restrictedPattern?: RegExp;
  showSearchIcon?: boolean;
};

const smallSizedInput = css`
  font-size: 14px;
  line-height: 16px;
  padding: 6px 8px 4px;

  &.ant-input-affix-wrapper > input.ant-input {
    line-height: 16px;
  }
`;

const normalSizedInput = css`
  font-size: 14px;
  line-height: 20px;

  &.ant-input-affix-wrapper > input.ant-input {
    line-height: 20px;
  }
`;

const StyledText = styled(AntdInput)<{ $inputSize: InputSize }>`
  ${commonStyles}
  ${(props) =>
    props.$inputSize === 'normal' ? normalSizedInput : smallSizedInput}
`;

const StyledPassword = styled(Password)<{ $inputSize: InputSize }>`
  ${commonStyles}
  .ant-input-password-icon {
    height: 20px;
  }

  ${(props) =>
    props.$inputSize === 'normal' ? normalSizedInput : smallSizedInput}
`;

const StyledSearch = styled(AntdInput)<{ $inputSize: InputSize }>`
  ${commonStyles}
  ${(props) =>
    props.$inputSize === 'normal' ? normalSizedInput : smallSizedInput}
  border: 1px solid ${theme.colors.grayscale_200};

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-focused):hover {
    border: 1px solid ${theme.colors.grayscale_200};
  }
`;

const StyledIconClear = styled.div`
  cursor: pointer;
  display: flex;
`;

const RenderInput = (
  {
    inputSize = 'normal',
    showSearchIcon = true,
    onClear,
    ...props
  }: Omit<Props, 'variant'>,
  ref: Ref<AntdInput>,
): Record<InputVariant, JSX.Element> => ({
  password: (
    <StyledPassword
      $inputSize={inputSize}
      ref={ref}
      {...props}
      iconRender={(visible) =>
        visible ? (
          <span className="ant-input-password-icon">
            <AppIcon
              name="eye_flash_fill"
              color={theme.colors.grayscale_600}
              size={20}
            />
          </span>
        ) : (
          <span className="ant-input-password-icon">
            <AppIcon
              name="eye_fill"
              size={20}
              color={theme.colors.grayscale_600}
            />
          </span>
        )
      }
    />
  ),
  search: (
    <StyledSearch
      $inputSize={inputSize}
      ref={ref}
      {...props}
      prefix={
        showSearchIcon && (
          <AppIcon name="search" size={20} color={theme.colors.grayscale_600} />
        )
      }
      suffix={
        onClear &&
        props.value && (
          <StyledIconClear onClick={onClear}>
            <AppIcon
              name="close"
              size={20}
              color={theme.colors.grayscale_600}
            />
          </StyledIconClear>
        )
      }
    />
  ),
  text: <StyledText $inputSize={inputSize} ref={ref} {...props} />,
});

const Input = forwardRef<AntdInput, Props>(
  (props, ref): JSX.Element => {
    const {
      variant = 'text',
      inputSize = 'normal',
      restrictedPattern,
      onChange,
      ...rest
    } = props;

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        if (restrictedPattern) {
          e.target.value = e.target.value.replace(restrictedPattern, '');
          onChange(e);
        } else {
          onChange(e);
        }
      }
    };

    return RenderInput({ inputSize, onChange: _onChange, ...rest }, ref)[
      variant
    ];
  },
);

export default Input;
