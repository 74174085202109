import { Modal as AntModal, ModalProps } from 'antd';
import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import { theme } from 'theme/theme';

import AppIcon from '../../atoms/SvgIcon';

const StyledModal = styled(AntModal)`
  .ant-modal-title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }
  .ant-modal-content {
    border-radius: 4px;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
  }

  .ant-modal-header {
    border-bottom: none;
    border-radius: 4px;
    padding: 10px 14px 10px 10px;
  }

  .ant-modal-body {
    padding: 20px;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 0 20px 20px 20px;
  }

  .ant-modal-close {
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
    .ant-modal-close-x {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

type Props = PropsWithChildren<Omit<ModalProps, 'maskClosable'>>;

const Modal = ({
  children,
  afterClose,
  centered = true,
  ...props
}: Props): JSX.Element => {
  const _afterClose = () => {
    afterClose && afterClose();
    // the toggle button for the modal will be focused back when it is closed
    // by blurring it will fix :focus style on Button element
    if (
      document &&
      (document.activeElement as HTMLElement).nodeName === 'BUTTON'
    )
      (document.activeElement as HTMLElement).blur();
  };

  return (
    <StyledModal
      {...props}
      afterClose={_afterClose}
      maskClosable={false}
      centered={centered}
      destroyOnClose
      closeIcon={
        <AppIcon name="close" color={theme.colors.grayscale_600} size={20} />
      }
      maskStyle={{ background: 'rgba(0, 0, 0, 0.3)' }}
    >
      <ErrorBoundary fallbackRender={() => null}>{children}</ErrorBoundary>
    </StyledModal>
  );
};

export { Modal };
