import { createGlobalStyle } from 'styled-components';
import { utilitySpacing, utilityGap } from './spacing';
import flexBox from './flexbox';
import {
  JustifyContent,
  JustifyItems,
  JustifySelf,
  AlignContent,
  AlignItems,
  AlignSelf,
} from './boxAlignment';
import customStyles from './customStyles';
import { tooltipStyle } from './tooltipStyle';
import { treeSelectStyle } from './treeSelectStyle';
import { switchStyle } from './switchStyle';
import { utilStyle } from './util';
import { CustomNotification } from './customMQTTNotification';
import { DatePickerCustomStyle } from './datePickerCustom';

const GlobalStyle = createGlobalStyle`
  ${utilitySpacing}
  ${utilityGap}
  ${flexBox}
  ${JustifyContent}
  ${JustifyItems}
  ${JustifySelf}
  ${AlignContent}
  ${AlignItems}
  ${AlignSelf}
  ${tooltipStyle}
  ${treeSelectStyle}
  ${switchStyle}
  ${customStyles}
  ${utilStyle}
  ${CustomNotification}
  ${DatePickerCustomStyle}
`;

export default GlobalStyle;
