import { css } from 'styled-components';
import { theme } from './theme';

export const popoverStyle = css`
  // simplifi customized popover
  .ant-popover-inner {
    border-radius: 6px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
    border: 1px solid ${theme.colors.grayscale_200};

    .ant-popover-inner-content {
      padding: 12px 31px 22px 12px;

      > * {
        &:first-child {
          position: relative;
          max-height: 144px;
          overflow: auto;
        }
      }
    }
  }

  .ant-popover-arrow {
    display: none;
  }

  padding: 0;
`;

export const avatarGroupStyle = css` 
    padding-top: 0;
    width: 320px;
    max-height: 221px;

    .ant-popover-arrow {
      display: none
    }
    
    .ant-popover-inner-content {
      padding: 12px;
      > * {
        &:first-child {
          position: relative;
          max-height: 221px;
          overflow: auto;
        }
    }
`;
