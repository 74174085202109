import { cleanEnv, str, num } from 'envalid';
import {
  getDeviceId,
  getPlatform,
  getPlatformVersion,
  getLocale,
  getDevice,
} from 'utils/token';

export const env = cleanEnv(process.env, {
  REACT_APP_ENV: str(),
  REACT_APP_API_URL: str(),
  REACT_APP_AUTH_URL: str(),
  REACT_APP_API_VERSION: str(),
  REACT_APP_CLIENT_ID: str(),
  REACT_APP_CAPTIVE_PORTAL_URL: str(),
  REACT_APP_PROXY_DOMAIN: str(),
  REACT_APP_SUPPORT_DOMAIN: str(),
  REACT_APP_MQTT_HOST: str(),
  REACT_APP_MQTT_PORT: num(),
});

export const defaultHeaders = {
  'X-Device-Id': getDeviceId(),
  'X-Device-Family': getDevice(),
  'X-Device-Os': getPlatform(),
  'X-Device-OS-Version': getPlatformVersion(),
  'X-Device-Locale': getLocale(),
  'X-App-Version': localStorage.getItem('APP_VERSION'),
};

export const BASE_AUTH_URL = env.REACT_APP_AUTH_URL;
export const PROXY_DOMAIN = env.REACT_APP_PROXY_DOMAIN;
export const APP_DOMAIN = env.REACT_APP_API_URL;
export const API_VERSION = env.REACT_APP_API_VERSION;
export const CLIENT_ID = env.REACT_APP_CLIENT_ID;
export const AUTH_URL = `${BASE_AUTH_URL}/api/${API_VERSION}/oauth`;
export const API_URL = `${env.REACT_APP_API_URL}/api/${API_VERSION}/portal`;
export const API_PROFILE_URL = `${env.REACT_APP_API_URL}/api/${API_VERSION}/profile`;
export const SUPPORT_DOMAIN = env.REACT_APP_SUPPORT_DOMAIN;
export const REACT_APP_MQTT_HOST = env.REACT_APP_MQTT_HOST;
export const REACT_APP_MQTT_PORT = env.REACT_APP_MQTT_PORT;
export const APP_ENVIRONMENT = env.REACT_APP_ENV;
